import { Component, ElementRef, ViewChild } from '@angular/core';
import { EventosService } from '../../services/eventos.service';
import { PaginationOptions } from '../../models/paginationOptions';
import { EventoFilter } from '../../models/eventoFilter';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  Input,
  initTE,
  Select,
  Datetimepicker
} from "tw-elements";
import Swal from 'sweetalert2';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { FormControl } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Toast } from 'src/app/base/services/toast';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent {
  faSpinner = faSpinner;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: PaginationOptions = new PaginationOptions();
  filtros: EventoFilter = new EventoFilter();
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  pageIndex = 1;
  length = 0;
  isLoading!: boolean;
  estaDescargandoExcel!:boolean;
  eventos: any;
  tipoEventos: { id:number, nombre:string }[];
  tipoObjetoAfectado: { id:number, nombre:string }[];
  targetEvent: any;
  filterControl = new FormControl();
  customDropdown = new FormControl();
  pickerInicio:any;
  pickerFin: any;
  today = new Date(); 
  minDate = new Date("Saturday, 1 January 2000 0:00:00");


  constructor(
    private eventosSvc: EventosService,
    private errorMensaje: ErrorMensajeService
    ){}

  ngOnInit(): void {
    initTE({ Input, Select, Datetimepicker});
    this.consultaFiltros();
    this.consultaEventos();
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }

  consultaEventos() {
    this.isLoading = true;
    
    this.eventosSvc.consultarEventos(this.options, this.filtros).subscribe({
      next : (response:any) => {
        this.eventos = response.list;
        this.dataSource = new MatTableDataSource<any>(response.list);
        this.pageIndex = (response.pageNum - 1);
        this.length = response.total;
        this.isLoading = false;
      },
      error: (error: any) => {
        this.errorMensaje.show(error, "obtener los eventos").then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            this.isLoading = false;
          }
        });
      }
    });
  }

  descargarExcel(): void {
   const filename = "listaEventos.csv"
    this.estaDescargandoExcel = true;

    this.eventosSvc.getFile( this.filtros )
    .subscribe(
      {
        next: (response: any) => {
          Toast.fire(
            "Descarga de eventos",
            "Descarga finalizada",
            "success"
          );
          this.eventosSvc.downloadBlob(filename, response);
          this.estaDescargandoExcel = false;
        }, error: (error: any) => {
          this.errorMensaje.show(error, "descargar los eventos");
          this.estaDescargandoExcel = false;
        }
      });
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.consultaEventos();
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 10;
    this.targetEvent = null;
    this.consultaEventos();
  }

  public limpiar(): void {
    this.filtros = new EventoFilter();
    this.filtrar();
  }

  obtenerEvento(evento: any) {
    this.targetEvent = evento;
  }

  consultaFiltros() {
    this.eventosSvc.consultarFiltros().subscribe({
      next : (response:any) => {
        this.tipoEventos = response.tipo_evento.map((item: { id:number, nombre:string }) => this.eliminaPrefijoObj(item));
        this.tipoObjetoAfectado = response.tipo_objeto_afectado.map((item: { id:number, nombre:string }) => this.eliminaPrefijoObj(item));
        
      },
      error: (error: any) => {
        this.errorMensaje.show(error, "obtener los filtros de eventos").then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            this.isLoading = false;
          }
        });
      }
    });
  }

  formatearFecha(f: string): string {
    function padTo2Digits(num:any) {
      return num.toString().padStart(2, '0');
    }
    const date = new Date(f);
    const fecha = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')

    return fecha;
  }

  eliminaPrefijoObj( item:any) : any {
    item.nombre = this.eliminaPrefijo(item.nombre);

    return item;
  }

  eliminaPrefijo(nombre: any): string{
    if (!nombre) return "";
    nombre = nombre.replace("ACTION_","").replace("OBJECT_TYPE_","");

    return nombre ;
  }

  obtenerObjetoJSON (json:any):any {
    if (typeof(json) == "object") return json;

    json= json.split('\"').join('"');
    let jsonObjecto = JSON.parse(json);

    return jsonObjecto;
  }


  obtenerKeys(json:any):string[] {
    const obj = this.obtenerObjetoJSON(json);
    return Object.keys(obj);
  }

  obtenerValues(json:string, key: string):any {
    return this.obtenerObjetoJSON(json)[key];
  }

  onSelectChangeTipoObjetoAfectado(valor:any) {
    if (!valor) return;
    this.filtros.idTipoObjetoAfectado = valor.id;
  }

  onSelectChangeTipoEvento(valor:any) {
    if (!valor) return;
    this.filtros.idTipoEvento = valor.id;
  }

  limpiarTipoObjetoAfectado() {
    this.filtros.idTipoObjetoAfectado = null;
  }
  limpiarTipoEvento() {
    this.filtros.idTipoEvento = null;
  }
  nombreTipoEventoSeleccionado(): string {
    if (!this.filtros.idTipoEvento) return "Muestro el nombre del tipo de evento seleccionado";
    return this.tipoEventos.find(item => item.id == this.filtros.idTipoEvento)?.nombre;
  }
  nombreTipoObjetoAfectadoSeleccionado(): string {
    if (!this.filtros.idTipoObjetoAfectado) return "Muestro el nombre del tipo de Objeto afectado seleccionados";
    return this.tipoEventos.find(item => item.id == this.filtros.idTipoObjetoAfectado)?.nombre;
  }
  esObjeto(test:any) {
   return typeof(test) == "object";
  }
  esArray(test:any) {
    return Array.isArray(test);
   }
}
