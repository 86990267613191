export class InfoSuspensionEximicion {
    rut: number;
    motivo: string;
    nombreMotivo: string;
    estado: string;
    fechaActualizacion: Date;
    fechaCreacion: Date;
    archivoSolicitud: string;
    archivoExtra: string;
    archivoConfirmacion: string;
    archivoObligatorio: string;
  
    constructor(){
        this.rut = null;
        this.motivo = null;
        this.nombreMotivo = null;
        this.estado = null;
        this.fechaActualizacion = null;
        this.fechaCreacion = null;
        this.archivoSolicitud = null;
        this.archivoExtra = null;
        this.archivoConfirmacion = null;
        this.archivoObligatorio = null;
    }
  }