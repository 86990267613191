import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { RubricaRevisionService } from "../../../services/rubrica-revision.service";
import { AlertsService } from "../../../../../../base/services/alerts.service";
import { Toast } from 'src/app/base/services/toast';
import { AuthService } from "../../../../../../base/services/auth.service";

interface AlternativasPregunta {
  id: number;
  respuesta: string;
}

interface Pregunta {
  rubricaId: number;
  bloqueado: boolean;
  pregunta: string;
  descripcion: string;
  alternativas: AlternativasPregunta[];
  respuesta: { idRubrica: number; idRespuesta: number } | null;
}

interface Respuesta {
  rubricaId: number;
  respuesta: number | null;
}

@Component({
  selector: 'app-rubrica-revision',
  templateUrl: './rubrica-revision.component.html',
  styleUrls: ['./rubrica-revision.component.css']
})
export class RubricaRevisionComponent implements OnInit {
  @Input() rutDocente!: number;
  @Input() asignadoAEsteOperador: boolean = true;
  @Output() successfulSaved = new EventEmitter<void>();

  isSaving = false;
  preguntas: Pregunta[] = [];
  respuestas: Respuesta[] = [];
  isLoading = false;
  readonlyUser = false;
  readonly = false;
  respuestaTemporal: { rubricaId: number; respuesta: number | null } | null = null;

  constructor(
    private rubricaService: RubricaRevisionService,
    private alerts: AlertsService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.readonlyUser = this.authService.getUserGroup().includes("READER_GRABACIONES");
    if (this.readonlyUser) {
      this.readonly = true;
    }
    this.cargarPreguntas();
  }

  cargarPreguntas(): void {
    this.isLoading = true;
    this.rubricaService.obtenerRubricaPorRutDocente(this.rutDocente).subscribe(
      (preguntas: Pregunta[]) => {
        this.preguntas = preguntas;
        this.inicializarRespuestas();
        this.isLoading = false;
      },
      (error: any) => {
        this.alerts.showExceptionAlert(error);
        this.isLoading = false;
      }
    );
  }

  inicializarRespuestas(): void {
    this.respuestas = this.preguntas.map((pregunta) => ({
      rubricaId: pregunta.rubricaId,
      respuesta: pregunta.respuesta ? pregunta.respuesta.idRespuesta : null
    }));
  }

  getRespuesta(rubricaId: number): number | null {
    const respuesta = this.respuestas.find(res => res.rubricaId === rubricaId);
    return respuesta ? respuesta.respuesta : null;
  }

  setRespuesta(rubricaId: number, idRespuesta: number): void {
    if (this.readonly) {
      return;
    }

    // Guarda el valor actual antes de actualizarlo
    this.respuestaTemporal = {
      rubricaId: rubricaId,
      respuesta: this.getRespuesta(rubricaId)
    };

    const respuesta = this.respuestas.find(res => res.rubricaId === rubricaId);
    if (respuesta) {
      respuesta.respuesta = idRespuesta;
    } else {
      this.respuestas.push({ rubricaId, respuesta: idRespuesta });
    }

    this.guardarRespuestas();
  }

  guardarRespuestas(): void {

    if(this.preguntas.filter(pregunta => pregunta.bloqueado == true).length > 0){
      const respuesta = this.respuestas.find(res => res.rubricaId === this.respuestaTemporal!.rubricaId);
      if (respuesta) {
        respuesta.respuesta = this.respuestaTemporal.respuesta;
      }
      this.respuestaTemporal = null; // Limpia la respuesta temporal después de restaurar
      return;
    }

    this.isSaving = true;
    const respuestasFormatoCorrecto = this.respuestas
      .filter((respuesta) => respuesta.respuesta !== null && respuesta.respuesta !== 0)
      .map((respuesta) => ({
        idRubrica: respuesta.rubricaId,
        idRespuesta: respuesta.respuesta as number
      }));

    this.rubricaService.guardarRespuestas(this.rutDocente, respuestasFormatoCorrecto).subscribe(
      () => {
        this.successfulSaved.emit()
        this.isSaving = false;
        Toast.fire("Guardado", "Respuestas guardadas exitosamente.", "success");
        this.respuestaTemporal = null; // Limpia la respuesta temporal si el guardado fue exitoso
      },
      (error: any) => {
        this.alerts.showExceptionAlert(error);
        this.isSaving = false;

        // Restaura la respuesta original si hay un error
        if (this.respuestaTemporal) {
          const respuesta = this.respuestas.find(res => res.rubricaId === this.respuestaTemporal!.rubricaId);
          if (respuesta) {
            respuesta.respuesta = this.respuestaTemporal.respuesta;
          }
          this.respuestaTemporal = null; // Limpia la respuesta temporal después de restaurar
        }
      }
    );
  }
}
