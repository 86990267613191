<div class="overflow-hidden left-0 content-center mx-10">
  <div aria-label="content">
    <div aria-label="portada" class="bg-portada bg-cover h-[650px] w-full relative rounded-xl  md:mt-0  lg:mt-16" >
    </div>

    <div class="absolute bottom-0 left-[300px]  sm:top-[460px] md:1/4 px-4 py-3 h-fit rounded-xl bg-primary-600/50  pr-4">
      <h1 class="text-white font-semibold text-4xl"> Bienvenida/o al nuevo portal </h1>
      <p class="text-xl text-gray-50  py-2 my-2">
        PORTAL OPERACIONES
      </p>

      <div aria-label="info usuario" class="text-gray-100">

        <p class="mb-2 truncate ">{{email}}</p>
        <div class="mb-2 flex ">
            <div class="text-[13px] font-bold" *ngFor="let item of grupos; let i=index">
              {{item}} <span class="text-xs px-2" *ngIf="i<grupos.length -1">|</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

