import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { Constants } from "src/app/base/constants/constants";
import { Region } from "../models/Region";
import { RegistroSeguimiento } from "../models/RegistroSeguimiento";
import { RegistroSeguimientoConOperador } from "../models/RegistroSeguimientoConOperador";
import { PaginationOptions } from "src/app/registros/models/paginationOptions";
import { AuthService } from "src/app/base/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class FichaSeguimientoService {
  constructor(
    private http: HttpClient,
    public authService: AuthService,
  ) { }

  getListaFichaSeguimiento(options: PaginationOptions,filtro: RegistroSeguimiento, fecha: string): Observable<any>{
    const params = this.obtenerParams(filtro, fecha);
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/ficha/?page=${options.page}&size=${options.size}`;
    return this.http.get(url, {params: params});
  }

  obtenerParams(filtro: RegistroSeguimiento, fecha: string) {
    let params = new HttpParams();

    if (filtro.numSeguimiento) {
      params = params.set('numSeguimiento', filtro.numSeguimiento);
    }
    if (filtro.rutSostenedor && filtro.rutSostenedor > 0) {
      params = params.set('rutSostenedor',filtro.rutSostenedor);
    }
    if (filtro.idRegion && filtro.idRegion > 0) {
      params = params.set('idRegion', filtro.idRegion);
    }
    if (filtro.comuna && filtro.comuna.trim() != "") {
      params = params.set('comuna', filtro.comuna);
    }
    if (filtro.idEstadoEnvio>=0 && filtro.idEstadoEnvio > -1) {
      params = params.set('idEstadoEnvio', filtro.idEstadoEnvio);
    }
    if (filtro.fechaEnvio) {
      params = params.set('fechaEnvio', fecha);
    }
    return params;
  }

  eliminar (ficha: RegistroSeguimiento): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/ficha/`;
    return this.http.delete(url,{
      params:{
        idFicha: ficha.idFicha,
        idNominaPrevio: ficha.idNominaPrevio ? ficha.idNominaPrevio : -1}
      });
  }

  nominasSostenedor(rutSostenedor: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/registro/nominas/${rutSostenedor}`;
    return this.http.get(url);
  }

  getRegiones(): Observable<any | Region[]>  {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/registro/regiones`;
    return this.http.get(url);
  }

  getComunas(): Observable<any | String[]> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/registro/comunas`;
    return this.http.get(url);
  }

  fichaTieneBulto(idFicha: number): Observable<any | Boolean | null> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/ficha/bulto/${idFicha}`;
    return this.http.get(url);
  }

  checkRutRepresentante(rutSostenedor: number): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/registro/checkRutRepresentante`;
    return this.http.get(url, {params: {rutSostenedor: rutSostenedor}});
  }

  checkNumeroSeguimiento(numero: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/registro/checkNumeroSeguimiento`;
    return this.http.get(url, {params: {numSeguimiento: `'${numero}'`}});
  }

  crearNuevaFicha(ficha: RegistroSeguimientoConOperador, fecha: string):  Observable<any>{
    let user = this.authService.getEmailUser();
    ficha.fechaEnvio = fecha;
    if (ficha.idEstadoEnvio == 0) ficha.fechaEnvio = null;
    ficha.operador = user;
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/ficha/`;
    return this.http.post(url, ficha);
  }

  editarFicha(ficha: RegistroSeguimiento):  Observable<any>{
    //ficha.fechaEnvio = fecha;
    if (ficha.idEstadoEnvio == 0) ficha.fechaEnvio = null;
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/ficha/`;
    return this.http.put(url, ficha);
  }

  docentesEnNomina(id: number) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/registro/docentes/nomina/${id}`;
    return this.http.get(url);
  }

  descargarFichas(url: string, filtro: RegistroSeguimiento, fecha: string): Observable<any> {
    let params = this.obtenerParams(filtro, fecha);
    return this.http.get(url, { responseType: 'blob', params: params }).pipe(
      map(response => response)
    );
  }

  downloadBlob(filename: string, blob: any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
