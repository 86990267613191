<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO style="flex-direction: row">
      <div class="flex flex-col gap-4 px-2">
        <p class="text-blue-950 text-2xl font-bold">Evaluaciones Históricas Extendida</p>
        <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
          <div class="flex flex-row gap-1 py-2">
            <mat-icon>filter_list</mat-icon>
            <p class="text-lg font-bold">Filtros</p>
          </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label>RUT Docente</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutDocente" name="rutDocente" />
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label>RBD</mat-label>
              <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345" class="fullWidth" [(ngModel)]="filtros.rbd" />

            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label class="fullWidth">Comuna</mat-label>

              <mat-select [(ngModel)]="filtros.comuna" placeholder="Comuna">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let item of comunas" [value]="item">{{item}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label>Año Portafolio</mat-label>
              <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="1234" class="fullWidth" [(ngModel)]="filtros.anioPortafolio" />

            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label>Tramo</mat-label>
              <mat-select [(ngModel)]="filtros.tramo" placeholder="Beneficios">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let item of tramo" [value]="item">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label class="fullWidth">Beneficios</mat-label>

              <mat-select [(ngModel)]="filtros.beneficios" placeholder="Beneficios">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let item of beneficios" [value]="item">{{item}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label class="fullWidth">DEPROV</mat-label>

              <mat-select [(ngModel)]="filtros.deprov" placeholder="Deprov">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let item of deprov" [value]="item">{{item}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>

        </div>

        <div class="d-flex">
          <div class="float-left d-flex  align-self-start ">
            <mat-card-actions class="d-flex row">
              <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>

              <button
                  matTooltip="Descarga esta tabla completa"
                  type="button"
                  data-te-ripple-init
                  (click)="descargarExcel()"
                  data-te-ripple-color="light"
                  class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                  <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                  <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                  <div *ngIf="!estaDescargandoExcel" class="text-[14px]">Descargar</div>
                  <div *ngIf="estaDescargandoExcel" class="text-[14px]">Descargando</div>
              </button>

              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" class="pr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                Limpiar
              </button>
            </mat-card-actions>
          </div>
          <div class="float-right ms-auto my-auto d-flex justify-content-end">
            <mat-card-actions>
              <div class="dropdown">
                <button color="primary"
                data-te-ripple-color="light"
                type="button"
                data-te-ripple-init
                class="flex w-60 justify-items-center justify-center text-center items-center rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                  id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style="float: right;">
                  <svg xmlns="http://www.w3.org/2000/svg" class="px-2" width="16" height="16" fill="currentColor" class="bi bi-layout-three-columns" viewBox="0 0 16 16"> <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5H5V1H1.5zM10 15V1H6v14h4zm1 0h3.5a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H11v14z"/> </svg>
                  <p class="px-1"></p> Editar columnas <p class="px-1"></p>
                  <svg xmlns="http://www.w3.org/2000/svg" class="px-2"width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <div class="container" style="width: 400px;">
                    <div class="row">
                      <div class="col" style="flex-direction: row;">
                        <div *ngFor="let item of toggleColumns" class="form-check">
                          <input [(ngModel)]="item.value" checked class="form-check-input" type="checkbox"
                            [id]="item.key">
                          <label class="form-check-label" [for]="item.key">{{item.name}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-actions>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table class="table table-striped">
      <thead>
        <tr style="font-size: 0.8em;">
          <th scope="col" [style.width.px]="100">{{nameDisplayedColumns[0]}}</th>
          <th scope="col" *ngIf="this.toggleColumns[0].value">{{nameDisplayedColumns[1]}}</th>
          <th scope="col" *ngIf="this.toggleColumns[1].value">{{nameDisplayedColumns[2]}}</th>
          <th scope="col" [style.width.px]="130" *ngIf="this.toggleColumns[2].value">{{nameDisplayedColumns[3]}}</th>
          <th scope="col" *ngIf="this.toggleColumns[3].value">{{nameDisplayedColumns[4]}}</th>
          <th scope="col" *ngIf="this.toggleColumns[3].value">{{nameDisplayedColumns[5]}}</th>
          <th scope="col" *ngIf="this.toggleColumns[4].value">{{nameDisplayedColumns[6]}}</th>
          <th scope="col" *ngIf="this.toggleColumns[5].value">{{nameDisplayedColumns[7]}}</th>
          <th scope="col" style="min-width: 100px;" *ngIf="this.toggleColumns[6].value">{{nameDisplayedColumns[8]}}</th>
          <th scope="col">{{nameDisplayedColumns[9]}}</th>
          <th scope="col" *ngIf="this.toggleColumns[7].value">{{nameDisplayedColumns[10]}}</th>
          <th scope="col">{{nameDisplayedColumns[11]}}</th>
          <th scope="col" *ngIf="this.toggleColumns[8].value">{{nameDisplayedColumns[12]}}</th>
          <th scope="col" style="min-width: 150px;" *ngIf="this.toggleColumns[9].value">{{nameDisplayedColumns[13]}}</th>
          <th scope="col" style="min-width: 150px;" *ngIf="this.toggleColumns[10].value">{{nameDisplayedColumns[14]}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="etiqueta-azul" *ngFor="let element of response">
          <td class="negrita-tabla"> {{element.rutDocente}}</td>
          <td class="negrita-tabla" style="text-align: center;" *ngIf="this.toggleColumns[0].value">{{element.experiencia}}</td>
          <td class="negrita-tabla" style="text-align: center;" *ngIf="this.toggleColumns[1].value">{{element.bienios}}</td>
          <td class="negrita-tabla" style="text-align: center;" *ngIf="this.toggleColumns[2].value">{{element.anioPortafolio}}</td>
          <td class="negrita-tabla" *ngIf="this.toggleColumns[3].value">{{element.tramo}}</td>
          <td class="negrita-tabla" >{{element.proceso}}</td>
          <td class="negrita-tabla" style="text-align: center;" *ngIf="this.toggleColumns[4].value">{{element.puntajePf}}</td>
          <td class="negrita-tabla" *ngIf="this.toggleColumns[5].value">{{element.nivelDesem}}</td>
          <td class="negrita-tabla" style="text-align: center;" *ngIf="this.toggleColumns[6].value">{{element.catLogro}}</td>
          <td class="negrita-tabla" >{{element.estado}}</td>
          <td class="negrita-tabla" *ngIf="this.toggleColumns[7].value">{{element.comuna}}</td>
          <td class="negrita-tabla" >{{element.rbd}}</td>
          <td class="negrita-tabla" *ngIf="this.toggleColumns[8].value">{{element.deprov}}</td>
          <td class="negrita-tabla" style="white-space: pre-line" *ngIf="this.toggleColumns[9].value">{{element.beneficio70ter?.replace(",",",\n")}}</td>
          <td class="negrita-tabla" style="white-space: pre-line" *ngIf="this.toggleColumns[10].value">{{element.beneficio19egne?.replace(",",",\n")}}</td>

        </tr>
      </tbody>
    </table>
  </div>
  <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
  </mat-paginator>
</mat-card>
