import { HttpClient,  HttpEventType,  HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Constants } from 'src/app/base/constants/constants';
import { PaginationOptions } from '../models/paginationOptions';
import { MantenedorUsuarios } from '../models/mantenedorUsuarios';

@Injectable({
  providedIn: 'root'
})
export class MantenedorService {
  estaDescargandoExcelEmmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private http: HttpClient
  ) { }

  emitirEstadoDescargaUsuarios(estado: boolean) {
    this.estaDescargandoExcelEmmit.emit(estado);
  }

  getUsuarios(options:PaginationOptions, filter: MantenedorUsuarios): 
    Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.LISTAR}/`;
    return this.http.get(url, {params:{page: options.page, size: options.size}}).pipe(
      map((response: any) => response));
  }

  getUsuariosFiltro(options:PaginationOptions, 
    rut:number=null,
    roles:string[]=null,): 
    Observable<any> {
      let params = this.obtenerParams(rut, roles);
      params = params
        .set('page', options.page)
        .set('size', options.size)

    const url = `${Constants.URL_SERVICE.MANTENEDOR_USUARIOS.LISTAR}`;
    return this.http.get(url, {params: params}).pipe(map((response: any) => response));
  }

  agregarUsuario(rut: number, tipoUsuario: String[]): Observable<any>{
    const url = `${Constants.URL_SERVICE.MANTENEDOR_USUARIOS.CREATE}`;
    const params = {"rut":rut, "tipoUsuario": tipoUsuario}
    return this.http.post(url, params).pipe(map((response: any) => response));
  }

  editarUsuario(rut: number, tipoUsuario: String[]): Observable<any>{
    const url = `${Constants.URL_SERVICE.MANTENEDOR_USUARIOS.UPDATE}`;
    const params = {"rut":rut, "tipoUsuario": tipoUsuario}
    return this.http.put(url, params).pipe(map((response: any) => response));
  }

  borrarUsuario(rut: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.MANTENEDOR_USUARIOS.DELETE}/${rut}`;
    return this.http.delete(url).pipe(map((response: any) => response));
  }

  listarRoles (): Observable<any> {
    const url = `${Constants.URL_SERVICE.MANTENEDOR_USUARIOS.ROLELIST}`;
    return this.http.get(url).pipe(map((response: any) => response));
  }

  obtenerParams(rut:any,tipoUsuario:any): HttpParams {
    let params = new HttpParams();

      if (rut && rut > 0) {
        params = params.set('rut',rut);
      }
      if (tipoUsuario && tipoUsuario.length > 0) {
        params = params.set('tipoUsuario',tipoUsuario);
      }
      return params;
  }
  descargaUsuarios(rut: number, roles: string[]): Observable<any> {
    this.emitirEstadoDescargaUsuarios(true);
      const params = this.obtenerParams(rut,roles);
    const url = `${Constants.URL_SERVICE.MANTENEDOR_USUARIOS.DOWNLOAD}`;
    return this.http.get(url,
      {
        responseType: 'blob', 
        observe: 'events', 
        reportProgress: true,
        params: params
      });
  }
}