import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/base/constants/constants';
import { PaginationOptions } from 'src/app/base/models/paginationOptions';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { EncargadoEstablecimiento } from 'src/app/registros/models/encargadoEstablecimiento';
import { EncargadoEvaluacionFilter } from 'src/app/registros/models/encargadoEvaluacionFilter';
import { EstablecimientoEncargado } from 'src/app/registros/models/establecimientoEncargado';
import { DependenciaService } from 'src/app/registros/services/dependencia.service';
import { EncargadoEvaluacionService } from 'src/app/registros/services/encargado-evaluacion.service';
import { RegionService } from 'src/app/registros/services/region.service';
import Swal from 'sweetalert2';
import {
  Ripple,
  initTE,
} from "tw-elements";
import { Toast } from '../../../../base/services/toast';
import { EncargadoEdicionService } from '../services/EncargadoEdicion.service';


@Component({
  selector: 'app-registro-encargado-evaluacion-list',
  templateUrl: './registro-encargado-evaluacion-list.component.html',
  styleUrls: ['./registro-encargado-evaluacion-list.component.css', '../../../modulo_registro.css']
})
export class RegistroEncargadoEvaluacionListComponent implements AfterViewInit {
  faSpinner = faSpinner;
  displayedColumns: string[] = ['rut', 'rutSostenedor', 'nombre', 'primerApellido',
    'segundoApellido', 'correo', 'ultimoAcceso', 'acciones'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  dataSource: MatTableDataSource<EncargadoEstablecimiento> = new MatTableDataSource();
  pageIndex: number;
  length: number;
  descargandoCsv!: boolean;
  filtros: EncargadoEvaluacionFilter = new EncargadoEvaluacionFilter();
  getEncargadosSub: Subscription;
  response: any;
  options: PaginationOptions = new PaginationOptions();
  dependencias: Dependencia[] = [];
  regiones: Region[] = [];
  agregarEncargado: boolean;
  editarEncargado: boolean;
  borrarEncargado: boolean;
  encargadoSeleccionado!: any;
  queryRBD:number = null;
  establecimientosEncargado!: any[];

  encargadoDataSection: { key: string, value: string }[] = [
    { key: 'rut', value: 'Rut' },
    { key: 'rutEmpresa', value: 'Empresa' },
    { key: 'nombre', value: 'Nombres' },
    { key: 'primerApellido', value: 'Primer Apellido' },
    { key: 'segundoApellido', value: 'Segundo Apellido' },
    { key: 'correo', value: 'Contacto General' },
    { key: 'ultimoAcceso', value: 'Últ. Acceso' },
    { key: 'acciones', value: 'Acciones' },
  ]
  encargadoDetalles : { key: string, value: string }[] = [
    { key: 'rut', value: 'Rut' },
    { key: 'nombre', value: 'Nombres' },
    { key: 'primerApellido', value: 'Primer Apellido' },
    { key: 'segundoApellido', value: 'Segundo Apellido' },
    { key: 'correo', value: 'Correo' },
    { key: 'telefono', value: 'Teléfono' },
    { key: 'rutSostenedor', value: 'Rut Sostenedor' },
    { key: 'nombreSostenedor', value: 'Nombre Empresa' },
    { key: 'ultimoAcceso', value: 'Último Acceso' },
  ]
  establecimientoDetalles : { key: string, value: string }[] = [
    { key: 'nombre', value:"Nombre"},
    { key: 'region', value:"Región"},
    { key: 'comuna', value:"Comuna"},
  ]

  keyValueTable: { key: string, value: string }[] = this.encargadoDataSection

  displayedColumnsTable: { [key: string]: string } = this.keyValueTable.reduce((acc: { [key: string]: string }, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});

  nameDisplayedColumns: string[] = Object.values(this.displayedColumnsTable);


  constructor(
    private encargadoEvaluacionService: EncargadoEvaluacionService,
    private dependenciaService: DependenciaService,
    private regionService: RegionService,
    private route: ActivatedRoute,
    @Inject(Router) private router: Router,
    private permisoPara: AutorizacionesService,
    private errorMensaje: ErrorMensajeService,
    private encargadoEdicionSvc: EncargadoEdicionService,
  ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {
    initTE({ Ripple });
    this.agregarEncargado = this.permisoPara.agregarEncargadoEvaluacion();
    this.editarEncargado = this.permisoPara.editarEncargadoEvaluacion();
    this.borrarEncargado = this.permisoPara.eliminarEncargadoEvaluacion();
    this.encargadoSeleccionado = null;

    const acciones = !this.editarEncargado && !this.borrarEncargado;
    if (acciones){
      this.displayedColumns.splice(this.displayedColumns.indexOf("acciones"))
      this.encargadoDataSection.splice(0,this.encargadoDataSection.length - 1);
    }
    this.filtros = new EncargadoEvaluacionFilter();
    this.getDependencias();
    this.getRegiones();
    this.getEncargados();
  }

  setEncargadoSeleccionado(encargado:  any) {
    this.encargadoSeleccionado = encargado;
    this.establecimientosEncargado = encargado.establecimientos;
    this.queryRBD = null;
  }

  getDependencias(): void {
    this.dependenciaService.dependenciaList().subscribe(response => {
      this.dependencias = response;
    });
  }

  buscarRBD(event: any) {
    const query = event.target.value.toString();
    this.establecimientosEncargado = this.encargadoSeleccionado.establecimientos.filter((item:EstablecimientoEncargado) => (item.rbd.toString().includes(query)));
  }

  getRegiones(): void {
    this.regionService.regionList().subscribe(response => {
      this.regiones = response;
    });
  }
  getEncargados(): void {
    this.isLoading = true;
    this.getEncargadosSub = this.encargadoEvaluacionService.encargadosEvaluacionList(this.options, this.filtros).subscribe(
      {
        next: (response: any) => {
          this.dataSource = new MatTableDataSource<EncargadoEstablecimiento>(response.list);
          this.response = response.list;
          this.pageIndex = (response.pageNum - 1);
          this.length = response.total;
          this.isLoading = false;
        },
        error: error => this.errorMensaje.show(error, "obtener los Encargados de Evaluación").then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            this.isLoading = false;
          }
        })
      });
  }


  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getEncargados();
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 10;
    this.getEncargados();
  }

  public limpiar(): void {
    this.filtros = new EncargadoEvaluacionFilter();
    this.filtrar();
  }

  public descargarCsv(): void {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.ENCARGADOS_EVALUACION.DESCARGAR}`;
    const filename = "listaEncargadosEvaluación.csv"
    this.descargandoCsv = true;
    this.encargadoEvaluacionService.getFile(url, this.filtros).subscribe((response: any) => {
      this.encargadoEvaluacionService.downloadBlob(filename, response);
      this.descargandoCsv = false;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editar(encargado: EncargadoEstablecimiento) {
    this.encargadoEdicionSvc.pushScope(encargado);
    this.router.navigate(['/private/registro-encargados-evaluacion-form']);
  }

  eliminar(encargado: EncargadoEstablecimiento) {
    if (!encargado) {
      return;
    }
    Swal.fire({
      title: '¿Desea Eliminar Encargado?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminarEncargado(encargado)
      }
    })

  }

  eliminarEncargado(encargado: EncargadoEstablecimiento) {
    this.encargadoEvaluacionService.delete(encargado).subscribe({
      next: (response) => {
        this.getEncargados()
        Toast.fire({
          title: 'Eliminado!',
          text: 'Se ha eliminado el Encargado',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        this.limpiar();
      },
      error: (error) => {
        const mensaje = error.error.hasOwnProperty('error') ? error.error.error : 'Ha ocurrido un error al eliminar el/la encargado/a ';

        Toast.fire(
          "Error al eliminar este Encargado",
          mensaje + ' [' + error.status + "]",
          'error'
        );
      },
    });
  }

  nuevo() {
    this.router.navigate(['/private/registro-encargados-evaluacion-form']);
  }
}



export interface Dependencia {
  id: number;
  nombre: string;
  sector: Sector;
}

export interface Sector {
  id: number;
  nombre: string;
}

export interface Region {
  idRegion: number;
  nombre: string;
  simbolo: number;
  orden: number;
}
