import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

const OP_MDA = [
  'ficha-docente',
  'registro-encargados-evaluacion-list',
  'representantes-legales',
  'oficios',
  'evaluaciones-historicas',
  'registro-docente-list'
];
const ADM_MDA = [
  'ficha-docente',
  'registro-encargados-evaluacion-list',
  'representantes-legales',
  'oficios',
  'evaluaciones-historicas',
  'registro-docente-list',
  'mantenedor-usuarios-cognito-mda'
];
const OP_CPEIP = [
  'ficha-docente',
  'registro-encargados-evaluacion-list',
  'representantes-legales',
  'oficios',
  'evaluaciones-historicas',
  'evaluaciones-historicas-extendida',
  'registro-docente-list'
];
@Injectable({
    providedIn: 'root'
  })
  export class AutorizacionesService {

    constructor(private authService: AuthService){}

    endpointPermitidosParaEsteUsuario(): string[] {
      if ( this.containRol(['ROLE_ADMIN','ADM_REGISTRO'])) {
        return ['all']
      }
      if (this.containRol(['OP_MDA'])) {
        return OP_MDA;
      }
      if (this.containRol(['OP_CPEIP'])) {
        return OP_CPEIP;
      }
      if (this.containRol(['ADM_MDA'])) {
        return ADM_MDA;
      }
      else return null;
    }

    mostrarMenuMantenedorCognito(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }

    mostrarMenuEstadoDocentes(): boolean {
        return this.containRol(['ROLE_ADMIN','OP_MDA','OP_CPEIP','ADM_REGISTRO']);
    }
    mostrarMenuEncargados(): boolean {
        return this.containRol(['ROLE_ADMIN','OP_CPEIP','ADM_REGISTRO']);
    }
    mostrarMenuHistoricas(): boolean {
        return this.containRol(['ROLE_ADMIN','OP_MDA','OP_CPEIP','ADM_REGISTRO']);
    }
    mostrarMenuRepresentantesLegales(): boolean  {
        return this.containRol(['ROLE_ADMIN','OP_CPEIP','ADM_REGISTRO']);
    }
    mostrarMenuRecursosReposicion() :boolean {
        return this.containRol(['ROLE_ADMIN']);
    }
    mostrarMenuMantenedorUsuarios() : boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }
    mostrarMenuHistoricasExtendida(): boolean  {
      return this.containRol(['ROLE_ADMIN','OP_CPEIP','ADM_REGISTRO']);
  }

    /* ENCARGADOS */

    eliminarEncargadoEvaluacion(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }
    editarEncargadoEvaluacion(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }
    agregarEncargadoEvaluacion(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }
    descargaEncargadoEvaluacion(): boolean {
        return this.containRol(['ROLE_ADMIN','OP_CPEIP','ADM_REGISTRO']);
    }


    /* REPRESENTANTES LEGALES */

    eliminarRepresentanteLegal(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }
    editarRepresentanteLegal(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }
    agregarRepresentanteLegal(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }
    descargaRepresentanteLegal(): boolean {
        return this.containRol(['ROLE_ADMIN','OP_CPEIP','ADM_REGISTRO']);
    }

    /* MANTENEDOR DE USUARIOS */

    agregarUsuarioMantenedor(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }

    editarUsuarioMantenedor(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }

    borrarUsuarioMantenedor(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }

    descargaUsuarioMantenedor(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }

    containRol(roles:string[]):boolean {
        return this.authService.containRol(roles);
    }

    /* DOCENTES */

    permisoDescargarInformeDocente(): boolean {
      return this.containRol(['ROLE_ADMIN','ADM_REGISTRO','ADM_INSTRUMENTOS', 'ADM_MDA']);
    }

    permisoAccionesEstadoDocente(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }

    permisoModificaciones(): boolean {
        return this.containRol(['ROLE_ADMIN','ADM_REGISTRO']);
    }

}
