import { AfterContentChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Editor, Toolbar } from 'ngx-editor';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Toast } from 'src/app/base/services/toast';
import { Portafolio } from '../../models/portafolio';
import { CierreProcesoService } from '../../services/cierreProcesos.service';
;

@Component({
  selector: 'app-cierre-procesos',
  templateUrl: './cierre-procesos.component.html',
})
export class CierreProcesosComponent implements AfterContentChecked, OnInit, OnDestroy{
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic','underline'],
  ];
  faSpinner = faSpinner;
  help!: boolean;
  procesos!: string[];
  portafolio: Portafolio;
  isLoading!: boolean;
  isLoadingPortafolio: boolean = false;
  menuActivo!: string;
  @ViewChild('preview') previewPortafolio: ElementRef;
  initPreviewPortafolio = false;
  estaEditando: boolean = false;


  constructor(
    private errorMensaje: ErrorMensajeService,
    private procesosSvc: CierreProcesoService) { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.getModulos();
  }

  getModulos() {
    this.isLoading = true;
    this.procesosSvc.getModulos().subscribe(
      {next: (value: {modulos: string[]}) => {
          this.procesos = value.modulos;
          this.isLoading = false;
      },
      error: (err) => {
          this.errorMensaje.show(err, "obtener la lista de módulos.");
          this.isLoading = false;
      },
      }
    );
  }

  openHelper() {
    this.help = true;
  }

  closeHelper() {
    this.help = false;
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  ngAfterContentChecked(): void {
    if (!this.previewPortafolio) return;
    if (this.menuActivo )  this.initPreviewPortafolio = true;
    if (this.menuActivo && this.initPreviewPortafolio
      && this.previewPortafolio && this.portafolio && this.portafolio.message)  {
      this.initPreviewPortafolio = false;
      this.previewPortafolio.nativeElement.innerHTML = this.portafolio.message;
    }
  }

  getModulo(modulo: string) {
    this.menuActivo = modulo;
    this.isLoadingPortafolio = true;
    this.procesosSvc.getPortafolio(modulo).subscribe(

      (value: { portafolios: Portafolio[] }) => {
        if (!value.portafolios) return;
        this.portafolio = value.portafolios[0];
        this.isLoadingPortafolio = false;
      },
      (error: any) => {
        this.errorMensaje.show(error, "obtener el módulo de Docentemás");
        this.isLoadingPortafolio = false;
      },

    );
  }

  getWIP(menu: string) {
    this.portafolio = null;
    this.menuActivo = menu;
    Toast.fire("Work in progress", "Estamos trabajando en este proceso", "info");
  }

  guardar() {
    this.estaEditando = true;
    this.portafolio.modulo = this.menuActivo;
    this.procesosSvc.editarPortafolio(this.portafolio).subscribe(
      (value: any) => {
        Toast.fire("Portafolio Editado", "Sus cambios se han registrado en el sistema.","success");
        this.estaEditando = false;
      },
      (error: any) => {
        this.errorMensaje.show(error, "editar el portafolio");
        this.estaEditando = false;
      }
    );
  }
}

