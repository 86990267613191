import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistroApertura } from '../../models/registroApertura';
import { AperturaCajaService } from '../../services/apertura-caja.service';
import { SD } from '../../models/sd';
import { FocusTrackerService } from '../../services/focus-tracker.service';
import Swal from 'sweetalert2';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Bulto } from '../../models/Bulto';
import { FiltroApertura } from '../../models/filtroApertura';
import { CajaBulto } from '../../models/CajaBulto';

const codigoRegex = /[A-Z0-9]{5}['-][A-Z0-9]{2}[0-9]{5}/;
const MSJ_DOCENTE_NOT_FOUND = "El rut asociado no es un docente inscrito o no se encontró. Agregue el rut manualmente.";
const MSJ_QR_NOT_FOUND = "Este QR no existe en los registros. Agregue los datos manualmente";

@Component({
  selector: 'insertar-sd',
  templateUrl: './insertar-sd.component.html',
  styleUrls: ["insertar-sd.component.css"]
})
export class InsertarSdComponent implements AfterViewInit, OnInit, OnChanges {
  @Input("modoSD") modoSD: boolean;
  @Input("caja") caja: RegistroApertura;
  bultoActual!: CajaBulto;
  bultosEnCaja!: CajaBulto[];
  actualizarInfoCaja!: boolean;
  codigoBloqueado: boolean = true;
  rutBloqueado: boolean = true;
  autoenfoque: boolean = true;
  autoenfoqueBulto: boolean = true;
  archivoAnimacionPrincipal: string = "recepcionBulto.json";

  codigoForm: FormGroup;
  QRForm: FormGroup;
  @ViewChild("btnCerrarModalCajaBulto") btnCerrarModalCajaBulto: ElementRef;
  @ViewChild('inputQR') inputQR: ElementRef<HTMLInputElement>;
  lecturaSD!: string;

  consultandoQR!: boolean;
  peticionQR!: string;
  inputBuffer: string = '';
  listaCodigos: SD[] = [];
  listaCodigosFiltrada: SD[] = [];
  estadoLecturaQR!: string;
  sdForm: FormGroup;
  pistolear!: boolean;
  focusEstaEnLaPagina: boolean = true;
  ingresados!: number;
  sdEnBulto!: number;

  idBultoIngresado!: number;
  idCajaActual!: number;

  private focusInterval: any;
  bultoForm: FormGroup;
  bultoControl: FormControl = new FormControl('');
  listaBultos!: string[];
  rutDocenteEnEdicion!: number;
  modalPost: boolean;
  diferenciaSDestaCaja: number;
  diferenciaSD!: number;
  permiteAgregarBulto: boolean;

  filterForm: FormGroup;
  cajaLlena: boolean;
  detalleInfoBultos: boolean = false;
  ready!: boolean;
  bloquearCantidadSD: boolean;
  bultoPorEliminar: CajaBulto;
  bultoSeleccionadoEnCajas: CajaBulto[];
  tipoCasoBultoAEliminar: { caso: number, actual: boolean};

  constructor(
    private router: Router,
    private servicio: AperturaCajaService,
    private fb: FormBuilder,
    private focusTrackerService: FocusTrackerService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private errorMensaje: ErrorMensajeService,
  ) {
    this.sdForm = this.fb.group(
      {
        codigoQR: ["", [Validators.pattern(/[A-Za-z0-9]{5}['-][A-Za-z0-9]{2}[0-9]{5}/)]],
        rutDocente: [""],
        comentario: [""]
      }
    );
    this.QRForm = this.fb.group({
      codigoQR: ["", [Validators.pattern(/[A-Za-z0-9]{5}['-][A-Za-z0-9]{2}[0-9]{5}/)]]
    });
    this.bultoForm = this.fb.group({
      codigoBulto: this.bultoControl,
      codigoCaja: ['', [Validators.required, Validators.pattern(/^BB[0-9][0-9][0-9]$/)]],
      manifiesto: [false],
      cantidadSD: [0, [Validators.required, Validators.min(0), Validators.pattern("^[0-9]*$")]]
    });
    this.filterForm = this.fb.group({
      bulto: [''],
      rbd:[''],
      rutDocente: [''],
      qr: ['']
    });
  }

  buscaSD() {
    const paramSearch = this.filterForm.value;
    this.listaCodigosFiltrada = this.listaCodigos;
    let lista:SD[] = this.listaCodigos;
    if (paramSearch.bulto) lista = lista.filter((item:SD) => item.folioBulto.toUpperCase() == paramSearch.bulto.toUpperCase().trim());
    if (paramSearch.rbd) lista = lista.filter((item:SD) => item.rbd.toString() == paramSearch.rbd.trim());
    if (paramSearch.rutDocente) lista = lista.filter((item:SD) => item.rutDocente.toString() == paramSearch.rutDocente.toString().trim());
    if (paramSearch.qr) lista = lista.filter((item:SD) => item.codigoQR == paramSearch.qr.trim());
    this.listaCodigosFiltrada = lista;
  }

  limpiaFiltroSD(){
    this.filterForm.reset();
    this.listarSD();
    this.actualizarConteos();
  }

  actualizarConteos() {
    this.servicio.actualizarConteos(this.caja.folio)
    .subscribe({
      next: (cajaActualizada: any) => {
          this.caja = cajaActualizada;
          this.bultoActual = this.listarBultosCajaActual(cajaActualizada.bultos); //doble check de bulto
      },
      error: (err: any) => {
        this.informarError(err, "actualizar la caja");
      },
    });
  }

  obtenerDiferenciaSDBulto(){
    if (!this.bultoActual) return;
    this.servicio.obtenerDiferenciaSDdeBulto(this.bultoActual.folioBulto)
      .subscribe(
      {
        next: (value: any | number) => {
          this.sdEnBulto = value
        },
        error: (err: any) => {
          this.informarError(err, "obtener la diferencia de sd del bulto");
        }
      });
  }

  ngOnInit() {
    this.ready = false;
    this.verificarCaja(this.caja);
    this.ingresados = 0;
    this.listarSD();

    this.obtenerDiferenciaSDBulto();

    this.bultoControl?.valueChanges.subscribe(value =>
    {
      this.permiteAgregarBulto = false;
      if (!value) return;
      if (!this.listaBultos?.includes(value)) return;
      this.servicio.obtenerDiferenciaSDdeBulto(value)
      .subscribe(
      {
        next: (value: any) => {
          this.diferenciaSD = value
          this.bultoForm.patchValue({cantidadSD: value},{emitEvent: false});
          this.bloquearCantidadSD = value != null;
          this.permiteAgregarBulto = true;
        },
        error: (err: any) => {
          this.informarError(err, "obtener la diferencia de sd del bulto");
        }
      });

    }
    );

    this.QRForm.get('codigoQR')?.valueChanges.subscribe(value =>
      {
        if (!value) return;
        value = value.trim();
        if (!value) return;
        if (this.bultoActual.capacidad == this.bultoActual.conteoSDBulto) {
          Swal.fire(
            {
              title: "Ingreso de SD",
              text: "No se puede ingresar más SD a esta caja porque ya se ha ingresado la totalidad de sd de este bulto actual (" + this.bultoActual.folioBulto + ")",
              icon: "warning",
              timer: 9000,
              timerProgressBar: true
            }
          );
          setTimeout(() => {
            this.QRForm.reset();
            this.estadoLecturaQR = "";
          }, 2000);
          return;
        }
        this.estadoLecturaQR = "";

        if (value && value?.includes("'")) {
          value = value.replace("'", "-");
          this.QRForm.patchValue({codigoQR: value},{emitEvent:false});
        }
        if (value.length == 13 ) {
          value = value.toUpperCase();
          if (codigoRegex.test(value)) {
            if (this.listaCodigos.findIndex(item => item.codigoQR === value) > -1) {
              this.estadoLecturaQR = "Código " + value + " ya está registrado en una caja. Limpiando en 5 seg.";
              setTimeout(() => {
                this.QRForm.reset();
                this.estadoLecturaQR = "";
              }, 5000);
            }
            else {
              this.agregarCodigo(value);
            }
            return;
          }
          else {
            this.estadoLecturaQR = "Código inválido, limpiando campo en 5 segundos."
            setTimeout(() => {
              this.QRForm.reset();
              this.estadoLecturaQR = "";
            }, 5000);
          }
        }
        if (value.length > 13) {
          this.QRForm.patchValue({codigoQR: ""}, { emitEvent: false });
        }
      }
    );

    this.sdForm?.get('comentario').valueChanges.subscribe(value =>
    {
      if (value?.length > 255) {
        value = value.slice(0,255);
        this.sdForm.patchValue({comentario: value}, { emitEvent: false });
      }
    }
    );
    this.ready = true;
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['caja']) {
      this.ready = false;
      this.listarSD();
      this.ready = true;
    }
  }

  verificarCaja(caja: RegistroApertura) {
    if (caja.idCaja == null ) {
      const filtro: FiltroApertura = new FiltroApertura();
      filtro.folio = caja.folio;
      this.servicio.getCajas(filtro, null)
      .subscribe(
        {
          next: (value: any) => {
            this.caja = value?.list[0];
          },
          error: (err:any ) => {
              this.informarError(err,"obtener los datos de esta caja")
          },
        });
    }
  }

  setearBultoActual(bulto: string){
    this.servicio.obtenerBulto(bulto)
    .subscribe(
      {
        next: (value: any) => {
          this.bultoActual = value;
        },
        error: (err:any ) => {
            this.informarError(err,"obtener los datos del bulto de esta caja")
        },
      });
  }

  ngAfterViewInit() {
    if (this.caja && (!this.idCajaActual || !this.idBultoIngresado)) {
      this.idBultoIngresado = this.caja.idBulto;
      this.idCajaActual = this.caja.idCaja;
    }
    this.inputQR?.nativeElement.focus();
    this.cdr.detectChanges();
    this.focusInterval = setInterval(() => {
      this.checkAndFocusInput();
      this.checkPistolear();
    }, 4000);
    // Método para comprobar si el campo está enfocado después de la inicialización de la vista
    this.checkFocus();
    //para efectos del pistoleo, debe activarse si el foco está dentro de la página
    this.focusTrackerService.isPageFocused$.subscribe(focused => {
      this.ngZone.run(() => {
        this.focusEstaEnLaPagina = focused;
      });
    });

    setTimeout(() => {
      const bultoInicial = this.caja.bultoInicial;
      if (bultoInicial) {
        //this.caja.manifiesto = bultoInicial.manifiesto;
        //this.caja.sd = bultoInicial.tarjetas;
      } else {
        //this.caja.manifiesto = this.bultoActual?.manifiesto;
        //this.caja.sd = this.bultoActual?.tarjetas
      }
      this.actualizarInfoCaja = false;
      this.cdr.detectChanges();
    }, 0);
  }

  checkFocus() {
    this.ngZone.run(() => {
      this.pistolear = this.inputQR?.nativeElement === document.activeElement;
    });
  }

  // Método para manejar el enfoque manualmente
  onFocus() {
    this.checkFocus();
  }

  onBlur() {
    this.checkFocus();
  }

  truncateText(text: string, maxLength: number = 20): string {
    return text?.length > maxLength ? `${text?.substring(0, maxLength)}...` : text;
  }



  ngOnDestroy() {
    // Limpiar el intervalo cuando el componente se destruya para evitar fugas de memoria
    if (this.focusInterval) {
      clearInterval(this.focusInterval);
      this.focusInterval = null;
      this.cdr.detectChanges();
    }
  }

  // Método que verifica y enfoca el input si no está enfocado
  checkAndFocusInput() {
    if (this.cajaLlena) return;
    if(this.autoenfoque && this.autoenfoqueBulto && !this.focusEstaEnLaPagina) {
      this.pistolear = false;
    }
    this.cdr.detectChanges();
    if (!this.autoenfoque || this.autoenfoqueBulto ) return;
    const inputElement = this.inputQR.nativeElement;

    if (document.activeElement !== inputElement) {
      inputElement.focus();
    }

    this.checkPistolear();
  }

  checkPistolear() {
    this.pistolear = document.activeElement == this.inputQR?.nativeElement;
    this.cdr.detectChanges();
  }

  abrirModal(sd: SD) {
    this.rutDocenteEnEdicion = sd.rutDocente;
    this.sdForm.setValue(
      {
        codigoQR: sd.codigoQR,
        rutDocente: sd.rutDocente,
        comentario: sd.comentario
      }
    );
    this.autoenfoque = false;
    this.openModal();
  }

  agregarCodigoFormulario() {

  }

  lanzarQRForm(sd: SD = null, qr: string, status: string) {
    let titulo = "";
    if ( sd ) titulo = "Docente no encontrado";
    else      titulo = "QR no encontrado";

    Swal.fire(
      {
        title:"Creación de SD cancelada",
        text: status,
        icon: "warning",
        timer:6000,
        timerProgressBar: true,
      }
    )

    this.sdForm.setValue(
      {
        codigoQR: qr,
        rutDocente: 0,
        comentario: ""
      }
    );
    this.modalPost = true;
    this.rutDocenteEnEdicion = null;
    this.autoenfoque = false;
    this.openModal();
  }

  agregarCodigo(qr: string) {
    if (!qr) return;
    this.consultandoQR = true;
    if (codigoRegex.test(qr)) {
      this.servicio.consultarQR(qr)
        .subscribe(
          {
            next: (data: any) => {
              const tarjeta = data?.grabacion;
              const disponible = data?.disponible;
              this.modalPost = false;
              if(!tarjeta) {
                this.consultandoQR = false;
                this.peticionQR ="";
                this.modalPost = true;

                Swal.fire(
                  {
                    title:"Creación de SD cancelada",
                    text: "No se encontró el código QR en el universo de QR",
                    icon: "warning",
                    timer: 5500,
                    timerProgressBar: true,
                  }
                )
                //this.lanzarQRForm(null, qr, "");
                return;
              }
              if (!disponible ) {
                this.peticionQR = "";
                this.consultandoQR = false;
                this.modalPost = true;
                this.estadoLecturaQR = "SD ya está registrada en otra caja."
                setTimeout(() => {
                  this.sdForm.reset();
                  this.estadoLecturaQR = "";
                }, 3000);
                return;
              }
              let nuevaSD = new SD();
              nuevaSD.codigoQR = qr;
              nuevaSD.rutDocente =  tarjeta?.rutDocente ? tarjeta.rutDocente : '';
              nuevaSD.comentario = "";
              nuevaSD.idBulto = this.bultoActual.idBulto;
              nuevaSD.idCaja =  this.caja.idCaja;
              nuevaSD.folioCaja = this.caja.folio;
              nuevaSD.nombreDocente = tarjeta?.nombreDocente;
              nuevaSD.rbd = tarjeta?.rbd;

              this.consultandoQR = false;
              this.peticionQR = "Agregando QR...";

              this.insertarSD(nuevaSD);
            },
            error: (err: any) => {
              this.consultandoQR = false;
              this.peticionQR = ""
            },
          });
      this.QRForm?.reset();
    }
  }

  insertarSD(nuevaSD: SD){
    this.servicio.insertarQR(nuevaSD)
    .subscribe(
    {
      next: (value: any | SD[]) => {
          this.peticionQR = "";
          if (!value) {
            Swal.fire("Problemas internos con la SD",
              "Faltan conexiones internas en los datos, reiniciamos el módulo en 5 segundos.",
              "warning");
            setTimeout(() => {
              window.location.reload();
            }, 5000);
            return;
          }
          Swal.fire({
            title: "SD registrada!",
            icon: "success",
            timerProgressBar: true,
            timer: 2000
          });
          this.ingresados = value?.listaSD?.length;
          this.listaCodigos = value?.listaSD;
          this.bultoActual = this.listarBultosCajaActual(value?.bultos);
          this.limpiaFiltroSD();
          this.closeModal();
      },
      error: (err: any) => {
        this.peticionQR = "";
        if (err.status != 406){
          Swal.fire(
            {
              title:"Creación de SD cancelada",
              text: err.error.status,
              icon: "warning",
              timer: 5500,
              timerProgressBar: true,
            }
          )
          this.modalPost = true;
        }
        else {
          this.lanzarQRForm(nuevaSD, nuevaSD.codigoQR, err?.error?.status);
        }
      },
    }
    );
  }

  crearSDRutNuevo() {
    //obtener datos de rut docente y si esta disponible para agregar
    // si esta disponible, forma el objeto SD y lo envia como post al servicio
    // sino, lanza error de q el docente ya esta registrado.
    const formularioSD: SD = this.sdForm.value;
    this.servicio.consultarQR(null, formularioSD.rutDocente)
    .subscribe(
      {
        next: (value: any) => {
            const disponible = value?.disponible
            const docente = value?.docente;
            if (!disponible) {
              Swal.fire(
                "Operación rechazada",
                "El rut ingresado ya está registrado en otra caja.",
                "warning"
              );
              return;
            }
            let nuevaSD = new SD();
            nuevaSD.codigoQR = formularioSD.codigoQR;
            nuevaSD.rutDocente =  formularioSD.rutDocente;
            nuevaSD.comentario = formularioSD.comentario;
            nuevaSD.idBulto = this.bultoActual.idBulto;
            nuevaSD.idCaja =  this.caja.idCaja;
            nuevaSD.folioCaja = this.caja.folio;
            nuevaSD.nombreDocente = docente?.nombreDocente;
            nuevaSD.rbd = docente?.rbd;

            this.consultandoQR = false;
            this.insertarSD(nuevaSD);
        },
        error:(err: any) => {
          this.informarError(err, "obtener y validar el rut de docente")
        },
      }
    );
  }

  confirmarBorradoSD(qr: string) {
    if (!qr) {
      return;
    }
    Swal.fire({
      title: '¿Desea eliminar este registro de SD?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result) => {
      if (result.isConfirmed) {
        this.borrarSD(qr);
      }
    })
  }

  borrarSD(qr:string) {
    this.servicio.borrarSD(qr, this.caja.idCaja, this.bultoActual.idBulto)
    .subscribe(
      {
        next: (bultoActual: any | string) => {
          Swal.fire({
            title: "Eliminación SD",
            text: "Todo correcto.",
            icon:"success",
            timer: 2000,
            timerProgressBar: true,
          });
          this.limpiaFiltroSD();
        },
        error: (err: any) => {
          this.informarError(err, "eliminar esta SD");
        },
      }
    );
  }

  @ViewChild('modalContainer') modalContainer!: ElementRef;
  @ViewChild('modalBultoContainer') modalBultoContainer!: ElementRef;
  @ViewChild('abreModalBultos') abreModalBultos: ElementRef;
  @ViewChild('abreModalCajaBultos') abreModalCajaBultosEliminacion: ElementRef;
  @ViewChild('cierraModalCajaBultosEliminacion') cierraModalCajaBultosEliminacion: ElementRef;

  openModal() {
    this.modalContainer.nativeElement.classList.remove('hidden');
  }

  closeModal() {
    this.autoenfoque = true;
    this.modalContainer.nativeElement.classList.add('hidden');
  }

  asignarComentario(sd:SD) {
    this.servicio.comentarioSD(sd)
    .subscribe(
      {
        next: (value: any) => {
            Swal.fire("Edición de SD", "Todo correcto!", "success");
            this.listarSD();
            this.sdForm.reset();
            this.closeModal();
            this.rutDocenteEnEdicion = null;
        },
        error: (err: any) => {
            Swal.fire(
              {
                title:"Edición de SD cancelada",
                text: err.error.status,
                icon: "warning",
                timer: 5500,
                timerProgressBar: true,
              }
            );
        },
      }
    );
  }

  listarSD() {
    const caja = this.caja.folio;
    this.servicio.listarSD(caja)
    .subscribe(
      {
        next: (value: any) => {
            this.listaCodigos = [];
            this.listaCodigos = value;
            this.listaCodigosFiltrada = this.listaCodigos;
            this.ingresados = this.listaCodigos.length;
            this.bultoActual = this.listarBultosCajaActual();
            this.cajaLlena = this.listaCodigos?.length == this.caja?.capacidad;
        },
        error: (err: any) => {
            this.listaCodigos = [];
        },
      }
    );
  }

  editarSD() {
    const sd = this.sdForm.value;
    let qr = sd.codigoQR?.trim();
    const rut = sd.rutDocente;
    if (qr?.length == 13 && rut && rut > 0) {
      if (codigoRegex.test(qr)) {
        this.servicio.validaRut(rut)
        .subscribe(
          {
            next: (value: any) => {
              let sdNew: SD = new SD();
              sdNew.codigoQR = qr;
              sdNew.comentario = sd.comentario;
              sdNew.folioCaja = this.caja.folio;
              sdNew.idBulto = this.caja.idBulto;
              sdNew.idCaja = this.caja.idCaja;
              sdNew.nombreDocente = value.nombreDocente;
              sdNew.rbd = value.rbd;
              sdNew.rutDocente = rut;
              this.asignarComentario(sdNew);
              return;
            },
            error: (err: any) => {
              this.informarError(err, "validar el rut del docente");
              return;
            }
          }
        );
      }
      else {
        this.estadoLecturaQR = "Código inválido, limpiando campo en 5 segundos."
        setTimeout(() => {
          this.sdForm.reset();
          this.estadoLecturaQR = "";
        }, 5000);
      }
    }
    this.sdForm.reset();
    this.closeModal();
  }



  closeModalBulto() {
    this.autoenfoque = true;
    this.bultoForm.reset();
    this.btnCerrarModalCajaBulto.nativeElement.click();
  }

  openModalBulto(){
    this.bultoForm.reset();
    this.bultoForm.patchValue({codigoCaja: this.caja.folio});
    this.abreModalBultos.nativeElement.click();
  }

  modalCajaBultos(bulto: CajaBulto) {
    this.bultoPorEliminar = bulto;
    this.abreModalCajaBultosEliminacion.nativeElement.click();
    this.bultoSeleccionadoEnCajas = null;
    this.servicio.obtenerCajasConBultoAEliminar(bulto.idBulto)
    .subscribe(
      {
        next: (value: any) => {
            this.bultoSeleccionadoEnCajas = value;
            this.tipoCasoBultoAEliminar = { caso: -1, actual: null};
            if (this.bultoSeleccionadoEnCajas?.length == 1 && this.bultosEnCaja?.length == 1) this.tipoCasoBultoAEliminar = { caso: 0, actual: false};
            if (this.bultoSeleccionadoEnCajas?.length == 1 && this.bultosEnCaja?.length > 1) this.tipoCasoBultoAEliminar = { caso: 1, actual: false};
            if (this.bultoSeleccionadoEnCajas?.length > 1 && this.bultosEnCaja?.length == 1) this.tipoCasoBultoAEliminar = { caso: 2, actual: false};
            if (this.bultoSeleccionadoEnCajas?.length > 1 && this.bultosEnCaja?.length > 1) this.tipoCasoBultoAEliminar = { caso: 3, actual: false};
            if (this.tipoCasoBultoAEliminar.caso != 0 && this.bultoActual?.folioBulto == this.bultoPorEliminar?.folioBulto) this.tipoCasoBultoAEliminar.actual = true;
        },
        error: (err: any) => {
            this.informarError(err, "obtener las cajas con el bulto a eliminar");
            setInterval(() => {
              this.cierraModalCajaBultosEliminacion.nativeElement.click();
            }, 4000);
        },
      }
    );
  }

  obtenerListaBultos() {
    if (this.cajaLlena) return;
    if (this.bultoActual && (this.bultoActual.capacidad != this.bultoActual.conteoSDBulto) ) {
      Swal.fire({
        title: "Atención!",
        text: "Primero, debes terminar de pistolear las SD del bulto actual (" + this.bultoActual.folioBulto + ")",
        timer: 5500,
        icon: "warning",
        timerProgressBar: true
      });
      this.closeModalBulto();
      return;
    }
    this.permiteAgregarBulto = false;
    this.servicio.obtenerBultos()
    .subscribe(
      {
        next: (bultos: any | string[]) => {
          this.listaBultos = bultos;
          if(!bultos) return;
          //sacar los bultos repetidos.
          this.bultosEnCaja?.map((cb: CajaBulto) => {
            this.listaBultos = this.listaBultos?.filter(item => item!= cb.folioBulto);
          })
          this.servicio.sdRestantes(this.caja.idCaja)
          .subscribe(
            {
              next: (value: any) => {
                this.diferenciaSDestaCaja = !value?.conteoCaja? 120: 120 - value.conteoCaja;
                this.bultoControl.patchValue({codigoCaja: this.caja.folio})
                this.openModalBulto();
                this.autoenfoque = false;
              },
              error: (err: any) => {
                  this.informarError(err, "obtener las sd restantes de la caja");
              },
            }
          );

        },
        error: (err: any) => {
          this.informarError(err, "obtener la lista de bultos");
          this.listaBultos = null;
          this.closeModalBulto();
        },
      }
    )
  }

  informarError(err: any, contexto: string) {
    this.errorMensaje.show(err, contexto).then((result:any) => {
      if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
        //this.finalizarConsulta();
      }
    });
  }

  agregarBulto() {
    if (this.bultoForm.invalid) return;
    let cajaBulto: CajaBulto = new CajaBulto();
    const bulto = this.bultoForm.value;
    cajaBulto.actual = true;
    cajaBulto.capacidad = bulto.cantidadSD;
    cajaBulto.folioBulto = bulto.codigoBulto;
    cajaBulto.manifiesto = bulto.manifiesto == true;
    cajaBulto.idCaja = this.caja.idCaja;
    this.autoenfoque = true;
    //obtener bulto (id) y luego crear
    this.servicio.obtenerBulto(bulto.codigoBulto)
    .subscribe({
      next: (bulto: any| Bulto) => {
          const idBulto = bulto.id;
          cajaBulto.idBulto = idBulto;
          this.crearCajaBulto(cajaBulto);
      },
      error: (err: any) => {
          this.informarError(err, "obtener datos del bulto.")
      },
    });

  }

  crearCajaBulto(cajaBulto: CajaBulto) {
    this.servicio.agregarCajaBulto(cajaBulto)
    .subscribe({
      next: (value: any | string) => {
          Swal.fire({
            title: "Agregado de bultos",
            text: "Todo correcto!",
            icon: "success",
            timer: 2500,
            timerProgressBar: true
          });
          this.limpiaFiltroSD();
      },
      error: (err: any) => {
          this.informarError(err, "agregar el nuevo bulto a esta caja");
      },
    });
  }

  listarBultosCajaActual(json: string = null): CajaBulto {
    this.bultosEnCaja = JSON.parse(json!= null ? json : this.caja.bultos);
    console.log(this.bultosEnCaja);
    if (this.bultosEnCaja?.length == 1 && this.bultosEnCaja[0].idBulto == null) this.bultosEnCaja = [];
    let bultoActual = this.bultosEnCaja?.find((item: CajaBulto) => item.actual == true);
    return bultoActual
  }

  eliminarBulto(bultoPorEliminar: CajaBulto) {
    this.servicio.eliminarBulto(this.caja.idCaja,bultoPorEliminar.idBulto, this.bultosEnCaja, this.tipoCasoBultoAEliminar)
    .subscribe(
      {
        next: (value: any) => {
          Swal.fire({
            title: "Eliminación de bulto y sus SD",
            text: "Todo correcto!",
            icon: "success",
            timer: 3500,
            timerProgressBar: true
          });
          this.limpiaFiltroSD();
        },
        error: (err: any) => {
            this.informarError(err, "eliminar el bulto seleccionado.");
        },
      }
    );
  }
}
