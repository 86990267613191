<div class="px-4 flex-row">
  <div class="px-2 flex justify-between">
    <h3 class="pb-4">Noticias</h3>
    <button class="bg-green-500 hover:bg-gray-50 hover:text-green-500 text-gray-50 font-bold my-3 mr-3 px-3 rounded-lg border-2 border-green-500 duration-300" (click)="openModal(null)" matTooltip="Agregar Noticia" matTooltipPosition="left"><mat-icon class="pt-0.5" fontIcon="add"></mat-icon></button>
  </div>
  <div class="rounded-xl border border-gray-200 bg-white shadow-none">
    <div class="overflow-x-auto p-4" >
      <table aria-label="tabla noticias" role="table"  class="w-full table-auto">
        <thead class="bg-gray-50 text-xs text-black cursor-pointer">
          <tr>
            <th class="border border-black text-center py-4 px-2">
              <b>Orden</b>
            </th>
            <th class="border border-black text-center py-4 px-2">
              <b>Mensaje</b>
            </th>
            <th class="border border-black text-center py-4 px-2">
              <b>Perfiles</b>
            </th>
            <th class="border border-black text-center py-4 px-2">
              <b>Acciones</b>
            </th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-100 text-sm noticias-list">
          <tr *ngFor="let noticia of noticiasList" class="hover:bg-neutral-100 noticias-box">
            <td class="py-8 text-center">
              <b>{{noticia.orden}}</b>
            </td>
            <td class="py-8 px-10 text-left">
              {{noticia.mensaje}}
            </td>
            <td class="py-8 w-48 text-left">
              <ul *ngFor="let perfil of noticia.perfiles">
                <li *ngIf="perfil==1"><b>{{perfil}}:</b> Encargado MUN-SLEP <br> Sostenedor MUN-SLEP</li>
                <li *ngIf="perfil==2"><b>{{perfil}}:</b> Director MUN-SLEP</li>
                <li *ngIf="perfil==3"><b>{{perfil}}:</b> Docente</li>
                <li *ngIf="perfil==4"><b>{{perfil}}:</b> Sostenedor PSAD</li>
                <li *ngIf="perfil==5"><b>{{perfil}}:</b> Director PSAD</li>
                <li *ngIf="perfil==7"><b>{{perfil}}:</b> Sostenedor JJII</li>
                <li *ngIf="perfil==8"><b>{{perfil}}:</b> Director JJII</li>
                <li *ngIf="perfil==11"><b>{{perfil}}:</b> Encargado PSAD</li>
                <li *ngIf="perfil==12"><b>{{perfil}}:</b> Encargado JJII</li>
                <li *ngIf="perfil==13"><b>{{perfil}}:</b> Docente Informe</li>
                <li *ngIf="perfil==14"><b>{{perfil}}:</b> Docente ECEP</li>
                <li *ngIf="perfil==15"><b>{{perfil}}:</b> Docente Tramo <br> Jefe UTP MUN-SLEP</li>
                <li *ngIf="perfil==21"><b>{{perfil}}:</b> Docente Objetado</li>
                <li *ngIf="perfil==30"><b>{{perfil}}:</b> MINEDUC</li>
              </ul>
            </td>
            <td class="text-center">
              <button class="text-blue-500" (click)="openModal(noticia)"><mat-icon fontIcon="edit" matTooltip="Editar" matTooltipPosition="below"></mat-icon></button>
              <button class="text-red-500 ml-2" (click)="eliminarNoticia(noticia.id)"><mat-icon fontIcon="delete" matTooltip="Borrar" matTooltipPosition="below"></mat-icon></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="showModal">
  <app-mantenedor-feed-modal [noticiaSelected] = 'noticiaSelected' (updateNoticiaSelected) = 'cleanModalFromChild($event)' (permanecerEnForm)="cambiarVista($event)"></app-mantenedor-feed-modal>
</div>
