export class SostenedorFilter {
    rutSostenedor: number;
    nombreSostenedor: string;
    idSector: number;

    constructor(){
        this.rutSostenedor = null;
        this.nombreSostenedor = "";
        this.idSector = null;
    }
}