export class FiltroApertura {
  folio!: string;
  bulto!: string;
  estado!: number;
  fecha!: string;
  operador!: string;
  rutSostenedor!: number;
  rutDocente!: number;
  qr!: string;
}
