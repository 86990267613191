<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO >
      <div class="flex flex-col gap-4 px-2">
        <p class="text-blue-950 text-2xl font-bold">Historial Llamadas</p>
        <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
          <div class="flex flex-row gap-1 py-2">
            <mat-icon>filter_list</mat-icon>
            <p class="text-lg font-bold">Filtros</p>
          </div>
              <div class="row" aria-label="sección de inputs">
                <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
                  <mat-form-field class="w-full">
                    <mat-label>RUT</mat-label>
                    <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="12345678" [(ngModel)]="filtros.rut" name="rut" />
                  </mat-form-field>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
                  <mat-form-field >
                    <mat-label>Rango de Fechas</mat-label>
                    <mat-date-range-input [rangePicker]="picker" [max]="today" [min]="minDate">
                      <input matStartDate placeholder="Start date" [(ngModel)]="filtros.fechaInicio">
                      <input matEndDate placeholder="End date" [(ngModel)]="filtros.fechaFin">
                    </mat-date-range-input>
                    <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>
              </div>
              <div class="d-flex" aria-describedby="footer filtro">
                <div aria-labelledby="botones inf. izq" class="float-left d-flex  align-self-start ">
                  <mat-card-actions class="d-flex row">
                    <button
                      type="button"
                      data-te-ripple-init
                      (click)="filtrar()"
                      data-te-ripple-color="light"
                      class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                      <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                      <p class="pr-1"></p> Filtrar
                    </button>
                    <button
                      type="button"
                      data-te-ripple-init
                      (click)="limpiar()"
                      data-te-ripple-color="light"
                      class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                      <p class="pr-1"></p> Limpiar
                    </button>
                  </mat-card-actions>
                  <mat-card-actions class="d-flex row">
                    <div class="float-right col d-flex justify-content-start">
                      <button
                          matTooltip="Descarga esta tabla completa"
                          *ngIf="length>0"
                          type="button"
                          data-te-ripple-init
                          (click)="descargarCsv(filtros.rut)"
                          data-te-ripple-color="light"
                          class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                          <fa-icon *ngIf="descargandoCsv" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                          <svg *ngIf="!descargandoCsv" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                          <div *ngIf="!descargandoCsv" class="text-[14px]">Descargar</div>
                          <div *ngIf="descargandoCsv" class="text-[14px]">Descargando</div>
                      </button>
                    </div>
                  </mat-card-actions>
                </div>
              </div>
        </div>
      </div>
    </div>
    <div class="overflow-x-scroll rounded-2xl border border-gray-200 m-2 p-2" aria-label="sección tabla" >
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
      <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50" role="table">
        <thead class="bg-blue-700 ">
          <tr>
            <th scope="col" class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black " ></th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >ID LLAMADA</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >EMISOR</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >RECEPTOR</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >NOMBRE RECEPTOR</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >NUMERO</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >FECHA</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >RUT</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >OPCION</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >MINUTOS LLAMADA</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >ESTADO</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >TIPO</th>
            <th scope="col" class="rounded-tr-2xl pl-2 pr-4 py-3 text-right text-blue-100 text-sm font-black " ></th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr class="min-w-full" *ngFor="let element of response">
            <td class="negrita-tabla"></td>
            <td class="negrita-tabla">{{element.callid}}</td>
            <td class="negrita-tabla">{{element.caller}}</td>
            <td class="negrita-tabla">{{element.forward}}</td>
            <td class="negrita-tabla">{{element.forward_name}}</td>
            <td class="negrita-tabla">{{element.did}}</td>
            <td class="negrita-tabla">
              <div class="text-xs flex flex-row gap-2 ">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-plus" viewBox="0 0 16 16"> <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/> <path fill-rule="evenodd" d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"/> </svg>{{element.start_date | date: 'dd-MM-YYYY HH:mm'}}
              </div>
            </td>
            <td class="negrita-tabla">{{element.dtmf}}</td>
            <td class="negrita-tabla">{{element.option}}</td>
            <td class="negrita-tabla">{{element.call_minute}}</td>
            <td class="negrita-tabla">{{element.call_status}}</td>
            <td class="negrita-tabla">
              <div class="text-xs flex flex-row gap-2">
                <div *ngIf="element.call_direction == 'INBOUND'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-inbound" viewBox="0 0 16 16"> <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/> </svg>
                </div>
                <div *ngIf="element.call_direction == 'OUTBOUND'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-outbound" viewBox="0 0 16 16"> <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/> </svg>
                </div>
                {{element.call_direction}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
      [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
    </mat-paginator>
  </mat-card>
