import { HttpClient, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class DocenteService {
  estaDescargandoExcelFichaDocemteEmmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient
  ) { }

  emitirEstadoDescargaFichaDocente(estado: boolean) {
    this.estaDescargandoExcelFichaDocemteEmmit.emit(estado);
  }

  docentesList(options: PaginationOptions,
    rutDocente: number = null,
    rutSostenedor: number = null,
    anioConvocatoria: number = null,
    rbd: number = null,
    estadoRegistro: string = ""): Observable<any> {
    let params = this.obtenerParamsDocente(rutDocente, rutSostenedor, anioConvocatoria, rbd, estadoRegistro);
    params = params
    const url = `${Constants.URL_SERVICE.BASE_DOCENTE + Constants.URL_SERVICE.DOCENTES.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

  obtenerParamsDocente(rutDocente: any, rutSostenedor: any, anioConvocatoria: any, rbd: any, estadoRegistro: any): HttpParams {
    let params = new HttpParams();

    if (rutDocente && rutDocente > 0) {
      params = params.set('rutDocente', rutDocente);
    }
    if (rutSostenedor && rutSostenedor > 0) {
      params = params.set('rutSostenedor', rutSostenedor);
    }
    if (anioConvocatoria && anioConvocatoria > 0) {
      params = params.set('anioConvocatoria', anioConvocatoria);
    }
    if (rbd && rbd > 0) {
      params = params.set('rbd', rbd);
    }
    if (estadoRegistro && estadoRegistro.trim() != "") {
      params = params.set('estadoRegistro', estadoRegistro.trim());
    }
    return params;
  }


  buscarEvaluacionesHistoricas(rutDocente: number) {
    const url = `${Constants.URL_SERVICE.BASE_DOCENTE}/${rutDocente}${Constants.URL_SERVICE.DOCENTES.EVALUACIONES_HISTORICAS}`;
    return this.http.get(url).pipe(map(response => response));
  }

  getFile(url: string,
    rutDocente: number = null,
    rutSostenedor: number = null,
    anioConvocatoria: number = null,
    rbd: number = null,
    estadoRegistro: string = ""): Observable<any> {
    let params = this.obtenerParamsDocente(rutDocente, rutSostenedor, anioConvocatoria, rbd, estadoRegistro);
    params = params
    return this.http.get(url, { responseType: 'blob', params: params }).pipe(
      map(response => response)
    );
  }

  downloadBlob(filename: string, blob: any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  descargarInformeDocente(
    rut: number = null,
    agno: number = null): void {
    const url = `${Constants.URL_SERVICE.BASE_ARCHIVOS + Constants.URL_SERVICE.DOCENTES.DESCARGA_INFORME_DOCENTE}`
      .replace("{rut}", rut.toString())
      .replace("{agno}", agno.toString());

    this.http.get(url,
      {
        responseType: 'json',
        observe: 'response',
        reportProgress: true,
      })
      .subscribe(
        {
          next: (response: HttpResponse<any>) => {
            this.emitirEstadoDescargaFichaDocente(true);
            if (response.type === HttpEventType.Response) {
              const link = response.body.data;
              window.open(link,"_blank");
            }
          },
          error: (error: any) => {
            console.error(error);
            this.emitirEstadoDescargaFichaDocente(false);
          },
        }
      );
  }

  handleError(error: any) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    return throwError(errorMessage);
  }

}
