import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QaGrabacionesService } from "../../../services/qaGrabacionesService";
import { AlertsService } from "../../../../../../base/services/alerts.service";
import {MOTIVOS, MOTIVOS_CAMBIO_VALOR} from "../../../constants/motivos-constants";
import {MotivoDTO} from "../../../dto/motivoDTO";
import {ProblemaCorreccionDTO} from "../../../dto/problemaCorreccionDTO";  // Importación de constantes

@Component({
  selector: 'app-nueva-correccion',
  templateUrl: './nueva-correccion.component.html',
  styleUrls: ['./nueva-correccion.component.css']
})
export class NuevaCorreccionComponent implements OnInit {
  correccionForm: FormGroup;

  categoriasMotivos: any[] = []; // Lista de categorías y sus motivos obtenidos del servicio
  categoriaSeleccionada: any = null; // Categoría seleccionada para filtrar motivos
  motivosFiltrados: MotivoDTO[] = []; // Motivos filtrados según la categoría seleccionada

  problemas: ProblemaCorreccionDTO[] = [];
  mostrarCamposValor: boolean = false;
  loading: boolean = false;
  saving: boolean = false;

  @Output() guardarCorreccion = new EventEmitter<{ problemas: any[] }>();
  @Output() cancelarCorreccion = new EventEmitter<void>();
  @Input() revisionData: any;
  @Input() edit: boolean = false;

  selectedMotivo: any = null;

  constructor(
    private fb: FormBuilder,
    private service: QaGrabacionesService,
    private alerts: AlertsService
  ) {
    this.correccionForm = this.fb.group({
      motivo: [null, Validators.required],
      valorAnterior: [{ value: '' }],  // Deshabilitado hasta que se necesite
      valorNuevo: [''],
      comentarios: ['']
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.service.obtenerCategoriasYMotivos().subscribe({
      next: (data) => {
        this.categoriasMotivos = data;  // Guardar las categorías y motivos
        if (this.edit) {
          this.cargarDatosCorreccion();  // Cargar datos en modo edición si es necesario
        } else {
          this.loading = false;
        }
      },
      error: (error) => {
        this.alerts.showExceptionAlert(error);
        console.error('Error al cargar motivos y categorías:', error);
        this.loading = false;
      }
    });
  }


  // Filtrar motivos para excluir los ya seleccionados
  filtrarMotivos(): void {
    if (this.categoriaSeleccionada) {
      const motivosSeleccionados = this.problemas.map(p => p.idMotivo);
      this.motivosFiltrados = this.categoriaSeleccionada.motivos
        .filter((motivo:any) => !motivosSeleccionados.includes(motivo.id));
    } else {
      this.motivosFiltrados = [];
    }
  }

  getMotivoById(id: number): any | null {
    const motivos = this.categoriasMotivos.flatMap(categoria => categoria.motivos);

    return motivos.find(motivo => motivo.id === id) || null;
  }

  onCategoriaChange(categoria: any): void {
    this.categoriaSeleccionada = categoria;
    this.filtrarMotivos();
    this.selectedMotivo = null; // Resetear el motivo seleccionado
    this.correccionForm.get('motivo')?.reset(); // Limpiar selección de motivo en el formulario
    this.correccionForm.get('valorAnterior')?.reset(); // Limpiar el valor anterior
    this.correccionForm.get('valorNuevo')?.reset(); // Limpiar el valor nuevo
    this.mostrarCamposValor = false; // Ocultar los campos de valor
  }



  onMotivoChange(motivoId: number | null): void {
    this.selectedMotivo = this.motivosFiltrados.find(m => m.id === motivoId);
    if (!this.selectedMotivo) {
      return;
    }

    this.correccionForm.get('comentarios')?.reset();

    this.mostrarCamposValor = MOTIVOS_CAMBIO_VALOR.includes(this.selectedMotivo.id);

    if (this.mostrarCamposValor) {

      this.correccionForm.get('valorNuevo')?.setValidators(Validators.required);
      this.correccionForm.get('valorNuevo')?.updateValueAndValidity();


      // Establecer `valorAnterior` en base a motivos específicos de RUT o correlativo
      if ([MOTIVOS.RUT_CON_ERROR_EN_DIGITO, MOTIVOS.RUT_NO_CORRESPONDE].includes(this.selectedMotivo.id)) {
        this.correccionForm.get('valorAnterior')?.setValue(this.revisionData.rutDocente);
      } else if (this.selectedMotivo.id === MOTIVOS.CORRELATIVO_INCORRECTO) {
        this.correccionForm.get('valorAnterior')?.setValue(this.revisionData.ordenCorrelativo);
      } else {
        this.correccionForm.get('valorAnterior')?.reset();
      }
    } else {
      this.correccionForm.get('valorNuevo')?.clearValidators();
      this.correccionForm.get('valorNuevo')?.updateValueAndValidity();
      this.mostrarCamposValor = false;
      this.correccionForm.get('valorAnterior')?.reset();
      this.correccionForm.get('valorNuevo')?.reset();
    }
  }


  agregarProblema(): void {
    if (this.correccionForm.valid) {
      const selectedMotivo = this.motivosFiltrados.find(m => m.id === this.correccionForm.value.motivo);
      if (selectedMotivo) {
        const nuevoProblema = {
          idMotivo: selectedMotivo.id,
          codigoCategoria: this.categoriaSeleccionada.codigo,
          codigoMotivo: selectedMotivo.codigo,
          motivoNombre: selectedMotivo.nombre,
          valorAnterior: this.correccionForm.value.valorAnterior || null,
          valorNuevo: this.correccionForm.value.valorNuevo || null,
          solucionable: selectedMotivo.solucionable,
          permiteComentarios: selectedMotivo.permiteComentarios,
          comentario: this.correccionForm.get("comentarios").value,
        } as ProblemaCorreccionDTO;

        this.problemas.push(nuevoProblema);
        this.correccionForm.reset();
        this.mostrarCamposValor = false;
        this.filtrarMotivos();
      }
    }
  }

  eliminarProblema(index: number): void {
    this.problemas.splice(index, 1);
    this.filtrarMotivos();
  }

  onGuardar(): void {
    this.saving = true;
    setTimeout(() => {
      this.guardarCorreccion.emit({ problemas: this.problemas });
    }, 100);
  }

  onCancelar(): void {
    this.cancelarCorreccion.emit();
  }

  cargarDatosCorreccion(): void {
    this.service.obtenerCorreccionesPorRevision(this.revisionData.id).subscribe({
      next: (correccion) => {
        if (correccion) {
          this.problemas = correccion.problemas.map((problema: any) => {
            const motivo = this.getMotivoById(problema.idMotivo);
            return {
              idMotivo: problema.idMotivo,
              codigoMotivo: problema.codigoMotivo,
              codigoCategoria: problema.codigoCategoria,
              permiteComentarios: motivo.permiteComentarios,
              motivoNombre: motivo?.nombre || 'Desconocido',
              valorAnterior: problema.valorAnterior,
              valorNuevo: problema.valorNuevo,
              solucionable: motivo?.solucionable || false,
              comentario: problema.comentario
            };
          });
          this.filtrarMotivos();
          this.loading = false;
        }
      },
      error: (error) => {
        this.alerts.showExceptionAlert(error);
        this.loading = false;
        this.cancelarCorreccion.emit();
      }
    });
  }

  protected readonly MOTIVOS = MOTIVOS;
  protected readonly MOTIVOS_CAMBIO_VALOR = MOTIVOS_CAMBIO_VALOR;
}
