import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private http: HttpClient
  ) { }


  ticketList(options: PaginationOptions,
    idTicket: number,
    asunto: string,
    rut: number,
    idSolicitante:number): Observable<any> {
    let params = this.obtenerParamsTicket(idTicket, asunto, rut, idSolicitante);
    const url = `${Constants.URL_SERVICE.BASE_TICKETS}/?page=${options.page}&size=${options.size}`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

  buscarAutor(id: number): Observable<any>  {
    let params = new HttpParams();
    params = params.set('idUser', id);
    const url = `${Constants.URL_SERVICE.BASE_TICKETS}/nombre`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

  obtenerParamsTicket(idTicket:any, asunto:any, rut:number, idSolicitante:any): HttpParams {
    let params = new HttpParams();

    if ( idTicket != null) {
      params = params.set('idTicket', idTicket);
    }
    if ( asunto?.trim() != null) {
      params = params.set('asunto', asunto);
    }
    if ( rut != null) {
      params = params.set('rut', rut);
    }
    if ( idSolicitante != null) {
      params = params.set('idSolicitante', idSolicitante);
    }

    return params;
  }

  obtenerComentarioTicket(idTicket: number): Observable<any> {
    let params = new HttpParams();
    params = params.set("idTicket", idTicket);
    const url = `${Constants.URL_SERVICE.BASE_TICKETS}/comentarios`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

}
