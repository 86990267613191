<div class="not-found-container">
  <div class="animation-container">
    <ng-lottie [options]="options" height="50vh"  (loopComplete)="onLoopComplete()" />
  </div>
  <div class="text-container">
    <h1>404</h1>
    <p>Página No Encontrada</p>
    <a (click)="home()">Volver al inicio</a>
  </div>
</div>
