<mat-card class="pt-4 lg:px-32 xl:px-36">
    <h3 *ngIf="modoEdicion == false" >Agregar Representante Legal</h3>
    <h3 *ngIf="modoEdicion == true ">Editar Representante Legal</h3>
    <div class="px-4 pt-6 pb-4 row">
        <div class="col-2 col-md-1 col-lg-3"></div>
        <form class="col-8 col-md-10 col-xs-12 col-lg-6" [formGroup]="formulario" #formRepresentante="ngForm" novalidate>
            <div *ngIf="errorRutEmpresa" class="alert alert-2-danger ">
                <h3 class="alert-title">Error</h3>
                <h3 class="alert-content">El rut de empresa no se encuentra registrado en el sistema</h3>
            </div>
            <div *ngFor="let input of inputs; let i = index" class="">
                <mat-form-field class="w-96">
                    <mat-label>{{ input.label }}</mat-label>
                    <input *ngIf="i!=3" matInput [(ngModel)]="filtros[input.attribute]" 
                        name="{{ input.label }}" min="0" 
                        class="fullwidth peer block  w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                        [readonly]="input.readonly" [type]="input.type" (focusout)="getNombreEmpresa(i)"
                        (ngModelChange)="onInputChange($event, input.type, input.attribute)"
                        [formControl]="input.control" required>
                    
                    <mat-icon *ngIf="input.readonly" matSuffix matTooltip="Campo sólo de lectura">
                        info</mat-icon>
                    <mat-select *ngIf="i ==3" [(value)]="filtros['tipo']" [formControl]="input.control" required placeholder="Tipo de representante">
                        <option [ngValue]="null" disabled>Asigne el rut sostenedor y Escoja el tipo.</option>
                        <mat-option *ngFor="let item of optionsTipo" [value]="item.nombre">{{item.nombre}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="input.control.invalid">{{getErrorMessage(input.control,input.error,input.errorMax)}}</mat-error>
                </mat-form-field>
            </div>
            <div class="float-right">
                <button mat-raised-button class="mx-2" (click)="cancelar()"><mat-icon>keyboard_return</mat-icon>
                    Cancelar y Volver</button>
                <button *ngIf="modoEdicion ==  false" mat-raised-button color="primary" [disabled]="this.inputs[0].control.invalid ||
                this.inputs[1].control.invalid ||
                this.inputs[3].control.invalid ||
                this.inputs[4].control.invalid ||
                this.inputs[5].control.invalid ||
                this.inputs[6].control.invalid ||
                this.inputs[7].control.invalid ||
                this.inputs[8].control.invalid " (click)="agregar()"
                ><mat-icon>cloud_upload</mat-icon>
                    Agregar</button>
                <button *ngIf="modoEdicion == true" mat-raised-button color="primary" [disabled]="this.inputs[0].control.invalid ||
                this.inputs[1].control.invalid ||
                this.inputs[3].control.invalid ||
                this.inputs[4].control.invalid ||
                this.inputs[5].control.invalid ||
                this.inputs[6].control.invalid ||
                this.inputs[7].control.invalid ||
                this.inputs[8].control.invalid" (click)="editar()">
                    <mat-icon>cloud_upload</mat-icon>
                    Guardar Cambios</button>
            </div>
        </form>
        <div class="col-2 col-md-1 col-lg-3"></div>
    </div>

</mat-card>
