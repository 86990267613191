import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './base/components/home/home.component';
import { InicioComponent } from './base/components/inicio/inicio.component';
import { LoginComponent } from './base/components/login/login.component';
import { PageNotFoundComponent } from './base/components/page-not-found/page-not-found.component';
import { RecoverPasswordComponent } from './base/components/recover-password/recover-password.component';
import { HomeGuard } from './base/guards/home.guard';
import { LoginGuard } from './base/guards/login.guard';
import { CargasMasivasComponent } from './registros/components/carga-masiva/carga-masiva.component';
import { CierreProcesosComponent } from './registros/components/cierre-procesos/cierre-procesos.component';
import { EvaluacionesHistoricasExtendidaComponent } from './registros/components/evaluaciones-historicas-extendida/evaluaciones-historicas-extendida.component';
import { EvaluacionesHistoricasComponent } from './registros/components/evaluaciones-historicas/evaluaciones-historicas.component';
import { EventosComponent } from './registros/components/eventos/eventos.component';
import { FichaDocenteComponent } from './registros/components/ficha-docente/ficha-docente.component';
import { AperturaCajaComponent } from './registros/components/grabaciones/apertura-caja/apertura-caja.component';
import { FichaSeguimientoComponent } from './registros/components/grabaciones/ficha-seguimiento/ficha-seguimiento.component';
import { RecepcionBultoComponent } from './registros/components/grabaciones/recepcion-bulto/recepcion-bulto.component';
import { HistorialLlamadasComponent } from './registros/components/historial-llamadas/historial-llamadas.component';
import { MantenedorFeedMainComponent } from './registros/components/mantenedor-feed/mantendor-feed-main/mantenedor-feed-main.component';
import { MantenedorFeedModalComponent } from './registros/components/mantenedor-feed/mantenedor-feed-modal/mantenedor-feed-modal/mantenedor-feed-modal.component';
import { MantenedorUsuariosListComponent } from './registros/components/mantenedor-usuarios/mantenedor-usuarios-list/mantenedor-usuarios-list.component';
import { OficiosComponent } from './registros/components/oficios/oficios.component';
import { PortafolioAvanceDocentemasComponent } from './registros/components/portafolio-avance/portafolio-avance.component';
import { PortafolioDocentemasComponent } from './registros/components/portafolio-docentemas/portafolio-docentemas.component';
import { PortafolioComponent } from './registros/components/portafolio-historico/portafolio.component';
import { RegistroDocenteListComponent } from './registros/components/registro-docentes/registro-docente-list/registro-docente-list.component';
import { RegistroDocenteFormComponent } from './registros/components/registro-encargado-evaluacion/registro-encargado-evaluacion-form/registro-encargado-evaluacion-form.component';
import { RegistroEncargadoEvaluacionListComponent } from './registros/components/registro-encargado-evaluacion/registro-encargado-evaluacion-list/registro-encargado-evaluacion-list.component';
import { RepresentanteLegalFormComponent } from './registros/components/representantes-legales/representante-legal-form/representante-legal-form.component';
import { RepresentantesLegalesComponent } from './registros/components/representantes-legales/representante-legal-list/representantes-legales.component';
import { TicketsMdaComponent } from './registros/components/tickets-mda/tickets-mda.component';
import { UsuariosOperacionesMdaComponent } from './registros/components/usuarios-operaciones-mda/usuarios-operaciones-mda.component';
import { UsuariosOperacionesComponent } from './registros/components/usuarios-operaciones/usuarios-operaciones.component';
import { QaGrabacionesComponent } from './registros/components/grabaciones/qa-grabaciones/qa-grabaciones.component';
import { DescargaInformesComponent } from './registros/components/descarga-informes/descarga-informes.component';
import { ReportesComponent } from './registros/components/reportes/reportes.component';

const routes: Routes = [
  {path: '', component: LoginComponent, canActivate: [LoginGuard]},
  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: 'recover', component: RecoverPasswordComponent, canActivate: [LoginGuard]},
  {
    path: 'private',
    component: HomeComponent,
    children:[
      { path: '', redirectTo: 'inicio', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'inicio', component: InicioComponent },
      { path: 'registro-encargados-evaluacion-list', component: RegistroEncargadoEvaluacionListComponent },
      { path: 'registro-encargados-evaluacion-form', component: RegistroDocenteFormComponent},
      { path: 'evaluaciones-historicas', component: EvaluacionesHistoricasComponent},
      { path: 'representantes-legales', component: RepresentantesLegalesComponent},
      { path: 'representantes-legales-form', component: RepresentanteLegalFormComponent},
      { path: 'registro-docente-list', component: RegistroDocenteListComponent},
      { path: 'mantenedor-usuarios-list', component: MantenedorUsuariosListComponent},
      { path: 'ficha-docente', component: FichaDocenteComponent},
      { path: 'evaluaciones-historicas-extendida', component: EvaluacionesHistoricasExtendidaComponent},
      { path: 'mantenedor-usuarios-cognito', component: UsuariosOperacionesComponent},
      { path: 'mantenedor-usuarios-cognito-mda', component: UsuariosOperacionesMdaComponent},
      { path: 'oficios', component: OficiosComponent},
      { path: 'cargas-masivas', component: CargasMasivasComponent},
      { path: 'tickets-mda', component: TicketsMdaComponent},
      { path: 'gestor-usuarios-mda', component: UsuariosOperacionesMdaComponent},
      { path: 'noticias', component: MantenedorFeedMainComponent},
      { path: 'mantenedor-feed-modal', component: MantenedorFeedModalComponent},
      { path: 'eventos', component: EventosComponent},
      { path: 'cierre-procesos-docentemas', component: CierreProcesosComponent},
      { path: 'portafolio2023', component: PortafolioComponent },
      { path: 'historial-llamadas', component: HistorialLlamadasComponent },
      { path: 'portafolio-actual', component: PortafolioAvanceDocentemasComponent },
      { path: 'portafolio-docentemas', component: PortafolioDocentemasComponent },
      { path: 'descarga-informes', component: DescargaInformesComponent },
      { path: 'tracklog/ficha-seguimiento', component: FichaSeguimientoComponent},
      { path: 'tracklog/recepcion-bulto-grabaciones', component: RecepcionBultoComponent},
      { path: 'tracklog/apertura-caja-grabaciones', component: AperturaCajaComponent},
      { path: 'tracklog/qa-grabaciones', component: QaGrabacionesComponent},
      { path: 'reportes', component: ReportesComponent },
      { path: '**', component: PageNotFoundComponent },
    ],
    canActivate: [HomeGuard]
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  //imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
