import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { ErrorMensajeService } from "src/app/base/services/errorMensaje.service";
import { DescargaInformesService } from "../../services/descarga-informes.service";

@Component({
  selector: 'app-descarga-informes',
  templateUrl: './descarga-informes.component.html',
  styleUrls: ['./descarga-informes.component.css']
})
export class DescargaInformesComponent{

  payload: any;
  searching!: boolean;
  rutDocente!: number;
  informe!: string;
  informeNoEncontradoBD: boolean = false;
  informeNoEncontradoBucket: boolean = false;
  isLoading = false;
  mensajeError: boolean = false;
  @ViewChild('iframe') iframe!: ElementRef;

  constructor(
    private descargaInformesService: DescargaInformesService,
    private errorMensaje: ErrorMensajeService,
  ) { }

  buscar(rut:number,archivos:string){
    this.informeNoEncontradoBD = false;
    this.informeNoEncontradoBucket = false;
    this.isLoading = true;
    this.iframe.nativeElement.contentWindow.location.replace("about:blank");
    this.descargaInformesService.obtenerArchivo(rut, archivos).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.iframe.nativeElement.contentWindow.location.replace(response.body.data);
      },
      error: (error: any) => {
        this.isLoading = false;
        if (error.status === 400) {
          this.informeNoEncontradoBD = true;
          return;
        }
        if (error.status === 404) {
          this.informeNoEncontradoBucket = true;
          return;
        }
        this.mensajeError = true;
        this.errorMensaje.show(error, "obtener el documento para el usuario " + rut);
      },

    });
    }

}
