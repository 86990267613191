import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Constants } from 'src/app/base/constants/constants';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Toast } from 'src/app/base/services/toast';
import Swal from 'sweetalert2';
import {
  Collapse,
  Dropdown,
  Ripple,
  SmoothScroll,
  initTE
} from "tw-elements";
import { Docente } from '../../models/docente';
import { FichaDocenteFilter } from '../../models/fichaDocenteFilter';
import { HistorialValidacion } from '../../models/historialValidacion';
import { PaginationOptions } from '../../models/paginationOptions';
import { FichaDocenteService } from '../../services/ficha-docente';
import { PortafolioAvanceService } from '../../services/portafolio-avance.service';

@Component({
  selector: 'app-portafolio-avance',
  templateUrl: './portafolio-avance.component.html',
  styleUrls: ['./portafolio-avance.component.css']
})
export class PortafolioAvanceDocentemasComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel: boolean = false;
  filtros: FichaDocenteFilter = new FichaDocenteFilter();
  isLoading!: boolean;
  portafolios!:any;
  pageIndex!: number;
  length!: number;
  dataSource: MatTableDataSource<Docente> = new MatTableDataSource();
  options: PaginationOptions = new PaginationOptions();

  @ViewChild('primerMenuAcordeon') primerMenuAcordeon: ElementRef;
  @ViewChild('primerMenuEdicion') primerMenuEdicion: ElementRef;
  @ViewChild('infoDatosPersonales') infoDatosPersonales: TemplateRef<any>;
  @ViewChild('infoDatosEstablecimiento') infoDatosEstablecimiento: TemplateRef<any>;
  @ViewChild('infoDatosInscripcion') infoDatosInscripcion: TemplateRef<any>;
  @ViewChild('infoValidacion') infoValidacion: TemplateRef<any>;
  @ViewChild('infoEximirSuspender') infoEximirSuspender: TemplateRef<any>;
  @ViewChild('infoHistorial') infoHistorial: TemplateRef<any>;
  doneTimeline: boolean;
  fechaCorteValidacion!: Date;
  historialValidacion: [null | HistorialValidacion];
  mostrarInfoDocente: boolean;
  docenteSeleccionadoModal!: any;
  acordeonesInfoDocenteName: string = "acordeonInfoDocente";
  acordeonesInfoDocente: {
    content: TemplateRef<any>,
    id: string,
    title: string,
    target: string,
    showFirst: boolean,
    keepOpen: boolean,
    context: any,
    injector:any,
  }[] = [];

  personalDataSection: { key: string, value: string }[] = [
    { key: 'rutDocente', value: 'Rut Docente' },
    { key: 'nombres', value: 'Nombres' },
    { key: 'apellidos', value: 'Apellidos' },
    { key: 'email', value: 'Email' },
    { key: 'numeroContacto1', value: 'Teléfono' },
    { key: 'numeroContacto2', value: 'Teléfono Opcional' },
  ]

  establishmentDataSection: { key: string, value: string }[] = [
    { key: 'nombreEstablecimiento', value: 'Nombre Establecimiento' },
    { key: 'rbd', value: 'RBD' },
    { key: 'rutSostenedor', value: 'Rut Sostenedor' },
    { key: 'nombreSostenedor', value: 'Nombre Sostenedor' },
  ]

  inscriptionDataTableSection: { key: string, value: string }[] = [
    { key: 'estadoRegistro', value: 'Estado Registro' },
    { key: 'debeRendir', value: 'Debe Rendir' },
    { key: 'codNivel', value: 'Agrupación' },
    { key: 'codSubsector', value: 'Asignatura' },
  ]

  inscriptionDataSection: { key: string, value: string }[] = [
    { key: 'estadoRegistro', value: 'Estado Registro' },
    { key: 'debeRendir', value: 'Debe Rendir' },
    { key: 'codNivel', value: 'Agrupación' },
    { key: 'codSubsector', value: 'Asignatura' },
    { key: 'motivoNoInscripcion', value: 'Motivo no Inscripción' },
    { key: 'fechaInscripcion', value: 'Fecha Inscripción' },
    { key: 'fechaUltMod', value: 'Fecha Ultima Modificación' },
  ]

  validationDataTableSection: { key: string, value: string }[] = [
    { key: 'estadoValidacion', value: 'Estado Validación' },
    { key: 'fechaValidacion', value: 'Fecha Validación' },
  ]

  validationDataSectionWithoutChanges: { key: string, value: string }[] = [
    { key: 'estadoValidacion', value: 'Estado Validación' },
    { key: 'agnoPortafolioCorregido', value: 'Últ. Portafolio Corregido'},
  ];

  validationDataSection: { key: string, value: string }[] = this.validationDataSectionWithoutChanges;

  validationDataSectionFull: { key: string, value: string }[] = [
    { key: 'estadoValidacion', value: 'Estado Validación' },
    { key: 'agnoPortafolioCorregido', value: 'Últ. Portafolio Corregido'},
  ]

  exemptSuspendDataSection: { key: string, value: string }[] = [
    { key: 'fechaSolicitud', value: 'Fecha de Solicitud Suspender '},
    { key: 'fechaResultado', value: 'Fecha Respuesta Sostenedor'},
    { key: 'estadoEximirSuspender', value: 'Estado Eximir Suspender' },
    { key: 'motivoSuspenderEximir', value: 'Motivo Eximir Suspender' },
  ]


  keyValue: { key: string, value: string }[] = this.personalDataSection
    .concat(this.establishmentDataSection)
    .concat(this.inscriptionDataSection)
    .concat(this.validationDataSection);

  displayedColumnsModal: { [key: string]: string } = this.keyValue.reduce((acc: { [key: string]: string }, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});

  constructor( private servicio: PortafolioAvanceService,
    private fichaDocenteService: FichaDocenteService,
    private errorMensaje: ErrorMensajeService,
  ){}

  ngAfterViewInit() {
    if (!this.paginator) return;
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;

  }

  ngOnInit(): void {

    initTE({ Dropdown, Ripple, Collapse, SmoothScroll });
    this.filtros = new FichaDocenteFilter();
    this.obtenerPortafolioDocente();
  }

  modalTareas(modulo: string) {

  }

  postProcesamientoPortafolios(response:any):any {
    const uniqueRutDocentes = [...new Set(response.map((item:any) => item.rutDocente))];
    const result = uniqueRutDocentes.map(rutDocente => {
      const docentesData = response.filter((item:any) => item.rutDocente === rutDocente);
      const uniqueSostenedor = [...new Set(docentesData.map(
        (item:any) => `${item.rutSostenedor}-${item.emailSostenedor}`))]
        .map((item:any) => {
          const [rutSostenedor, emailSostenedor] = item.split('-');
          return { rutSostenedor: Number(rutSostenedor), emailSostenedor: emailSostenedor };
        });

      return {
        rutDocente: rutDocente,
        docente: docentesData[0],
        sostenedores: uniqueSostenedor
      };
    });
    return result;
  }

  obtenerPortafolioDocente() {
    this.isLoading = true;
    this.servicio.portafolioList(
      this.options,
      this.filtros.rutDocente,
      this.filtros.rutSostenedor,
      this.filtros.rbd
    ).subscribe(
      { next : (response:any) => {
        if (!response.portafolios) {
          this.isLoading = false;
          return;
        }
        response = response.portafolios;
        this.portafolios = this.postProcesamientoPortafolios(response.list);
        this.dataSource = new MatTableDataSource<Docente>(this.portafolios);
        this.pageIndex = (response.pageNum - 1);
        this.length = response.total;
        this.isLoading = false;
        }, error: (error: any) => {
          this.isLoading = false;
          this.errorMensaje.show(error, "obtener los docentes").then((result:any) => {
            if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
              this.isLoading = false;
            }
          });
      }});

  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 5;
    this.obtenerPortafolioDocente();
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 5;
    this.obtenerPortafolioDocente();
  }

  public limpiar(): void {
    this.filtros = new FichaDocenteFilter();
    this.filtrar();
  }

  getFechaCorte() {
    this.fichaDocenteService.getFechaCorte().subscribe(
      {
        next: (response:any) => {
          if (response) this.fechaCorteValidacion = new Date(response.fecha);
        },
        error: (error:any) => {
          this.fechaCorteValidacion = new Date("2024-06-19T00:00:00");
        }
      }
    );
  }

  descargarExcel(){
    const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/csv`;
    const filename = "listaAvancePortafolioDocente.csv"
    this.estaDescargandoExcel = true;

    this.servicio.descargarAvance(
      url,
      this.filtros.rutDocente,
      this.filtros.rutSostenedor,
      this.filtros.rbd
    ).subscribe(
      {
        next: (response: any) => {
          Toast.fire(
            "Descarga de avance de Portafolio Docente",
            "Descarga finalizada",
            "success"
          );
          this.servicio.downloadBlob(filename, response);
          this.estaDescargandoExcel = false;
        }, error: (error: any) => {
          this.errorMensaje.show(error, "descargar el avance de portafolio docente");
          this.estaDescargandoExcel = false;
        }
      });
  }


  datosDocenteValidados(): boolean{
    return new Date (this.docenteSeleccionadoModal['fechaValidacion'])
      .getTime() < this.fechaCorteValidacion.getTime();
  }

  obtenerDocente(element: Docente) {
    this.docenteSeleccionadoModal = null;
    this.docenteSeleccionadoModal = element;
    this.mostrarInfoDocente = false;
    this.acordeonesInfoDocente = [];
    this.docenteSeleccionadoModal.ultimoLogin = 'Consultando detalles de docente';
    const rut = this.docenteSeleccionadoModal.rutDocente;
    const idDocente = this.docenteSeleccionadoModal.idDocente;
    this.validationDataSection = this.validationDataSectionFull;
    if (!this.docenteSeleccionadoModal.solicitaCambioSubs) {
      this.validationDataSection = this.validationDataSectionWithoutChanges;
    }
    this.fichaDocenteService.getDocenteDetalles(idDocente, rut)
    .subscribe(
      { next: (response:any) => {
        if (response) {
          this.docenteSeleccionadoModal.nivelPrevio = response.nivelPrevio;
          this.docenteSeleccionadoModal.ultimoLogin = response.ultimoLogin;
          this.docenteSeleccionadoModal.subsectorPrevio = response.subsectorPrevio;
          this.docenteSeleccionadoModal.fechaUltEstado = response.fechaUltEstado;
          this.docenteSeleccionadoModal.solicitaCambioSubsector = response.solicitaCambioSubsector;
          this.docenteSeleccionadoModal.nivelSolicitado = response.nivelSolicitado;
          this.docenteSeleccionadoModal.subsectorSolicitado = response.subsectorSolicitado;
          this.docenteSeleccionadoModal.estadoConfirmacionSubsector = response.estadoConfirmacionSubsector;
          this.docenteSeleccionadoModal.aplazamiento = response.aplazamiento;
          this.docenteSeleccionadoModal.beneficio19n = response.beneficio19n;
          this.docenteSeleccionadoModal.agnoPortafolioCorregido = response.agnoPortafolioCorregido;
          this.docenteSeleccionadoModal.tramos = response.tramos;
          this.docenteSeleccionadoModal.nombreNivelPrevio = response.nombreNivelPrevio;
          this.docenteSeleccionadoModal.nombreNivelSolicitado = response.nombreNivelSolicitado;
          this.docenteSeleccionadoModal.nombreSubsectorPrevio = response.nombreSubsectorPrevio;
          this.docenteSeleccionadoModal.nombreSubsectorSolicitado = response.nombreSubsectorSolicitado;
          this.docenteSeleccionadoModal.codDebeRendir = response.debeRendir;
          this.docenteSeleccionadoModal.fechaInicioSolicitud = response.fechaInicioSolicitud;
          this.docenteSeleccionadoModal.fechaResultado = response.fechaResultado;
          this.docenteSeleccionadoModal.fechaSolicitud = response.fechaSolicitud;
        }
        else {
          this.docenteSeleccionadoModal.ultimoLogin = "No ha ingresado a la plataforma"
        }
      },
        error: (error:any) => {}
      });
    this.doneTimeline = false;
    this.fichaDocenteService.getHistorialValidacion(rut)
      .subscribe(
        {
          next: (response:any) => {
            this.doneTimeline = true;
            if (!response || response.length == 0) response = null;
            this.historialValidacion = response;
              this.acordeonesInfoDocente.push(
                {
                  content: this.infoDatosPersonales,
                  id: "headingOne",
                  title: "Datos Personales",
                  target: "#acordeonUno",
                  showFirst: true,
                  keepOpen: false,
                  context:{
                    personalDataSection:this.personalDataSection,
                    docenteSeleccionadoModal:this.docenteSeleccionadoModal
                  },
                  injector:null,
                });
              this.acordeonesInfoDocente.push(
                {
                  content: this.infoDatosEstablecimiento,
                  id: "headingTwo",
                  title: "Datos de Establecimiento",
                  target: "#acordeonDos",
                  showFirst: false,
                  keepOpen: false,
                  context:{
                    establishmentDataSection: this.establishmentDataSection,
                    docenteSeleccionadoModal: this.docenteSeleccionadoModal
                  },
                  injector:null,
                });
                this.acordeonesInfoDocente.push(
                  {
                    content: this.infoDatosInscripcion,
                    id: "headingThree",
                    title: "Datos de Inscripción",
                    target: "#acordeonTres",
                    showFirst: false,
                    keepOpen: false,
                    context:{
                      inscriptionDataSection: this.inscriptionDataSection,
                      docenteSeleccionadoModal: this.docenteSeleccionadoModal
                    },
                    injector:null,
                  });

                  this.acordeonesInfoDocente.push(
                    {
                      content: this.infoValidacion,
                      id: "headingFour",
                      title: "Ficha de Validación de Datos y Beneficios",
                      target: "#acordeonCuatro",
                      showFirst: false,
                      keepOpen: false,
                      context:{
                        validationDataSection: this.validationDataSection,
                        esFecha: this.esFecha,
                        docenteSeleccionadoModal: this.docenteSeleccionadoModal,
                        historialValidacion: response,
                      },
                      injector:null,
                    });
                this.acordeonesInfoDocente.push(
                  {
                    content: this.infoEximirSuspender,
                    id: "headingFive",
                    title: "Datos de Eximir Suspender",
                    target: "#acordeonCinco",
                    showFirst: false,
                    keepOpen: false,
                    context:{
                      exemptSuspendDataSection: this.exemptSuspendDataSection,
                      docenteSeleccionadoModal: this.docenteSeleccionadoModal
                    },
                    injector:null,
                  });


          },
          error: (err:any) => {

          },
      });



      this.mostrarInfoDocente = true;
  }

  esFecha(atributo: string): boolean {
    return atributo.toLowerCase().includes('fecha');
  }

  copyText(rut: number): void {
    navigator.clipboard.writeText(rut.toString()).then(() => {
      Swal.fire("Rut Copiado","","success",);
    }).catch(err => {
      console.error('Error al copiar el texto: ', err);
    });
  }
}
