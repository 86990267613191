import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import player from 'lottie-web/build/player/lottie_light';
import { LottieModule, provideLottieOptions } from 'ngx-lottie';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from '../app-routing.module';
import { UsuariosOperacionesComponent } from '../registros/components/usuarios-operaciones/usuarios-operaciones.component';
import { AcordeonTWComponent } from './components/acordeon-tw/acordeon-tw.component';
import { HomeComponent } from './components/home/home.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { ListaAutocompletableComponent } from './components/lista-autocompletable/lista-autocompletable.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    LoginComponent,
    RecoverPasswordComponent,
    HomeComponent,
    InicioComponent,
    UsuariosOperacionesComponent,
    AcordeonTWComponent,
    ListaAutocompletableComponent,
  ],
    imports: [
        CommonModule,
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        NgxPaginationModule,
        MatListModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatProgressBarModule,
        MatIconModule,
        FormsModule,
        MatTooltipModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatSelectModule,
        LottieModule.forRoot({ player: playerFactory }),
        PageNotFoundComponent,
    ],
  exports: [
    AcordeonTWComponent,
    LottieModule,
    PageNotFoundComponent,
    ListaAutocompletableComponent,
  ],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web/build/player/lottie_light'),
    }),
  ],
})
export class BaseModule { }
