import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Constants } from "src/app/base/constants/constants";
import { AuthService } from "src/app/base/services/auth.service";


@Injectable({
  providedIn: 'root'
})
export class DescargaInformesService {

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  obtenerArchivo(rut:number, archivo:string): Observable<any>{
    let header = new HttpHeaders();
    const url = `${Constants.URL_SERVICE.BASE_ARCHIVOS + Constants.URL_SERVICE.DESCARGA_INFORMES.DESCARGA}/${rut}/archivo/${archivo}  `;
    header = header.set('Authorization','Bearer '+ this.authService.getToken());
    return this.http.get(url, {headers: header, responseType: 'json', observe: 'response'});
  }

}
