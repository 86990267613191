import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Toast } from 'src/app/base/services/toast';
import { Noticia } from 'src/app/registros/models/noticia';
import { MantenedorFeedService } from 'src/app/registros/services/mantenedor-feed.service';

@Component({
  selector: 'app-mantenedor-feed-modal',
  templateUrl: './mantenedor-feed-modal.component.html',
  styleUrls: ['./mantenedor-feed-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MantenedorFeedModalComponent {

  constructor(
    private mantenedorFeedService: MantenedorFeedService,
    private errorMensaje: ErrorMensajeService,
    private formBuilder: FormBuilder,
  ) { this.form = new FormGroup({
        orden: new FormControl('', [
          Validators.required,
          Validators.min(1)
        ]),
        mensaje: new FormControl('', [
          Validators.required
        ]),
        perfiles: new FormControl('', [
          Validators.required
        ]),
        mostrar: new FormControl('', [])
      })
  }

  form: any;
  get orden() {
    return this.form.get('orden')
  }
  get mensaje() {
    return this.form.get('mensaje')
  }
  get perfiles() {
    return this.form.get('perfiles')
  }
  get mostrar() {
    return this.form.get('mostrar')
  }
  updateNoticia: Noticia;
  @Input() noticiaSelected: Noticia;
  @Output() updateNoticiaSelected = new EventEmitter<Noticia>();
  @Output() permanecerEnForm = new EventEmitter<boolean>();

  // Cambiar por consulta dinamica a servicio backend
  perfilesStatic = [
    {id: 1, nombre: "Encargado MUN-SLEP / Sostenedor MUN-SLEP"},
    {id: 2, nombre: "Director MUN-SLEP"},
    {id: 3, nombre: "Docente"},
    {id: 4, nombre: "Sostenedor PSAD"},
    {id: 5, nombre: "Director PSAD"},
    {id: 7, nombre: "Sostenedor JJII"},
    {id: 8, nombre: "Director JJII"},
    {id: 11, nombre: "Encargado PSAD"},
    {id: 12, nombre: "Encargado JJII"},
    {id: 13, nombre: "Docente Informe"},
    {id: 14, nombre: "Docente ECEP"},
    {id: 15, nombre: "Docente Tramo / Jefe UTP MUN-SLEP"},
    {id: 21, nombre: "Docente Objetado"},
    {id: 30, nombre: "MINEDUC"},
  ]

  ngOnInit() {
    if (this.noticiaSelected != undefined && this.noticiaSelected != null) {
      this.form = new FormGroup({
        orden: new FormControl(this.noticiaSelected.orden || '', [
          Validators.required,
          Validators.min(1)
        ]),
        mensaje: new FormControl(this.noticiaSelected.mensaje || '', [
          Validators.required
        ]),
        perfiles: new FormControl(this.noticiaSelected.perfiles || '', [
          Validators.required
        ]),
        mostrar: new FormControl(this.noticiaSelected.mostrar || '', [])
      })
    }
  }

  closeModal(){
    this.mantenedorFeedService.$modal_feed.emit(false);
    this.updateNoticia = null;
    this.updateNoticiaSelected.emit(this.updateNoticia);
  }

  agregarNoticia() {
    const formData = new Noticia (null, this.form.value.orden, this.form.value.mensaje, this.form.value.perfiles, this.form.value.mostrar);
    this.mantenedorFeedService.agregarNoticia(formData).subscribe(
      {
        next: (response: Noticia) => {
          Toast.fire("Noticia agregada", response.mensaje , "success");
          this.closeModal();
          this.permanecerEnForm.emit(false);
        },
        error: (error: any) => {
          this.errorMensaje.show(error, 'agregar la noticia');
        }
      }
    );
  }

  editarNoticia() {
    const formData = {
      id: this.noticiaSelected.id,
      orden: this.form.value.orden,
      mensaje: this.form.value.mensaje,
      perfiles: this.form.value.perfiles,
      mostrar: this.form.value.mostrar
    };
    this.mantenedorFeedService.editarNoticia(formData).subscribe(
      {
        next: (response: any) => {
          Toast.fire("Noticia editada", response.mensaje , "success");
          this.closeModal();
          this.permanecerEnForm.emit(false);
        },
        error: (error: any) => {
          this.errorMensaje.show(error, 'editar la noticia');
        }
      }
    );
  }
}
