<div *ngIf="isLoading" class="flex items-center justify-center">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="relative">
  <h2 class="text-lg font-semibold mb-4">Complete las Respuestas</h2>
  <p class="text-gray-700 mb-6">Seleccione la opción que considere correcta para cada pregunta. Las respuestas se guardarán automáticamente al realizar una selección.</p>

  <!-- Ícono de candado si el usuario es readonly -->
  <div *ngIf="readonly" class="absolute top-2 right-2 z-10 flex items-center text-gray-500">
    <mat-icon>lock</mat-icon>
  </div>

  <!-- Spinner en la esquina superior derecha dentro del componente -->
  <div *ngIf="isSaving" class="absolute top-2 right-12 z-10">
    <mat-spinner diameter="30"></mat-spinner>
  </div>

  <!-- Formulario de preguntas -->
  <form class="space-y-4">
    <div *ngFor="let pregunta of preguntas" class="bg-gray-50 p-4 rounded-md shadow-sm">
      <p class="font-medium text-gray-800 mb-2">{{ pregunta.pregunta }}</p>
      <p class="text-gray-600 mb-4">{{ pregunta.descripcion }}</p>
      <!-- Indicador de que la pregunta está bloqueada -->

      <div *ngIf="pregunta.bloqueado" class="mb-2 text-red-600 flex items-center">
        <mat-icon class="mr-2">lock</mat-icon>
        <span>Esta pregunta está bloqueada y no se puede modificar.</span>
      </div>

      <mat-radio-group
        [disabled]="!asignadoAEsteOperador || isSaving || readonly || pregunta.bloqueado"
        [ngModel]="getRespuesta(pregunta.rubricaId)"
        (ngModelChange)="setRespuesta(pregunta.rubricaId, $event)"
        name="respuesta-{{ pregunta.rubricaId }}"
      >
        <mat-radio-button
          *ngFor="let alternativa of pregunta.alternativas"
          [value]="alternativa.id"
        >
          {{ alternativa.respuesta }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </form>
</div>
