import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ErrorMensajeService } from '../../../base/services/errorMensaje.service';
import { CargasMasivasService } from '../../services/cargas-masivas.service';
import { PaginationOptions } from '../../models/paginationOptions';
import { Toast } from 'src/app/base/services/toast';
import Swal from 'sweetalert2';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-carga-masiva',
  templateUrl: './carga-masiva.component.html',
  styleUrls: ['./carga-masiva.component.css']
})

export class CargasMasivasComponent implements AfterViewInit{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  users:any
  pageIndex: number;
  length: number;
  faSpinner = faSpinner;
  estaCargandoTabla: boolean = false;
  seHizoPrimeraCarga: boolean = false;
  options: PaginationOptions = new PaginationOptions();
  selectedFile: File | null = null;
  opciones = [
    { id: 1, nombre: 'nombres', checked: false, showName: "Nombres" },
    { id: 2, nombre: 'primerApellido', checked: false, showName: "Primer Apellido" },
    { id: 3, nombre: 'segundoApellido', checked: false, showName:"Segundo Apellido" },
    { id: 4, nombre: 'rbd', checked: false, showName: "RBD" },
    { id: 5, nombre: 'estado', checked: false, showName: "Estado"},
    { id: 6, nombre: 'debeRendir', checked: false, showName: "Debe Rendir" },

];

  constructor(
    private cargasMasivasService: CargasMasivasService,
    private errorMensaje: ErrorMensajeService) {

     }
  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
  }

  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
  }

  readCsvFile(){
    this.estaCargandoTabla = true;
    if (this.selectedFile){
      this.cargasMasivasService.verificarHeaders(this.selectedFile).subscribe({
        next: (response) => {
          if(response){
            this.cargasMasivasService.cargarDatos(this.selectedFile).subscribe({
              next:(respuesta)=>{
                this.cargasMasivasService.cargasList(this.options).subscribe({
                  next:(data)=>{
                    this.users = data.list;
                    this.length = data.total;
                    this.seHizoPrimeraCarga = true;
                    this.limpiarCampo();
                    this.estaCargandoTabla = false;
                  }

                });
              }

            })
          }
          else{
            this.errorMensaje.show("Error ", "al seleccionar el archivo, verifique que es el correcto ");
            this.estaCargandoTabla = false;
          }
        },
        error: (error) => {
          this.errorMensaje.show(error, "obtener los headers");
          this.estaCargandoTabla = false;
        }

      });
    }
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    if (this.seHizoPrimeraCarga){
      this.cambioPagina();
    }
  }

  cargarDatosTemp(){
    const datosParaEnviar: Map<string, boolean> = new Map();
    this.opciones.forEach(opcion => {
        datosParaEnviar.set(opcion.nombre, opcion.checked);
    });
    this.cargasMasivasService.cargarDatosTemp(datosParaEnviar).subscribe({
      next: (response) => {
        Toast.fire(
          "Carga Masiva",
          "Finalizada exitosamente",
          "success"
        );
      },
      error: (err) => {
        this.errorMensaje.show(err, " guardar datos en la tabla temporal");
      }

    })

  }

  confirmarCarga(){
    Swal.fire({
      title: '¿Desea Realizar la carga?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result:any) => {
      if (result.isConfirmed) {
        this.cargarDatosTemp();
      }
    })
  }

  limpiarCampo(){
    this.selectedFile = null;
  }

  cambioPagina(){
    this.cargasMasivasService.cargasList(this.options).subscribe({
      next:(data)=>{
        this.users = data.list;
        this.length = data.total;
      }

    });
  }

}
