import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { RepresentanteLegal } from 'src/app/registros/models/representanteLegal';
import { RepresentanteService } from 'src/app/registros/services/representante.service';
import { Toast }  from '../../../../base/services/toast';

@Component({
  selector: 'app-representante-legal-form',
  templateUrl: './representante-legal-form.component.html',
  styleUrls: ['./representante-legal-form.component.scss']
})
export class RepresentanteLegalFormComponent implements AfterContentInit, AfterContentChecked {
  filtros: RepresentanteLegal = new RepresentanteLegal();
  inputs: { label: string, control: FormControl, readonly: boolean, attribute: string, type: string, error: string, errorMax: string }[] = [];
  errorRutEmpresa: boolean = false;
  modoEdicion: boolean = false;
  esMunicipal = true;
  optionsTipo: any[] = [];
  tipos:any = {}
  tiposRepresentante: { [key: string]: number } = {
    "Coordinador Territorial": 0,
    "Coordinador Comunal": 1,
    "Representante Legal": 2
  }
  optionsTipoMunicipal = [
    {value: 0, nombre: "Coordinador Territorial"},
    {value: 1, nombre: "Coordinador Comunal"},
  ];
  optionsTipoNoMunicipal = [
    {value: 2, nombre: "Representante Legal"}
  ];

  @Input() datosEditar: RepresentanteLegal=null;
  @Output() permanecerEnForm = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private representanteService: RepresentanteService,
    private errorMensaje: ErrorMensajeService,
    private cdRef: ChangeDetectorRef)

    { }

  formulario: FormGroup;
  @ViewChild("formRepresentante")
  formRepresentante: NgForm;

  ngOnInit(): void {

    this.inputs = [
      { label: this.filtros.getFieldForm(0), control: this.filtros.getControl(0),readonly: false, attribute: 'rut', type: 'number', error: 'Se requiere el Rut', errorMax: 'el rut no es válido' },
      { label: this.filtros.getFieldForm(1), control: this.filtros.getControl(1),readonly: false, attribute: 'rutEmpresa', type: 'number', error: 'Se requiere el Rut Empresa', errorMax: 'el rut no es válido' },
      { label: this.filtros.getFieldForm(2), control: this.filtros.getControl(2),readonly: true,  attribute: 'nombreEmpresa', type: 'string', error: '', errorMax: ''},
      { label: this.filtros.getFieldForm(3), control: this.filtros.getControl(3),readonly: false, attribute: 'tipo', type: 'string', error: 'Se requiere el tipo', errorMax: '' },
      { label: this.filtros.getFieldForm(4), control: this.filtros.getControl(4),readonly: false, attribute: 'nombres', type: 'string', error: 'Se requiere el nombre', errorMax: 'el nombre ingresado no es válido' },
      { label: this.filtros.getFieldForm(5), control: this.filtros.getControl(5),readonly: false, attribute: 'primerApellido', type: 'string', error: 'Se requiere el primer apellido', errorMax: 'el primer apellido ingresado no es válido' },
      { label: this.filtros.getFieldForm(6), control: this.filtros.getControl(6),readonly: false, attribute: 'segundoApellido', type: 'string', error: 'Se requiere el segundo apellido', errorMax: 'el segundo apellido ingresado no es válido'},
      { label: this.filtros.getFieldForm(7), control: this.filtros.getControl(7),readonly: false, attribute: 'correo', type: 'string', error: 'Se requiere el correo', errorMax:'el correo ingresado no es válido'},
      { label: this.filtros.getFieldForm(8), control: this.filtros.getControl(8),readonly: false, attribute: 'telefono', type: 'number', error: 'Se requiere el teléfono', errorMax:'el teléfono ingresado no es válido' },
    ];
    this.formulario = this.formBuilder.group({});
  }

  ngAfterContentInit(): void {
    this.formulario = this.formBuilder.group({});
    if (!this.modoEdicion && this.datosEditar.rut != null) {
      this.modoEdicion = true;
      this.datosEditar = this.datosEditar as RepresentanteLegal;
      this.filtros = this.datosEditar;
      this.getNombreEmpresa(1);
      this.inputs[0].readonly= true;
      this.inputs[1].readonly= true;
      return;
    }
    this.modoEdicion = false;
    this.inputs.map(item => {
      this.formulario.addControl(item.label, item.control);
    });
    this.datosEditar = null;
    this.inputs[0].readonly= false;
    this.inputs[1].readonly= false;
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  onInputChange(value: any, type: string, attributeName: string) {
    if (type == 'number' && value && value instanceof String) {
      value = value.replace(/^\d*[0-9]\d*$/,"");
      value = Number(value)
    }
    this.filtros[attributeName] = value;
  }

  getErrorMessage(control: FormControl,mensaje:string ,mensajeMax: string) {
    if (control.hasError('required')) {
      return mensaje;
    }

    if(control.hasError('maxlength')) {
      return mensajeMax;
    }

    return control.hasError('email') ? 'El email no es válido' : '';
  }

  getNombreEmpresa(index:number) {
    if ( index != 1) return;
    const rutEmpresa = this.filtros.rutEmpresa;
    if( rutEmpresa == 0 || !rutEmpresa) return;
    this.representanteService.getNombreEmpresa(rutEmpresa).subscribe(
      {
        next: (response: {data: any}) => {
          if (response.data.sectorMunicipal){
            this.optionsTipo = this.optionsTipoMunicipal
          } else {
            this.optionsTipo = this.optionsTipoNoMunicipal;
          }
          if (response.data.nombre){
            this.filtros.nombreEmpresa = response.data.nombre;
            this.errorRutEmpresa = false;
            return;
          }
          this.optionsTipo = [];
          this.filtros.nombreEmpresa = "";
          this.errorRutEmpresa = true;
        },
        error: (error:any) => {
          this.errorRutEmpresa = true;
        }
      }
    );
  }

  agregar() {
    const voidFields: string[]= [];
    this.inputs.map((item,index) => {
      const field = this.filtros[item.attribute]
      if (field == null || field == ""|| this.filtros.rut <= 0 ||
       this.filtros.rutEmpresa <=0 || this.filtros.telefono <= 0 ) {
        voidFields.push(item.label);
      }
    });
    if (voidFields.length > 0 || this.errorRutEmpresa) {
      return;
    }
    const tipo: number = this.tiposRepresentante[this.filtros.tipo];
    this.representanteService.agregarRepresentanteLegal(this.filtros, tipo).subscribe(
      {
        next: (response: { data: any; mensaje: string; }) => {
          Toast.fire(
            "Representante Legal agregado",
            response.mensaje,
            "success"
          );
          this.formRepresentante.resetForm();
          this.permanecerEnForm.emit(false);
        },
        error: (error:any) => this.errorMensaje.show(error, "agregar el Representante Legal.")
      }
    );

  }

  editar() {
    if (!this.modoEdicion) {
      return;
    }
    const tipo: number = this.tiposRepresentante[this.filtros.tipo];
    this.representanteService.editarRepresentanteLegal(this.filtros, tipo).subscribe(
      {
        next: (response: { data: any; mensaje: string; }) => {
          Toast.fire(
            "Representante Legal editado",
            response.mensaje,
            "success"
          );
          this.formRepresentante.resetForm();
          this.permanecerEnForm.emit(false);
        },
        error: (error:any) => {
          this.errorMensaje.show(error, "editar al Representante Legal.");
        }
      }
    );
  }

  cancelar() {
    this.formRepresentante.resetForm();
    this.permanecerEnForm.emit(false);
  }
}
