import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Constants } from '../../base/constants/constants';
import { Portafolio2023 } from '../models/Portafolio2023';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class PortafolioDocentemasService {
  estaDescargandoExcelEmmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private http: HttpClient
  ) { }

  emitirEstadoDescargaPortafolio(estado: boolean)
  {
    this.estaDescargandoExcelEmmit.emit(estado);
  }

  getTipos():  Observable<any>
  {
    const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/tipos`;
    return this.http.get(url).pipe(map((response: any) => response));
  }

  getDocentesSospechosos(): Observable<any>
    {
      const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/listasospechosos/`;
      return this.http.get(url).pipe(map(response => response));
    }

  getPortafolios(options: PaginationOptions, filter: Portafolio2023):
    Observable<any>
    {
    const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/pf-docentemas`;
    let params =  this.obtenerParams(filter.idAgrupacion, filter.idDocente, filter.idPregunta, filter.tipo);
    params = params
      .set('page', options.page)
      .set('size', options.size)
    return this.http.get(url, { params: params }).pipe(
      map((response: any) => response));
  }

  private obtenerParams(idAgrupacion:number, idDocente:number, idPregunta:number, tipo: string): HttpParams
  {
    let params = new HttpParams();

    if (idAgrupacion && idAgrupacion > -1) {
      params = params.set('idAgrupacion', idAgrupacion);
    }
    if (idDocente && idDocente > -1) {
      params = params.set('idDocente', idDocente);
    }
    if (idPregunta && idPregunta> -1) {
      params = params.set('idPregunta', idPregunta);
    }
    if (tipo && tipo.trim() != "") {
      params = params.set('tipo', tipo.trim());
    }
    return params;
  }

  obtenerArchivo(archivo: string)
  {
    let header = new HttpHeaders();
    const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/respuesta`;
    header = header.set('Authorization','Bearer ');
    return this.http.get(url, {headers: header, params: {archivo: archivo}});
  }

  descargarSospechosos(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob'}).pipe(map(response => response));
  }

  downloadBlob(filename: string, blob: any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  preguntasPortafolio(rutDocente: number, respondidas: boolean): Observable<any>
    {
      const solorespondidas = respondidas ? 1 : 0
      const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/preguntasformulario/${rutDocente}/respondidas/${solorespondidas}`;
      return this.http.get(url).pipe(map(response => response));
    }

    respuestas(rutDocente: number, agrupacionID: number, preguntaID: number): Observable<any>
    {
      const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/respuesta/${rutDocente}/${agrupacionID}/${preguntaID}`;
      return this.http.get(url).pipe(map(response => response));
    }

    obtenerConfirmacion(rutDocente: number): Observable<any> {
      const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/confirmacion-portafolio/${rutDocente}`;
      return this.http.get(url).pipe(map(response => response));
    }
}
