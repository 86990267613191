export class Bulto{
  id!: number;
  folio!: string;
  seguimiento!: string;
  fecha!: string;
  estado!: number;
  observaciones!: string;
  operador!: string;

}
