import { Injectable } from '@angular/core';
import { EncargadoEstablecimiento } from 'src/app/registros/models/encargadoEstablecimiento';

@Injectable({
    providedIn:'root'
  })
export class EncargadoEdicionService {

    public scope: EncargadoEstablecimiento | null;

    constructor() {
    }

    public popScope(): EncargadoEstablecimiento | null {
        const encargado = this.scope;
        this.scope = null;
        return encargado;
    }

    public pushScope(scope: any): void {
        this.scope = scope;
    }
}