import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

const errorToast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  iconColor: 'white',
  color: 'white',
  showConfirmButton: false,
  timer: 4500,
  timerProgressBar: true,
})

@Injectable({
    providedIn: 'root'
  })
  export class ErrorMensajeService {

    constructor(@Inject(Router) private router: Router,){}

    show ( error:any, contexto: string ) : any {
        let mensaje = 'Ha ocurrido un error al ' + contexto;
        if (error.error){
          mensaje = error.error.hasOwnProperty('error') ? error.error.error : mensaje;
        }

        mensaje = mensaje + ' [' + error.status + "]"
        if (error.status == 0) {
          mensaje = "El servicio no está disponible, intente más tarde [0]";
        }

        if (error.status == 401) {
          mensaje = "Su sesión ha caducado, reingrese sus credenciales";
        }

        if (error.status == 403) {
          mensaje = "No tienes permisos para realizar esta operación [403]";
        }

        const titulo = "Error al intentar " + contexto;

        return errorToast.fire({
          title: titulo,
          text: mensaje,
          icon: "error",
          background: "red"
        });

      }

      showWarning ( error:any, mensaje: string ) : any {
        mensaje = mensaje + ' [' + error.status + "]"

        return errorToast.fire({
          title: "Atención",
          text: mensaje,
          icon: 'warning',
          confirmButtonText: 'Entendido.'
        });

      }
  }
