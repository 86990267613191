import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class PortafolioAvanceService {

  constructor(
    private http: HttpClient
  ) { }


  portafolioList(options: PaginationOptions,
    rutDocente: number = null,
    rutSostenedor: number = null,
    rbd: number = null): Observable<any>
    {
      let params = this.obtenerParamsDocente(rutDocente, rutSostenedor, rbd);
      const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/?page=${options.page}&size=${options.size}`;
      return this.http.get(url, { params: params }).pipe(map(response => response));
    }

  obtenerParamsDocente(rutDocente: any, rutSostenedor: any, rbd: any): HttpParams {
    let params = new HttpParams();

    if (rutDocente && rutDocente > 0) {
      params = params.set('rutDocente', rutDocente);
    }
    if (rutSostenedor && rutSostenedor > 0) {
      params = params.set('rutSostenedor', rutSostenedor);
    }
    if (rbd && rbd > 0) {
      params = params.set('rbd', rbd);
    }
    return params;
  }

  descargarAvance(
    url: string,
    rutDocente: number = null,
    rutEncargado: number = null,
    rutSostenedor: number = null): Observable<any> {
      let params = this.obtenerParamsDocente(rutDocente, rutEncargado, rutSostenedor);
      params = params
      return this.http.get(url, { responseType: 'blob', params: params }).pipe(
        map(response => response)
      );
    }

    downloadBlob(filename: string, blob: any) {
      const dwldLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
      if (isSafariBrowser) {
        dwldLink.setAttribute('target', '_blank');
      }
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute('download', filename);
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
}
