<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO >
      <div class="flex flex-col gap-4 px-2">
        <p class="text-blue-950 text-2xl font-bold">Usuarios Mesa de Ayuda</p>
        <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
          <div class="flex flex-row gap-1 py-2">
            <mat-icon>filter_list</mat-icon>
            <p class="text-lg font-bold">Filtros</p>
          </div>

        <div class="row ms-auto my-auto d-flex">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xl-6 ">
              <mat-form-field class="fullWidth">
                <input matInput [(ngModel)]="filtro.username" placeholder="correo@iie.cl" name="Correo">
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex">
            <div class="mx-2 float-left d-flex align-self-start ">
              <div class="d-flex row">
                  <button
                  type="button"
                  data-te-ripple-init
                  (click)="buscarUsuario()"
                  data-te-ripple-color="light"
                  class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                  <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                  <p class="pr-1"></p> Buscar
                </button>
                <button
                  type="button"
                  data-te-ripple-init
                  (click)="limpiarFiltro()"
                  data-te-ripple-color="light"
                  class="d-flex w-28  m-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                  <p class="pr-1"></p> Limpiar
                </button>
              </div>
            </div>
            <div class="mx-2 float-right ms-auto my-auto d-flex justify-content-end">
              <button
                type="button"
                data-bs-toggle="modal" data-bs-target="#modalForm" (click)="abrirFormCreacion()"
                matTooltip="Agregar un usuario al sistema"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16"> <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/> </svg>
                <p class="pr-1"></p> Agregar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4 px-10 w-full" style="overflow-x:auto;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" class="py-2 my-4" />
    <table role="table" aria-label="tablausuarios-cognito" *ngIf="listaUsuariosCognito?.list"  class="tables-info py-4 w-full" >
      <thead>
        <tr class="py-4 text-sm font-medium uppercase leading-normal text-black">
          <th class="head">Correo del usuario</th>
          <th class="head">Fecha Creación</th>
          <th class="head">Fecha Edición</th>
          <th class="head">Activo</th>
          <th class="head">Acciones</th>
        </tr>
      </thead>
      <tbody *ngIf="listaUsuariosCognito">
        <tr class="etiqueta-azul" *ngFor="let element of listaUsuariosCognito.list">
          <td class="negrita-tabla m-auto items-center py-2 w-fit text-sm text-left font-semibold uppercase leading-normal">
            {{element.email}}
          </td>
          <td class="negrita-tabla m-auto items-center py-2 w-fit text-sm text-left font-semibold uppercase leading-normal">
            {{element.createdAt}}
          </td>
          <td class="negrita-tabla m-auto items-center py-2 w-fit text-sm text-left font-semibold uppercase leading-normal">
            {{element.updatedAt}}
          </td>
          <td class="negrita-tabla m-auto items-center py-2 w-fit text-sm text-left font-semibold uppercase leading-normal">
            {{element.enabled == true? "Activo":"Inactivo"}}
          </td>
          <td class="negrita-tabla" style="text-align: center;">
            <div class="mx-1 btn-group" role="group" style="width: max-content;">
              <button (click)="eliminar(element.email)" aria-label="element.username"
                type="button"
                class="-ml-0.5 inline-block rounded-md   border-2 border-[#005A9C] px-2 py-1 text-[14px] font-medium uppercase leading-normal text-[#005A9C] transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                data-te-ripple-init
                data-te-ripple-color="light">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

          </td>
        </tr>
      </tbody>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
  </mat-paginator>
    <div *ngIf="listaUsuariosCognito?.list?.length == 0" class="centradoXY p-4 m-2 ">
      <p *ngIf="listaUsuariosCognito?.list?.length == 0 && !isLoading">No se encontraron usuarios.</p>
      <p *ngIf="listaUsuariosCognito?.list?.length == 0 && isLoading">Consultando usuarios.</p>
    </div>
  </div>
</mat-card>

<div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="modalAgregarUsuarioLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 *ngIf="!usuarioEditar" class="modal-title fs-5">Vista de grupos del usuario
        </h1>
        <h1 *ngIf="usuarioEditar" class="modal-title fs-5">Modificando los grupos del usuario
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mx-1">
        <mat-card>
          <div class="px-4 row">
            <div class=""></div>
            <form class=" col-md-10 col-xs-12 col-lg-12" [formGroup]="formularioUsuario" novalidate>
              <div class="row px-4">
                <div class="row ">
                  <div class=" ">
                    <h4>Correo</h4>
                    <mat-form-field class="fullWidth px-4">
                      <mat-label>Correo</mat-label>
                      <input matInput type="string" id="email" formControlName="username" [readonly]="!modoCrear" />
                    </mat-form-field>
                  </div>
                </div>
                <mat-error *ngIf="this.formularioUsuario.invalid">
                  <span>
                    Debes ingresar un email.
                  </span>
                </mat-error>
              </div>
              <div class="p-4 float-right">
                <button mat-raised-button class="mx-2" data-bs-dismiss="modal">
                  <mat-icon>keyboard_return</mat-icon>
                  Cancelar</button>
                <button *ngIf="!usuarioEditar || usuarioEditar && !usuarioEditar.username" mat-raised-button
                  color="primary" (click)="agregarForm()" [disabled]="this.formularioUsuario.invalid"
                  data-bs-dismiss="modal"><mat-icon>cloud_upload</mat-icon>
                  Agregar</button>
              </div>
            </form>
          </div>

        </mat-card>
      </div>
    </div>
  </div>
</div>
