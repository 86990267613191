<div class="flex-row general-header-inner">
    <div class="whb-column whb-col-left whb-visible-lg">

        <div class="wd-header-text set-cont-mb-s reset-last-child ">

            <a href="https://www.mineduc.cl/" class="wd-logo wd-main-logo" rel="home">
                <img src="/assets/images/ministerio-de-educacion-chile.png">
            </a>
        </div>
        <div class="whb-space-element " style="width:30px;"></div>
    </div>
    <div class="whb-column whb-col-center whb-visible-lg whb-empty-column">
    </div>
    <div class="whb-column whb-col-right whb-visible-lg">
        <div class="site-logo">
            <a href="https://www.docentemas.cl/" class="wd-logo wd-main-logo" rel="home">
                <img width="180" src="/assets/images/7e98c41c-188e-4af8-8c40-188ffda5f84f.png"
                    class="attachment-full size-full px-2" alt="docentemás" loading="lazy" style="max-width:180px;">
            </a>
        </div>
    </div>
</div>

<main role="main" class="container py-4">
    <div class="row py-4">
        <p class="text-title py-4">Recuperar contraseña</p>
    </div>

    <div class="flex justify-center">
        <div class=" w-full max-w-sm ">
            <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-t-4 border-blue-600 rounded-b">
                <div class="mb-2">
                    <label class="block text-xl text-gray-700 font-bold mb-2" for="username">
                        Correo
                    </label>
                    <input
                        class=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="username" type="email" [(ngModel)]="usuario.email" placeholder="Ingrese su correo">
                </div>

                <div class="w-full py-2 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    *ngIf="showInputForm">
                    <label class="block text-gray-700 font-bold mb-2" for="username">
                        Código
                    </label>
                    <input type="text" [(ngModel)]="usuario.codigo" name="codigo" class="form-control"
                        id="formGroupExampleInput" placeholder="Ingrese el código" autofocus required>
                </div>
                <div class="w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    *ngIf="showInputForm">
                    <label class="block text-gray-700 font-bold mb-2" for="username">
                        Nueva Contraseña
                    </label>
                    <input type="password" [(ngModel)]="usuario.password" name="password" class="form-control"
                        id="formGroupExampleInput" placeholder="Ingrese su contraseña" autofocus required>
                </div>
                <p *ngIf="showRecMsje" class="text-blue-600 px-2 my-2 text-md text-justify border-l-4 border-blue-600 rounded-b" > Pronto recibirás el código de recuperación a tu correo ingresado. </p>
                <p *ngIf="authFail" class="animate-pulse text-red-500 py-2 italic">{{msjeError}} </p>
                <div class="card-footer">
                    <button (click)="sendMail()" *ngIf="!showInputForm" class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        <div class="grid justify-items-center">
                            <svg *ngIf="loading" class="animate-spin " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4335 4335" width="25" height="25">
                                <path fill="#ffffff" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"/>
                              </svg>
                            <p class="text-center justify-center" *ngIf="!loading">Solicitar</p>
                        </div>
                    </button>

                    <input type="button" *ngIf="showInputForm" (click)="cambiar()"
                        class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        value="Cambiar"/>
                    <button type="button" class=" w-full text-center my-2 py-2 font-bold text-md text-gray-500 hover:text-gray-800 border-gray-500 border"
                     routerLink="/login">Volver</button>
                </div>
            </form>
            <p class="text-center text-gray-500 text-xs">
                &copy;Equipo EDD, Informática Educativa UFRO.
            </p>
        </div>
    </div>
</main>


<footer class="mt-auto">
    <div class="container main-footer">
        <div data-elementor-type="wp-post" data-elementor-id="9835" class="elementor elementor-9835">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-691fa5c elementor-section-full_width elementor-section-height-min-height elementor-section-stretched elementor-section-height-default elementor-section-items-middle wd-section-disabled"
                data-id="691fa5c" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;gradient&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4830bd6"
                        data-id="4830bd6" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="wd-negative-gap elementor-section elementor-top-section elementor-element elementor-element-e2b3834 elementor-section-boxed elementor-section-height-default elementor-section-height-default wd-section-disabled"
                data-id="e2b3834" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-20dc34a"
                        data-id="20dc34a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-de23852 elementor-widget elementor-widget-wd_text_block"
                                data-id="de23852" data-element_type="widget" data-widget_type="wd_text_block.default">
                                <div class="elementor-widget-container">
                                    <div class="wd-text-block reset-last-child text-left"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</footer>