import { Noticia } from '../../../models/noticia';
import { Component } from '@angular/core';
import { MantenedorFeedService } from '../../../services/mantenedor-feed.service'
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import Swal from 'sweetalert2';
import { Toast } from 'src/app/base/services/toast';

@Component({
  selector: 'app-mantenedor-feed',
  templateUrl: './mantenedor-feed-main.component.html',
  styleUrls: ['./mantenedor-feed-main.component.css']
})
export class MantenedorFeedMainComponent {

  noticiasList: Noticia[] = [];
  noticiaSelected: Noticia;
  showModal: boolean = false;

  constructor(
    private mantenedorFeedService: MantenedorFeedService,
    private errorMensaje: ErrorMensajeService
  ) { }

  ngOnInit() {
    this.mantenedorFeedService.$modal_feed.subscribe((valor)=> (this.showModal = valor))
    this.getNoticias();
  }

  getNoticias() {
    this.mantenedorFeedService.noticiasList().subscribe(
      {
        next : (response:Noticia[]) => {
          if (!response){
            this.noticiasList = null;
            return;
          }
          this.noticiasList = response;
        },
        error: (error: any) => {
          this.errorMensaje.show(error, 'obtener las noticias');
          this.noticiasList = null;
        }
      }
    );
  }

  // dragAndDrop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.noticiasList, event.previousIndex, event.currentIndex);
  //   // Implementar cambio de orden con dragAndDrop
  // }

  openModal(noticia: Noticia){
    this.showModal = !this.showModal;
    if (noticia != null) {
      this.noticiaSelected = noticia;
    }
  }

  cleanModalFromChild($event: Noticia) {
    this.noticiaSelected = $event;
  }

  eliminarNoticia(id: number) {
    Swal.fire({
      title: '¿Desea eliminar esta noticia?',
      text: 'Este acción es irreversible',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.mantenedorFeedService.eliminarNoticia(id).subscribe(
          {
            next: (response: any) => {
              Toast.fire("Noticia eliminada", response.mensaje , "success");
              this.getNoticias();
            },
            error: (error: any) => {
              this.errorMensaje.show(error, 'eliminar la noticia');
            }
          }
        )
      }
    })
  }

  cambiarVista(valor: boolean) {
    if (!valor) {
      this.noticiaSelected = null;
      this.getNoticias();
    }
  }
}
