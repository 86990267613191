import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { EstablecimientoFilter } from '../models/establecimientoFilter';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class EstablecimientoService {

  constructor(
    private http: HttpClient
  ) { }

  encargadosEstablecimientoList(options: PaginationOptions, filter: EstablecimientoFilter): Observable<any> {
    let params = this.obtenerParamsEstablecimiento(filter.rutSostenedor, filter.rbd, filter.nombreEstablecimiento);
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.ESTABLECIMIENTO.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url,{params:params}).pipe(map(response => response));
  }

  obtenerParamsEstablecimiento(rutSostenedor: any, rbd: any, nombreEstablecimiento: any): HttpParams {
    let params = new HttpParams();

    if (rutSostenedor && rutSostenedor > 0) {
      params = params.set('rutSostenedor', rutSostenedor);
    }
    if (rbd && rbd > 0) {
      params = params.set('rbd', rbd);
    }
    if (nombreEstablecimiento && nombreEstablecimiento.trim() != "") {
      params = params.set('nombreEstablecimiento', nombreEstablecimiento.trim());
    }
    return params;
  }

}
