import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { UsuarioOperaciones } from '../../models/usuarioOperaciones';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { PaginationOptions } from '../../../base/models/paginationOptions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UsuariosCognitoService } from '../../services/usuariosCognito.service';
import { ErrorMensajeService } from '../../../base/services/errorMensaje.service';
import { Toast }  from '../../../base/services/toast';
import Swal from 'sweetalert2';
import {InfoPagUsuariosOps} from "../../models/InfoPageUsuariosOps";
import {FiltrosUsuariosOps, Grupo} from "./models/filtrosUsuariosOps";

@Component({
  selector: 'app-mantenedor-usuarios-cognito',
  templateUrl: './usuarios-operaciones.component.html',
  styleUrls: ['./usuarios-operaciones.component.css']
})
export class UsuariosOperacionesComponent implements OnInit, AfterViewInit{
  isLoading: boolean = true;
  infoPagUsuariosOps: InfoPagUsuariosOps = new InfoPagUsuariosOps();
  filtro: UsuarioOperaciones = new UsuarioOperaciones();
  gruposFiltro: { name: string, description: string, selected: boolean }[] = [];
  abrirModal: boolean = false;
  modoCrear: boolean = false;
  formularioValido!: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: PaginationOptions = new PaginationOptions();
  usuarioEditar: UsuarioOperaciones;
  formularioUsuario: FormGroup;
  usuarioEnEspera:string = "";
  estadoConfirmacion: {[key: string]: string} = {
    'UNCONFIRMED': 'No confirmado/a',
    'CONFIRMED': 'Confirmado/a',
    'ARCHIVED': 'Archivado/a',
    'COMPROMISED': 'Comprometido/a',
    'UNKNOWN': 'Desconocido',
    'RESET_REQUIRED': 'Requiere Reinicio',
    'FORCE_CHANGE_PASSWORD': 'Cambiar contraseña'
  };

  gruposUsuario: {[key: string]: string} = {};
  grupoSeleccionados: { name: string, selected: boolean }[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private cognitoService: UsuariosCognitoService,
    private errorMensaje: ErrorMensajeService) { }


  ngOnInit(): void {
    this.getListaGruposCognito();
    this.formularioUsuario = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      groups: ['', Validators.required]
    });
    this.getListaUsuarios();
  }
  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
  }

  getListaGruposCognito() {
    this.cognitoService.getListaGruposCognito()
      .subscribe(
        {
          next: (response: FiltrosUsuariosOps) => {
            response.grupos.forEach((grupo: Grupo) => {
              this.gruposUsuario[grupo.nombre] = grupo.descripcion;
              this.gruposFiltro.push({name: grupo.nombre, description: grupo.descripcion, selected: true})
            });
          },
          error: (error: any) => this.errorMensaje.show(error, "obtener los grupos de Cognito")
        }
      )
  }

  getListaUsuarios() {
    this.isLoading = true;
    this.cognitoService.getListaUsuariosCognito(this.options.page, this.options.size, this.filtro)
      .subscribe(
        {
          next: (response: InfoPagUsuariosOps) => {
            this.infoPagUsuariosOps = response;
            this.isLoading = false;
          },
          error: (error: any) => {
            this.errorMensaje.show(error, "obtener los usuarios de Cognito");
            this.isLoading = false;
          },
        }
      )
  }

  buscarUsuario() {
    this.isLoading = true;
    this.options.page = 1;
    this.options.size = 10;
    this.getListaUsuarios();

  }

  limpiarFiltro() {
    this.filtro = new UsuarioOperaciones();
    this.getListaUsuarios();
  }

  eliminar(username: string) {
    Swal.fire({
      title: '¿Desea Eliminar este usuario?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',

    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarUsuario(username);
      }
    })
  }

  eliminarUsuario(username: string) {
    this.isLoading = true;
    this.cognitoService.eliminarUsuario(username)
      .subscribe({
        next: (response: any) => {
          Toast.fire("Usuario eliminado", "El usuario ha sido eliminado", "success");
          this.getListaUsuarios();
          this.isLoading = false;
        },
        error: (error: any) => this.errorMensaje.show(error, "eliminar el usuario de cognito")
      });
  }

  limpiarformularioUsuario() {
    this.usuarioEditar = null;
    this.grupoSeleccionados = [];
  }

  toggleSelection(index: number): void {
    this.grupoSeleccionados[index].selected = !this.grupoSeleccionados[index].selected;
    this.obtenerGruposSeleccionados();
  }

  abrirFormCreacion() {
    this.limpiarformularioUsuario();
    this.formularioUsuario.controls['username'].setValue(null);
    this.formularioUsuario.controls['groups'].setValue(null);
    this.abrirModal = true;
    this.usuarioEditar = null;
    this.modoCrear = true;
    this.grupoSeleccionados = [];
    for (let key in this.gruposUsuario) {
      this.grupoSeleccionados.push({name: key, selected: false});
    }
  }

  abrirFormEdicion(element: UsuarioOperaciones) {
    this.limpiarformularioUsuario();
    this.formularioUsuario.controls['username'].setValue(null);
    this.formularioUsuario.controls['groups'].setValue(null);
    this.abrirModal = true;
    this.usuarioEditar = element;
    this.modoCrear = false;

    for (let key in this.gruposUsuario) {
      this.grupoSeleccionados.push({name: key, selected: element.groups.indexOf(key) > -1})
    }

    this.formularioUsuario.controls["username"].setValue(element.username);
    this.formularioUsuario.controls["groups"].setValue(element.groups);
  }

  obtenerGruposSeleccionados() {
    this.formularioUsuario.controls['groups'].setValue(null)
    this.formularioUsuario.controls['groups'].setValue(
      this.grupoSeleccionados
        .filter(item => item.selected)
        .map(item => item.name)
    );
  }

  agregarForm() {
    const username = this.formularioUsuario.controls['username'].value;
    this.isLoading = true;
    this.usuarioEnEspera = "Agregando al usuario " + username;
    this.cognitoService.agregarUsuario(this.formularioUsuario.getRawValue())
      .subscribe(
        {
          next: (response: any) => {
            if (response) {
              this.usuarioEnEspera = "Usuario "+ username + " ha sido agregado al portal Operaciones!";
              Toast.fire("Creación de usuario finalizada", "Se ha creado exitosamente al usuario.", "success").
              then((result:any) => {
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                  this.isLoading = false;
                  this.usuarioEnEspera = "";
                }
              });
            }
            this.getListaUsuarios();
          },
          error: (error: any) => this.errorMensaje.show(error, "crear el usuario en Cognito")
        }
      )
  }

  editarForm() {
    this.obtenerGruposSeleccionados();
    if (!this.formularioUsuario.controls['username'].value ||
      this.formularioUsuario.controls['groups'].value.length == 0
    ) {
      this.formularioValido = false;
      return;
    }

    const body = this.formularioUsuario.getRawValue();
    const username = this.formularioUsuario.controls['username'].value;
    this.usuarioEnEspera = "Asignando nuevos grupos a " + username;
    this.isLoading = true;

    this.cognitoService.editarUsuario(body)
      .subscribe(
        {
          next: (response: any) => {
            if (response) {
              this.usuarioEnEspera = "El Usuario "+ username + " tiene nuevos grupos asignados en el portal Operaciones!";
              this.getListaUsuarios();
              Toast.fire("Edición Finalizada", "Se actualizaron los datos del usuario.", "success").
              then((result:any) => {
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                  this.isLoading = false;
                  this.usuarioEnEspera = "";
                }
              });
              
            }
          },
          error: (error: any) => this.errorMensaje.show(error, "editar el usuario en Cognito")
        }
      )
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getListaUsuarios();
  }
}
