export class Noticia {
  id: number | null = null;
  orden: number;
  mensaje: string;
  perfiles: number[];
  mostrar: boolean;

  constructor(id: number, orden: number, mensaje: string, perfiles: number[], mostrar: boolean) {
    this.id = id;
    this.orden = orden;
    this.mensaje = mensaje;
    this.perfiles = perfiles;
    this.mostrar = mostrar;
  }
}
