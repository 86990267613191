import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { Constants } from "src/app/base/constants/constants";


@Injectable({
    providedIn: 'root'
  })
  export class ReportesService {
    constructor(
        private http: HttpClient
      ) { }

    handleError(error: any) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    return throwError(errorMessage);
    }

    descargaReporte(bbdd:string,reporte:string,separador:string): Observable<any> {
        const url = `${Constants.URL_SERVICE.BASE_REPORTE + Constants.URL_SERVICE.DESCARGA_REPORTE.DESCARGA}/${bbdd}/reporte/${reporte}/separador/${separador}`;
        return this.http.get(url,
          {
            responseType: 'blob',
            observe: 'events',
            reportProgress: true
          });

    }

    reportesPorAgno(bbdd:string): Observable<any>{
      let params = new HttpParams();
      params= params.set('bbdd', bbdd);
      const url = `${Constants.URL_SERVICE.BASE_REPORTE + Constants.URL_SERVICE.DESCARGA_REPORTE.REPORTESBBDD}`;
      return this.http.get(url, {params:params});
    }
  }
