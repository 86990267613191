import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html'
})
export class InicioComponent implements OnInit {

  email: string = "usuario@iie.cl"
  grupos!: string[];


  constructor(
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.grupos = this.authService.getUserGroupDescription();
    this.email = this.authService.getEmailUser();
  }

}
