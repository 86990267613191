<div class="panel-body px-4" style="flex-direction: row">

  <div class="row px-2">
    <h3 class="pb-4">Cargas Masivas</h3>
    <div aria-label="panel filtros de oficios" class="form-group row cardfiltros p-2 mx-2">
      <div class="row">
        <div class="col-2 verticalmente-centrado" color="primary" style="display: flex; flex-direction: row">
          <h4 style="text-align: left;">
            Selección Archivo
          </h4>
        </div>
      </div>
      <div class="row mt-2">
        <input type="file" class="form-control w-50 mx-4" [(ngModel)]="this.selectedFile" (change)="onFileChange($event)" >
        <div data-te-sidenav-slim="false"
        class="group relative flex h-6  px-6 my-2  text-black outline-none">
          <span pl-5 class="font-italic text-slate-600">El archivo no debe superar los 6000 registros</span>
        </div>
      </div>
      <div class="float-right d-flex flex-row gap-1 justify-content-end mb-2">
        <button color="primary"
        data-te-ripple-color="light"
        type="button"
        class="bg-slate-50 hover:bg-slate-100 text-black shadow-[0_4px_9px_-4px_#3b71ca] py-2 px-4 border border-blue-700 rounded"
        (click)="limpiarCampo()" [disabled]="this.selectedFile == null"> Limpiar
        </button>
        <button color="primary"
        data-te-ripple-color="light"
        type="button"
        class="bg-primary hover:bg-blue-700 text-white shadow-[0_4px_9px_-4px_#3b71ca] py-2 px-4 border border-blue-700 rounded"
        (click)="readCsvFile()" [disabled]="this.selectedFile == null || estaCargandoTabla">
        <fa-icon *ngIf="estaCargandoTabla" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
        Cargar Archivo
        </button>
      </div>
  </div>

  <div class="table-responsive p-2" aria-label="sección tabla" >
    <table class="table table-striped" role="table" aria-describedby="tabla-carga" *ngIf="users">
      <thead>
          <tr>
              <th *ngFor="let opcion of opciones"><input style="margin-right: 2%;" type="checkbox" [(ngModel)]="opcion.checked">{{opcion.showName}}</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let user of users">
              <td>{{user.nombres}}</td>
              <td>{{user.primerApellido}}</td>
              <td>{{user.segundoApellido}}</td>
              <td>{{user.rbd}}</td>
              <td>{{user.estado}}</td>
              <td>{{user.debeRendir}}</td>

          </tr>
      </tbody>
  </table>
  </div>
<div>
  <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
      [pageIndex]="pageIndex" (page)="pageChanged($event)" >
    </mat-paginator>
</div>
<div class="modal-body mx-1 float-right d-flex justify-content-end" *ngIf="users">
  <button
    type="button"
    (click)="confirmarCarga()"
    class="bg-primary hover:bg-blue-700 text-white shadow-[0_4px_9px_-4px_#3b71ca] py-2 px-4 border border-blue-700 rounded">
    Continuar
    </button>
</div>
</div>



