import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { interceptorSpringProvider } from './base/interceptors/api.interceptor';
import { RegistrosModule } from './registros/registros.module';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FroalaViewModule } from 'angular-froala-wysiwyg';
import player from 'lottie-web/build/player/lottie_light';
import { LottieModule, provideLottieOptions } from 'ngx-lottie';
import { BaseModule } from './base/base.module';
import {MatDialogModule} from "@angular/material/dialog";
import {UtilsModule} from "./utils/utils-module";

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BaseModule,
    RegistrosModule,
    UtilsModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRippleModule,
    FontAwesomeModule,
    MatDialogModule,
    FroalaViewModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),

  ],
  exports: [
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatRippleModule,
    FontAwesomeModule,
    LottieModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    interceptorSpringProvider,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
