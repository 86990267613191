<button class="col-1 mx-2 ajustar" style="visibility: hidden;" #abrirModalPDF id="abrirModalPDF" data-bs-toggle="modal"
  data-bs-target="#modalPDF">abrirmodal</button>
<mat-card class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2 flex flex-col gap-4" #FILTRO>
    <div class="flex flex-col gap-4 px-2">
      <p class="text-blue-950 text-2xl font-bold">Portafolio 2023</p>
      <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
        <div class="flex flex-row gap-1 py-2">
          <mat-icon>filter_list</mat-icon>
          <p class="text-lg font-bold">Filtros</p>
        </div>
        <div class="row ms-auto my-auto d-flex">
          <div class="col-12 row ">
            <div *ngFor="let input of inputs" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xl-4">
              <mat-form-field class="w-full" [style.fontSize.px]=12 *ngIf="input.attribute != 'tipo'">
                <mat-label>{{ input.label }}</mat-label>
                <input matInput [(ngModel)]="filtros[input.attribute]" min=0 name="{{ input.label }}"
                  [type]="input.type" (ngModelChange)="onInputChange($event, input.type, input.attribute)">
              </mat-form-field>

              <mat-form-field class="w-full" [style.fontSize.px]=12 *ngIf="input.attribute == 'tipo'">
                <mat-label *ngIf="tipos"> Tipo de contenido</mat-label>
                <mat-label *ngIf="!tipos"> Obteniendo lista de contenidos</mat-label>
                <mat-select [(ngModel)]="filtros[input.attribute]" [disabled]="!tipos">
                  <mat-option [value]=""></mat-option>
                  <mat-option [value]="tipo" *ngFor="let tipo of tipos"> {{tipo}} </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <mat-card-actions class="d-flex">
          <div class="float-left d-flex align-self-start">
            <button type="button" data-te-ripple-init [disabled]="isLoading" (click)="filtrar()"
              data-te-ripple-color="light"
              class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
              <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p class="pr-1"></p> Filtrar
            </button>

            <button type="button" data-te-ripple-init [disabled]="isLoading" (click)="limpiar()"
              data-te-ripple-color="light"
              class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
              <svg xmlns="http://www.w3.org/2000/svg" class="pr-1" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <rect x="2" y="6" width="20" height="12" rx="2" />
                <path d="M12 12h.01" />
                <path d="M17 12h.01" />
                <path d="M7 12h.01" />
              </svg>
              Limpiar
            </button>
          </div>
        </mat-card-actions>
      </div>
    </div>
  </div>

  <div class="p-4">
    <div class="overflow-x-scroll">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <table role="table" aria-label="tabla portafolio 2023" class="tables-info py-4 w-full bg-zinc-300">

        <tbody>
          <th *ngFor="let column of tableColumns" class="uppercase"> {{column.label}}</th>
          <tr *ngFor="let portafolio of portafolios">
            <td *ngFor="let column of tableColumns">
              <p *ngIf="column.attribute != 'respuesta'">{{portafolio[column.attribute]}}</p>

              <div *ngIf="column.attribute == 'respuesta'" class="flex flex-col text-center justify-center">

                <button type="button" (click)="verArchivoRespuesta(portafolio)" *ngIf="portafolio['tipo'] == 'file'"
                  class="focus:border-emerald-500 hover:animate-pulse-bg-once  from-primary-100 to-primary-400 max-w-fit inline-block align-middle self-center rounded-full border-2 border-gray-400 px-6 py-1 text-[14px] font-bold uppercase leading-normal text-gray-600 transition duration-150 ease-in-out hover:border-primary-900 hover:white hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600  focus:outline-none focus:ring-0"
                  matTooltip="Descargar el archivo de este registro">
                  <svg class="text-blue-500 inline-block mx-2 flex text-center" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" fill="none" viewBox="0 0 16 16" stroke="currentColor">
                    <path
                      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                    <path
                      d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                  </svg>
                </button>

                <button type="button" (click)="mostrarRespuestaEscrita(portafolio)" data-bs-toggle="modal"
                  data-bs-target="#modalPDF" *ngIf="portafolio['tipo'] != 'file'"
                  class="focus:border-emerald-500 hover:animate-pulse-bg-once from-primary-100 to-primary-400 max-w-fit inline-block align-middle self-center rounded-full border-2 border-gray-400 px-6 py-1 text-[14px] font-bold uppercase leading-normal text-gray-600 transition duration-150 ease-in-out hover:border-primary-900 hover:white hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600  focus:outline-none focus:ring-0"
                  matTooltip="Visualizar el contenido de este registro">
                  <svg xmlns="http://www.w3.org/2000/svg" class="text-blue-500 text-xl mx-2 flex text-center"
                    xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" width="24" height="24"
                    viewBox="0 0 512 512">
                    <title>ionicons-v5-l</title>
                    <rect x="96" y="48" width="320" height="416" rx="48" ry="48"
                      style="fill:none;stroke:#000;stroke-linejoin:round;stroke-width:32px" />
                    <line x1="176" y1="128" x2="336" y2="128"
                      style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px" />
                    <line x1="176" y1="208" x2="336" y2="208"
                      style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px" />
                    <line x1="176" y1="288" x2="256" y2="288"
                      style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px" />
                  </svg>
                </button>

                <p class="text-gray-500 text-xs uppercase" *ngIf="portafolio['tipo'] != 'file'">{{portafolio['tipo']}}
                </p>
                <p class="text-gray-500 text-xs uppercase"
                  *ngIf="portafolio['tipo'] == 'file' && portafolio['respuesta']">
                  {{portafolio['respuesta'].slice(portafolio['respuesta'].lastIndexOf('.') + 1)}}</p>
              </div>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
  <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="paginador de portafolios">
  </mat-paginator>

</mat-card>


<div class="modal fade" id="modalPDF" tabindex="-1" aria-labelledby="modalPDF" aria-hidden="true">
  <div
    class="modal-dialog modal-lg  modal-dialog-scrollable overflow-auto border-l-8 rounded-xl h-auto border-primary-600">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" *ngIf="selected">Respuesta de Docente: {{selected.idDocente}}</h1>
        <button type="button" id="cerrarModalPDF" data-bs-dismiss="modal" aria-label="Close">✕</button>
      </div>

      <div class="modal-body" oncontextmenu="return false;" class="px-4 pt-4 max-h-50 overflow-y-auto">
        <div *ngIf="selected">
          <h3 class="pb-4">Pregunta id #{{selected.idPregunta}}</h3>

          <div *ngIf="selected.tipo!= 'file'">
            <div [froalaView]="selected.respuesta==undefined || selected.respuesta == null ? '' : selected.respuesta"
              style="max-height: 50vh;"></div>
          </div>
          <p *ngIf="selected.tipo == 'file'">Documento descargado</p>
        </div>

        <iframe #iframe title="pdfOficio"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" height="0%" toolbar="0"
          width="100%"></iframe>
      </div>

      <div class="modal-footer">
        <button type="button" class="hover:bg-slate-100 border border-slate-300 py-2 px-4 rounded-xl"
          data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>
