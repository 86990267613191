export const tiposEvento = {
  CREACION_REVISION: {
    id: 1,
    nombre: 'Creacion de Revision',
    descripcion: 'Se ha creado una nueva revision.'
  },
  CREACION_CORRECCION: {
    id: 2,
    nombre: 'Creacion de Correccion',
    descripcion: 'Se ha creado una nueva correccion para la revision.'
  },
  ACTUALIZACION_CORRECCION: {
    id: 3,
    nombre: 'Actualizacion de Correccion',
    descripcion: 'Se ha actualizado una correccion existente.'
  },
  ELIMINACION_CORRECCION: {
    id: 4,
    nombre: 'Eliminacion de Correccion',
    descripcion: 'Se ha eliminado una correccion.'
  },
  CIERRE_REVISION: {
    id: 5,
    nombre: 'Cierre de Revision',
    descripcion: 'La revision ha sido cerrada/finalizada.'
  },
  REAPERTURA_REVISION: {
    id: 6,
    nombre: 'Reapertura de Revision',
    descripcion: 'La revision ha sido reabierta.'
  }
};

export type TipoEvento = {
  id: number;
  nombre: string;
  descripcion: string;
};

export function getTipoEventoById(id: number): TipoEvento | undefined {
  return Object.values(tiposEvento).find(evento => evento.id === id);
}
