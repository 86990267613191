export class EstablecimientoFilter {
    rutSostenedor: number;
    rbd: number;
    nombreEstablecimiento: string;


    constructor(){
        this.rutSostenedor = null;
        this.rbd = null;
        this.nombreEstablecimiento = "";
    }
}