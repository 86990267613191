import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Constants } from 'src/app/base/constants/constants';
import { PaginationOptions } from '../models/paginationOptions';
import { RepresentanteLegal } from '../models/representanteLegal';

@Injectable({
  providedIn: 'root'
})
export class RepresentanteService {
  estaDescargandoExcelEmmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private http: HttpClient
  ) { }

  emitirEstadoDescargaRepresentantes(estado: boolean) {
    this.estaDescargandoExcelEmmit.emit(estado);
  }

  getRepresentantesLegales(options: PaginationOptions, filter: RepresentanteLegal):
    Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.LISTAR}/`;
    return this.http.get(url, { params: { page: options.page, size: options.size } }).pipe(
      map((response: any) => response));
  }

  getRepresentantesLegalesFiltro(options: PaginationOptions,
    rut: number = null,
    rbd: number = null,
    rutEmpresa: number = null,
    nombres: string = "",
    primerApellido: string = "",
    segundoApellido: string = ""):
    Observable<any> {
    let params = this.obtenerParams(rut, rbd, rutEmpresa, nombres, primerApellido, segundoApellido);
    params = params
      .set('page', options.page)
      .set('size', options.size)

    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.LISTAR}`;
    return this.http.get(url, { params: params }).pipe(map((response: any) => response));
  }

  getNombreEmpresa(rutEmpresa: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.EMPRESA}${rutEmpresa}`;

    return this.http.get(url).pipe(map(response => response));
  }

  agregarRepresentanteLegal(representante: any, tipo: number): Observable<any> {
    representante.idTipo = tipo;
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.AGREGAR}`;
    return this.http.post(url, representante).pipe(map(response => response));
  }

  editarRepresentanteLegal(representante: any, tipo: number): Observable<any> {
    representante.idTipo = tipo;
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION}/representante-legal/representante-legal/${representante.rut}/sostenedor/${representante.rutEmpresa}`;
    return this.http.put(url, representante).pipe(map(response => response));
  }

  deleteRepresentanteLegal(rutRepresentante: number, rutEmpresa: number) {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.ELIMINAR}/${rutRepresentante}/${rutEmpresa}`;
    return this.http.delete(url);
  }

  handleError(error: any) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    return throwError(errorMessage);
  }

  descargarRepresentantes(filtros: RepresentanteLegal): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.DESCARGAR}}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      catchError(this.handleError),
    );

  }

  obtenerParams(rut: any, rbd:any, rutEmpresa: any, nombres: any, primerApellido: any, segundoApellido: any): HttpParams {
    let params = new HttpParams();

    if (rut && rut > 0) {
      params = params.set('rut', rut);
    }
    if (rbd && rbd > 0) {
      params = params.set('rbd', rbd);
    }
    if (rutEmpresa && rutEmpresa > 0) {
      params = params.set('rut_sostenedor', rutEmpresa);
    }
    if (nombres && nombres.trim() != "") {
      params = params.set('nombres', nombres.trim());
    }
    if (primerApellido && primerApellido.trim() != "") {
      params = params.set('primer_apellido', primerApellido.trim());
    }
    if (segundoApellido && segundoApellido.trim() != "") {
      params = params.set('segundo_apellido', segundoApellido.trim());
    }
    return params;
  }

  descargaRepresentantesLegales(
    rut: number = null,
    rbd: number = null,
    rutEmpresa: number = null,
    nombres: string = "",
    primerApellido: string = "",
    segundoApellido: string = ""): Observable<any> {
    this.emitirEstadoDescargaRepresentantes(true);
    const params = this.obtenerParams(rut, rbd, rutEmpresa, nombres, primerApellido, segundoApellido);
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.DESCARGAR}`;
    return this.http.get(url,
      {
        responseType: 'blob',
        observe: 'events',
        reportProgress: true,
        params: params
      })
  }
}
