
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventBusService } from '../services/event-bus.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private eventBusService: EventBusService
  ) { }

  // revisar https://www.bezkoder.com/logout-when-token-expired-angular-14/
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let intReq = request;
    const token = this.authService.getToken();
    if (token != null && request.url.includes(environment.host)) {
      intReq = request.clone({ headers: request.headers.set(environment.AUTHORIZATION, environment.BEARER + token) });
    }
    return next.handle(intReq);
  }
}

export const interceptorSpringProvider = [{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }]
