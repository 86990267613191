import {Component, OnInit, ViewChild} from '@angular/core';
import { estadosRevisionCorreccion } from "../constants/EstadosRevisionCorreccion";
import { QaGrabacionesService } from "../services/qaGrabacionesService";
import { PaginationOptions } from "../../../models/paginationOptions";
import {MatPaginator} from "@angular/material/paginator";
import { MatDialog } from '@angular/material/dialog';
import {DetalleRevisionComponent} from "./detalle-revision/detalle-revision.component";
import {RevisionFilter} from "./filters/revision-filter";
import Swal from "sweetalert2";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-qa-grabaciones',
  templateUrl: './qa-grabaciones.component.html',
  styleUrls: ['./qa-grabaciones.component.css']
})
export class QaGrabacionesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  paginationOptions: PaginationOptions = new PaginationOptions();

  bypassRevisionConflicts: boolean = environment.features.revisionesGrabaciones.bypassOperatorAssignment;

// Inicializa el pageIndex
  pageIndex: number = 0;
  length: number = 0; // Total de elementos
  pageSize: number = 10; // Tamaño de página
  pageSizeOptions: number[] = [5, 10, 30, 50]; // Opciones de tamaño de página
  filtros: RevisionFilter = new RevisionFilter();
  estadosFiltro = estadosRevisionCorreccion;
  estadosFiltroFiltrados: any[] = [];

  reloadDataAfterRevisionDetailsClosed: boolean = true;
  isLoading: boolean = false;
  errorLoading: boolean = false;
  error: any;

  listaRuts: any[] = [];

  constructor(
    protected service: QaGrabacionesService,
    public dialog: MatDialog
  ) {}

  pageChanged(event: any) {
    // Ajusta el pageNum para que comience desde 1 y establece el tamaño de página
    this.paginationOptions.page = event.pageIndex + 1; // El paginador de Angular comienza en 0
    this.paginationOptions.size = event.pageSize;

    this.filtrarDatos(); // Vuelve a cargar los datos con la nueva paginación
  }



  filtrarDatos(resetPagination = false) {
    this.isLoading = true;
    if(resetPagination){
      this.paginationOptions = new PaginationOptions();
    }
    this.service.getListaRevisionesPorRut(this.paginationOptions, this.filtros).subscribe({
      next: (response) => {
        this.errorLoading = false;
        this.listaRuts = response.list;
        this.length = response.total; // Total de elementos
        this.paginationOptions.page = response.pageNum; // Actualiza la página actual desde el backend
        this.paginationOptions.size = response.pageSize; // Tamaño de página desde el backend
      },
      error: (error) => {
        this.error = error.error;
        this.errorLoading = true;
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  limpiarFiltro(resetPagination = false) {
    this.filtros = new RevisionFilter();
    if(resetPagination){
      this.paginationOptions = new PaginationOptions();

    }
    this.filtrarDatos();
  }

  verDetalles(revisionId: number) {
    const dialogRef = this.dialog.open(DetalleRevisionComponent, {
      width: 'auto',
      height: 'auto',
      maxWidth: '900px',
      data: { id: revisionId },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.reloadDataAfterRevisionDetailsClosed){
        this.filtrarDatos();
      }
    });
  }

  verConflictos(conflictos: any[]): void {
    if (!conflictos || conflictos.length === 0) {
      Swal.fire({
        title: 'Sin Conflictos',
        text: 'No se han detectado conflictos en las revisiones seleccionadas.',
        icon: 'info',
        customClass: {
          confirmButton: 'bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md text-sm flex items-center ng-star-inserted',
        },
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    let htmlContent = '<div class="conflictos-container">';
    conflictos.forEach(conflicto => {
      const backgroundColor = (!conflicto.canBeBypassed || !this.bypassRevisionConflicts) ? '#f8d7da' : '#ffffff'; // Rojo si no se puede omitir el conflicto o bypass está desactivado

      htmlContent += `
    <div class="conflicto-item" style="margin-bottom: 1rem; padding: 1rem; border: 1px solid #e0e0e0; border-radius: 8px; background-color: ${backgroundColor};">
      <h3 style="margin-bottom: 0.5rem; font-weight: bold; color: #d9534f;">${conflicto.nombreParaMostrar}</h3>
      <ul style="list-style: none; padding: 0;">
        ${conflicto.scope === 'LOCAL' ? `
          <li><strong>Campo:</strong> ${conflicto.nombreCampo}</li>
          <li><strong>Valor del Campo:</strong> ${conflicto.valorCampo}</li>
        ` : ''}
        <li><strong>Detalle:</strong> ${conflicto.detalle}</li>
        ${conflicto.scope === 'LOCAL' ? `
          <li><strong>IDs de Revisiones Relacionadas:</strong> ${conflicto.revisionIds.join(', ')}</li>
        ` : ''}
      </ul>
    </div>
  `;
    });
    htmlContent += '</div>';




    Swal.fire({
      title: 'Conflictos Detectados',
      html: htmlContent,
      icon: 'warning',
      customClass: {
        confirmButton: 'bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md text-sm flex items-center ng-star-inserted',
      },
      confirmButtonText: 'Cerrar'
    });
  }


  ngAfterViewInit() {
    if(!this.paginator) return;
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';

  }


  ngOnInit(): void {
    this.estadosFiltroFiltrados = Object.values(this.estadosFiltro).filter(estado => estado.id !== 6);
    this.filtrarDatos();
  }

  protected readonly Object = Object;
}
