<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO style="flex-direction: row">
      <div class="flex flex-col gap-4 px-2">
        <p class="text-blue-950 text-2xl font-bold">Eventos Docentemás</p>
        <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
          <div class="flex flex-row gap-1 py-2">
            <mat-icon>filter_list</mat-icon>
            <p class="text-lg font-bold">Filtros</p>
          </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-2">
          <input
            class="h-14 w-full control-form bg-white border-gray-300 border-2 rounded-md px-2 active:border-primary-600"
            type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="RUT"
            [(ngModel)]="filtros.rut" name="idEvento" />
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-3">
          <select [(ngModel)]="filtros.idTipoEvento"
            class="h-14 form-select bg-white border-gray-300 border-2 rounded-md px-2">
            <option value="-1" class="text-gray-400 py-2 my-2">Tipo de Evento</option>
            <option *ngFor="let option of tipoEventos" value="{{option.id}}" title="{{option.nombre}}"
              placeholder="Tipo de Evento"
              class="transititext-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 px-2 py-4"
              data-te-toggle="tooltip">
              {{option.id}} - {{option.nombre}}
            </option>
          </select>

        </div>


        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-3">
          <select [(ngModel)]="filtros.idTipoObjetoAfectado"
            class="h-14 form-select bg-white border-gray-300 border-2 rounded-md px-2">
            <option value="-1" class="text-gray-400 py-2 my-2">Tipo de Objeto Afectado</option>
            <option *ngFor="let option of tipoObjetoAfectado" value="{{option.id}}" title="{{option.nombre}}"
              placeholder="Objeto Afectado"
              class="transititext-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 px-2 py-4"
              data-te-toggle="tooltip">
              {{option.id}} - {{option.nombre}}
            </option>
          </select>
        </div>


        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
          <mat-form-field >
            <mat-label>Rango de Fechas</mat-label>
            <mat-date-range-input [rangePicker]="picker" [max]="today" [min]="minDate">
              <input matStartDate placeholder="Start date" [(ngModel)]="filtros.fechaInicio">
              <input matEndDate placeholder="End date" [(ngModel)]="filtros.fechaFin">
            </mat-date-range-input>
            <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

      </div>

      <div class="d-flex">
        <div class="float-left d-flex  align-self-start ">
          <mat-card-actions class="d-flex row">
            <button type="button" data-te-ripple-init (click)="filtrar()"
              class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center m-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
              <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p class="pr-1"></p> Filtrar
            </button>

            <button
                matTooltip="Descarga esta tabla completa"
                type="button"
                data-te-ripple-init
                (click)="descargarExcel()"
                data-te-ripple-color="light"
                class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                <div *ngIf="!estaDescargandoExcel" class="text-[14px]">Descargar</div>
                <div *ngIf="estaDescargandoExcel" class="text-[14px]">Descargando</div>
            </button>

            <button type="button" data-te-ripple-init (click)="limpiar()" data-te-ripple-color="light"
              class="d-flex w-28  m-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <rect x="2" y="6" width="20" height="12" rx="2" />
                <path d="M12 12h.01" />
                <path d="M17 12h.01" />
                <path d="M7 12h.01" />
              </svg>
              <p class="pr-1"></p> Limpiar
            </button>
            <button class="col-1 mx-2 ajustar" style="visibility: hidden;" #abrirModalPDF id="abrirModalPDF"
              data-bs-toggle="modal" data-bs-target="#modalPDF">abrirmodal</button>

          </mat-card-actions>
        </div>
      </div>
    </div>
    </div>
  </div>

  <div class=" mx-2 my-4 rounded-sm border border-gray-200 bg-white shadow-none">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
    <div class="overflow-x-auto p-3" *ngIf="eventos">
      <table aria-label="tabla eventos" role="table" class="table table-striped tablaEventos">
        <thead>
          <tr class="etiqueta-azul">
            <th scope="col">Id Evento</th>
            <th scope="col">Fecha evento</th>
            <th scope="col">Usuario</th>
            <th scope="col">Tipo de Evento</th>
            <th scope="col">Tipo Objeto Afectado</th>
          </tr>
        </thead>

        <tbody class="">
          <tr *ngFor="let evento of eventos" class="etiqueta-azul">
            <td class="negrita-tabla">
              <div class="min-w-[130px]">
                <button type="button"
                  class="min-w-32 inline-block rounded-full border-2 border-primary px-2 pb-[6px] pt-2 text-[15px] font-bold uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                  data-bs-toggle="modal" data-bs-target="#modalEventos" (click)="obtenerEvento(evento)">
                  {{evento.id}}
                </button>
              </div>
            </td>
            <td class="negrita-tabla">
              <div class="font-medium text-gray-800">{{formatearFecha(evento.timestamp)}}</div>
            </td>
            <td class="negrita-tabla">
              <div class="font-medium text-gray-800">{{evento.idUsuario}}</div>
            </td>
            <td class="negrita-tabla">
              <div class="font-medium text-gray-800">{{eliminaPrefijo(evento.tipoEvento?.nombre)}}</div>
            </td>
            <td class="negrita-tabla">
              <div class="font-medium text-gray-800">{{eliminaPrefijo(evento.tipoObjetoAfectado?.nombre)}}</div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [size]="options.size" [showFirstLastButtons]="true"
      [length]="length" [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="paginador eventos">
    </mat-paginator>
  </div>

</mat-card>

  <!-- modal de eventos -->
  <div class="modal fade" id="modalEventos" tabindex="-1" aria-labelledby="eventos" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable max-w-screen-2xl h-screen">

      <div class="modal-content shadow-lg h-screen rounded-lg" *ngIf="targetEvent">
        <div class="modal-header rounded-lg">
          <h3 class="modal-title text-2xl pb-2" id="eventos">Evento #{{targetEvent.id}} </h3>
          <button type="button" data-bs-dismiss="modal" aria-label="Close"
            class="rounded-md p-2 inline-flex text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Close menu</span>
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="modal-body h-auto flex md:flex-row xs:flex-col gap-4">

          <div class="flex flex-col gap-1 xs:w-full sm:w-full md:w-1/4">
            <div #detallesUsuario
              class="  mb-2  h-40 border-slate-300 rounded-lg p-4 border-2 border-l-8 border-l-sky-700 ">
              <div class="flex flex-row gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                  class="object-cover w-16 mr-2 ml-4 py-1 rounded-full shadow-lg flex flex-row ">
                  <path
                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c39.77 0 72 32.24 72 72S295.8 272 256 272c-39.76 0-72-32.24-72-72S216.2 128 256 128zM256 448c-52.93 0-100.9-21.53-135.7-56.29C136.5 349.9 176.5 320 224 320h64c47.54 0 87.54 29.88 103.7 71.71C356.9 426.5 308.9 448 256 448z" />
                </svg>

                <div class="flex flex-col gap-1 justify-center">
                  <div class="font-bold text-lg text-sky-900 flex flex-row">
                    Usuario: {{targetEvent.idUsuario}}
                  </div>
                  <div *ngIf="targetEvent.rbd" class="font-bold text-lg text-sky-900 flex flex-row">
                    RBD: {{targetEvent.rbd}}
                  </div>
                  <div *ngIf="targetEvent.idPerfil" class="font-bold text-lg text-sky-900 flex flex-row">
                    Perfil ID: {{targetEvent.idPerfil}}
                  </div>
                </div>
              </div>
            </div>
            <div #detallesEvento
              class="  border-2 border-slate-300 rounded-lg p-2 border-2 border-l-8 border-l-sky-700  h-full overflow-auto">
              <div class="px-4 pb-2">
                <h3 class="py-4">Detalles Evento</h3>
                <div class="text-slate-600  my-2 border-2 border-l-8 border-l-sky-700 rounded-xl p-4 ">
                  <div class="flex flex-row gap-2">
                    <p class="font-bold">Creación de evento</p>
                    <p>{{formatearFecha(targetEvent.timestamp)}} </p>
                  </div>
                </div>

                <div>
                  <h4 class="text-xl">Tipo de Evento</h4>
                  <p class="pl-8 text-sm break-words" *ngIf="targetEvent.tipoEvento['nombre']"
                  title="{{targetEvent.tipoEvento['nombre']}}">{{eliminaPrefijo(targetEvent.tipoEvento['nombre'])}} </p>
                <p class="pl-8" *ngIf="!targetEvent.tipoEvento['nombre']">No especificado. </p>
                </div>

                <div>
                  <h4 class="text-xl">Tipo de objeto afectado</h4>
                  <p class="pl-8 text-sm break-words" *ngIf="targetEvent.tipoObjetoAfectado"
                  title="{{targetEvent.tipoObjetoAfectado['nombre']}}">{{eliminaPrefijo(targetEvent.tipoObjetoAfectado.nombre)}} </p>
                <p class="pl-8" *ngIf="!targetEvent.tipoObjetoAfectado">Ningún objeto afectado. </p>
                </div>
                <div>
                  <h4 class="text-xl">Id de objeto afectado</h4>
                  <p class="pl-8 text-sm break-words"
                    *ngIf="targetEvent.idObjetoAfectado && targetEvent.idObjetoAfectado.nombre">
                    {{targetEvent.idObjetoAfectado.nombre}} </p>
                  <p class="pl-8 text-sm break-words"
                    *ngIf="targetEvent.idObjetoAfectado && !targetEvent.idObjetoAfectado.nombre">
                    {{targetEvent.idObjetoAfectado}} </p>
                  <p class="pl-8" *ngIf="!targetEvent.idObjetoAfectado">Ningún id objetivo afectado. </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 13642995 -->
          <div #modificaciones
            class="xs:w-full sm:w-full md:w-3/4 border-2 border-slate-300 rounded-lg  p-4  h-auto overflow-auto">
            <h3 class="pb-2 p-6">Modificaciones</h3>

            <div *ngIf="!targetEvent.previo && !targetEvent.nuevo" class="p-6 h-40">
              Sin datos previos ni nuevos en este evento.
            </div>

            <div *ngIf="targetEvent.previo && !targetEvent.nuevo" class="py-8 px-4">
              <ng-container *ngTemplateOutlet="tableTemplate; context:{json:targetEvent.previo}"></ng-container>
            </div>

            <div class="flex md:flex-row xs:flex-col  h-fit">
              <div class="md:w-50 xs:w-full h-auto flex flex-row gap-8 py-8 px-4" *ngIf="targetEvent.previo && targetEvent.nuevo">
              <div class="w-full border-slate-300 border-2 rounded-lg p-4 my-4 border-l-8 border-l-sky-700">
                <h3 class="pb-4">Información Previa</h3>
                <div *ngIf="targetEvent.previo">
                  <ng-container *ngTemplateOutlet="tableTemplate; context:{json:targetEvent.previo}"></ng-container>
                </div>
              </div>
              </div>

              <div class="md:w-50 xs:w-full h-auto flex flex-row gap-8 py-8 px-4">

                <div *ngIf="targetEvent.nuevo"
                  class="w-full border-slate-300 border-2 rounded-lg p-4 my-4 border-l-8 border-l-sky-600">
                  <h3 class="pb-4">Nuevos Cambios</h3>
                  <ng-container *ngTemplateOutlet="tableTemplate; context: {json:targetEvent.nuevo}"></ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ng-template #tableArray let-json="json">
    <div *ngIf="esObjeto(json[0])" >
      <div *ngFor="let index of json"
        class="flex flex-col w-full border-slate-300 border-2 rounded-lg mx-2 p-4 my-4 border-l-8 border-l-sky-900">
        <ng-container *ngTemplateOutlet="tableTemplate; context:{json:index}"></ng-container>
      </div>
    </div>
    <div *ngIf="!esObjeto(json[0])">
      <p>{{json}}</p>
    </div>
  </ng-template>

  <ng-template #tableTemplate let-json="json">

    <table aria-describedby="tabla json" role="table" class="w-full table-hover table-striped">

      <tr *ngFor="let key of obtenerKeys(json)" class="w-fit">
        <div *ngIf="esObjeto(obtenerValues(json, key))" class="flex flex-col w-full border-slate-300 border-2 rounded-lg p-4 my-4 border-l-8 border-l-sky-700">
          <h3 class="py-2">{{key}}</h3>
          <div *ngIf="esArray(obtenerValues(json, key))" class="flex flex-col">
            <ng-container *ngTemplateOutlet="tableArray; context:{json:obtenerValues(json, key)}"></ng-container>
          </div>
          <div *ngIf="!esArray(obtenerValues(json, key))" class="flex flex-col">
            <ng-container *ngTemplateOutlet="tableTemplate; context:{json:obtenerValues(json, key)}"></ng-container>
          </div>
        </div>
        <div *ngIf="!esObjeto(obtenerValues(json, key)) && !esArray(obtenerValues(json, key))"
          class="flex flex-row w-full">
          <th class="py-2 w-full">{{key}}</th>
          <td class=" flex flex-row gap-2 w-full">
            <div class="text-gray-400" title="{{obtenerValues(json, key)}}" class="flex flex-row gap-2 w-full">
              <div
                class="cursor-help flex flex-row gap-2 transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                data-te-toggle="tooltip">🛈</div> {{obtenerValues(json, key)}}
            </div>
          </td>
        </div>
      </tr>

    </table>
  </ng-template>
