import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import Swal from 'sweetalert2';
import { ComentarioMda } from '../../models/comentarioMda';
import { PaginationOptions } from '../../models/paginationOptions';
import { TicketMda } from '../../models/ticketMda';
import { TicketsMdaFilter } from "../../models/ticketsMdaFilter";
import { TicketService } from '../../services/tickets.service';

@Component({
  selector: 'app-tickets-mda',
  templateUrl: './tickets-mda.component.html',
  styleUrls: ['./tickets-mda.component.css']
})
export class TicketsMdaComponent implements OnInit{
  filtros = new TicketsMdaFilter();
  options: PaginationOptions = new PaginationOptions();
  dataSource: MatTableDataSource<TicketMda> = new MatTableDataSource();
  pageIndex: number;
  length: number;
  response: any;
  isLoading!: boolean;
  docenteTicket: { nombre: string, email: string, via: string }[]=[];
  @ViewChild("descriptionTicket") descriptionTicket: ElementRef;

  columnas = [
    { columna: "idTicket", name:"ID Ticket"},
    { columna: "asunto", name: "Asunto"},
    { columna: "rut", name:"Rut"},
    { columna: "nombre", name: "Nombre"},
  ]
  comentarios: any =[];
  ticket: TicketMda;
  innerHTMLTicket!: boolean;

  constructor(
    private ticketService: TicketService,
    private errorMensaje: ErrorMensajeService
  ) {}

  ngOnInit(): void {
    this.filtrar();
  }

  ngAfterContentChecked(): void {
    if (this.descriptionTicket && this.innerHTMLTicket && this.comentarios && this.ticket && this.docenteTicket[0] && this.ticket?.description) {
      this.descriptionTicket.nativeElement.innerHTML = "<p>"+this.ticket.description+"</p>";
      this.innerHTMLTicket = false;
    }
  }

  getTickets() {
    this.isLoading = true;
    this.ticketService.ticketList(
      this.options,
      this.filtros.idTicket,
      this.filtros.asunto,
      this.filtros.rut,
      this.filtros.idSolicitante)
    .subscribe({
      next: (response:any) => {
        this.dataSource = new MatTableDataSource<TicketMda>(response.tickets);
        this.response = response.tickets;
        this.pageIndex = (response.pageNum - 1);
        this.length = response.total;
        this.isLoading = false;
      },
      error: (error:any) => {
        this.errorMensaje.show(error, 'obtener los tickets de Mesa de Ayuda.').then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            this.isLoading = false;
          }
        });
      }
    });
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getTickets();
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 10;
    this.getTickets();
  }

  public limpiar(): void {
    this.filtros = new TicketsMdaFilter();
    this.filtrar();
  }

  obtenerTicketyComentarios(element: TicketMda) {
    const idTicket = element.id;
    this.isLoading = true;
    this.comentarios = [];
    this.ticket = null;
    this.ticketService.obtenerComentarioTicket(idTicket)
    .subscribe(
      {
        next: (value:any) => {
            this.ticket = element;
            this.isLoading = false;
            if (value?.comentarios) {
              this.obtenerNombreDentroDeComentarios(value.comentarios);
              this.comentarios = value.comentarios;
              this.innerHTMLTicket = true;
              this.buscarAutores();
            } else {
              alert("sin comentarios.")
            }
        },
        error: (error:any) => {
          this.errorMensaje.show(error, 'obtener los comentarios del ticket seleccionado.').then((result:any) => {
            if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
              this.isLoading = false;
            }
          });
        }
      }
    );
  }

  buscarAutores() {
    this.comentarios.map((item: ComentarioMda) => {
      const id = Number(item.author_id);
      item.autorComentario = "cargando usuario";
      this.ticketService.buscarAutor(id)
      .subscribe(
        {
          next: (res:{nombre: string}) => {
            item.autorComentario = res.nombre!="" ? res.nombre : "No registrado en bd";
          },
          error: (error: any) => {
          }
        }
      );
    });
  }

  fecha(created_at: string ): string {
    return formatDate(new Date(created_at), 'dd-MM-yyyy hh:mm:ss', 'en_US')
  }

  //limpia los objetos string de los 'None' y cambia las comillas a "", luego convierte a objeto json.
  jsonLimpio(via:string) {
    const data = via.toString().split("").map((item:any) => item =="'" ? '"' : item).join("").split("None").join('"0"');

    return JSON.parse(data.toString().split("").map((item:any) => item =="'" ? '"' : item).join("").replace("None","0"))
  }

  obtenerNombreDentroDeComentarios(comentario: any ) {
    const viaticket = this.jsonLimpio(this.ticket.via);
    if(viaticket.source.rel !="0") {
      this.docenteTicket = [];
      this.docenteTicket.push({nombre: 'Ticket interno', email: viaticket.source.rel=="follow_up" ? "Seguimiento" : viaticket.source.rel, via: 'interno'});
      return;
    }
    let via = comentario[0]?.via;
    if (!via)  return;

    let obj = this.jsonLimpio(via);
    let i = 0;
    while ( obj.source.from.address !="consultas@docentemas.cl"
      && (!obj.source.to.name) && (i+1) < comentario.length) {
      //se busca el nombre dentro de los comentarios
      i+=1;
      via = comentario[i].via;
      obj = this.jsonLimpio(via);
    }

    this.docenteTicket = [];
    this.docenteTicket.push(
      {
        nombre: obj.source.from.name ? obj.source.from.name : obj.source.to.name ? obj.source.to.name : 'Ticket interno',
        email: obj.source.from.address? obj.source.from.address: viaticket.source.rel!="0" ?
          viaticket.source.rel.toString().toUpperCase() : obj.source.to.address, via: viaticket.channel});
    if (this.ticket.nombre == '0.0') this.ticket.nombre = this.docenteTicket[0]['nombre'];
  }

  catalogarEmail(viaComm: string, autor:string) {
    const objVia = this.jsonLimpio(viaComm);
    if(this.docenteTicket[0].nombre === autor) return "El autor del ticket ha enviado un mensaje a Mesa de Ayuda";
    if (objVia.channel =='system') return "Acción de sistema interno de mesa de ayuda del tipo " + objVia.source.rel.toString().toUpperCase();
    if (objVia.channel =='email' && objVia.source.from.address) return "El autor del ticket ( "+ objVia.source.from.address + " ) ha enviado un mensaje a Mesa de Ayuda";
    if (!objVia.source.from.address && !objVia.source.to.address) return "Mensaje interno";
    return "Se responde al consultante";
  }

  satisfaccion(score: string):any {
    return this.jsonLimpio(score);
  }

  satisfaccionTraducida(score: string): string {
    const rating = this.jsonLimpio(score);
    return rating == "offered" ? "Ofrecido pero no respondido" : "Calificación no ofrecida";
  }
}
