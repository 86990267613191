export class Encargado {
    rut	!: number | null;
    nombre !: string | null;
    primerApellido !: string | null;
    segundoApellido !: string | null;
    telefono !: any | null;
    correo !: string | null;
    establecimientos !:  Array<number> | {rbd: number}[];
    rutSostenedor !: number | null;
    constructor(){
        this.rut = null;
        this.nombre = "";
        this.primerApellido = "";
        this.segundoApellido = "";
        this.telefono = null;
        this.correo = "";
        this.establecimientos = [];
        this.rutSostenedor = null;
    }
}
