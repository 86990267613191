<div *ngIf="historialValidacion && historialValidacion.length > 0">

  <div>
    <h3
    class="ms-3 py-4 text-2xl font-bold text-slate-700">
    Historial de Cambios
  </h3>

  <div #historial class="flex flex-row gap-4">
    <ol  #timeline class="border-s-2 border-blue-600" >
      <li class="h-12" *ngFor="let validacion of historialValidacion; let i = index">

        <div class="flex-start md:flex">
          <div  #calendario
            class="-ms-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-slate-400 text-blue-700"
            [ngClass]="{'bg-green-500 text-green-950': validacion.estadoConfirmacionSubsector =='Aceptado',
              'bg-slate-400 text-slate-900': validacion.estadoConfirmacionSubsector =='Pendiente',
              'bg-red-300 text-red-900': validacion.estadoConfirmacionSubsector =='Rechazado',
             }">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16"> <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/> <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/> </svg>
          </div>
          <button class="ml-1 text-xs px-2 rounded-2xl border-2 border-blue-500 hover:bg-blue-100" matTooltip="Ver cambios"
          [ngClass]="{'font-bold': indexSeleccionado == i}" *ngIf="formatearHoraChile(validacion.createdAt)"
          (click)="verCambios(validacion,i)" > {{formatearHoraChile(validacion.createdAt) | date:'dd-MM-yyyy HH:mm:ss'}} </button>
        </div>
      </li>
    </ol>

    <div *ngIf="!cambioSeleccionado"> Seleccione una fecha con cambios para mostrar</div>

    <div #infoCambios *ngIf="cambioSeleccionado" class="border-2 rounded-2xl border-slate-600 w-3/4 h-fit p-4">
      <div class="flex flex-row gap-2">
        <p>Cambios Solicitados</p>
        <p class="rounded-xl px-2 bg-green-500 text-xs w-fit flex text-center items-center"
        [ngClass]="{'bg-green-500':cambioSeleccionado.estadoConfirmacionSubsector == 'Aceptado', 'bg-red-900 text-red-50':cambioSeleccionado.estadoConfirmacionSubsector == 'Rechazado',
        'bg-slate-500 text-slate-50': cambioSeleccionado.estadoConfirmacionSubsector =='Pendiente' }">
        Estado de Confirmación {{cambioSeleccionado.estadoConfirmacionSubsector}} </p>
      </div>
      <table class="m-4 table ">
        <tr>
          <th class="w-1/4 text-[0.8em]">Fecha de evento</th>
          <td class="flex flex-col border-0">
            <p *ngIf="formatearHoraChile(cambioSeleccionado.createdAt)" class="text-xs border-0" >{{formatearHoraChile(cambioSeleccionado.createdAt) | date:'dd-MM-yyyy HH:mm:ss'}}</p>
          </td>
        </tr>
        <tr >
          <th class="w-1/4 text-[0.8em]">Correo</th>
          <td class="flex flex-col border-0 "> <p class="text-xs border-0" >{{cambioSeleccionado.correo_electronico}}</p> </td>
        </tr>
        <tr >
          <th class="w-1/4 text-[0.8em]">Contacto</th>
          <td class="flex flex-col border-0">
            <p class="text-xs border-0" >{{cambioSeleccionado.numero_contacto_1}}</p>
            <p class="text-xs border-0" >{{cambioSeleccionado.numero_contacto_2}}</p>
          </td>
        </tr>
        <tr >
          <th class="w-1/4 text-[0.8em]">Rinde Portafolio</th>
          <td class="flex flex-col border-0 "> <p class="text-xs border-0" >{{cambioSeleccionado.rinde_portafolio ? 'Sí':'No'}}</p> </td>
        </tr>
        <tr >
          <th class="w-1/4 text-[0.8em]">Ubicación</th>
          <td class="flex flex-col border-0">
            <p class="text-xs border-0" >{{cambioSeleccionado.direccion}}</p>
            <p class="text-xs border-0" >{{cambioSeleccionado.comuna}}, Región de {{cambioSeleccionado.region}}</p>
          </td>
        </tr>
        <tr >
          <th class="w-1/4 text-[0.8em]">Docente solicita cambio de Subsector?</th>
          <td class="flex flex-col border-0">
            <p class="text-xs border-0" >{{cambioSeleccionado.solicitaCambioSubsector ? 'Solicita': 'No Solicita'}}</p>
            <p class="rounded-xl px-2 bg-green-500 text-xs text-black w-fit flex text-center items-center"
            [ngClass]="{'bg-green-500':cambioSeleccionado.estadoConfirmacionSubsector == 'Aceptado',
               'bg-red-900 text-red-50':cambioSeleccionado.estadoConfirmacionSubsector == 'Rechazado',
              'bg-slate-500 text-slate-50': cambioSeleccionado.estadoConfirmacionSubsector =='Pendiente' }">
            {{cambioSeleccionado.estadoConfirmacionSubsector}} </p>
          </td>
        </tr>
      </table>

      <p class=" pb-2">Cambios de Agrupación</p>
      <div class="flex flex-row gap-2 justify-center items-center">
        <div class="flex flex-col border-2 border-slate-400 rounded-xl p-2  w-40"><p class="text-xs">{{cambioSeleccionado.nombreNivelPrevio}}</p> <p class="text-sm">{{cambioSeleccionado.nivelPrevio}}</p></div>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 436 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        <div class="flex flex-col border-2 border-slate-400 rounded-xl p-2  w-40"><p class="text-xs">{{cambioSeleccionado.nombreNivelSolicitado}}</p> <p class="text-sm">{{cambioSeleccionado.nivelSolicitado}}</p></div>
      </div>
      <p class=" py-2">Cambios de Asignatura</p>

      <div class="flex flex-row gap-2 justify-center items-center">
        <div class="flex flex-col border-2 border-slate-400 rounded-xl p-2 w-40"><p class="text-xs">{{cambioSeleccionado.nombreSubsectorPrevio}}</p> <p class="text-sm">{{cambioSeleccionado.subsectorPrevio}}</p></div>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="36" viewBox="0 0 436 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        <div class="flex flex-col border-2 border-slate-400 rounded-xl p-2  w-40"><p class="text-xs">{{cambioSeleccionado.nombreSubsectorSolicitado}}</p> <p class="text-sm">{{cambioSeleccionado.subsectorSolicitado}}</p></div>
      </div>
    </div>
  </div>
  </div>

</div>

<div *ngIf="!historialValidacion">
  Sin Historial de Validación.
</div>

