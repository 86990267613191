import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { PaginationOptions } from 'src/app/base/models/paginationOptions';
import { Encargado } from 'src/app/registros/models/encargado';
import { EncargadoEstablecimiento } from 'src/app/registros/models/encargadoEstablecimiento';
import { EstablecimientoFilter } from 'src/app/registros/models/establecimientoFilter';
import { SostenedorFilter } from 'src/app/registros/models/sostenedorFilter';
import { EncargadoEvaluacionService } from 'src/app/registros/services/encargado-evaluacion.service';
import { EstablecimientoService } from 'src/app/registros/services/establecimiento.service';
import { SectorService } from 'src/app/registros/services/sector.service';
import { SostenedorService } from 'src/app/registros/services/sostenedor.service';
import Swal from 'sweetalert2';
import { Toast } from '../../../../base/services/toast';
import { EncargadoEdicionService } from '../services/EncargadoEdicion.service';


@Component({
  selector: 'app-registro-encargado-evaluacion-form',
  templateUrl: './registro-encargado-evaluacion-form.component.html',
  styleUrls: ['./registro-encargado-evaluacion-form.component.css', '../../../modulo_registro.css']
})
export class RegistroDocenteFormComponent implements OnInit {

  //Data for table establecimiento
  displayedColumnsEstablecimiento: string[] = ['seleccion', 'rbd', 'nombreEstablecimiento'];
  ELEMENT_DATA: Establecimiento[] = [];
  @ViewChild(MatPaginator) paginatorEstablecimiento: MatPaginator;
  isLoadingEstablecimiento = false;
  dataSourceEstablecimiento: MatTableDataSource<Establecimiento> = new MatTableDataSource();
  pageIndexEstablecimiento: number;
  lengthEstablecimiento: number;
  filtrosEstablecimiento: EstablecimientoFilter = new EstablecimientoFilter();
  getEstablecimientoSub: Subscription;
  optionsEstablecimiento: PaginationOptions = new PaginationOptions();
  responseEstablecimiento: any;
  selectionEstablecimiento = new SelectionModel<any>(true, []);

  //Data for table Sostenedores
  displayedColumnsSostenedor: string[] = ['seleccion', 'rut', 'nombre', 'sector'];
  ELEMENT_DATA_SOSTENEDOR: Sostenedor[] = [];
  @ViewChild(MatPaginator) paginatorSostenedor: MatPaginator;
  isLoadingSostenedor = false;
  dataSourceSostenedor: MatTableDataSource<Sostenedor> = new MatTableDataSource();
  pageIndexSostenedor: number;
  lengthSostenedor: number;
  filtrosSostenedor: SostenedorFilter = new SostenedorFilter();
  getSostenedorSub: Subscription;
  optionsSostenedor: PaginationOptions = new PaginationOptions();
  responseSostenedor: any;
  selectionSostenedor = new SelectionModel<any>(true, []);
  sostenedorSelected: Sostenedor = null;
  sectorSelected: Sector = null;

  secondFormGroup = this._formBuilder.group({
    rut: ['', Validators.required],
    nombre: ['', Validators.required],
    primerApellido: ['', Validators.required],
    segundoApellido: ['', Validators.required],
    correo: ['', Validators.required],
    telefono: ['', Validators.required]
  });

  estaAgregando: boolean;
  sectores: Sector[] = [];
  encargadoObjetivo!: EncargadoEstablecimiento;
  encargadoActual: any;
  rutSostenedorOriginal: number;
  establecimientoOriginal: any;
  nameSectorSelected: any;
  encargadoEvaluacionForm: string[] = ["Rut", "Nombre", "Primer Apellido", "Segundo Apellido", "Correo", "Teléfono"];

  constructor(
    private _formBuilder: FormBuilder,
    private establecimientoService: EstablecimientoService,
    private sostenedorService: SostenedorService,
    private encargadoEvaluacionService: EncargadoEvaluacionService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    @Inject(Router) private router: Router,
    private sectorService: SectorService,
    private encargadoEdicionSvc: EncargadoEdicionService,
  ) { }

  ngAfterViewInit() {
    this.paginatorEstablecimiento._intl.itemsPerPageLabel = "Elementos por página"
    this.paginatorEstablecimiento._intl.nextPageLabel = 'Siguiente página';
    this.paginatorEstablecimiento._intl.previousPageLabel = 'Página Anterior';
    this.paginatorEstablecimiento._intl.lastPageLabel = 'Ir a la última página';
    this.paginatorEstablecimiento._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSourceEstablecimiento.paginator = this.paginatorEstablecimiento;

    this.paginatorSostenedor._intl.itemsPerPageLabel = "Elementos por página"
    this.paginatorSostenedor._intl.nextPageLabel = 'Siguiente página';
    this.paginatorSostenedor._intl.previousPageLabel = 'Página Anterior';
    this.paginatorSostenedor._intl.lastPageLabel = 'Ir a la última página';
    this.paginatorSostenedor._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSourceSostenedor.paginator = this.paginatorSostenedor;
  }

  ngOnInit(): void {
    this.optionsEstablecimiento.size = 5;
    this.getSectores();
    this.limpiarFiltroSostenedor();
    this.estaAgregando = true;
    this.encargadoObjetivo = this.encargadoEdicionSvc.popScope();
    if (this.encargadoObjetivo != null) {
      this.estaAgregando = false;
      this.encargadoActual = this.encargadoObjetivo;
      this.loadEncargado(this.encargadoObjetivo);
      this.spinner.hide();
    }
  }

  getSectores(): void {
    this.sectorService.sectorList().subscribe(response => {
      this.sectores = response;
    });
  }

  getSostenedores(): void {
    this.isLoadingSostenedor = true;
    this.getSostenedorSub = this.sostenedorService.sostenedoresList(this.optionsSostenedor, this.filtrosSostenedor).subscribe(response => {
      this.dataSourceSostenedor = new MatTableDataSource<Sostenedor>(response.list);
      this.responseSostenedor = response.list;
      this.pageIndexSostenedor = response.pageNum -1;
      this.lengthSostenedor = response.total;
      this.isLoadingSostenedor = false;
      const sostenedor: Sostenedor = response.list.find((item:any) => item.rutSostenedor = this.filtrosSostenedor.rutSostenedor);
      if (sostenedor) {
        this.nameSectorSelected = sostenedor.sector.nombre;
      }
    });
  }
  getEstablecimientos(): void {
    this.isLoadingEstablecimiento = true;
    this.getEstablecimientoSub = this.establecimientoService.encargadosEstablecimientoList(this.optionsEstablecimiento, this.filtrosEstablecimiento).subscribe(response => {
      this.dataSourceEstablecimiento = new MatTableDataSource<Establecimiento>(response.list);
      this.responseEstablecimiento = response;
      this.pageIndexEstablecimiento = (response.pageNum - 1);
      this.lengthEstablecimiento = response.total;
      this.isLoadingEstablecimiento = false;
    });
  }

  pageChanged(event: PageEvent) {
    this.optionsEstablecimiento.page = event != null ? (event.pageIndex + 1) : 1;
    this.optionsEstablecimiento.size = event != null ? event.pageSize : 10;
    this.getEstablecimientos();
  }
  pageChangedSostenedor(event: PageEvent) {
    this.optionsSostenedor.page = event != null ? (event.pageIndex + 1) : 1;
    this.optionsSostenedor.size = event != null ? event.pageSize : 10;
    this.getSostenedores();
  }

  loadEncargado(encargado: any) {
    this.rutSostenedorOriginal = encargado.rutSostenedor;
    this.establecimientoOriginal = encargado.establecimientos;
    this.filtrosSostenedor.rutSostenedor = encargado.rutSostenedor;
    this.filtrarSostenedor();
    this.filtrosEstablecimiento.rutSostenedor = encargado.rutSostenedor;
    this.filtrarEstablecimientos();

    this.secondFormGroup.setValue({
      rut: encargado.rut,
      nombre: encargado.nombre,
      primerApellido: encargado.primerApellido,
      segundoApellido: encargado.segundoApellido,
      correo: encargado.correo,
      telefono: encargado.telefono,
    });
    for (let item of encargado.establecimientos) {
      this.selectionEstablecimiento.toggle(item);
    }
    this.selectionSostenedor = new SelectionModel<any>(true, []);
    this.selectionSostenedor.toggle(encargado.rutSostenedor);

  }

  public filtrarEstablecimientos(): void {
    this.optionsEstablecimiento.page = 1;
    this.optionsEstablecimiento.size = 5;
    this.getEstablecimientos();
  }

  filtrarSostenedor() {
    this.optionsSostenedor.page = 1;
    this.optionsSostenedor.size = 10;
    this.getSostenedores();
  }

  onCheckboxWrapperClick(event: any) {
    event.stopPropagation();
  }

  onCheckboxWrapperSostenedorClick(event: any) {
    event.stopPropagation();
  }

  onCheckboxSostenedorChange(row: any) {
    if (this.selectionSostenedor.isSelected(row.rut)) {
      this.selectionSostenedor = new SelectionModel<any>(true, []);
    } else {
      this.selectionSostenedor = new SelectionModel<any>(true, []);
      this.selectionSostenedor.toggle(row.rut);
      this.sostenedorSelected = row;
      this.sectorSelected = row.sector.id;
      this.nameSectorSelected = row.sector.nombre;
      this.filtrosEstablecimiento.rutSostenedor = row.rut;
      this.filtrarEstablecimientos();
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  onCheckboxEstablecimientoChange(row: any) {
    this.selectionEstablecimiento.toggle(row.rbd);
  }


  submitSecondForm() {
    const nombre = this.secondFormGroup.get('nombre').value;
    const primerApellido = this.secondFormGroup.get('primerApellido').value;
    const segundoApellido = this.secondFormGroup.get('segundoApellido').value;
    const sector = this.secondFormGroup.get('sector').value;
  }

  verificarSostenedorEstablecimiemto() {
    this.selectionEstablecimiento = new SelectionModel<any>(true, []);
    if(this.rutSostenedorOriginal === this.filtrosEstablecimiento.rutSostenedor &&
      this.nameSectorSelected != 'MUNICIPAL') {
        for (let item of this.establecimientoOriginal) {
          this.selectionEstablecimiento.toggle(item);
        }
    }
  }

  limpiarFiltroSostenedor() {
    this.filtrosSostenedor = new SostenedorFilter();
    this.filtrarSostenedor();
  }

  guardar() {
    const encargado = new Encargado();
    encargado.rut = Number(this.secondFormGroup.get('rut').value);
    encargado.nombre = this.secondFormGroup.get('nombre').value;
    encargado.primerApellido = this.secondFormGroup.get('primerApellido').value;
    encargado.segundoApellido = this.secondFormGroup.get('segundoApellido').value;
    encargado.correo = this.secondFormGroup.get('correo').value;
    encargado.telefono = this.secondFormGroup.get('telefono').value;
    encargado.rutSostenedor = this.filtrosEstablecimiento.rutSostenedor;
    encargado.establecimientos = [];

    if (this.selectionEstablecimiento.selected.length > 0) {
      let selectedRbd: {rbd: number}[] = [];
      for (let item of this.selectionEstablecimiento.selected) {
        if (Number.isInteger(item)) {
          selectedRbd.push({rbd:item});
        }
      }
      encargado.establecimientos = selectedRbd;
    }

    if (this.encargadoObjetivo != null) {
      this.actualizar(encargado, this.rutSostenedorOriginal);
    } else {
      this.crear(encargado);
    }
  }

  crear(encargado: Encargado) {
    this.spinner.show();
    this.encargadoEvaluacionService.save(encargado).subscribe({
      next: (response:any) => {
        const mensaje = response.hasOwnProperty('mensaje') ? response.mensaje : "Se ha creado el encargado.";
        this.spinner.hide();
        Toast.fire(
          "Creación de Encargado",
          mensaje,
          "success"
        );
        this.router.navigate(['private/registro-encargados-evaluacion-list']);
      }, error: (error:any) => {
        const mensaje = error.error.hasOwnProperty('error') ? error.error.error : 'Ha ocurrido un error al crear el/la encargado/a ';

        Toast.fire(
          "Error al crear Encargado",
          mensaje + ' ['+error.status+"]",
          'error'
        );

      }
    });

  }

  actualizar(encargado: Encargado, rutSostenedorOriginal: number) {
    this.spinner.show();
    this.encargadoEvaluacionService.update(encargado, rutSostenedorOriginal).subscribe({
      next: (response: any) => {
      this.spinner.hide();
      Toast.fire(
        "Edición de Encargado",
        "Edición de encargado se ha realizado exitosamente",
        "success"
      );
      this.router.navigate(['private/registro-encargados-evaluacion-list']);
    },
    error: (error: any) => {
      const mensaje = error.error.hasOwnProperty('error') ? error.error.error : 'Ha ocurrido un error al editar el/la encargado/a ';

      Toast.fire(
        "Error al editar este Encargado",
        mensaje + ' ['+error.status+"]",
        'error'
      );
    }});
  }

  buscarPorRutEncargado() {
    const rut = this.secondFormGroup.get('rut').value;
    if (!rut) return;

    this.encargadoEvaluacionService.getEncargado(Number(rut), this.filtrosEstablecimiento.rutSostenedor).subscribe(
      {
        next: (value:any) => {
          if (value) {
            Swal.fire({
              title: 'Advertencia',
              html: 'Ya existen datos previamente guardados para este encargado/a. Si decides cargarlos, se perderán todos los cambios no guardados que hayas realizado. <br><br><br> <b style="font-size:1em">¿Deseas continuar y cargar los datos guardados?</b>',
              icon: 'warning',
              showDenyButton: false,
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Confirmar',
              confirmButtonColor: '#d9534f',
              cancelButtonColor: '#c8c8c8',

            }).then((result:any) => {
              if (result.isConfirmed) {
                this.secondFormGroup.setValue(
                  {
                    rut: value.rut,
                    nombre: value.nombre,
                    primerApellido: value.primerApellido,
                    segundoApellido:value.segundoApellido,
                    correo: value.correo,
                    telefono: value.telefono
                  }
                );
              }
            })
          }
        },
        error: (err:any) => {
        }
      }
    );
  }

}


//create interface for tables
export interface Establecimiento {
  rutSostenedor: number;
  rbd: number;
  nombreEstablecimiento: string;
  rutEncargado: number;
  nombreEncargado: string;
  primerApellido: string;
  segundoApellido: string;
  mail: string;
  estadoAcceso: string;
}
export interface Sostenedor {
  rut: number;
  nombre: string;
  sector: Sector;
}

export interface Sector {
  id: number;
  nombre: string;
}
