export class evaluacionHistoricaExtFilter {
  rutDocente: number;
  rutSostenedor: number;
  sistemaEducativo: number;
  dependencia: string;
  rbd: number;
  comuna: string;
  anioPortafolio: number;
  tramo: String;
  beneficios: string;
  deprov: string;

  constructor(){
    this.rutDocente = null;
    this.rutSostenedor = null;
    this.sistemaEducativo = null;
    this.dependencia = null;
    this.rbd = null;
    this.comuna = null;
    this.anioPortafolio = null;
    this.tramo = null;
    this.beneficios = null;
    this.deprov = null;
  }
}
