<div class="container-md">

    <mat-stepper [linear]="true" #stepper>
        <mat-step >
                <ng-template matStepLabel>Seleccione Sostenedor</ng-template>

                <h3 class="pb-4">Búsqueda de sostenedor</h3>
                <div class="row px-4 pb-4 cardfiltros">
                    <h3 class="pb-4">Filtro</h3>
                    <div class="col-sm-4">
                        <mat-form-field  appearance="outline" class="example-full-width fullWidth">
                            <mat-label>RUT</mat-label>
                            <input
                              type="rut"
                              matInput
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              placeholder="Rut Sostenedor"
                              [(ngModel)]="filtrosSostenedor.rutSostenedor"
                              name="rutSostenedor"
                            />
                          </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field  appearance="outline" class="w-full">
                            <mat-label>Nombre</mat-label>
                            <input
                              type="text"
                              matInput
                              placeholder="Nombre Sostenedor"
                              [(ngModel)]="filtrosSostenedor.nombreSostenedor"
                              name="nombreSostenedor"
                            />
                          </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field  appearance="outline" class="example-full-width fullWidth">
                            <mat-label>Sector</mat-label>
                            <mat-select [(ngModel)]="filtrosSostenedor.idSector" name="sector" >
                            <mat-option [value]="null">todos</mat-option>
                              <mat-option *ngFor="let sector of sectores" [value]="sector.id">
                                {{sector.nombre}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <button
                            mat-raised-button
                            color="primary"
                            type="submit"
                            aria-label="boton agregar"
                            matTooltip="Busqueda filtrada de sostenedores"
                            class="btn btn-outline-primary"
                            (click)="filtrarSostenedor()"
                        >
                            <mat-icon>search</mat-icon> Buscar
                        </button>
                        <button
                            mat-raised-button
                            style="margin-left: 10px;"
                            color="secondary"
                            type="reset"
                            aria-label="boton agregar"
                            class="btn btn-outline-secondary"
                            matTooltip="Limpiar Filtros"
                            (click)="limpiarFiltroSostenedor()"
                        >
                            <mat-icon>delete</mat-icon> Limpiar
                        </button>
                    </div>
                </div>
                <h3 class="pb-4">Seleccione el sostenedor</h3>
                <mat-progress-bar mode="indeterminate" *ngIf="isLoadingSostenedor"></mat-progress-bar>
                <table role="table" aria-label="tabla encargados" mat-table [dataSource]="dataSourceSostenedor"
                class="tables-info">
                    <ng-container matColumnDef="seleccion">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell class="selection-column" *matCellDef="let row" (click)="onCheckboxSostenedorChange(row)">
                            <div class="checkbox-wrapper" (click)="onCheckboxWrapperSostenedorClick($event)">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? onCheckboxSostenedorChange(row) : null"
                                    [checked]="selectionSostenedor.isSelected(row.rut)">
                                </mat-checkbox>
                            </div>
                        </td>
                    </ng-container>
                        <ng-container matColumnDef="rut">
                            <th mat-header-cell *matHeaderCellDef>Rut </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.rut }}</td>
                        </ng-container>
                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef>Nombre </th>
                            <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
                        </ng-container>
                        <ng-container matColumnDef="sector">
                            <th mat-header-cell *matHeaderCellDef>Sector </th>
                            <td mat-cell *matCellDef="let element">{{ element.sector.nombre }}</td>
                        </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsSostenedor"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsSostenedor"></tr>
                </table>

                <mat-paginator #paginatorSostenedor
                    *ngIf="lengthSostenedor>-1"
                    [pageSizeOptions]="[10, 50, 100]"
                    [showFirstLastButtons]="true"
                    [length]="lengthSostenedor"
                    [pageIndex]="pageIndexSostenedor"
                    (page)="pageChangedSostenedor($event)"
                    aria-label="Seleccionar sostenedores">
                </mat-paginator>

                <div class="d-flex flex-row-reverse bd-highlight mt-2">
                    <button mat-raised-button [ngClass]="{'animate-bounce': selectionSostenedor.selected.length ==1}" color="primary" matStepperNext [disabled]="selectionSostenedor.selected.length != 1" >
                        Siguiente Paso
                    </button>

                </div>
        </mat-step>

        <mat-step [formGroup]="secondFormGroup" >
            <ng-template matStepLabel>Datos de Encargado</ng-template>
          <div style="margin-top: 25px;">
            <h3 *ngIf="estaAgregando" class="pb-4">Creación de encargado</h3>
            <h3 *ngIf="!estaAgregando" class="pb-4">Edición de encargado</h3>
          </div>

            <div class="max-w-xl mx-auto p-8 bg-white rounded-md shadow-md">
                <h3 class="py-4">Datos generales</h3>
                <div class="py-2">
                    <p class="font-black text-md pb-2">Rut</p>
                    <div class="flex flex-row w-full">
                      <input matInput type="number" formControlName="rut" [readonly]="!!encargadoObjetivo" min="0"
                      placeholder="Rut" required class="w-[90%] px-3 py-2 border rounded-s-md focus:outline-none focus:border-blue-400">
                      <button class="w-[10%] bg-primary-600 rounded-e-md animate-pulse-bg-once from-primary-500 to-indigo-900" (click)="buscarPorRutEncargado()" title="Verificar los datos de este encargado">
                        <svg style="color: white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="animate- w-9 h-9 py-2 pl-4 flex justify-center align-middle"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" fill="white"></path></svg>
                      </button>
                    </div>
                </div>
                <div class="py-2">
                    <p class="font-black text-md pb-2">Nombre</p>
                    <input matInput type="text" formControlName="nombre"
                    placeholder="Nombre" required class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500">
                </div>
                <div class="flex flex-row gap-4 w-full">
                    <div class="py-2 w-full">
                        <p class="font-black text-md pb-2">Primer Apellido</p>
                        <input matInput type="text" formControlName="primerApellido"
                        placeholder="Primer Apellido" required class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500">
                    </div>
                    <div class="py-2 w-full">
                        <p class="font-black text-md pb-2">Segundo Apellido</p>
                        <input matInput type="text" formControlName="segundoApellido"
                        placeholder="Segundo Apellido" required class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500">
                    </div>
                </div>
                <div class="py-2">
                    <p class="font-black text-md pb-2">Correo</p>
                    <input matInput type="email" formControlName="correo"
                    placeholder="Correo" required class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500">
                </div>
                <div class="py-2">
                    <p class="font-black text-md pb-2">Número de contacto</p>
                    <input matInput type="number" formControlName="telefono"
                    placeholder="Número de contacto" required class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500">
                </div>

                <div class="row mt-8">
                    <div class="col-sm-6">
                        <div class="d-flex flex-row bd-highlight mb-3">
                            <button mat-raised-button class="bg-primary-300" matStepperPrevious>
                                Atrás
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="d-flex flex-row-reverse bd-highlight">
                            <button mat-raised-button color="primary" matStepperNext
                            (click)="verificarSostenedorEstablecimiemto()">
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>

            </div>


        </mat-step>
        <mat-step >

                <ng-template matStepLabel>RBDs asociados</ng-template>
                {{nameSectorSelected}}
                <div class="row mt-2" *ngIf="nameSectorSelected != 'MUNICIPAL'">
                    <div class="col-sm-2 p-5">

                    </div>
                    <div class="col-sm-8 p-2">
                        <div class="col" style="text-align: center;">
                            <h3 class="py-2">Selección Establecimientos</h3>
                        </div>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoadingEstablecimiento"></mat-progress-bar>
                        <table role="table" aria-label="tabla encargados" mat-table [dataSource]="dataSourceEstablecimiento"
                        class="tables-info">
                            <ng-container matColumnDef="seleccion">
                                <th id="select" class="selection-column" mat-header-cell *matHeaderCellDef>

                                </th>
                                <td mat-cell class="selection-column" *matCellDef="let row">
                                    <div class="checkbox-wrapper" (click)="onCheckboxWrapperClick($event)">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? onCheckboxEstablecimientoChange(row) : null"
                                            [checked]="selectionEstablecimiento.isSelected(row.rbd)">
                                        </mat-checkbox>
                                    </div>
                                </td>
                            </ng-container>
                                <ng-container matColumnDef="rbd">
                                    <th mat-header-cell *matHeaderCellDef>RBD </th>
                                    <td mat-cell *matCellDef="let element">{{ element.rbd }}</td>
                                </ng-container>
                                <ng-container matColumnDef="nombreEstablecimiento">
                                    <th mat-header-cell *matHeaderCellDef>Establecimiento </th>
                                    <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
                                </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsEstablecimiento"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsEstablecimiento"></tr>
                        </table>
                        <mat-paginator #paginatorEstablecimiento
                            [pageSizeOptions]="[5,10, 50, 100]"
                            [showFirstLastButtons]="true"
                            [length]="lengthEstablecimiento"
                            [pageIndex]="pageIndexEstablecimiento"
                            (page)="pageChanged($event)"
                            aria-label="Select page of periodic elements">
                        </mat-paginator>
                    </div>
                    <div class="col-sm-2 p-5">

                    </div>
                </div>


                <div class="row mt-2" *ngIf="nameSectorSelected === 'MUNICIPAL'">
                    <div class="col-sm-2 p-5">

                    </div>

                    <div class="col-sm-8 p-5">
                        <div class="col">
                            <h3 class="py-2">Resumen de Establecimientos Asignados </h3>
                        </div>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoadingEstablecimiento"></mat-progress-bar>
                        <table role="table" aria-label="tabla encargados" mat-table [dataSource]="dataSourceEstablecimiento"
                        class="tables-info">
                            <ng-container matColumnDef="seleccion">
                                <th id="select" mat-header-cell *matHeaderCellDef>

                                </th>
                                <td mat-cell  *matCellDef="let row">

                                </td>
                            </ng-container>
                                <ng-container matColumnDef="rbd">
                                    <th mat-header-cell *matHeaderCellDef>RBD </th>
                                    <td mat-cell *matCellDef="let element">{{ element.rbd }}</td>
                                </ng-container>
                                <ng-container matColumnDef="nombreEstablecimiento">
                                    <th mat-header-cell *matHeaderCellDef>Establecimiento </th>
                                    <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
                                </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsEstablecimiento"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsEstablecimiento"></tr>
                        </table>
                        <mat-paginator #paginatorEstablecimiento
                            [pageSizeOptions]="[5,10, 50, 100]"
                            [showFirstLastButtons]="true"
                            [length]="lengthEstablecimiento"
                            [pageIndex]="pageIndexEstablecimiento"
                            (page)="pageChanged($event)"
                            aria-label="Select page of periodic elements">
                        </mat-paginator>
                    </div>
                    <div class="col-sm-2 p-5">

                    </div>
                </div>

                <div class="p-5 mx-2 text-center flex justify-center align-middle border-2 border-yellow-600 rounded-xl ">
                    <p>IMPORTANTE: Recuerde que la edición y creación de Encargados de Evaluación solo registra los datos, pero no otorga el acceso a la plataforma Docentemás.
                        Para brindar el acceso, diríjase  a la sección <b>Usuarios Docentemás</b></p>
                </div>

                <div class="row my-4">
                    <div class="col-sm-6">
                        <div class="d-flex flex-row bd-highlight mb-3">
                            <button mat-raised-button class="bg-primary-300" matStepperPrevious>
                                Atrás
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="d-flex flex-row-reverse bd-highlight"
                        *ngIf="selectionEstablecimiento.selected.length>0 && nameSectorSelected != 'MUNICIPAL'">
                            <button mat-raised-button color="primary" matStepperNext (click)="guardar()">
                                Finalizar
                            </button>
                        </div>
                        <div class="d-flex flex-row-reverse bd-highlight"
                         *ngIf="selectionEstablecimiento.selected.length==0 && nameSectorSelected != 'MUNICIPAL'" >
                            <button mat-raised-button matStepperNext disabled="true">Seleccione Establecimiento(s)</button>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight"
                        *ngIf="nameSectorSelected == 'MUNICIPAL'">
                            <button mat-raised-button color="primary" matStepperNext (click)="guardar()">
                                Finalizar
                            </button>
                        </div>
                    </div>
                </div>
        </mat-step>
    </mat-stepper>



</div>
