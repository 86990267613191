import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../base/services/auth.service";
import { Constants } from "../../base/constants/constants";
import { Observable, map } from "rxjs";
import { PaginationOptions } from "../models/paginationOptions";
import {UsuarioOperaciones} from "../models/usuarioOperaciones";

@Injectable({
    providedIn: 'root'
})
export class UsuariosCognitoMdaService {
    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) { }

    getListaGruposCognito() : Observable<any> {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES + Constants.URL_SERVICE.GESTOR_COGNITO.GRUPOS}`;
        return this.http.get(url).pipe(map(response => response));
    }

    getListaUsuariosCognito(options: PaginationOptions, email: string): Observable<UsuarioOperaciones[]> {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES + Constants.URL_SERVICE.GESTOR_COGNITO_MDA.BASE}`;
        let params = this.obtenerParams( options.size, options.page, email);
        return this.http.get<UsuarioOperaciones[]>(url, {params: params});
    }

    buscarUsuario(email: string): Observable<any> {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES}${Constants.URL_SERVICE.GESTOR_COGNITO.BASE}`;
        return this.http.get(url).pipe(map(response => response));
    }
    agregarUsuario (body: {email: string, grupos: string[]}) {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES}${Constants.URL_SERVICE.GESTOR_COGNITO_MDA.BASE}`;
        return this.http.post(url, body).pipe(map(response => response));
    }
    eliminarUsuario (email: string) {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES}${Constants.URL_SERVICE.GESTOR_COGNITO_MDA.BASE}/${email}`;
        return this.http.delete(url).pipe(map(response => response));
    }

    obtenerParams( size: number, page: number, email: string): HttpParams {
      let params = new HttpParams();
        if (size && size > 0) {
          params = params.set('size',size);
        }
        if (page && page > 0) {
          params = params.set('page',page);
        }
        if (email && email.trim() != "") {
          params = params.set('username',email);
        }
        return params;
    }
}
