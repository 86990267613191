import { FormControl, Validators } from "@angular/forms";

const nameTableColumns: string[] = [
    "Docente ID",
    "Agrupacion ID",
    "Pregunta ID",
    "Tipo",
]

const fieldForm: string[] = [
    "Docente ID",
    "Agrupacion ID",
    "Pregunta ID",
    "Tipo",
]

const fieldControl: FormControl[] = [
    new FormControl('', [Validators.required, Validators.maxLength(8)]),
    new FormControl('', [Validators.required, Validators.maxLength(9)]),
    new FormControl('', [Validators.required, Validators.maxLength(10)]),
    new FormControl('', Validators.required),
];


export class Portafolio2023 {
  [index: string]: any;
  idDocente!: number;
  idAgrupacion!: number;
  idPregunta!: number;
  respuesta!: string;
  fechaHora!: string;
  tipo!: string;

  getControl(index: number): FormControl {
    fieldControl[index].setValue('', { emitEvent: false });
    return fieldControl[index];
  }

  getNameColumns(index: number): string {
    return nameTableColumns[index];
  }

  getFieldForm(index: number): string {
    return fieldForm[index];
  }
}
