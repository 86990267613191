import { HttpClient, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionesHistoricasExtendidaService {
  estaDescargandoExcelFichaDocemteEmmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient
  ) { }

  emitirEstadoDescargaFichaDocente(estado: boolean) {
    this.estaDescargandoExcelFichaDocemteEmmit.emit(estado);
  }

  docentesList(options: PaginationOptions,
    rutDocente: number = null,
    rutSostenedor: number = null,
    sistemaEducativo:number = null,
    dependencia: string = "",
    rbd: number = null,
    comuna: string = "",
    anioPortafolio: number = null,
    tramo: String = null,
    beneficios: string = "",
    deprov: string = ""): Observable<any> {
    let params = this.obtenerParamsDocente(rutDocente, rutSostenedor, sistemaEducativo, dependencia,
       rbd, comuna, anioPortafolio, tramo, beneficios, deprov);
    params = params
    const url = `${Constants.URL_SERVICE.BASE_EVALUACIONES_HISTORICAS_EXTENDIDA + Constants.URL_SERVICE.EVALUACION_HISTORICA_EXTENDIDA.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

  obtenerParamsDocente(rutDocente: any, rutSostenedor: any, sistemaEducativo: any, dependencia: any,
    rbd: any, comuna: any, anioPortafolio: any, tramo: any, beneficios: any, deprov: any): HttpParams {
    let params = new HttpParams();

    if (rutDocente && rutDocente > 0) {
      params = params.set('rutDocente', rutDocente);
    }
    if (rutSostenedor && rutSostenedor > 0) {
      params = params.set('rutSostenedor', rutSostenedor);
    }
    if (sistemaEducativo && sistemaEducativo > 0) {
      params = params.set('sistemaEducativo', sistemaEducativo);
    }
    if (dependencia && dependencia.trim() != "") {
      params = params.set('dependencia', dependencia.trim());
    }
    if (rbd && rbd > 0) {
      params = params.set('rbd', rbd);
    }
    if (comuna && comuna.trim() != "") {
      params = params.set('comuna', comuna.trim());
    }
    if (anioPortafolio && anioPortafolio > 0) {
      params = params.set('anioPortafolio', anioPortafolio);
    }
    if (tramo && tramo.trim() != "") {
      params = params.set('tramo', tramo);
    }
    if (beneficios && beneficios.trim() != "") {
      params = params.set('beneficios', beneficios.trim());
    }
    if (deprov && deprov.trim() != "") {
      params = params.set('deprov', deprov.trim());
    }
    return params;
  }

  getFile(url: string,
    rutDocente: number = null,
    rutSostenedor: number = null,
    sistemaEducativo:number = null,
    dependencia: string = "",
    rbd: number = null,
    comuna: string = "",
    anioPortafolio: number = null,
    tramo: String = null,
    beneficios: string = "",
    deprov: string = ""): Observable<any> {
    let params = this.obtenerParamsDocente(rutDocente, rutSostenedor, sistemaEducativo, dependencia,
      rbd, comuna, anioPortafolio, tramo, beneficios, deprov);
    params = params
    return this.http.get(url, { responseType: 'blob', params: params }).pipe(
      map(response => response)
    );
  }

  downloadBlob(filename: string, blob: any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  descargarInformeDocente(
    rut: number = null,
    agno: number = null): void {
    const url = `${Constants.URL_SERVICE.BASE_ARCHIVOS + Constants.URL_SERVICE.DOCENTES.DESCARGA_INFORME_DOCENTE}`
      .replace("{rut}", rut.toString())
      .replace("{agno}", agno.toString());

    this.http.get(url,
      {
        responseType: 'json',
        observe: 'response',
        reportProgress: true,
      })
      .subscribe(
        {
          next: (response: HttpResponse<any>) => {
            this.emitirEstadoDescargaFichaDocente(true);
            if (response.type === HttpEventType.Response) {
              const link = response.body.data;
              window.open(link,"_blank");
            }
          },
          error: (error: any) => {
            console.error(error);
            this.emitirEstadoDescargaFichaDocente(false);
          },
        }
      );
  }
  handleError(error: any) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    return throwError(errorMessage);
  }
  getFiltros(){
    const url = `${Constants.URL_SERVICE.BASE_EVALUACIONES_HISTORICAS_EXTENDIDA + Constants.URL_SERVICE.EVALUACION_HISTORICA_EXTENDIDA.FILTROS}`;
    return this.http.get(url);
  }
}
