<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO >
      <div class="flex flex-col gap-4 px-2">
        <p class="text-blue-950 text-2xl font-bold">Oficios</p>
        <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
          <div class="flex flex-row gap-1 py-2">
            <mat-icon>filter_list</mat-icon>
            <p class="text-lg font-bold">Filtros</p>
          </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="w-full">
              <mat-label>RUT Docente</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutDocente" name="rutDocente" />
            </mat-form-field>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="w-full">
              <mat-label>RUT Encargado</mat-label>
              <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345" class="w-full" [(ngModel)]="filtros.rutEncargado" />

            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="w-full">
              <mat-label>RUT Empresa</mat-label>
              <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345" class="w-full" [(ngModel)]="filtros.rutSostenedor" />

            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="w-full">
              <mat-label class="w-full">Estado</mat-label>

              <mat-select [(ngModel)]="filtros.estadoRegistro" placeholder="Estado">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let item of estados" [value]="item.id">{{item.nombre}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>

        </div>

        <div class="d-flex">
          <div class="float-left d-flex  align-self-start ">
            <mat-card-actions class="d-flex row">
              <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center m-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>
              <button
                  matTooltip="Descarga esta tabla."
                  *ngIf="permisoAcciones"
                  type="button"
                  data-te-ripple-init
                  (click)="descargarExcel()"
                  data-te-ripple-color="light"
                  class="d-flex w-28  m-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                  <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                  <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                  <div *ngIf="!estaDescargandoExcel" class="text-sm text-slate-700">Descargar</div>
                  <div *ngIf="estaDescargandoExcel" class="text-sm text-slate-700">Descargando</div>
              </button>

              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                data-te-ripple-color="light"
                class="d-flex w-28  m-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                <p class="pr-1 text-sm text-slate-700"></p> Limpiar
              </button>
              <button class="col-1 mx-2 ajustar" style="visibility: hidden;" #abrirModalPDF id="abrirModalPDF"
              data-bs-toggle="modal" data-bs-target="#modalPDF">abrirmodal</button>

            </mat-card-actions>
          </div>
          <div class="float-right ms-auto my-auto d-flex justify-content-end">
            <mat-card-actions>
              <div class="dropdown">
                <button color="primary"
                data-te-ripple-color="light"
                type="button"
                data-te-ripple-init
                class="flex w-60 justify-items-center justify-center text-center items-center rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                  id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style="float: right;">
                  <svg xmlns="http://www.w3.org/2000/svg" class="px-2" width="16" height="16" fill="currentColor" class="bi bi-layout-three-columns" viewBox="0 0 16 16"> <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5H5V1H1.5zM10 15V1H6v14h4zm1 0h3.5a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H11v14z"/> </svg>
                  <p class="px-1"></p> Editar columnas <p class="px-1"></p>
                  <svg xmlns="http://www.w3.org/2000/svg" class="px-2"width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <div class="container" style="width: 400px;">
                    <div class="row">
                      <div class="col" style="flex-direction: row;">
                        <div *ngFor="let item of toggleColumns" class="form-check">
                          <input [(ngModel)]="item.value" checked class="form-check-input" type="checkbox"
                            [id]="item.key">
                          <label class="form-check-label" [for]="item.key">{{item.name}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-actions>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="h-auto overflow-x-scroll rounded-2xl border border-gray-500 mx-2 px-2 ">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50" aria-label="tabla oficios" role="table">
      <thead class="bg-blue-700 cursor-grab  smooth-scroll sticky top-0"
        (mousedown)="startDrag($event)"
        (mouseup)="stopDrag($event)"
        (mousemove)="onDrag($event)"
        (dblclick)="onLeftDblClick($event)"
        (contextmenu)="onRightDblClick($event)">
        <tr >
            <th class="rounded-tl-2xl min-w-[200px] mx-4 py-3 text-left text-blue-100 text-sm font-black ">
              <div class="text-left font-semibold flex flex-row" (click)="sort('fechaIngreso')"
              [ngClass]="{pointer: true,desc:isDesc, asc:!isDesc}"
              >Fecha de Ingreso
              <div [ngClass]="{'text-black font-[20px]': activeColumn == 'fechaIngreso'}">
                <p *ngIf="ordenColumnas['fechaIngreso'].isDesc">▼</p>
                <p *ngIf="!ordenColumnas['fechaIngreso'].isDesc">▲</p>
              </div>
            </div>
            </th>
            <th class="truncate-cell  pr-4 py-3 text-left text-blue-100 text-sm font-black ">
              <div class="text-left font-semibold flex flex-row" (click)="sort('rutDocente')"
                [ngClass]="{pointer: true,desc:isDesc, asc:!isDesc}"
                >Datos Docente
                <div [ngClass]="{'text-black': activeColumn == 'rutDocente'}">
                  <p *ngIf="ordenColumnas['rutDocente'].isDesc">▼</p>
                  <p *ngIf="!ordenColumnas['rutDocente'].isDesc">▲</p>
                </div>
              </div>
            </th>
            <th class="pr-4 py-3 text-left text-blue-100 text-sm font-black ">
              <div class="text-left font-semibold">RBD / Dependencia</div>
            </th>
            <th class="min-w-[160px] pr-4 py-3 text-left text-blue-100 text-sm font-black ">
                <div class="text-left font-semibold">Tipo y Estado de Oficio</div>
            </th>
            <th class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">
                <div class="text-left font-semibold">Observación</div>
            </th>
            <th class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">
              <div class="text-left font-semibold">Causal</div>
            </th>
            <th class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">
                <div class="text-left font-semibold">Ver Archivo</div>
            </th>
            <th class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="toggleColumns[1].value">
              <div class="text-left font-semibold">Suspensión</div>
            </th>
            <th class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="toggleColumns[3].value">
              <div class="text-left font-semibold">Estado Registro</div>
            </th>
            <th class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="toggleColumns[5].value">
              <div class="text-left font-semibold">Motivo No Inscripción</div>
            </th>
            <th class="  pr-4 py-3 text-left text-blue-100 text-sm font-black" *ngIf="toggleColumns[6].value">
              <div class="text-left font-semibold">Beneficios</div>
            </th>
            <th class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">
              <div class="text-left font-semibold flex flex-row" (click)="sort('rutEncargado')"
              [ngClass]="{pointer: true,desc:isDesc, asc:!isDesc}"
              >Rut Encargado
              <div [ngClass]="{'text-black': activeColumn == 'rutEncargado'}">
                <p *ngIf="ordenColumnas['rutEncargado'].isDesc">▼</p>
                <p *ngIf="!ordenColumnas['rutEncargado'].isDesc">▲</p>
              </div>
            </div>
            </th>
            <th class="rounded-tr-2xl pr-4 py-3 text-left text-blue-100 text-sm font-black">
              <div class="text-left font-semibold">Rut Empresa</div>
            </th>

        </tr>
      </thead>

      <tbody class="divide-y divide-gray-200">
          <tr *ngFor="let oficio of oficios" class="hover:bg-neutral-100 ">
              <td class="mx-4 pr-4" >
                  <button type="button"
                    class="inline-block rounded-full border-2 border-primary px-2 pb-[6px] pt-2 text-[13px] font-bold uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                    data-bs-toggle="modal" data-bs-target="#modalObservaciones"
                    (click)="obtenerOficio(oficio)">
                    {{oficio.fechaIngreso}}
                  </button>
              </td>
              <td class="px-2">
                  <div class="flex flex-col gap-1 w-full" *ngIf="oficio.nombres">
                    <div class="text-gray-950">{{oficio.rutDocente}}-{{oficio.dv}}</div>
                    <div class="text-xs text-gray-700">{{oficio.nombres}}
                      {{oficio.apellidoPaterno ? oficio.apellidoPaterno : "-"}}
                      {{oficio.apellidoMaterno ? oficio.apellidoMaterno : "-"}}
                    </div>
                  </div>
                  <div class="flex flex-col gap-1 w-full" *ngIf="!oficio.nombres">
                    <div class="text-gray-800">{{oficio.rutDocente}}</div>
                    <div class="text-xs text-gray-400">Registro corrupto </div>
                  </div>
              </td>
              <td class="w-fit pr-4 ">
                <div class="flex flex-col gap-1">
                  <div class="font-medium text-gray-600">{{oficio.rbd}}</div>
                  <div class="pr-4 font-medium truncate-cell text-[15px] text-gray-600">{{oficio.dependencia}}</div>
                </div>
              </td>
              <td class="w-fit text-sm">
                <div class="flex flex-col gap-2">
                  <div class="text-gray-700">{{oficio.tipo}}</div>
                  <div class="flex flex-row gap-1">
                      <div *ngIf="oficio.idEstado==1" class="text-gray-400"><img width="24" height="24" src="../../../../assets/images/content/pending.svg" alt="pending"></div>
                      <div *ngIf="oficio.idEstado==2" class="text-green-600"><svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7 12.5L10 15.5L17 8.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg></div>
                      <div *ngIf="oficio.idEstado==3" class="text-red-400"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/> </g> </svg></div>
                      <div *ngIf="!estados">
                        <fa-icon [icon]="faSpinner" disabled animation="spin"></fa-icon>
                      </div>
                      <div *ngIf="estados">
                        <p *ngIf="!permisoAcciones" class="text-gray-600">{{oficio.estado}} </p>
                        <select *ngIf="permisoAcciones" data-te-select-init data-te-select-size="sm" class="bg-gray-50 text-gray-800 border-2 rounded-sm w-full"
                        [(ngModel)]="oficio.idEstado"
                        (ngModelChange)="cambiarEstadoAutomatico(oficio)">
                        <option *ngFor="let itemEstado of estados" [value]="itemEstado.id">{{itemEstado.nombre}}</option>
                        </select>
                      </div>
                  </div>
                </div>
              </td>
              <td class="pr-4 font-medium truncate-cell text-[15px] text-gray-800" matTooltip={{oficio.observacion}}>
                  {{oficio.observacion}}
              </td>
              <td class="pr-4 font-medium truncate-cell text-[15px] text-gray-800" matTooltip={{oficio.causal}} >
                {{oficio.causal}}
            </td>
            <td class="w-fit h-auto pr-4">
                <button type="button"
                    class="inline-block rounded-full border-2 border-gray-400 px-6 py-1 text-[14px] font-bold uppercase leading-normal text-gray-600 transition duration-150 ease-in-out hover:border-primary-900 hover:white hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600  focus:outline-none focus:ring-0"
                    (click)="setUrl(oficio.rutDocente, oficio.archivo)"
                    matTooltip="Visualizar el archivo del oficio"
                    [disabled]="!oficio.archivo">
                    <svg class="text-blue-500 text-xl" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg>
                </button>
            </td>
            <td class="pr-4 truncate-cell" *ngIf="toggleColumns[1].value">
              <div class="flex flex-col" *ngIf="oficio.estadoSuspension" matTooltip={{oficio.motivoSuspension}}>
                <div class="pr-4 font-medium truncate-cell text-[15px] text-gray-800 border-2 border-gray-400 rounded-md px-3">{{oficio.estadoSuspension}}</div>
                <div *ngIf="oficio.motivoSuspension" class="border-0 pr-4 font-medium truncate-cell text-xs text-gray-800">{{oficio.motivoSuspension}}</div>
              </div>
            </td>

            <td class="pr-4 font-medium truncate-cell text-[15px] text-gray-800" *ngIf="toggleColumns[3].value" matTooltip={{oficio.debeRendir}}>
              <div class="flex flex-col gap-1" *ngIf="oficio.estadoDocente">
                <p class="font-bold"> Docente {{oficio.estadoDocente}}</p>
                <div class="pr-4 font-medium truncate-cell text-xs text-gray-800">{{oficio.debeRendir}}</div>
              </div>

            </td>
            <td class="pr-4 font-medium truncate-cell text-[15px] text-gray-800" *ngIf="toggleColumns[5].value" matTooltip={{oficio.motivoNoInscripcion}}>
              <div class="pr-4 font-medium truncate-cell text-[15px] text-gray-800">{{oficio.motivoNoInscripcion}}</div>
            </td>
            <td class="pr-4" >
              <div class="flex flex-col border-0 pb-2 " >
                <p *ngIf="oficio['beneficio19n'] == null && oficio['aplazamiento'] == null &&
                oficio['tramos'] == null && oficio['codDebeRendir'] != 1"
                class="text-left text-gray-600">Sin beneficios</p>

                <span *ngIf=" oficio['beneficio19n'] != null"
                  class="badge rounded-pill me-2 mb-1" [ngClass]="{'bg-green-500' : oficio['beneficio19n'] == true,
                              'bg-slate-600' : oficio['beneficio19n'] == false}">19Ñ
                </span>
                <span *ngIf=" oficio['aplazamiento'] != null"
                  class="badge rounded-pill me-2 mb-1" [ngClass]="{'bg-green-500' : oficio['aplazamiento'] == true,
                              'bg-slate-600' : oficio['aplazamiento'] == false}">Aplazamiento

                </span>
                <span *ngIf=" oficio['tramos'] != null"
                  class="badge rounded-pill me-2 mb-1" [ngClass]="{'bg-green-500' : oficio['tramos'] == true,
                              'bg-slate-600' : oficio['tramos'] == false}">Tramos Voluntarios
                </span>

                <span *ngIf=" oficio['codDebeRendir'] == 1 && oficio['tramos'] == null"
                  class="badge rounded-pill me-2 mb-1 text-slate-900 bg-slate-200">Tramos Volunt. Disponible
                </span>
              </div>
            </td>
            <td class="w-fit pr-4">
              <div class="font-medium text-gray-800">{{oficio.rutEncargado}}</div>
            </td>
            <td class="w-fit pr-4">
              <div class="font-medium text-gray-800">{{oficio.rutSostenedor}}</div>
            </td>

          </tr>

      </tbody>
    </table>
  </div>

  <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [size]="options.size"
    [showFirstLastButtons]="true" [length]="length" [pageIndex]="pageIndex" (page)="pageChanged($event)"
    aria-label="Select page of periodic elements">
  </mat-paginator>

</mat-card>

<!-- Modal Observaciones -->



<div class="modal fade"  id="modalObservaciones"
  tabindex="-1" aria-labelledby="modalObservaciones" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5">Detalles de oficios</h1>
        <button type="button"  data-bs-dismiss="modal" aria-label="Close">✕</button>
      </div>
      <div class="modal-body">
        <div *ngIf="oficioSeleccionadoModal">
          <table role="table" aria-describedby="tabla oficios" class="mx-2 [width:100%]">
            <tr class="w-full mb-4" *ngFor="let item of oficioDataSection; let i=index">
              <th class="w-1/3 h-auto py-0">
                {{item.value}}
              </th>
              <td class="tdWordWrap [width:100%] max-w-none h-auto pr-4 pb-2">
                <div *ngIf="i == 1">
                  {{oficioSeleccionadoModal.nombres}}
                      {{oficioSeleccionadoModal.apellidoPaterno ? oficioSeleccionadoModal.apellidoPaterno : "-"}}
                      {{oficioSeleccionadoModal.apellidoMaterno ? oficioSeleccionadoModal.apellidoMaterno : "-"}}
                </div>
                <div class="break-words [table-layout:fixed] "
                  *ngIf="i<9 && !['archivo','estado', 'observacion', 'causal'].includes(item.key) "
                  >{{oficioSeleccionadoModal[item.key]}} </div>

                <textarea *ngIf="i>8 " name="idTextArea(item.key)" id="idTextArea(item.key)" [readOnly]="true"
                class="w-full h-auto wrap resize-none" rows={{rowsTextArea(oficioSeleccionadoModal[item.key])}}
                >{{oficioSeleccionadoModal[item.key]}} </textarea>

                <button class="btn btn-primary d-flex text-align-center" (click)="setUrl(oficioSeleccionadoModal['rutDocente'], oficioSeleccionadoModal['archivo'])"
                  *ngIf="item.key == 'archivo'">
                  <mat-icon>visibility</mat-icon>
                  <div class="px-2">Visualizar Archivo</div>
                </button>

                <select class="form-select" *ngIf="item.key == 'estado' && estadoOficio" [(ngModel)]="estadoOficio">
                  <option *ngFor="let itemEstado of estados" [value]="itemEstado.id">{{itemEstado.nombre}}</option>
                </select>

                <div *ngIf="item.key == 'observacion'">
                  <textarea class="form-control" rows="3" maxlength="255" [readOnly]="!permisoAcciones" (input)="actualizaLimiteObservacion($event)"
                  [(ngModel)]="observacionOficio"></textarea>
                  <div class="float-right"  *ngIf="permisoAcciones" [ngClass]="{'text-red-600 animate-pulse font-bold': observacionOficio.length>254}">{{observacionOficio.length}}/255 </div>
                </div>

              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal-footer modal-footer--sticky w-full d-flex float-right ms-auto my-auto d-flex justify-content-end">
        <button mat-raised-button color="primary" *ngIf="permisoAcciones" data-bs-dismiss="modal" (click)="guardarCambios()">Guardar
          Cambios</button>
        <button mat-raised-button color="secondary" #cerrarModal id="cerrarModal" class="mx-2"
          data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>



<!-- PDF Viewer -->



<div class="modal fade" id="modalPDF" tabindex="-1" aria-labelledby="modalPDF" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content" style=" height: 90vh;">
      <div class="modal-body" oncontextmenu="return false;">
        <button type="button" #cerrarModalPDF id="cerrarModalPDF" data-bs-dismiss="modal" aria-label="Close"
          class="rounded-md p-2 inline-flex text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <iframe #iframe title="pdfOficio" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" height="95%"
          toolbar="0" width="100%"></iframe>
      </div>
    </div>
  </div>
</div>
