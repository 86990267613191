<button class="col-1 mx-2 ajustar" style="visibility: hidden;" #abrirModalPDF id="abrirModalPDF" data-bs-toggle="modal"
  data-bs-target="#modalPDF">abrirmodal</button>

<mat-card class="p-4 mx-4 min-h-screen overflow-hidden" style="box-shadow: none !important;" data-te-smooth-scroll-init>
  <p class="text-blue-950 text-2xl font-bold  mb-2">Portafolio Docentemás</p>
  <p *ngIf="!datos" class="text-gray-500">Primero consulte el rut del docente y luego la pregunta</p>
  <div class="  p-2 gap-4 flex flex-col" #CONTAINER>

    <div #contenedorSUPERIOR class="panel-body p-2 gap-4 flex xs:flex-col xl:flex-row" >
      <div #SECTOR_IZQUIERDO_SUPERIOR class="flex flex-col gap-4 lg:w-1/2 xs:w-full">
        <div #CONSULTARUT class=" filtros3 form-group row pt-2  px-2 rounded-2xl border-l-8 border-2  border-l-blue-600 shadow-4">
          <div #tabsBusqueda class=" mx-auto">
            <div class="bg-white py-2 px-3">
              <nav class="flex flex-wrap gap-4">
                <a  class="cursor-pointer inline-flex whitespace-nowrap border-b-4 border-transparent py-2 px-3 text-sm font-semibold "
                  (click)="filtro('simple')"
                  [ngClass]="{'text-red-500 border-b-red-500  transition-all duration-200 ease-in-out': tabFiltro == 'simple'}">
                  Búsqueda Singular </a>
                <a  class="cursor-pointer inline-flex whitespace-nowrap border-b-4 border-transparent py-2 px-3 text-sm font-semibold "
                  (click)="filtro('avanzada')"
                  [ngClass]="{'text-red-500 border-b-red-500  transition-all duration-200 ease-in-out': tabFiltro == 'avanzada'}">
                  Búsqueda Avanzada </a>
              </nav>
            </div>
          </div>

          <div class="mb-6">
            <div #busquedaSIMPLE *ngIf="tabFiltro=='simple'">
              <div class="m-2 ">
                <div class="rounded-xl border border-gray-200 bg-white p-6 shadow-lg">
                  <div class="mt-2 flex flex-row ">
                    <div *ngFor="let input of inputs" class="flex flex-row gap-2 align-middle">
                      <div class="flex flex-col">
                        <label for="rut" class="text-stone-600 text-sm font-medium">{{ input.label }}</label>
                        <div class="flex flex-col space-x-4">
                          <input type="number" id="rut" placeholder="Rut Docente" [(ngModel)]="filtrosPFActual['rutDocente']" min=0 [type]="input.type" (keydown.enter)="obtenerPortafolioDocente(true)"
                          class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" />
                        </div>
                        <p class="text-red-800 text-sm pt-1 animate-pulse">{{mensajeBusquedaSimple}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="justify-end space-x-4 mt-4 flex ">
                    <button class="active:scale-95 rounded-lg bg-gray-200 px-8 h-10 align-bottom font-medium text-gray-600 outline-none focus:ring hover:opacity-90"
                      (click)="limpiar()">Limpiar</button>
                    <button class="active:scale-95 rounded-lg bg-blue-600 px-8 h-10 align-bottom font-medium  outline-none focus:ring hover:opacity-90"
                    (click)="obtenerPortafolioDocente(true)" [disabled]="isLoading || estaDescargandoExcel" [ngClass]="{'cursor-not-allowed animate-pulse-bg-once from-slate-400 to-blue-700':isLoading || estaDescargandoExcel}">
                    <p *ngIf="!isLoading && !estaDescargandoExcel" [ngClass]="{'cursor-not-allowed':isLoading || estaDescargandoExcel}" class="cursor-not-allowed text-white">Buscar</p>
                    <p *ngIf="isLoading || estaDescargandoExcel" class="animate-bounce text-white font-bold cursor-not-allowed">...</p>
                  </button>
                  </div>
                </div>
              </div>
            </div>

            <div #BUSQUEDAavanzada *ngIf="tabFiltro=='avanzada'">
              <ng-container *ngTemplateOutlet="filtrosAvanzados"></ng-container>

              <div *ngIf="rutDocenteSospechosos" class="mx-2 p-4 bg-white shadow-4 rounded-xl">
                <p class="py-2 text-lg font-bold">Lista de Docentes Sospechosos</p>
                <p *ngIf="tutorial">Seleccione un rut para ver sus detalles</p>
                <div class="flex flex-row gap-2">
                  <select name="respuestaSospechosos" [(ngModel)]="docenteSospechosoSeleccionado" (change)="onSospechosoChange()"
                  class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                  <option  *ngFor="let sospechoso of rutDocenteSospechosos; let i=index"
                  value={{sospechoso}}>{{sospechoso}}</option>
                  </select>

                  <button class="active:scale-95 rounded-lg border-2 border-blue-600 px-8 h-10 mt-2 align-bottom text-xs font-medium hover:shadow-2 hover:shadow-blue-600 outline-none focus:ring hover:opacity-90"
                    (click)="descargarExcelSospechosos()" [disabled]="isLoading || estaDescargandoExcel" [ngClass]="{'cursor-not-allowed animate-pulse-bg-once from-slate-50 to-blue-700': isLoading || estaDescargandoExcel}">
                    <div *ngIf="!isLoading && !estaDescargandoExcel"
                      [ngClass]="{'cursor-not-allowed':estaDescargandoExcel}"
                      class="cursor-not-allowed text-blue-900 flex flex-row gap-1 items-center">
                      <svg xmlns="http://www.w3.org/2000/svg"  height="24" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                      Exportar Todo
                    </div>
                    <p *ngIf="isLoading || estaDescargandoExcel" class="animate-bounce text-blue-950 font-bold cursor-not-allowed">...</p>
                  </button>
                </div>

                <p *ngIf="isLoading" class="animate-fadeIn text-lg py-2">Consultando datos.</p>

                <div *ngIf="preguntasNulasDocenteSospechoso" class="grid 2xl:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-2 py-2 mt-2">
                  <div *ngFor="let preguntaNula of preguntasNulasDocenteSospechoso; let i = index" class="p-4 border border-gray-300 rounded-lg shadow-md">
                    <p class="mb-2 text-sm"><strong class="text-sm">Fecha:</strong> {{preguntaNula.fechahora | date: 'short'}}</p>
                    <p class="mb-2 text-sm"><strong class="text-sm">Formulario ID:</strong> {{preguntaNula.formularioID}}</p>
                    <p class="mb-2 text-sm"><strong class="text-sm">Pregunta ID:</strong> {{preguntaNula.preguntaID}}</p>
                    <p class="mb-2 text-sm"><strong class="text-sm">Respuesta ID:</strong> {{preguntaNula.respuestaID}}</p>
                    <button class="border-[2px] rounded-xl px-4 py-2 flex flex-col gap-2 bg-gray-100 text-sm
                    hover:border-b-blue-700 hover:shadow-md hover:shadow-blue-300" (click)="verHistorialSospechoso(preguntaNula)">
                      Ver
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div #SECTOR_DERECHO_SUPERIOR class="lg:w-1/2 xs:w-full flex flex-col gap-4 h-full" *ngIf="datos">
        <div #INFODOCENTE class="relative w-full mx-2 border-2 border-slate-300 rounded-2xl py-4 px-10 flex flex-col gap-2 ">
          <div class="text-pretty uppercase">
            <b class="text-lg">Datos Docente:</b>
            <p class="text-2xl text-blue-800">{{datos.docente.nombres}} {{datos.docente.primerApellido}} {{datos.docente.segundoApellido}} </p>
            <div class="flex flex-row gap-2 align-middle">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><title>ionicons-v5-o</title><path d="M441.6,171.61,266.87,85.37a24.57,24.57,0,0,0-21.74,0L70.4,171.61A40,40,0,0,0,48,207.39V392c0,22.09,18.14,40,40.52,40h335c22.38,0,40.52-17.91,40.52-40V207.39A40,40,0,0,0,441.6,171.61Z" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><path d="M397.33,368,268.07,267.46a24,24,0,0,0-29.47,0L109.33,368" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="309.33" y1="295" x2="445.33" y2="192" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="61.33" y1="192" x2="200.33" y2="297" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
              <p class="text-gray-500 text-sm"> {{datos.docente.email}} </p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-phone-vibrate" viewBox="0 0 16 16"> <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"/> <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"/> </svg>
              <p class="text-gray-500 text-sm">{{datos.docente.numeroContacto1}}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-phone-vibrate" viewBox="0 0 16 16"> <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"/> <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"/> </svg>
              <p class="text-gray-500 text-sm">{{datos.docente.numeroContacto2}}</p>
            </div>
          </div>
          <div class="text-pretty uppercase">
            <b class="text-lg">Establecimiento</b>
            <div class="text-gray-500">{{datos.docente.nombreEstablecimiento}} <span class="font-medium text-blue-800 border-[1px] border-blue-800 rounded-2xl px-2 mx-1">RBD</span> {{datos.docente.rbd}}</div>
          </div>
          <div class="text-pretty uppercase">
            <b class="text-lg">Agrupación</b>
            <p class="text-gray-500">{{datos.docente.codNivel}}</p>
          </div>
          <div class="text-pretty uppercase">
            <b class="text-lg">Asignatura:</b>
            <p class="text-gray-500">{{datos.docente.codSubsector}}</p>
          </div>
        </div>
      </div>
    </div>

    <div #contenedorPREGUNTAYRESPUESTAS class="panel-body p-2 gap-4 flex xs:flex-col lg:flex-row xl:h-screen" *ngIf="datos">

      <div #PREGUNTAS  class="w-full relative my-2 border-2  border-slate-300 rounded-2xl"
        [formGroup]="formulario" novalidate (scroll)="syncScroll($event)">
      <div #headerLeft class="sticky top-0 bg-blue-700 rounded-t-2xl w-full flex xl:flex-row xs:flex-col 2xl:gap-4 xl:gap-2 justify-items-start align-middle py-4 px-6 relative">
          <div class="absolute inset-x-0 mx-auto 2xl:top-[-35px] xl:top-[-25px] 2xl:h-16 2xl:w-16 xl:h-12 xl:w-12 bg-blue-200 rounded-full xs:hidden
          flex items-center justify-center border-4 border-white" [ngClass]="{'animate-pulse-bg-once from-blue-500 to to-sky-400': cargandoPregunta}">
              <span [ngClass]="{'animate-spin ': cargandoPregunta}" class="text-blue-700 text-2xl font-bold">?</span>
          </div>
            <div class="flex flex-col xl:max-w-20 2xl:max-w-24 xs:w-44">
                <p class="text-white 2xl:text-sm xs:text-xs">ID Formulario</p>
                <input #searchQuestion formControlName="idform" min="0" type="number" (keydown.enter)="buscar()" class="rounded-tl-lg rounded-br-lg bg-slate-200 text-md p-2" placeholder="ID Form.">
            </div>
            <div class="flex flex-col xl:max-w-20 2xl:max-w-24 xs:w-44 xs:mt-2 lg:mt-0">
                <p class="text-white 2xl:text-sm xs:text-xs">ID Pregunta</p>
                <input #searchQuestion formControlName="idpreg" min="0" type="number" (keydown.enter)="buscar()" class="rounded-tl-lg rounded-br-lg bg-slate-200 text-md p-2" placeholder="ID Preg.">
            </div>
            <div class="flex flex-col xl:max-w-40 2xl:max-w-50 xs:w-full xs:mt-2 lg:mt-0">
                <p class="text-white 2xl:text-sm xs:text-xs">Enunciado y/o Tipo</p>
                <input #searchQuestion formControlName="enunciado" (keydown.enter)="buscar()" class="rounded-tl-lg rounded-br-lg bg-slate-200 text-md p-2" placeholder="Buscar enunciado">
            </div>

            <div class="flex flex-col w-fit xs:mt-2 lg:mt-0" matTooltip="Mostrar solo preguntas respondidas por módulo?">
              <p class="text-white 2xl:text-sm xs:text-xs cursor-help" >S/Resp.</p>
              <input type="checkbox" class="form-checkbox h-6 w-6 rounded-tl-lg rounded-br-lg text-blue-600" formControlName="respondidas">
            </div>

            <button class="border-2 border-white rounded-2xl py-1 px-3 mt-3 text-blue-100 hover:shadow-3 hover:shadow-white" (click)="buscar()">Buscar</button>

            <button class="border-2 border-white rounded-2xl py-1 px-3 mt-3 text-blue-100 hover:shadow-3 hover:shadow-white"
              matTooltip="Descarga de Respuestas de este portafolio" (click)="descargarExcelRespuestasPortafolioDocente()">CSV</button>

          </div>

        <div #AreaMÓDULOS class="h-5/6 overflow-y-auto">
          <!-- <div class="p-4 animate-pulse text-lg" *ngIf="!modulosHTML[0] || modulosHTML[0]?.modulo?.preguntas?.length == 0">
            Sin resultados.
          </div> -->
          <div *ngFor="let labelModulo of labelModulos; let m=index">
            <div #modulos class="relative flex flex-col gap-4 bg-blue-50 border-slate-200 border-2 rounded-2xl mx-4 mb-4 p-4" *ngIf="modulosHTML && modulosHTML[m].modulo && modulosHTML[m].modulo.preguntas.length > 0">
              <div #modulo class="rounded-2xl bg-slate-50 border-l-8 border-blue-900 p-4 flex flex-col gap-2" >
                <b class="text-lg text-slate-700 cursor-pointer" (click)="toggleCollapse(m)">
                  {{labelModulo}}
                  <span class="ml-2">
                    <ng-container *ngIf="modulosHTML[m].colapsado">[+]</ng-container>
                    <ng-container *ngIf="!modulosHTML[m].colapsado">[-]</ng-container>
                  </span>
                </b>
                <div #acordeonPregunta *ngIf="modulosHTML[m].modulo && !modulosHTML[m].colapsado">
                  <p class="text-sm pb-2 text-slate-500"> {{modulosHTML[m].modulo?.formularioTitulo}}</p>
                  <div #pregunta class="border-[2px] rounded-xl px-4 py-2 flex flex-col gap-2 bg-gray-100
                  hover:border-b-blue-700 hover:shadow-md hover:shadow-blue-300"
                  matTooltip={{item.preguntaTitulo}}
                  *ngFor="let item of modulosHTML[m].modulo?.preguntas; let p=index"
                    (click)="seleccionarPregunta(item, modulosHTML[m].modulo)"
                    [ngClass]="{' border-blue-700 shadow-xl shadow-blue-300': preguntaSeleccionada == item}">
                    <p class="text-xs text-slate-500">
                      Formulario {{modulosHTML[m].modulo.formularioID}} |
                      Agrupación: {{item.agrupacionID}} |
                      ID pregunta: {{item.preguntaID}} |
                      Tipo: {{item.preguntaTipo.toUpperCase()}}
                    </p>
                    <div class="cursor-pointer" (onmouseenter)="ampliarPregunta(true)" [froalaView]="!ampliarDescripcionPregunta ? limitarPreguntaTitulo(item.preguntaTitulo) : item.preguntaTitulo"
                    style="max-height: 50vh;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div #RESPUESTAS  id="respuestas" class=" bottom-0 sticky w-full mx-2 border-2 border-slate-300 rounded-2xl py-4 px-10 flex flex-col gap-2 h-full overflow-y-auto ">
        <div #tabsRespuesta class=" mx-auto">
          <div class="bg-white py-2 px-3">
            <nav class="flex flex-wrap gap-4">
              <a  class="cursor-pointer inline-flex  text-lg  whitespace-nowrap border-b-4 border-transparent py-2 px-3 font-semibold "
                (click)="asignarTabRespuesta('versiones')"
                [ngClass]="{'text-red-500 border-b-red-500  transition-all duration-200 ease-in-out': tabRespuesta == 'versiones'}">
                Versiones </a>
              <a  class="cursor-pointer inline-flex text-lg whitespace-nowrap border-b-4 border-transparent py-2 px-3 font-semibold "
                (click)="asignarTabRespuesta('respuestas')"
                [ngClass]="{'text-red-500 border-b-red-500  transition-all duration-200 ease-in-out': tabRespuesta == 'respuestas'}">
                Respuestas </a>
            </nav>
          </div>
        </div>

        <div *ngIf="confirmacionPortafolio" #confirmacion class="px-4 text-center align-middle items-center mt-2 py-2 border-2 text-gray-600 text-sm border-gray-50 shadow-2 shadow-green-500 rounded-xl flex flex-row gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16"> <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/> </svg>
          Docente acepta compartir sus datos
        </div>

        <div *ngIf="!confirmacionPortafolio" #confirmacion class="px-4 text-center align-middle items-center mt-2 py-2 border-2 text-gray-600 text-sm border-gray-50 shadow-2 shadow-gray-500 rounded-xl flex flex-row gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16"> <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/> </svg>
          Docente no acepta compartir sus datos
        </div>

        <div *ngIf="tabRespuesta=='respuestas'" class="py-4 px-2">
          <div *ngIf="!preguntaSeleccionada && !sinRespuesta"> Seleccione una pregunta en el panel izquierdo </div>
          <div *ngIf="sinRespuesta"> El docente no ha respondido esta pregunta</div>

          <div *ngIf="preguntaSeleccionada" class="my-2 bg-slate-100 rounded-2xl p-4 overflow-y-auto">
            <p class="text-lg pb-4 font-bold"> {{moduloSeleccionado.formularioTitulo}}</p>
            <div class="pb-4" [froalaView]="preguntaSeleccionada.agrupacionTitulo ? preguntaSeleccionada.agrupacionTitulo : ''"></div>
            <div class="font-bold" [froalaView]="preguntaSeleccionada.preguntaTitulo ? preguntaSeleccionada.preguntaTitulo : ''"></div>
            <div class="border-2 flex flex-row gap-2 border-s-slate-300 w-fit p-2 my-4 text-slate-600 rounded-2xl text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alarm" viewBox="0 0 16 16"> <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/> <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/> </svg>
              Versión de Respuesta: <b class="text-sm text-slate-700">{{respuestaSeleccionada.version | date: 'dd-MM-YYYY HH:mm:ss'}}</b>
              <sub *ngIf="respuestaSeleccionada.actual" class="border-2 bg-green-500 border-green-500 rounded-full h-2 w-2 animate-ping"></sub>
            </div>
            <div *ngIf="respuestaSeleccionada.tipo !=  'file'" class="inline-flex rounded-md shadow-sm" role="group">
              <button type="button" [ngClass]="{'border-blue-600': mostrarRespuestaComoHTML}" (click)="respuestaHTML(true)"
                class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-l-md hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-50">
                Texto enriquecido
              </button>
              <button type="button" [ngClass]="{'border-blue-600': !mostrarRespuestaComoHTML}" (click)="respuestaHTML(false)"
              class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-r-md hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-50">
                Texto codificado
              </button>
            </div>
            <div *ngIf="respuestaSeleccionada.tipo !=  'file'">
              <div *ngIf="mostrarRespuestaComoHTML" class="bg-slate-200 rounded-xl p-4" [froalaView]="respuestaSeleccionada.respuestas ? respuestaSeleccionada.respuestas : ''"></div>
              <div *ngIf="!mostrarRespuestaComoHTML" class="bg-slate-200 rounded-xl p-4"> {{respuestaSeleccionada.respuestas}} </div>
              <p class="text-right text-sm" *ngIf="respuestaSeleccionada.tipo !=  'file'">Carácteres: {{respuestaSeleccionada.respuestas ? respuestaSeleccionada.respuestas.length: 0}}</p>
            </div>
              <div *ngIf="respuestaSeleccionada.tipo === 'file'" class="flex flex-row gap-2">
              <button type="button" (click)="verArchivoRespuesta(archivo)" *ngFor="let archivo of [respuestaSeleccionada.respuestas]; let i=index"
                  [ngClass]="{'bg-gray-100': isLoadingFile}" [disabled]="isLoadingFile" matTooltip="{{archivo}}"
                  class="focus:border-emerald-500 hover:animate-pulse-bg-once from-primary-100 to-primary-400 max-w-fit inline-block align-middle self-center rounded-full border-2 border-gray-400 px-6 py-1 text-[14px] font-bold uppercase leading-normal text-gray-600 transition duration-150 ease-in-out hover:border-primary-900 hover:white hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600  focus:outline-none focus:ring-0"
                  >
                  <svg *ngIf="!isLoadingFile" class="text-blue-500 inline-block mx-2 flex text-center" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" fill="none" viewBox="0 0 16 16" stroke="currentColor">
                    <path
                      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                    <path
                      d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                  </svg>
                  <p *ngIf="isLoadingFile" class="font-bold animate-bounce cursor-not-allowed">...</p>
                </button>
            </div>
          </div>
        </div>

        <div *ngIf="tabRespuesta=='versiones'" class="flex flex-col gap-2 h-full py-4 px-2 overflow-y-auto">
          <div *ngIf="!preguntaSeleccionada && !sinRespuesta"> Seleccione una pregunta en el panel izquierdo </div>
          <div *ngIf="sinRespuesta"> El docente no ha respondido esta pregunta</div>
          <div *ngIf="preguntaSeleccionada && versionesRespuestas && versionesRespuestas.length > 0" class="flex flex-col gap-2 pb-2">
            <p class="font-bold text-lg text-blue-900">Versiones de Respuestas</p>
            <p class="text-slate-600 mb-2">Seleccione una versión a revisar para esta pregunta</p>
            <div *ngFor="let respuestas of [respuestasActual, versionesRespuestas]; let ir=index" class="flex flex-row w-50"
            [ngClass]="{'flex-row gap-2': ir==0, 'flex-col gap-1': ir==1}">
              <p *ngIf="ir === 1" class="py-2 text-lg">Versiones respaldadas</p>
              <div *ngFor="let item of respuestas; let i=index" class="relative flex" >
                <button class="border-[1px] border-gray-500 font-medium rounded-2xl p-2 hover:shadow-md hover:shadow-blue-500 flex items-center gap-2 relative"
                (click)="seleccionarVersionRespuesta(item)">
                  {{item.version | date:'dd/MM/yyyy HH:mm:ss'}}
                  <span *ngIf="item.actual" class="rounded-2xl border-2 border-green-600 px-2 text-center align-middle text-xs">Actual</span>
                  <span *ngIf="i == 0 && respuestaSeleccionada && !respuestaSeleccionada.respuestas" class="rounded-2xl border-2 border-red-600 px-2 text-center align-middle text-xs">Nula</span>
                  <span *ngIf="i > 0 && !item.respuestas" class="rounded-2xl border-2 border-red-600 px-2 text-center align-middle text-xs">Nula</span>
                  <div *ngIf="item.actual" class="absolute top-[-2px] right-[-2px]">
                    <div class="border-2 bg-green-500 border-green-500 rounded-full h-2 w-2 animate-ping"></div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="!versionesRespuestas || versionesRespuestas.length == 0">
            Primero, seleccione una respuesta
          </div>
        </div>

      </div>
    </div>
  </div>

</mat-card>

<ng-template #filtrosAvanzados>
  <div class="m-2 ">
    <div class="rounded-xl border border-gray-200 bg-white p-6 shadow-lg">
      <h2 *ngIf="tutorial" class="text-stone-700 text-xl font-bold">Obtener Consultas</h2>
      <p *ngIf="tutorial"  class="mt-1 text-sm">Use el selector de filtros para obtener un conjunto de resultados</p>
      <div class="grid grid-cols-1 " [ngClass]="{'mt-4': tutorial}">
        <div class="flex flex-col">
          <label for="status" class="text-stone-600 text-sm font-medium">Filtrar por</label>

          <div class="flex flex-row gap-2 pt-1 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" class="w-[56px] h-full pt-1"><rect width="28px" height="28px" fill="none"/><path d="M180,40h28a8,8,0,0,1,8,8V76" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M180,216h28a8,8,0,0,0,8-8V180" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M76,216H48a8,8,0,0,1-8-8V180" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M76,40H48a8,8,0,0,0-8,8V76" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><circle cx="128" cy="112" r="32" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M74.9,176a60.1,60.1,0,0,1,106.2,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg>

            <select id="status" class="mt-2 block w-full rounded-md border border-gray-200 h-full px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              [(ngModel)]="selectedOption" placeholder="Seleccione tipo de consulta">
              <option *ngFor="let option of optionSelect" [value]="option.value">{{ option.label }}</option>
            </select>
            <button *ngIf="selectedOption" class="active:scale-95 rounded-lg bg-blue-600 px-8 py-2 mt-2 h-full font-medium text-white outline-none focus:ring hover:opacity-90"
              type="button" (click)="filtroAvanzado()" [ngClass]="{
                'cursor-not-allowed animate-pulse-bg-once from-slate-400 to-blue-700':isLoading
              }"
            >
              <p *ngIf="!isLoading && !estaDescargandoExcel" [ngClass]="{'cursor-not-allowed':isLoading}" class="cursor-not-allowed text-white">Consultar</p>
              <p *ngIf="isLoading || estaDescargandoExcel" class="animate-bounce text-white font-bold cursor-not-allowed">...</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="modal fade" id="modalPDF" tabindex="-1" aria-labelledby="modalPDF" aria-hidden="true">
  <div
    class="modal-dialog modal-lg  modal-dialog-scrollable overflow-auto border-l-8 rounded-xl h-auto border-primary-600">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" *ngIf="respuestaSeleccionada">Documento del Docente</h1>
        <button type="button" id="cerrarModalPDF" data-bs-dismiss="modal" aria-label="Close">✕</button>
      </div>

      <div class="modal-body" oncontextmenu="return false;" class="px-4 pt-4 max-h-50 overflow-y-auto">
        <div *ngIf="respuestaSeleccionada">

          <div *ngIf="respuestaSeleccionada.tipo!= 'file'">
            <div [froalaView]="respuestaSeleccionada.respuestas==undefined || respuestaSeleccionada.respuestas == null ? '' : respuestaSeleccionada.respuestas"
              style="max-height: 50vh;"></div>
          </div>
          <div *ngIf="respuestaSeleccionada.tipo == 'file'" class="flex flex-col gap-2 text-lg ">Descarga de documento
            <p class="text-xs">{{respuestaSeleccionada.respuestas}}</p></div>

          <div *ngIf="errorArchivoRespuesta" class="text-red-800">
            {{ errorArchivoRespuesta }}
          </div>
        </div>

        <iframe #iframe title="pdfOficio"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" height="0%" toolbar="0"
          width="100%">
        </iframe>

      </div>

      <div class="modal-footer">
        <button type="button" class="hover:bg-slate-100 border border-slate-300 py-2 px-4 rounded-xl"
          data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>
