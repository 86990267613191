import { Component } from "@angular/core";
import { ErrorMensajeService } from "src/app/base/services/errorMensaje.service";
import { ReportesService } from "../../services/reportes.service";
import { HttpEventType } from "@angular/common/http";
import { Toast } from "src/app/base/services/toast";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-reportes',
    templateUrl: './reportes.component.html',
    styleUrls: ['./reportes.component.css']
  })
  export class ReportesComponent{
    bbdd!: string;
    reporte: string = null;
    reportes: any = null;
    searching!: boolean;
    faSpinner = faSpinner;
    separador: string = 'puntoycoma';
    constructor(
      public reporteService: ReportesService,
      private errorMensaje: ErrorMensajeService) { }

    buscar(bbdd: string, reporte: string){
    const filename = 'reporte '+this.reporte +'.csv';
    this.searching = true;
    this.reporteService.descargaReporte(this.bbdd,this.reporte, this.separador)
      .subscribe(

          value => {
            if (value.type === HttpEventType.Response) {
              const blob = new Blob([value.body], { type: 'text/csv' });
              const downloadUrl = URL.createObjectURL(blob);
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.setAttribute('style', 'display: none');
              a.href = downloadUrl;
              a.download = filename;
              a.click();
              URL.revokeObjectURL(downloadUrl);
              a.remove();
              this.searching = false;
              Toast.fire("Descarga Finalizada", "La descarga de reporte ha finalizado.", "success");
            }
          },
          error => {
            this.searching = false;
          }

      );

    }

    isAgnoNull(){
      if(this.bbdd == null){
        return true;
      }
      return false;
    }

    isReporteNull(){
      if(this.reporte == null){
        return true;
      }
      return false;
    }

    buscarReporte(bbdd: string){
      if (bbdd != null){
        this.reporte = null;
        this.reporteService.reportesPorAgno(bbdd).subscribe(
          response => {
            this.reportes = response;
          }

        );
      }


    }
  }
