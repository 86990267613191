export class PaginationOptions {
    orderBy: string;
    orderDir: 'ASC' | 'DESC';
    search: string;
    size: number;
    page: number;

    constructor(){
        this.size = 10;
        this.page = 1;
        this.search = "";
        this.search = "ASC";
        this.orderBy = "";
    }
}