import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { PaginationOptions } from "../models/paginationOptions";
import { Observable, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { Constants } from "src/app/base/constants/constants";
import { HistorialLlamadasFilter } from '../models/historialLlamadasFilter';



@Injectable({
    providedIn: 'root'
})
export class HistorialLlamadasService{


    constructor(
        private http: HttpClient
      ) { }

  historialList(options: PaginationOptions, filtro: HistorialLlamadasFilter): Observable<any> {
    let params = this.obtenerParamsLlamadas(filtro.rut, filtro.fechaInicio, filtro.fechaFin);
    const url = `${Constants.URL_SERVICE.BASE_LLAMADAS + Constants.URL_SERVICE.HISTORIAL_LLAMADAS.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

  obtenerParamsLlamadas(rut: any, fechaInicio: any, fechaFin: any): HttpParams {
    let params = new HttpParams();

    if (rut && rut.trim() != "") {
      params = params.set('rut', rut);
    }
    params = params.set("fechaInicio", fechaInicio && fechaInicio!="" ? new Date(fechaInicio).toISOString().split('T')[0] + " 00:00:00": "")
    params = params.set("fechaFin", fechaFin && fechaFin!="" ? new Date(fechaFin).toISOString().split('T')[0] + " 23:59:59.999" : "")
    
    return params;
  }

  getFile(filtro: HistorialLlamadasFilter): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_LLAMADAS + Constants.URL_SERVICE.HISTORIAL_LLAMADAS.DESCARGAR}`;
    let params = this.obtenerParamsLlamadas(filtro.rut, filtro.fechaInicio, filtro.fechaFin);
    return this.http.get(url, { responseType: 'blob', params:params }).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error:any) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    return throwError(errorMessage);
  }


  downloadBlob(filename:string, blob:any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
        dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }

}
