import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor() {}

  showAlert(options: {
    icon?: SweetAlertIcon,
    title?: string,
    text?: string,
    html?: string,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    customClass?: any
  } = {}): void {
    const {
      icon = 'info',
      title = 'Aviso',
      text = '',
      html = '',
      confirmButtonText = 'Aceptar',
      confirmButtonColor = '#3085d6',
      cancelButtonText = 'Cancelar',
      showCancelButton = false,
      customClass = {}
    } = options;

    Swal.fire({
      icon,
      title,
      text,
      html,
      confirmButtonText,
      confirmButtonColor,
      cancelButtonText,
      showCancelButton,
      customClass,
      didOpen: () => {
        const swalContainer = document.querySelector('.swal2-container') as HTMLElement;
        if (swalContainer) {
          swalContainer.style.zIndex = '999999';
        }
      }
    });
  }

  showExceptionAlert(exception: any, options: {
    alertTitle?: string,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    customClass?: any
  } = {}): void {
    let title = options.alertTitle ? options.alertTitle : 'Error';
    let message = 'Ha ocurrido un error inesperado.';
    if (exception && exception.error && exception.error.message) {
      message = exception.error.message;
    } else {
      switch (exception.status) {
        case 400:
          message = 'Solicitud incorrecta. Verifique los datos enviados y vuelva a intentarlo.';
          title = 'Solicitud Incorrecta';
          break;
        case 401:
          message = 'No está autenticado. Por favor, inicie sesión para continuar.';
          title = 'No Autenticado';
          break;
        case 403:
          message = 'Usted no tiene permisos para realizar esta acción.';
          title = 'Permiso Denegado';
          break;
        case 404:
          message = 'El recurso solicitado no se pudo encontrar.';
          title = 'No Encontrado';
          break;
        case 409:
          message = 'Conflicto de datos. Es posible que la información ya haya sido actualizada o modificada.';
          title = 'Conflicto de Datos';
          break;
        case 422:
          message = 'Los datos proporcionados son inválidos. Por favor, revise los campos y vuelva a intentarlo.';
          title = 'Datos Inválidos';
          break;
        case 500:
          message = 'Ha ocurrido un error interno en el servidor. Intente nuevamente más tarde.';
          title = 'Error Interno del Servidor';
          break;
        case 503:
          message = 'El servicio no está disponible en este momento. Por favor, intente más tarde.';
          title = 'Servicio No Disponible';
          break;
        default:
          message = 'Ha ocurrido un error inesperado. Por favor, intente nuevamente más tarde.';
          break;
      }
    }

    const {
      confirmButtonText = 'Aceptar',
      confirmButtonColor = '#3085d6',
      cancelButtonText = 'Cancelar',
      showCancelButton = false,
      customClass = {}
    } = options;

    this.showAlert({
      icon: 'error',
      title,
      text: message,
      confirmButtonText,
      confirmButtonColor,
      cancelButtonText,
      showCancelButton,
      customClass
    });
  }

  showSuccessAlert(message: string, options: {
    title?: string,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    customClass?: any
  } = {}): void {
    const {
      title = 'Éxito',
      confirmButtonText = 'Aceptar',
      confirmButtonColor = '#3085d6',
      cancelButtonText = 'Cancelar',
      showCancelButton = false,
      customClass = {}
    } = options;

    this.showAlert({
      icon: 'success',
      title,
      text: message,
      confirmButtonText,
      confirmButtonColor,
      cancelButtonText,
      showCancelButton,
      customClass
    });
  }

  showWarningAlert(message: string, options: {
    title?: string,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    customClass?: any
  } = {}): void {
    const {
      title = 'Advertencia',
      confirmButtonText = 'Aceptar',
      confirmButtonColor = '#3085d6',
      cancelButtonText = 'Cancelar',
      showCancelButton = false,
      customClass = {}
    } = options;

    this.showAlert({
      icon: 'warning',
      title,
      text: message,
      confirmButtonText,
      confirmButtonColor,
      cancelButtonText,
      showCancelButton,
      customClass
    });
  }
}
