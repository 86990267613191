<!-- image-modal.component.html -->
<div mat-dialog-content class="flex justify-center items-center max-w-full max-h-full">
  <img [src]="data.image" alt="Thumbnail" class="max-w-full max-h-[80vh] h-auto w-auto object-contain rounded-md"/>
  <p class="text-center mt-2">
    Frame: {{ data.frameNumber }}, Tiempo: {{ formatTimeFromSeconds(data.timeSeconds) }}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cerrar</button>
</div>
