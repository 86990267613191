<div class="flex-row general-header-inner h-auto">
    <div class="whb-column whb-col-left whb-visible-lg">

        <div class="wd-header-text set-cont-mb-s reset-last-child ">

            <a href="https://www.mineduc.cl/" class="wd-logo wd-main-logo" rel="home">
                <img alt="ministerio-de-educacion-chile" src="/assets/images/ministerio-de-educacion-chile.png">
            </a>
        </div>
        <div class="whb-space-element " style="width:30px;"></div>
    </div>
    <div class="whb-column whb-col-center whb-visible-lg whb-empty-column">
    </div>
    <div class="float-right px-2">
        <div class="site-logo">
            <a href="https://www.docentemas.cl/" class="wd-logo wd-main-logo" rel="home">
                <img width="180" alt="ministerio-de-educacion-chile"
                    src="/assets/images/7e98c41c-188e-4af8-8c40-188ffda5f84f.png" alt="docentemás" loading="lazy"
                    style="max-width:180px;">
            </a>
        </div>
    </div>
</div>

<main role="main" class="container py-4">
    <div class="row py-4">
        <p class="text-title py-4">Portal Operaciones DocenteMás</p>
    </div>

    <div class="flex justify-center">
        <div class=" w-full max-w-xs">
            <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-t-4 border-blue-600 rounded-b">
                <div class="mb-4">
                    <label class="block text-gray-700 text-xl font-bold mb-2" for="username">
                        Correo Institucional
                    </label>
                    <input
                        class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="username" [(ngModel)]="usuario.username" type="text" placeholder="Ingrese su correo">
                </div>
                <div class="mb-6">
                    <label class="block text-gray-700 text-xl font-bold mb-2" for="password">
                        Contraseña
                    </label>

                    <div class="flex items-center border rounded ">
                        <input
                            class="appearance-none bg-transparent border-none w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none"
                            type={{typePassword}} placeholder="Ingrese su contraseña"
                            [(ngModel)]="usuario.password" name="password" type="password" 
                            aria-label="Contraseña">
                        <button (click)="visualizacionPassword()"
                            class="flex-shrink-0 border-blue-400 hover:border-blue-600 hover:text-blue-600 text-sm border-2 text-blue-600 py-1 px-2 rounded"
                            type="button">
                            <svg *ngIf="!isPasswordVisible" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path
                                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path
                                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg>
                            <svg *ngIf="isPasswordVisible" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                <path
                                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                <path
                                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                <path
                                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                            </svg>
                        </button>
                    </div>

                    <p *ngIf="authFail" class="animate-pulse text-red-500 text-md italic">{{msjeError}} </p>
                </div>
                <div class="">
                    <button
                        class=" w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit" (click)="iniciaSesion()">
                        <div class="w-full grid justify-items-center">
                            <svg *ngIf="loading" class="animate-spin" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 4335 4335" width="25" height="25">
                                <path fill="#ffffff"
                                    d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z" />
                            </svg>
                            <p *ngIf="!loading">Acceder</p>
                        </div>
                    </button>
                    <button
                        class=" w-full border p-2 mt-2 border-blue-500 hover:border-transparent rounded hover:border hover:border-transparent inline-block align-baseline font-bold text-md text-blue-500 hover:text-blue-700 hover:border-blue-700"
                        routerLink="/recover">
                        Olvidé mi clave
                    </button>
                </div>
            </form>
            <p class="text-center text-gray-500 text-xs">
                &copy;Equipo EDD, Informática Educativa UFRO.
            </p>
        </div>
    </div>
</main>



<footer class="mt-auto py-4">
    <div class="container main-footer">
        <div data-elementor-type="wp-post" data-elementor-id="9835" class="elementor elementor-9835">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-691fa5c elementor-section-full_width elementor-section-height-min-height elementor-section-stretched elementor-section-height-default elementor-section-items-middle wd-section-disabled"
                data-id="691fa5c" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;gradient&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4830bd6"
                        data-id="4830bd6" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="wd-negative-gap elementor-section elementor-top-section elementor-element elementor-element-e2b3834 elementor-section-boxed elementor-section-height-default elementor-section-height-default wd-section-disabled"
                data-id="e2b3834" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-20dc34a"
                        data-id="20dc34a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-de23852 elementor-widget elementor-widget-wd_text_block"
                                data-id="de23852" data-element_type="widget" data-widget_type="wd_text_block.default">
                                <div class="elementor-widget-container">
                                    <div class="wd-text-block reset-last-child text-left">



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </div>
</footer>