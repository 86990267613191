<mat-card class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2"  #FILTRO style="flex-direction: row">

    <div class="flex flex-col gap-4 px-2">
      <p class="text-blue-950 text-2xl font-bold">Gestor de Usuarios DocenteMás</p>
      <div class="filtros form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
        <div class="flex flex-row gap-1 py-2">
          <mat-icon>filter_list</mat-icon>
          <p class="text-lg font-bold">Filtros</p>
        </div>

        <div class="row">
          <div class="col-5">
            <mat-form-field class="fullWidth">
              <mat-label>Rut</mat-label>
              <input matInput type="number" id="rut" [(ngModel)]="rut" />
            </mat-form-field>
          </div>
          <div class=" col-7">
            <mat-form-field class="fullWidth">
              <mat-label>Roles</mat-label>
              <mat-select [(ngModel)]="rol" >
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let rol of roles" [value]="rol">{{rol}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-card-actions class="d-flex row" >
          <button
            type="button"
            data-te-ripple-init
            (click)="botonFiltrar()"
            data-te-ripple-color="light"
            class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
            <svg class="pr-1" width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
            Filtrar
          </button>

          <button
              matTooltip="Descarga esta tabla completa"
              *ngIf="descagaUsuarioMantenedor"
              type="button"
              data-te-ripple-init
              (click)="descargar()"
              data-te-ripple-color="light"
              class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
              <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
              <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
              <div *ngIf="!estaDescargandoExcel" class="text-[14px]">Descargar</div>
              <div *ngIf="estaDescargandoExcel" class="text-[14px]">Descargando</div>
          </button>

          <button
            type="button"
            data-te-ripple-init
            (click)="limpiar()"
            data-te-ripple-color="light"
            class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
            <p class="pr-1"></p> Limpiar
          </button>

          <div class="float-right col d-flex justify-content-end">

            <button
              type="button"
              data-bs-toggle="modal" data-bs-target="#modalForm" (click)="lanzarModalAgregar()"
              matTooltip="Agregar un usuario al sistema"
              *ngIf="agregarUsuarioMantenedor"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16"> <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/> </svg>
              <p class="pr-1"></p> Agregar
            </button>
          </div>
        </mat-card-actions>
      </div>
    </div>
  </div>

  <div class="p-4" style="overflow-x:auto;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table role="table" aria-label="tabla representantes legales" mat-table [dataSource]="dataSource"
      class="tables-info py-4" style="max-width: 100%;">

      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i = index">

        <th mat-header-cell *matHeaderCellDef> {{filtros.getNameColumns(i)}} </th>
        <td mat-cell *matCellDef="let emp" [style]="widthColumns[i]">
          <div *ngIf="i!=1">
            {{emp[column]}}
          </div>
          <div *ngIf="i==1">
            <mat-chip-listbox aria-label="lista de chips#{{i}}">
              <mat-chip *ngFor="let item of emp[column]" class="chips" style="background-color: lightblue;">
                {{item}}</mat-chip>
            </mat-chip-listbox>
          </div>
          <div class="mx-1 btn-group" role="group" *ngIf="column == 'acciones' " style="width: max-content;">
            <button (click)="editar(emp)" aria-label="{mat-cell}"
              type="button"
              class="inline-block rounded-l-xl border-2 border-primary px-6 pb-[6px] pt-2 text-[14px] font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
              data-te-ripple-init
              data-te-ripple-color="light"
              *ngIf="editarUsuarioMantenedor" data-bs-toggle="modal" data-bs-target="#modalForm">
              <mat-icon>edit</mat-icon>
            </button>
            <button (click)="eliminar(emp['rut'])" aria-label="{mat-cell}"
              type="button"
              class="-ml-0.5 inline-block rounded-r-xl  border-2 border-primary px-6 pb-[6px] pt-2 text-[14px] font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
              data-te-ripple-init
              data-te-ripple-color="light"
              *ngIf="borrarUsuarioMantenedor">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns">

    </table>
  </div>
  <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
  </mat-paginator>
</mat-card>

<div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="modalAgregarUsuarioLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 *ngIf="!usuarioEditar" class="modal-title fs-5" id="agregarUsuarioLabel">Formulario para agregar un Usuario
        </h1>
        <h1 *ngIf="usuarioEditar" class="modal-title fs-5" id="agregarUsuarioLabel">Formulario de edición de Usuario
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mx-1">
        <ng-card>
          <div class="px-4 row">
            <div class=""></div>
            <form class=" col-md-10 col-xs-12 col-lg-12" [formGroup]="formularioUsuario" novalidate>
              <div class="row px-4">
                <div class="row ">
                  <div class=" ">
                    <h4>Rut</h4>
                    <mat-form-field class="fullWidth px-4">
                      <mat-label>Rut</mat-label>
                      <input matInput type="number" id="rut" formControlName="rut"
                        [readonly]="usuarioEditar && usuarioEditar.rut>0" />
                      <mat-error *ngIf="!checkFormRut()">
                        <span>
                          Se requiere un rut válido.
                        </span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <h4>Seleccione los roles</h4>
                <div class="button-container ">
                  <div class="column">
                    <mat-chip-listbox selectable multiple class="mat-mdc-chip-set-stacked" aria-label="Role selection"
                      formControlName="rolesSeleccionados" class="dobleColumna">
                      <mat-chip-option *ngFor="let chip of tipoUsuario.slice(0, middleIndex)" [selected]="chip.selected"
                        color="primary" (click)="onChangeChip(chip)" style="width: 45%;">
                        {{chip.name}}
                      </mat-chip-option>
                    </mat-chip-listbox>
                  </div>

                  <div class="column">
                    <mat-chip-listbox selectable multiple class="mat-mdc-chip-set-stacked" aria-label="Role selection"
                      formControlName="rolesSeleccionados" class="dobleColumna">
                      <mat-chip-option *ngFor="let chip of tipoUsuario.slice(middleIndex)" [selected]="chip.selected"
                        color="primary" (click)="onChangeChip(chip)" style="width: 45%;">
                        {{chip.name}}
                      </mat-chip-option>
                    </mat-chip-listbox>
                  </div>
                </div>
                <mat-error *ngIf="!formularioValido">
                  <span>
                    Debes ingresar un rut y seleccionar al menos un rol.
                  </span>
                </mat-error>
              </div>
              <div class="p-4 float-right" >
                <button mat-raised-button class="mx-2" (click)="cancelarForm()" data-bs-dismiss="modal">
                  <mat-icon>keyboard_return</mat-icon>
                  Cancelar</button>
                <button *ngIf="!usuarioEditar || usuarioEditar && !usuarioEditar.rut" mat-raised-button color="primary"
                  (click)="agregarForm()" [disabled]="!formularioValido"
                  data-bs-dismiss="modal"><mat-icon>cloud_upload</mat-icon>
                  Agregar</button>
                <button *ngIf="usuarioEditar && usuarioEditar.rut>0" mat-raised-button color="primary"
                  (click)="editarForm()" [disabled]="!formularioValido" data-bs-dismiss="modal">
                  <mat-icon>cloud_upload</mat-icon>
                  Guardar Cambios</button>
              </div>
            </form>
            <div class=""></div>
          </div>

        </ng-card>
      </div>
    </div>
  </div>
</div>
