export class DocenteFilter {
    rutDocente: number;
    rutSostenedor: number;
    anioConvocatoria: number;
    rbd: number;
    estadoRegistro: string;

    constructor(){
        this.rutDocente = null;
        this.rutSostenedor = null;
        this.anioConvocatoria = null;
        this.rbd = null;
        this.estadoRegistro = null;
    }
}
