import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/base/constants/constants';
import { PaginationOptions } from 'src/app/base/models/paginationOptions';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Docente } from 'src/app/registros/models/docente';
import { DocenteFilter } from 'src/app/registros/models/docenteFilter';
import { DocenteService } from 'src/app/registros/services/docente.service';
import Swal from 'sweetalert2';
import { Collapse, initTE } from "tw-elements";
import { Toast } from '../../../../base/services/toast';
@Component({
  selector: 'app-registro-docente-list',
  templateUrl: './registro-docente-list.component.html',
  styleUrls: ['./registro-docente-list.component.css']
})
export class RegistroDocenteListComponent {

  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel: boolean = false;
  mostrarTabla: boolean = true;
  @ViewChild('primerMenuAcordeon') primerMenuAcordeon: ElementRef;
  estadosInscripcion: string[] = ['En Revisión', 'Inscrito', 'Desinscrito', 'Pendiente', 'Cancelado'];
  isDragging = false;
  startX = 0;
  scrollLeft = 0;

  personalDataSection: { key: string, value: string }[] = [
    { key: 'agno', value: 'Año' },
    { key: 'rutDocente', value: 'Rut Docente' },
    { key: 'nombres', value: 'Nombres' },
    { key: 'apellidos', value: 'Apellidos'},
    { key: 'email', value: 'Email' },
    { key: 'numeroContacto1', value: 'Teléfono'},
    { key: 'numeroContacto2', value: 'Teléfono Opcional'},
  ]

  establishmentDataSection: { key: string, value: string }[] = [
    { key: 'nombreEstablecimiento', value: 'Nombre Establecimiento'},
    { key: 'rbd', value: 'RBD'},
    { key: 'rutSostenedor', value: 'Rut Sostenedor' },
    { key: 'nombreSostenedor', value: 'Nombre Sostenedor'},
  ]

  inscriptionDataSection: { key: string, value: string}[] = [
    { key: 'estadoRegistro', value: 'Estado Registro'},
    { key: 'debeRendir', value: 'Debe Rendir'},
    { key: 'codNivel', value: 'Nivel'},
    { key: 'codSubsector', value: 'Subsector' },
    { key: 'motivoNoInscripcion', value: 'Motivo no Inscripción' },
    { key: 'fechaInscripcion', value: 'Fecha Inscripción'},
    { key: 'fechaUltMod', value: 'Fecha Ultima Modificación' },
  ]

  validationDataSection: { key: string, value: string }[] = [
    { key: 'estadoValidacion', value: 'Estado Validación' },
    { key: 'fechaValidacion', value: 'Fecha Validación' },
    { key: 'solicitaCambioSubsector', value: 'Solicita Cambio Nivel/Subsector' },
    { key: 'estadoConfirmacionSubsector', value: 'Estado Cambio Nivel/Subsector'},
    { key: 'nivelSolicitado', value: 'Nivel Solicitado' },
    { key: 'subsectorSolicitado', value: 'Subsector Solicitado'},
    { key: 'beneficios', value: 'Beneficios'},
  ]

  exemptSuspendDataSection: { key: string, value: string }[] = [
    { key: 'estadoEximirSuspender', value: 'Estado Eximir Suspender'},
    { key: 'motivoSuspenderEximir', value: 'Motivo Eximir Suspender'},
  ]

  informeDataSection: { key: string, value: string}[] = [
    { key: 'informeDocente', value: 'Tiene Informe' },
  ]

  keyValue: { key: string, value: string }[] = this.personalDataSection
    .concat(this.establishmentDataSection)
    .concat(this.inscriptionDataSection)
    .concat(this.validationDataSection)
    .concat(this.exemptSuspendDataSection)
    .concat(this.informeDataSection);

  displayedColumnsModal: { [key: string]: string } = this.keyValue.reduce((acc: { [key: string]: string }, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});

  displayedColumns: string[] = Object.keys(this.displayedColumnsModal);
  nameDisplayedColumns: string[] = Object.values(this.displayedColumnsModal);

  toggleColumns: { key: string, value: boolean, name: string }[] = [
    { key: 'nombres', value: true, name: 'Nombres' },
    { key: 'apellidos', value: true, name: 'Apellidos' },
    { key: 'email', value: true, name: 'Email' },
    { key: 'numeroContacto1', value: true,  name: 'Teléfono' },
    { key: 'numeroContacto2', value: true,  name: 'Teléfono Opcional' },
    { key: 'nombreEstablecimiento', value: true, name: 'Nombre Establecimiento' },
    { key: 'nombreSostenedor', value: true, name: 'Nombre Sostenedor' },
    { key: 'estadoRegistro', value: true,  name: 'Estado Registro' },
    { key: 'debeRendir', value: true, name: 'Debe Rendir' },
    { key: 'motivoNoInscripcion', value: true, name: 'Motivo no Inscripción' },
    { key: 'fechaInscripcion', value: true, name: 'Fecha Inscripción' },
    { key: 'fechaUltMod', value: true, name: 'Fecha Ultima Modificación' },
    { key: 'estadoValidacion', value: true, name: 'Estado Validación' },
    { key: 'fechaValidacion', value: true, name: 'Fecha Validación' },
    { key: 'solicitaCambioSubsector', value: true, name: 'Solicita Cambio Nivel/Subsector' },
    { key: 'estadoConfirmacionSubsector', value: true, name: 'Estado Cambio Nivel/Subsector' },
    { key: 'nivelSolicitado', value: true, name: 'Nivel Solicitado' },
    { key: 'subsectorSolicitado', value: true, name: 'Subsector Solicitado' },
    { key: 'beneficios', value: true, name: 'Beneficios' },
    { key: 'estadoEximirSuspender', value: true, name: 'Estado Eximir Suspender' },
    { key: 'motivoSuspenderEximir', value: true, name: 'Motivo Eximir Suspender' },
    { key: 'informeDocente', value: true, name: 'Tiene Informe' }
  ]
    ;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  dataSource: MatTableDataSource<Docente> = new MatTableDataSource();
  pageIndex: number;
  length: number;
  filtros: DocenteFilter = new DocenteFilter();
  response: any;
  options: PaginationOptions = new PaginationOptions();
  docenteSeleccionadoModal: any;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;

  getDocenteSub: Subscription;
  permisoDescargarInforme: boolean = false;


  @ViewChild('infoDatosPersonales') infoDatosPersonales: TemplateRef<any>;
  @ViewChild('infoDatosEstablecimiento') infoDatosEstablecimiento: TemplateRef<any>;
  @ViewChild('infoDatosInscripcion') infoDatosInscripcion: TemplateRef<any>;
  @ViewChild('infoValidacion') infoValidacion: TemplateRef<any>;
  @ViewChild('infoEximirSuspender') infoEximirSuspender: TemplateRef<any>;
  @ViewChild('infoInformes') infoInformes: TemplateRef<any>;

  mostrarInfoDocente!: boolean;
  acordeonesInfoDocenteName: string = "acordeonInfoDocenteHistorico";
  acordeonesInfoDocente: {
    content: TemplateRef<any>,
    id: string,
    title: string,
    target: string,
    showFirst: boolean,
    keepOpen: boolean,
    context: any,
    injector:any,
  }[] = [];

  constructor(
    private docenteService: DocenteService,
    private errorMensaje: ErrorMensajeService,
    private permisoPara: AutorizacionesService,
  ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    initTE({ Collapse });
    this.docenteSeleccionadoModal = {
      rutDocente: {
        val: 0,
      }
    }
    this.filtros = new DocenteFilter();
    this.permisoDescargarInforme = this.permisoPara.permisoDescargarInformeDocente();
    this.getDocentes();
  }

  getDocentes(): void {
    this.isLoading = true;
    this.getDocenteSub = this.docenteService.docentesList(this.options, this.filtros.rutDocente,
      this.filtros.rutSostenedor,
      this.filtros.anioConvocatoria,
      this.filtros.rbd,
      this.filtros.estadoRegistro).subscribe(
        {
          next: (response: any) => {
            this.response = response.list;
            this.pageIndex = (response.pageNum - 1);
            this.length = response.total;
            this.isLoading = false;
          }, error: (error: any) => {
            this.errorMensaje.show(error, "obtener los docentes").then((result:any) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                this.isLoading = false;
              }
            });
          }
        });
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getDocentes();
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 10;
    this.getDocentes();
  }

  public limpiar(): void {
    this.filtros = new DocenteFilter();
    this.filtrar();
  }

  public descargarExcel(): void {
    const url = `${Constants.URL_SERVICE.BASE_DOCENTE + Constants.URL_SERVICE.DOCENTES.DESCARGAR}`;
    const filename = "lista.csv"
    this.estaDescargandoExcel = true;

    this.docenteService.getFile(url,
      this.filtros.rutDocente,
      this.filtros.rutSostenedor,
      this.filtros.anioConvocatoria,
      this.filtros.rbd,
      this.filtros.estadoRegistro
    ).subscribe(
      {
        next: (response: any) => {
          Toast.fire(
            "Descarga de docentes",
            "Descarga finalizada",
            "success"
          );
          this.docenteService.downloadBlob(filename, response);
          this.estaDescargandoExcel = false;
        }, error: (error: any) => {
          this.errorMensaje.show(error, "descargar los docentes");
          this.estaDescargandoExcel = false;
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  obtenerDocente(element: Docente) {
    this.docenteSeleccionadoModal = element;
    this.mostrarInfoDocente = false;
    this.acordeonesInfoDocente = [];

    this.acordeonesInfoDocente.push(
      {
        content: this.infoDatosPersonales,
        id: "headingOneHist",
        title: "Datos Personales",
        target: "#acordeonUnoHist",
        showFirst: true,
        keepOpen: true,
        context:{
          personalDataSection:this.personalDataSection,
          docenteSeleccionadoModal:this.docenteSeleccionadoModal
        },
        injector:null,
      });
    this.acordeonesInfoDocente.push(
      {
        content: this.infoDatosEstablecimiento,
        id: "headingTwoHist",
        title: "Datos de Establecimiento",
        target: "#acordeonDosHist",
        showFirst: false,
        keepOpen: false,
        context:{
          establishmentDataSection: this.establishmentDataSection,
          docenteSeleccionadoModal: this.docenteSeleccionadoModal
        },
        injector:null,
      });
      this.acordeonesInfoDocente.push(
        {
          content: this.infoDatosInscripcion,
          id: "headingThreeHist",
          title: "Datos de Inscripción",
          target: "#acordeonTresHist",
          showFirst: false,
          keepOpen: false,
          context:{
            inscriptionDataSection: this.inscriptionDataSection,
            docenteSeleccionadoModal: this.docenteSeleccionadoModal
          },
          injector:null,
        });
    this.acordeonesInfoDocente.push(
      {
        content: this.infoValidacion,
        id: "headingFourHist",
        title: "Datos de Validación",
        target: "#acordeonCuatroHist",
        showFirst: false,
        keepOpen: false,
        context:{
          validationDataSection: this.validationDataSection,
          esFecha: this.esFecha,
          docenteSeleccionadoModal: this.docenteSeleccionadoModal
        },
        injector:null,
      });

      this.acordeonesInfoDocente.push(
        {
          content: this.infoEximirSuspender,
          id: "headingFiveHist",
          title: "Datos de Eximir Suspender",
          target: "#acordeonCincoHist",
          showFirst: false,
          keepOpen: false,
          context:{
            exemptSuspendDataSection: this.exemptSuspendDataSection,
            docenteSeleccionadoModal: this.docenteSeleccionadoModal
          },
          injector:null,
        });

        this.acordeonesInfoDocente.push(
          {
            content: this.infoInformes,
            id: "headingSixHist",
            title: "Datos de Informe",
            target: "#acordeonSeisHist",
            showFirst: false,
            keepOpen: false,
            context:{
              informeDataSection: this.informeDataSection,
              docenteSeleccionadoModal: this.docenteSeleccionadoModal
            },
            injector:null,
          });

      this.mostrarInfoDocente = true;
  }

  esFecha(atributo: string): boolean {
    return atributo.toLowerCase().includes('fecha');
  }

  descargarInformeDocente() {
    const rut = Number(this.docenteSeleccionadoModal['rutDocente']);
    const agno = Number(this.docenteSeleccionadoModal['agno']);
    this.docenteService.descargarInformeDocente(rut, agno);
  }

  startDrag(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.pageX - (event.target as HTMLElement).closest('div')!.scrollLeft;
    this.scrollLeft = (event.target as HTMLElement).closest('div')!.scrollLeft;
    (event.target as HTMLElement).closest('div')!.classList.add('cursor-grabbing');
  }

  stopDrag(event: MouseEvent) {
    this.isDragging = false;
    (event.target as HTMLElement).closest('div')!.classList.remove('cursor-grabbing');
  }

  onDrag(event: MouseEvent) {
    if (!this.isDragging) return;
    event.preventDefault();
    const x = event.pageX - (event.target as HTMLElement).closest('div')!.offsetLeft;
    const scroll = x - this.startX;
    (event.target as HTMLElement).closest('div')!.scrollLeft = this.scrollLeft - scroll;
  }
  onRightDblClick(event: MouseEvent) {
    event.preventDefault();
    if (event.button === 2) { // Asegurarse de que es clic derecho
      const target = (event.target as HTMLElement).closest('div')!;
      target.scrollLeft = target.scrollWidth - target.clientWidth;
    }
  }

  // Nueva función para manejar el doble clic izquierdo
  onLeftDblClick(event: MouseEvent) {
    if (event.button === 0) { // Asegurarse de que es clic izquierdo
      const target = (event.target as HTMLElement).closest('div')!;
      target.scrollLeft = 0;
    }
  }
}
