<div class="h-full flex flex-col">
  <h3 class="text-md font-bold text-blue-700 mb-2">{{ edit ? 'Editar Incidencia' : 'Nueva Incidencia' }}</h3>

  <div *ngIf="loading" class="flex flex-col justify-center items-center h-full">
    <mat-spinner diameter="40"></mat-spinner>
    <p class="text-gray-600 text-sm mt-2">Cargando...</p>
  </div>

  <div *ngIf="saving" class="flex flex-col justify-center items-center h-full">
    <mat-spinner diameter="40"></mat-spinner>
    <p class="text-gray-600 text-sm mt-2">Guardando...</p>
  </div>

  <div *ngIf="(!loading && !saving)" class="flex flex-col h-full">
    <!-- Sección de Agregar Problema -->
    <div class="bg-gray-50 p-2 rounded-md shadow-inner mb-2">
      <h4 class="text-sm font-bold text-gray-700 mb-1">Agregar un Problema</h4>

      <form [formGroup]="correccionForm" (ngSubmit)="agregarProblema()" class="space-y-2">
        <!-- Selector de Categoría -->
        <div>
          <mat-card class="w-full p-2">
            <mat-card-content>
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Categoría</mat-label>
                <mat-select id="categoria" (selectionChange)="onCategoriaChange($event.value)" required>
                  <mat-option *ngFor="let categoria of categoriasMotivos" [value]="categoria">
                    <span style="background-color: #e0e0e0; padding: 2px 6px; border-radius: 4px; font-weight: bold;">
                      {{categoria.codigo}}
                    </span>
                    <span style="margin-left: 8px;">{{ categoria.nombre }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>

        <!-- Selector de Motivo -->
        <div *ngIf="motivosFiltrados.length > 0">
          <mat-card class="w-full p-2">
            <mat-card-content>
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Motivo</mat-label>
                <mat-select id="motivo" formControlName="motivo" required (selectionChange)="onMotivoChange($event.value)">
                  <mat-option
                    *ngFor="let motivo of motivosFiltrados"
                    [value]="motivo.id"
                    [matTooltip]="motivo.descripcion"
                    matTooltipPosition="left"
                  >
                  <span style="background-color: #d0d7ff; padding: 2px 6px; border-radius: 4px; font-weight: bold;">
                    {{motivo.codigo}}
                  </span>
                    <span style="margin-left: 8px;">{{ motivo.nombre }}</span>
                  </mat-option>
                </mat-select>

                <mat-icon matSuffix>report_problem</mat-icon>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>

        <!-- Campos de Valor Anterior y Valor Nuevo (Solo para ciertos motivos) -->
        <div *ngIf="mostrarCamposValor">
          <mat-card class="w-full p-2">
            <mat-card-content>
              <!-- Campo de Valor Anterior (Solo lectura) -->
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Valor Anterior</mat-label>
                <input matInput type="text" id="valorAnterior" formControlName="valorAnterior" class="block w-full bg-gray-200" readonly>
              </mat-form-field>

              <!-- Campo de Valor Nuevo (numérico o texto según motivo seleccionado) -->
              <mat-form-field appearance="outline" class="w-full mt-1">
                <mat-label>Valor Nuevo</mat-label>

                <!-- Input numérico para RUT y Correlativo -->
                <input *ngIf="selectedMotivo?.id === MOTIVOS.RUT_NO_CORRESPONDE || selectedMotivo?.id === MOTIVOS.CORRELATIVO_INCORRECTO"
                       matInput type="number" id="valorNuevo" formControlName="valorNuevo" class="block w-full no-spinner"
                       [min]="1" required>

                <!-- Input de texto para otros motivos solucionables -->
                <input *ngIf="selectedMotivo?.solucionable && selectedMotivo?.id !== MOTIVOS.RUT_NO_CORRESPONDE && selectedMotivo?.id !== MOTIVOS.CORRELATIVO_INCORRECTO"
                       matInput type="text" id="valorNuevo" formControlName="valorNuevo" class="block w-full" required>

                <mat-icon matSuffix>edit</mat-icon>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>

        <div *ngIf="selectedMotivo?.permiteComentarios">
          <mat-form-field appearance="outline" class="w-full mt-2">
            <mat-label>Agregar/modificar comentarios</mat-label>
            <textarea matInput id="comentarios" formControlName="comentarios" rows="3" class="block w-full"></textarea>
          </mat-form-field>
        </div>


        <!-- Botón de Agregar Problema -->
        <div class="flex justify-end">
          <button mat-raised-button color="primary" type="submit" class="text-sm"
                  [disabled]="mostrarCamposValor && correccionForm.get('valorNuevo')?.value === ''">
            <mat-icon>add_circle</mat-icon> Agregar
          </button>
        </div>

      </form>
    </div>
    <!-- Lista de Problemas Agregados -->

    <div *ngIf="problemas.length > 0" class="flex-grow flex flex-col">
      <h4 class="text-sm font-bold text-gray-700 mb-1">Problemas Agregados</h4>
      <div class="overflow-y-auto max-h-72">
        <ul class="space-y-1">

          <li *ngFor="let problema of problemas; let i = index" class="bg-white rounded-md shadow p-3 flex flex-col">
            <!-- Encabezado: Código de Categoría y Motivo, Nombre del Problema, Botón de Eliminar -->
            <div class="flex items-center justify-between">
              <!-- Izquierda: Códigos y Nombre del problema -->
              <div class="flex items-center">
                <!-- Código de la Categoría -->
                <span class="bg-gray-200 px-2 py-1 rounded-l text-xs font-bold">
              {{ problema.codigoCategoria }}
            </span>

                <!-- Código del Motivo -->
                <span class="bg-blue-100 px-2 py-1 rounded-r text-xs font-bold -ml-px">
              {{ problema.codigoMotivo }}
            </span>

                <!-- Nombre del problema -->
                <p class="text-sm font-semibold text-gray-800 ml-2">{{ problema.motivoNombre }}</p>
              </div>

              <!-- Botón para eliminar -->
              <button mat-icon-button color="warn" class="p-0" (click)="eliminarProblema(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <!-- Detalles adicionales: "No Solucionable", valores y comentario -->
            <div class="mt-2 pl-3 border-l-4 border-gray-200 space-y-1">
              <!-- "No Solucionable" si aplica -->
              <div *ngIf="!problema.solucionable" class="flex items-center space-x-1">
                <p class="text-xs text-red-600 font-semibold">No Solucionable</p>
                <button mat-icon-button class="p-0" [matTooltip]="'Este problema no puede ser solucionado por el operador. La revisión se marcará como fallida.'" matTooltipPosition="above">
                  <mat-icon class="text-red-500 text-xs">help_outline</mat-icon>
                </button>
              </div>

              <!-- Valores Anterior y Nuevo cuando es solucionable -->
              <div *ngIf="MOTIVOS_CAMBIO_VALOR.includes(problema.idMotivo)" class="text-xs text-gray-600">
                <p><span class="font-semibold">Valor Anterior:</span> {{ problema.valorAnterior ?? 'N/A' }}</p>
                <p><span class="font-semibold">Valor Nuevo:</span> {{ problema.valorNuevo ?? 'N/A' }}</p>
              </div>

              <!-- Comentario, si existe y se permiten comentarios -->
              <ng-container *ngIf="problema.comentario !== null && problema.permiteComentarios">
                <details class="mt-2 bg-gray-50 rounded-lg p-2 shadow-inner">
                  <summary class="text-blue-600 text-xs font-semibold cursor-pointer flex items-center">
                    <mat-icon class="mr-1">comment</mat-icon>
                    <span>Ver comentario</span>
                  </summary>
                  <p class="mt-2 text-gray-700 bg-gray-100 p-2 rounded break-words">
                    {{ problema.comentario }}
                  </p>
                </details>
              </ng-container>
            </div>
          </li>

        </ul>
      </div>
    </div>

    <!-- Botones Guardar y Cancelar -->
    <div *ngIf="!saving" class="flex justify-end space-x-2 mt-2">
      <button mat-stroked-button color="warn" type="button" class="text-sm" (click)="onCancelar()">
        <mat-icon>cancel</mat-icon> Cancelar
      </button>
      <button mat-raised-button color="primary" type="button" class="text-sm" (click)="onGuardar()" [disabled]="problemas.length == 0">
        <mat-icon>save</mat-icon> Guardar
      </button>
    </div>
  </div>
</div>
