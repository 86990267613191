export class ProblemaCorreccionDTO {
  idMotivo: number;
  codigoMotivo: string |null;
  codigoCategoria: string |null;
  motivoNombre: string;
  solucionable: boolean;
  valorNuevo: string | null = null;
  valorAnterior: string | null = null;
  comentario: string | null = null;
  permiteComentarios: boolean = false;
}
