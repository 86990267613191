import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lista-autocompletable',
  templateUrl: './lista-autocompletable.component.html',
})
export class ListaAutocompletableComponent implements OnInit {
  @Input() control!: FormControl;
  @Input() options: string[] = [];
  @Input() placeholder: string = '';

  filteredOptions: string[] = [];
  isDropdownVisible: boolean = false; // Control de visibilidad del dropdown
  inputError!: boolean;

  ngOnInit(): void {
    // Filtramos las opciones cada vez que el valor del input cambia
    this.control.valueChanges.subscribe(value => {
      this.filteredOptions = this._filter(value || '');
      this.isDropdownVisible = this.filteredOptions.length > 0;
      this.inputError = !this.options.includes(value);
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  // Método para seleccionar la opción y ocultar el dropdown
  selectOption(option: string): void {
    this.control.setValue(option);
    this.isDropdownVisible = false; // Ocultar el dropdown al seleccionar
  }

  // Método para abrir el dropdown al hacer focus o click
  onFocus(): void {
    this.onClick();
  }

  // Prevenir que el dropdown se oculte inmediatamente después de seleccionar
  onBlur(): void {
    setTimeout(() => {
      this.isDropdownVisible = false;
    }, 200); // Esperamos un pequeño delay para que el evento de selección se complete
  }

  // Mostrar el dropdown cuando se hace clic en el input
  onClick(): void {
    if (!this.control.value) this.filteredOptions = this.options;
    if (this.filteredOptions.length > 0) {
      this.isDropdownVisible = true;
    }
  }
}
