const nameTableColumns: string[] = [
  'Rut Empresa',
  'RBD',
  'Establecimiento',
  'Rut',
  'Nombres',
  'Primer Apellido',
  'Segundo Apellido',
  'email',
  'Estado Acceso',
  'Acciones'
]
export class EncargadoEvaluacionFilter {
    rutSostenedor: number;
    rutEncargadoEvaluacion: number;
    rbd: number;
    dependencia: number;
    region: number;
    estadoAcceso: number;

    constructor(){
        this.rutSostenedor = null;
        this.rutEncargadoEvaluacion = null;
        this.rbd = null;
        this.dependencia = null;
        this.region = null;
        this.estadoAcceso = null;
    }
    getNameColumns(index: number): string{
      return nameTableColumns[index];
  }
}
