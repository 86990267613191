import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
  standalone: true,
  imports: [LottieComponent],
})
export class PageNotFoundComponent {
  options: AnimationOptions = {
    path: '../../../../assets/lotties/lost.json',
  };

  onLoopCompleteCalledTimes = 0;

  constructor(
    private ngZone: NgZone,
    private ref: ChangeDetectorRef,
    private router: Router,
  ) {}

  home() {
    this.router.navigate(["private"])
  }

  onLoopComplete(): void {
    this.ngZone.run(() => {
      this.onLoopCompleteCalledTimes++;
      this.ref.detectChanges();
    });
  }
}
