import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { Oficio } from '../models/oficio';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class OficiosService {
  constructor(
    private http: HttpClient
  ) { }

  oficiosList(options: PaginationOptions,
    rutDocente: number = null,
    rutEncargado: number = null,
    rutSostenedor: number = null,
    estado: string = ""): Observable<any> {
    let params = this.obtenerParamsOficio(rutDocente, rutEncargado, rutSostenedor, estado);
    const url = `${Constants.BASE_OFICIOS}?page=${options.page}&size=${options.size}`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

  descargarOficios(
    url: string,
    rutDocente: number = null,
    rutEncargado: number = null,
    rutSostenedor: number = null,
    estado: string = ""):  Observable<any> {
      let params = this.obtenerParamsOficio(rutDocente, rutEncargado, rutSostenedor, estado);
      params = params
      return this.http.get(url, { responseType: 'blob', params: params }).pipe(
        map(response => response)
      );
    }

    downloadBlob(filename: string, blob: any) {
      const dwldLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
      if (isSafariBrowser) {
        dwldLink.setAttribute('target', '_blank');
      }
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute('download', filename);
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }

  obtenerParamsOficio(rutDocente: number, rutEncargado: number, rutSostenedor: number, estadoOficio: string): HttpParams {
    let params = new HttpParams();

    if (rutDocente && rutDocente > 0) {
      params = params.set('rutDocente', rutDocente);
    }
    if (rutEncargado && rutEncargado > 0) {
      params = params.set('rutEncargado', rutEncargado);
    }
    if (rutSostenedor && rutSostenedor > 0) {
      params = params.set('rutSostenedor', rutSostenedor);
    }
    if (estadoOficio ) {
      params = params.set('estado', estadoOficio);
    }
    return params;
  }

  obtenerArchivo(archivo: string){
    let header = new HttpHeaders();
    const url = `${Constants.BASE_OFICIOS}/documento`;
    header = header.set('Authorization','Bearer ');
    return this.http.get(url, {headers: header, params: {archivo: archivo}});
  }

  filtrosFormulario(): Observable<any>{
    const url = `${Constants.BASE_OFICIOS}${Constants.URL_SERVICE.OFICIOS.FILTROS}`;
    return this.http.get(url).pipe(map(response => response));
  }

  modificarOficio(dataOficio: Oficio): Observable<any> {
    const url = `${Constants.BASE_OFICIOS}`;
    const body = {
      "idOficio": dataOficio.idOficio,
      "idDocente": dataOficio.idDocente,
      "idCausal": dataOficio.idCausal,
      "idEstado": dataOficio.idEstado,
      "observacion": dataOficio.observacion
    };
    return this.http.put(url, body).pipe(map(response => response));
  }
}
