export class EventoFilter {
    idTipoEvento: number;
    idTipoObjetoAfectado: number;
    fechaInicio: string;
    fechaFin: string;
    rut: number;

    constructor(){
        this.idTipoEvento = -1;
        this.idTipoObjetoAfectado = -1;
    }
}