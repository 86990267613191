<div class="form-group relative">
  <input
    [formControl]="control"
    type="text"
    [placeholder]="placeholder"
    (focus)="onFocus()"
    (click)="onClick()"
    (blur)="onBlur()"
    class="form-control block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  />

  <div *ngIf="inputError" class="text-sm text-red-600">
    Debes ingresar una opción válida.
  </div>

  <div *ngIf="!control.value" class="text-sm text-red-600">
    Debes ingresar un valor.
  </div>

  <!-- Dropdown para autocompletar -->
  <ul *ngIf="isDropdownVisible" class="absolute bg-white border border-gray-300 rounded-md mt-1 w-full max-h-60 overflow-auto z-10">
    <li
      *ngFor="let option of filteredOptions"
      (click)="selectOption(option)"
      class="cursor-pointer hover:bg-indigo-500 hover:text-white p-2"
    >
      {{ option }}
    </li>
  </ul>
</div>
