import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class FichaDocenteService {

  estaDescargandoExcelFichaDocemteEmmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient
  ) { }

  getFechaCorte(): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_FICHA_DOCENTE}/fecha_corte_validacion`;
    return this.http.get(url);
  }

  docentesList(options: PaginationOptions,
    rutDocente: number = null,
    rutSostenedor: number = null,
    rbd: number = null,
    estadoRegistro: string = "",
    estadoValidacion: number): Observable<any> {
    let params = this.obtenerParamsDocente(rutDocente, rutSostenedor, rbd, estadoRegistro, estadoValidacion);
    const url = `${Constants.URL_SERVICE.BASE_FICHA_DOCENTE + Constants.URL_SERVICE.FICHA_DOCENTE.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

  infoSuspenderEximir(rut: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_FICHA_DOCENTE + Constants.URL_SERVICE.FICHA_DOCENTE.SUSPENDER_EXIMIR}${rut}`;
    return this.http.get(url);
  }

  getDocenteDetalles(idDocente: number, rut: number): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_FICHA_DOCENTE}/detallesdocente/${idDocente}/${rut}`;
    return this.http.get(url);
  }

  getEstablecimientos(): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_FICHA_DOCENTE + Constants.URL_SERVICE.FICHA_DOCENTE.LISTAR_ESTABLECIMIENTOS}`;
    return this.http.get(url);
  }

  getHistorialValidacion(rutDocente:number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_FICHA_DOCENTE}/historial-validacion/${rutDocente}`;
    return this.http.get(url).pipe(map(response => response));
  }


  guardarEdicionDocente(datosDocente: {
    idRegistro: number,
    idValidacion: number,
    rutDocente: number,
    nombres: string,
    primerApellido: string,
    segundoApellido: string,
    rbd: number,
    email: string,
    numeroContacto1: number,
    numeroContacto2: number,
    estadoRegistro: string | number,
    codNivel: string,
    codSubsector: string,
     }): Observable<any> {
      datosDocente.estadoRegistro = Number(datosDocente.estadoRegistro);
      const url = `${Constants.URL_SERVICE.BASE_FICHA_DOCENTE + Constants.URL_SERVICE.FICHA_DOCENTE.EDITAR_DOCENTE}`;
      return this.http.put(url, datosDocente);
  }

  obtenerParamsDocente(rutDocente: any, rutSostenedor: any, rbd: any, estadoRegistro: any, estadoValidacion: any): HttpParams {
    let params = new HttpParams();

    if (rutDocente && rutDocente > 0) {
      params = params.set('rutDocente', rutDocente);
    }
    if (rutSostenedor && rutSostenedor > 0) {
      params = params.set('rutSostenedor', rutSostenedor);
    }
    if (rbd && rbd > 0) {
      params = params.set('rbd', rbd);
    }
    if (estadoRegistro && estadoRegistro.trim() != "") {
      params = params.set('estadoRegistro', estadoRegistro.trim());
    }
    if (estadoValidacion && estadoValidacion > -1) {
      params = params.set('estadoValidacion', estadoValidacion);
    }
    return params;
  }

  obtenerToken(rut: string):Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_TOKEN_DOCENTE}/${rut}`;
    return this.http.get(url);
  }

  obtenerArchivo(token: string, rut:string){
    let header = new HttpHeaders();
    const url = `${Constants.URL_SERVICE.BASE_ARCHIVO_DOCENTE}/archivo/obtenerArchivoNotificacion/${rut}`;
    header = header.set('Authorization','Bearer '+token);
    return this.http.get(url, {headers: header, responseType: 'blob'});
  }

  obtenerArchivoObligatorio(archivo:string): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_ARCHIVOS + Constants.URL_SERVICE.DOCENTES.DESCARGA_ARCHIVO_SUSPENSION}/${archivo}  `;
    return this.http.get(url,
      {
        responseType: 'json',
        observe: 'response',
        reportProgress: true,
      });
  }
}
