import { UsuarioOperaciones } from "./usuarioOperaciones";

export class InfoPagUsuariosOps {
  total: number = 0;
  list: UsuarioOperaciones[] = [];
  pageNum: number = 0;
  size: number = 0;
  startRow: number = 0;
  endRow: number = 0;
  pages: number = 0;
  prePage: number = 0;
  nextPage: number = 0;
  isFirstPage: boolean = null;
  isLastPage: boolean = null;
  hasPreviousPage: boolean = null;
  hasNextPage: boolean = null;
  navigatePages: number = 0;
  navigatePageNums: number[] = [];
  navigateFirstPage: number = 0;
  navigateLastPage: number = 0;
}
