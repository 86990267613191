import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { DocenteService } from '../../services/docente.service';

@Component({
  selector: 'app-evaluaciones-historicas',
  templateUrl: './evaluaciones-historicas.component.html',
  styleUrls: ['./evaluaciones-historicas.component.css']
})
export class EvaluacionesHistoricasComponent implements OnInit {

  inscripcionForm: any;
  payload: any;
  error: boolean = false;
  rutDocente!: number;
  rutActual! : number;
  evHistorica!: any;
  docente:any;
  searching!: boolean;
  agnoSistReconocimiento = Array.from({ length: new Date().getFullYear() - 2016 }, (_, i) => new Date().getFullYear() -1- i);

  constructor(
    private docenteService: DocenteService,
    private spinner: NgxSpinnerService,
    private errorMensaje: ErrorMensajeService
  ) {
  }

  ngOnInit(): void {
    this.searching = false;
  }

  public filtrar(): void {
    this.searching = true;
    this.rutActual = this.rutDocente;
    this.docenteService.buscarEvaluacionesHistoricas(this.rutDocente).subscribe({
      next: (response:any) => {
        this.error = false;
        this.payload = response;
        this.docente = response.infoDocente;
        if (this.docente && this.docente.nombres == null && this.docente.primerApellido == null && this.docente.segundoApellido == null)
          this.docente = null;
        this.evHistorica = response.evaluacionesHistoricas;
        this.searching = false;
      },
      error: (error) => {
        this.payload = undefined;
        this.error = true;
        this.errorMensaje.show(error, "obtener las evaluaciones históricas");
        this.searching = false;
      }
    });
  }

  isNumeric(str: number | string) {
    if (typeof str != "string") return false // we only process strings!
    return true
  }

  getData(agno:number) {
    return this.evHistorica.find( (item:any)=> item.agno === agno)?.sistemaReconocimiento || '';
  }

  public limpiar(): void {
    this.rutDocente = null;
    this.searching = false;
    this.evHistorica = null;
    this.docente = null;
    this.payload = null;
  }

  rutEntrada() {
    if (this.rutActual != this.rutDocente) {
      this.evHistorica = null;
      this.docente = null;
      this.payload = null;
    }
  }

}
