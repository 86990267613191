import { FormControl, Validators } from "@angular/forms";

const nameTableColumns: string[] = [
    "Rut",
    "Tipo de Usuario",
    "Acciones"
]
const fieldForm: string[] = [
    "Rut",
    "Tipo de Usuario",
    "Perfiles"
]

const fieldControl: FormControl[] = [
    new FormControl('', [Validators.required, Validators.maxLength(8)]),
    new FormControl('', Validators.required),
    new FormControl('', Validators.required),
];

export class MantenedorUsuarios {
    [index: string] :any;
    rut: number;
    username: number;
    roles: string[];
    tipoUsuario: string[];

    constructor(){
        this.rut = null;
        this.username = this.rut;
        this.roles = [];
        this.tipoUsuario = [];
    }

    getControl(index: number): FormControl {
        fieldControl[index].setValue('', {emitEvent: false});
        return fieldControl[index];
    }

    getNameColumns(index: number): string{
        return nameTableColumns[index];
    }

    getFieldForm(index: number): string {
        return fieldForm[index];
    }
}
