<div id="accordeonParent" #scroll>
    <div *ngIf="acordeones && acordeones.length > 0">
        <div *ngFor="let menu of acordeones; let i = index">
            <div class="rounded-t-lg border border-neutral-200 bg-white">
                <h2 class="mb-0" id="menu.id">
                    <div *ngIf="i>0">
                        <button
                        class="shadow-none group border-0  relative flex w-full items-center bg-white px-5 py-4 text-left text-base transition [overflow-anchor:none]   [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary "
                        (click)="clickAcordeon(i, menu.id)" 
                        data-te-collapse-init 
                        aria-expanded="false"
                        aria-controls="menu.id">
                        {{menu.title}}
                        <span
                            class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </button>
                    </div>
                    <div *ngIf="i==0">
                        <button
                        class="shadow-none text-sidebar rounded-t-[15px] border-0  group relative flex w-full items-center bg-white px-5 py-4 text-left text-sm text-blue-950 transition [overflow-anchor:none] focus:outline-none [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary "
                        (click)="clickAcordeon(i, menu.id)" 
                        data-te-collapse-init 
                        aria-expanded="false"
                        aria-controls="menu.id">
                        {{menu.title}}
                        <span
                            class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </button>
                    </div>
                </h2>
                <div *ngIf=" menu.id != activeMenu" class="!visible hidden" 
                    data-te-collapse-item 
                    aria-labelledby="menu.id"
                    data-te-parent="accordeonParentHash">
                    <div class="px-5 py-4">
                        <ng-container 
                        *ngTemplateOutlet="menu.content; context: menu.context; injector:menu.injector"></ng-container>
                    </div>
                </div>
                <div *ngIf="menu.id == activeMenu || menu.keepOpen" class="!visible" 
                    data-te-collapse-item 
                    aria-labelledby="menu.id"
                    data-te-parent="accordeonParentHash">
                    <div  class="px-5 py-4">
                        <ng-container 
                        *ngTemplateOutlet="menu.content; context: menu.context; injector:menu.injector"></ng-container>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>