<app-representante-legal-form *ngIf="selected" [datosEditar]="selected" (permanecerEnForm)="cambiarVista($event)">
</app-representante-legal-form>


<mat-card *ngIf="!selected" class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2"  #FILTRO style="flex-direction: row">
      <div class="flex flex-col gap-4 px-2">
        <p class="text-blue-950 text-2xl font-bold">Representantes Legales</p>
        <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
          <div class="flex flex-row gap-1 py-2">
            <mat-icon>filter_list</mat-icon>
            <p class="text-lg font-bold">Filtros</p>
          </div>

              <div class="row ms-auto my-auto d-flex">
                  <div class="col-12 row ">
                      <div *ngFor="let input of inputs" class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-xl-4">
                          <mat-form-field class="fullWidth">
                              <mat-label>{{ input.label }}</mat-label>
                              <input matInput [(ngModel)]="filtros[input.attribute]" placeholder="12345678"
                                  name="{{ input.label }}" [type]="input.type"
                                  (ngModelChange)="onInputChange($event, input.type, input.attribute)">
                          </mat-form-field>
                      </div>
                  </div>

                  <div class="col-12 row ">
                      <div *ngFor="let input of inputsRepresentante" class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-xl-4">
                          <mat-form-field class="fullWidth ">
                              <mat-label>{{ input.label }}</mat-label>
                              <input matInput [(ngModel)]="filtros[input.attribute]"
                                  name="{{ input.label }}" [type]="input.type"
                                  (ngModelChange)="onInputChange($event, input.type, input.attribute)">
                          </mat-form-field>
                      </div>
                  </div>
              </div>
          <mat-card-actions class="d-flex" >
              <div class="float-left d-flex align-self-start">
                  <button
                      type="button"
                      data-te-ripple-init
                      (click)="filtrar()"
                      data-te-ripple-color="light"
                      class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                      <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                      <p class="pr-1"></p> Filtrar
                  </button>

                  <button
                      matTooltip="Descarga esta tabla completa"
                      type="button"
                      data-te-ripple-init
                      (click)="descargar()"
                      data-te-ripple-color="light"
                      class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                      <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                      <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                      <div *ngIf="!estaDescargandoExcel" class="text-[14px]">Descargar</div>
                      <div *ngIf="estaDescargandoExcel" class="text-[14px]">Descargando</div>
                  </button>

                  <button
                      type="button"
                      data-te-ripple-init
                      (click)="limpiar()"
                      data-te-ripple-color="light"
                      class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                      <svg xmlns="http://www.w3.org/2000/svg" class="pr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                      Limpiar
                  </button>
              </div>
              <div class="float-right ms-auto my-auto d-flex justify-content-end">
                  <button
                      type="button"
                      (click)="lanzarAgregarNuevoRepresentanteLegal()"
                      matTooltip="Agregar un representante legal al sistema"
                      *ngIf="agregarRepresentante"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16"> <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/> </svg>
                      <p class="pr-1"></p> Agregar
                  </button>
              </div>


          </mat-card-actions>
      </div>
    </div>
  </div>


    <div #TABLA class="">
        <div class="overflow-x-scroll rounded-2xl border border-gray-200 m-2 p-2">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

            <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50" role="table">
              <thead class="bg-blue-700 ">
                <tr>
                  <th scope="col" class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black " >{{filtros.getNameColumns(0)}}</th>
                  <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{filtros.getNameColumns(1)}}</th>
                  <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{filtros.getNameColumns(2)}}</th>
                  <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{filtros.getNameColumns(3)}} </th>
                  <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{filtros.getNameColumns(4)}}</th>
                  <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{filtros.getNameColumns(5)}}</th>
                  <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{filtros.getNameColumns(6)}}</th>
                  <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black max-w-[150px]">{{filtros.getNameColumns(7)}}</th>
                  <th scope="col" [ngClass]="{ 'rounded-tr-2xl ': !acciones }">
                    <p class=" pr-4 py-2 text-left text-blue-100 text-sm font-black">Teléfono</p>
                  </th>
                  <th scope="col" class="rounded-tr-2xl pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="acciones" >{{filtros.getNameColumns(9)}}</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr class="min-w-full" *ngFor="let representante of response; let i = index">
                  <td class="negrita-tabla" >
                    <div class="text-sm h-full font-bold text-slate-600 flex flex-row gap-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><rect fill="none"/><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/></svg>
                      <p class="text-sm">{{representante['rut']}}</p>
                    </div>

                  </td>
                  <td class="negrita-tabla" class="max-w-[200px]">
                    <p class="text-sm font-bold text-slate-600 flex flex-row gap-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z"/></svg>
                      {{representante['rutEmpresa']}}</p>
                    <p class="text-xs text-wrap" matTooltip="{{representante['nombreEmpresa']}}">{{representante['nombreEmpresa']}}</p>
                  </td>
                  <td class="negrita-tabla">
                    <div class="max-w-[200px] text-sm font-bold text-slate-700 flex flex-col gap-1">
                      <div class="flex flex-row gap-1">
                        <span class="rounded bg-blue-600 font-bold text-blue-100 text-[0.6em] px-1">RBD</span>
                        <p class="text-sm font-bold text-slate-600">{{representante['rbd']}}</p>
                      </div>
                      <p class="text-xs text-wrap" matTooltip="{{representante['nombreEstablecimiento']}}">{{representante['nombreEstablecimiento']}}</p>
                    </div>
                  </td>
                  <td class="negrita-tabla" class="text-sm max-w-[150px] text-ellipsis text-nowrap" matTooltip="{{representante['tipo']}}"> {{representante['tipo']}} </td>
                  <td class="negrita-tabla" class="text-sm max-w-[150px] text-ellipsis text-nowrap" matTooltip="{{representante['nombres']}}"> {{representante['nombres']}}</td>
                  <td class="negrita-tabla" class="text-sm max-w-[150px] text-ellipsis text-nowrap" matTooltip="{{representante['primerApellido']}}"> {{representante['primerApellido']}}</td>
                  <td class="negrita-tabla" class="text-sm max-w-[150px] text-ellipsis text-nowrap" matTooltip="{{representante['segundoApellido']}}"> {{representante['segundoApellido']}}</td>
                  <td class="negrita-tabla" class="text-sm max-w-[150px] truncate-cell" matTooltip="{{representante['correo']}}"> {{representante['correo']}}</td>
                  <td class="negrita-tabla" class="text-sm max-w-[150px] text-ellipsis text-nowrap" matTooltip="{{representante['telefono']}}"> {{representante['telefono']}}</td>
                  <td class="negrita-tabla" class="mx-1 inline-flex" role="group" style="width: max-content;" *ngIf="acciones">
                    <button (click)="editar(representante)" aria-label="{mat-cell}"
                        type="button"
                        class="inline-block rounded-l-xl border-2 border-primary px-6 pb-[6px] pt-2 text-[14px] font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        *ngIf="editarRepresentante">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button (click)="eliminar(representante['rut'], representante['rutEmpresa'])" aria-label="{mat-cell}"
                        type="button"
                        class="-ml-0.5 inline-block rounded-r-xl  border-2 border-primary px-6 pb-[6px] pt-2 text-[14px] font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        *ngIf="borrarRepresentante">
                        <mat-icon>delete</mat-icon>
                    </button> </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
            [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
    </mat-paginator>
</mat-card>
