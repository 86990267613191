import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private poolData: any;
  constructor(private router: Router) { }
  detalles: {[key: string]: string} =  {
    "ROLE_ADMIN": "Administrador/a" ,
    "ADM_REGISTRO": "Administrador/a Registro" ,
    "ADM_MDA": " Administrador/a MDA" ,
    "OP_CPEIP": "Operador/a CPEIP" ,
    "OP_MDA": "Operador/a MDA",
    "ADM_INSTRUMENTOS": "Administrador/a Instrumentos",
    "EJECUTIVO_GRABACIONES": "Ejecutivo/a Grabaciones",
    "READER_GRABACIONES": "Visualizador de procesos de Grabaciones",
    "ADM_GRABACIONES": "Administrador/a de procesos de Grabaciones",
    "OPERADOR_GRABACIONES": "Operador de procesos de Grabaciones",
    "REVISOR_GRABACIONES": "Revisor de procesos de Grabaciones"
  }


  getPoolData(): any {
    this.poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoClietId,
      Storage: sessionStorage
    };
    return this.poolData;
  }
  getCurrentUser(): any {
    const userPool = new CognitoUserPool(this.getPoolData());
    const currentUser = userPool.getCurrentUser();
    return currentUser;
  }

  isAuth(): boolean {
    let isAuth = false;
    const currentUser = this.getCurrentUser();
    if (currentUser != null) {
      currentUser.getSession((err: any, session: any) => {
        isAuth = session.isValid();
      })
    } else {
      sessionStorage.clear();
    }
    return isAuth;
  }

  getToken(): string {
    for (let i = 0; i < sessionStorage.length; i++) {
      if (sessionStorage.key(i).endsWith(environment.ACCESS_TOKEN)
        && sessionStorage.key(i).includes(environment.cognitoClietId)) {
        return sessionStorage.getItem(sessionStorage.key(i));
      }
    }
    return null;
  }
  containRol(roles: any) {
    let contain = this.hasRole(roles);
    return contain;
  }

  logout(): void {
    const userPool = new CognitoUserPool(this.poolData);
    const currentUser = userPool.getCurrentUser();
    currentUser.signOut();
    sessionStorage.clear();
    this.router.navigate(['']);
  }

  private dataCognito(): any {
    let token: string = this.getToken();
    let frag: string[] = token.split(".");
    let payload: string = frag[1];
    return JSON.parse(atob(payload));
  }

  public getUserGroup(): string[] {

    return this.dataCognito()['cognito:groups'];
  }

  public getUserGroupDescription(): string[] {
    let grupos: string[] = [];
    this.getUserGroup().forEach(group =>
      grupos.push(this.detalles[group]))
    return grupos;
  }

  public getEmailUser(): string {
    const email = this.dataCognito()['username'];
    return email.indexOf("@") > 0 ? email : this.dataCognito()['email'];
  }

  public hasRole(arrayRol: any[]) {
    if (this.getUserGroup()) {
      for (let rol of arrayRol) {
        if (this.getUserGroup().includes(rol)) {
          return true;

        }
      }
    }
    return false;
  }

}
