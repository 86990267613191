import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { HistorialValidacion } from 'src/app/registros/models/historialValidacion';

@Component({
  selector: 'ficha-docente-timeline-validacion',
  templateUrl: './timeline-validacion.component.html',
  styleUrls: ['./timeline-validacion.component.css']
})
export class TimelineValidacionComponent {
  @Input() historialValidacion: [null | HistorialValidacion];
  cambioSeleccionado: any;
  indexSeleccionado: number = -1;

  constructor(){}

  verCambios(validacion: any, index: number) {
    this.cambioSeleccionado = validacion;
    this.indexSeleccionado = index;
  }

  formatearHoraChile(hora: string): string {
    const format = 'YYYY-MM-DD HH:mm:ss.SSSSS';
    const momentHora = moment.utc(hora, format).tz('America/Santiago');

    // Formatear la hora a un formato legible
    return momentHora.format('YYYY-MM-DD HH:mm:ss');
  }
}
