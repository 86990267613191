import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {formatTimeFromSeconds} from '../../../../../utils/functions/time-functions'

@Component({
  selector: 'app-image-modal',
  templateUrl: 'image-modal.component.html',
  styleUrls: [`image-modal.component.css`]
})
export class ImageModalComponent {
  protected readonly formatTimeFromSeconds = formatTimeFromSeconds;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
