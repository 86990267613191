import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DocenteNomina } from '../../models/docenteNomina';

@Component({
  selector: 'nominas-modal',
  templateUrl: './nominas-modal.component.html',
})
export class NominasModalComponent implements OnInit {
  @Input() rutSostenedor: number;
  @Input() nombreSostenedor: string;
  @Input() docentes: DocenteNomina[];
  searchTerm: string = '';
  filteredRuts!: DocenteNomina[];

  ngOnInit(): void {
    this.updateFilteredRuts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['docentes'] && Array.isArray(this.docentes)) {
      this.updateFilteredRuts();
    }
  }

  private updateFilteredRuts(): void {
    if (Array.isArray(this.docentes)) {
      this.filteredRuts = [...this.docentes];
    } else {
      this.filteredRuts = [];
    }
  }


  filterRuts(): void {
    this.filteredRuts = this.docentes.filter(persona =>
      persona.rutDocente.toString().includes(this.searchTerm)
    );
  }
}
