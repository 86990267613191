import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../base/services/auth.service";
import { Constants } from "../../base/constants/constants";
import { Observable, map } from "rxjs";
import {UsuarioOperaciones} from "../models/usuarioOperaciones";
import {InfoPagUsuariosOps} from "../models/InfoPageUsuariosOps";
import {FiltrosUsuariosOps} from "../components/usuarios-operaciones/models/filtrosUsuariosOps";

@Injectable({
    providedIn: 'root'
})
export class UsuariosCognitoService {
    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) { }

    getListaGruposCognito() : Observable<FiltrosUsuariosOps> {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES + Constants.URL_SERVICE.GESTOR_COGNITO.GRUPOS}`;
        return this.http.get<FiltrosUsuariosOps>(url).pipe(map(response => response));
    }

    getListaUsuariosCognito(page: number, size: number, filter: UsuarioOperaciones): Observable<InfoPagUsuariosOps> {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES + Constants.URL_SERVICE.GESTOR_COGNITO.BASE}`;
        let params = this.obtenerParams(page, size, filter);
        return this.http.get<InfoPagUsuariosOps>(url, {params: params});
    }

    buscarUsuario(email: string): Observable<any> {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES}${Constants.URL_SERVICE.GESTOR_COGNITO.BASE}`;
        return this.http.get(url).pipe(map(response => response));
    }
    agregarUsuario (body: {email: string, grupos: string[]}) {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES}${Constants.URL_SERVICE.GESTOR_COGNITO.BASE}`;
        return this.http.post(url, body).pipe(map(response => response));
    }
    editarUsuario (body: {email: string, grupos: string[]}) {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES}${Constants.URL_SERVICE.GESTOR_COGNITO.BASE}`;
        return this.http.put(url, body).pipe(map(response => response));
    }
    eliminarUsuario (email: string) {
        const url = `${Constants.URL_SERVICE.BASE_USUARIOS_OPERACIONES}${Constants.URL_SERVICE.GESTOR_COGNITO.BASE}${email}`;
        return this.http.delete(url).pipe(map(response => response));
    }

    obtenerParams(page: number, size: number, filter: UsuarioOperaciones): HttpParams {
      let data: {[key: string]: any} = {
        'page': page,
        'size': size,
      };
      if (filter.email && filter.email.trim() != "") {
        data['email'] = filter.email.trim();
      }

      if (filter.groups && filter.groups.length > 0) {
        data['grupos'] = filter.groups.join(',');
      }

      return new HttpParams({ fromObject: data });
    }
}
