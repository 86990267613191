import { Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/base/constants/constants';
import { PaginationOptions } from 'src/app/base/models/paginationOptions';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Docente } from 'src/app/registros/models/docente';
import { evaluacionHistoricaExtFilter } from '../../models/evaluacionHistoricaExtFilter';
import { EvaluacionesHistoricasExtendidaService } from 'src/app/registros/services/evaluaciones-historicas-extendidas.service';
import Swal from 'sweetalert2';
import { Toast }  from '../../../base/services/toast';

@Component({
  selector: 'app-evaluaciones-historicas-extendida',
  templateUrl: './evaluaciones-historicas-extendida.component.html',
  styleUrls: ['./evaluaciones-historicas-extendida.component.css']
})
export class EvaluacionesHistoricasExtendidaComponent {

  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel!: boolean;
  mostrarTabla: boolean = true;
  sistemaEducativo: string[] = ['sistemaEducativo1', 'sistemaEducativo2', 'sistemaEducativo3'];
  dependencias: string[] = ['dependencia1', 'dependencia2', 'dependencia3'];
  estadosInscripcion: string[] = ['En Revisión', 'Inscrito', 'Desinscrito', 'Pendiente', 'Cancelado'];
  comunas: string[] = [];
  beneficios: string[] = [];
  tramo: string[] = [];
  deprov: string[] = [];

  personalDataSection: { key: string, value: string }[] = [
    { key: 'rutDocente', value: 'Rut' },
    { key: 'experiencia', value: 'Experiencia' },
    { key: 'bienios', value: 'Bienios' },
  ]

  portfolioDataSection: { key: string, value: string }[] = [
    { key: 'agno_portafolio', value: 'Año Rendición Portafolio'},
    { key: 'tramo', value: 'Tramo'},
    { key: 'proceso', value: 'Proceso'},
    { key: 'puntaje_pf', value: 'Puntaje Portafolio' },
    { key: 'nivel_desempenio', value: 'Nivel Desempeño' },
    { key: 'categoria_logro', value: 'Cat. Logro Portafolio'},
    { key: 'Estado', value: 'Estado'},
  ]

  establishmentDataSection: { key: string, value: string }[] = [
    { key: 'comuna', value: 'Comuna'},
    { key: 'rbd', value: 'RBD'},
    { key: 'deprov', value: 'DEPROV' },
    { key: 'beneficio70ter', value: '70 ter'},
    { key: 'beneficio19n', value: '19 Ñ'},
  ]

  keyValue: { key: string, value: string }[] = this.personalDataSection
    .concat(this.portfolioDataSection)
    .concat(this.establishmentDataSection)

  displayedColumnsModal: { [key: string]: string } = this.keyValue.reduce((acc: { [key: string]: string }, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});

  displayedColumns: string[] = Object.keys(this.displayedColumnsModal);
  nameDisplayedColumns: string[] = Object.values(this.displayedColumnsModal);

  toggleColumns: { key: string, value: boolean, name: string }[] = [
    { key: 'experiencia', value: true, name: 'Experiencia' },
    { key: 'bienios', value: true, name: 'Bienios' },
    { key: 'anioPortafolio', value: true, name: 'Año Rendicion Portafolio' },
    { key: 'tramo', value: true,  name: 'Tramo' },
    { key: 'puntajePf', value: true,  name: 'Puntaje PF' },
    { key: 'nivel_desempenio', value: true, name: 'Nivel Desempeño' },
    { key: 'categoriaLogro', value: true, name: 'Categoria Logro' },
    { key: 'comuna', value: true,  name: 'Comuna' },
    { key: 'deprov', value: true, name: 'DEPROV' },
    { key: '70ter', value: true, name: '70 Ter' },
    { key: '19enie', value: true, name: '19 Ñ' },

  ]
    ;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  dataSource: MatTableDataSource<Docente> = new MatTableDataSource();
  pageIndex: number;
  length: number;
  filtros: evaluacionHistoricaExtFilter = new evaluacionHistoricaExtFilter();
  response: any;
  options: PaginationOptions = new PaginationOptions();
  docenteSeleccionadoModal: any;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;

  getDocenteSub: Subscription;

  constructor(
    private evaluacionHistoricaExtendidaService: EvaluacionesHistoricasExtendidaService,
    private errorMensaje: ErrorMensajeService,
    private permisoPara: AutorizacionesService,
  ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.docenteSeleccionadoModal = {
      rutDocente: {
        val: 0,
      }
    }
    this.evaluacionHistoricaExtendidaService.getFiltros().subscribe(
      {
        next: (response: any) => {
          this.comunas = response.comunas;
          this.deprov = response.deprov;
          this.tramo = response.tramos;
          this.beneficios = response.beneficios;
        }

    })
    this.filtros = new evaluacionHistoricaExtFilter();
    this.getDocentes();
  }

  getDocentes(): void {
    this.isLoading = true;
    this.getDocenteSub = this.evaluacionHistoricaExtendidaService.docentesList(this.options, this.filtros.rutDocente,
      this.filtros.rutSostenedor,
      this.filtros.sistemaEducativo,
      this.filtros.dependencia,
      this.filtros.rbd,
      this.filtros.comuna,
      this.filtros.anioPortafolio,
      this.filtros.tramo,
      this.filtros.beneficios,
      this.filtros.deprov).subscribe(
        {
          next: (response: any) => {
            this.response = response.list;
            this.pageIndex = (response.pageNum - 1);
            this.length = response.total;
            this.isLoading = false;
          }, error: (error: any) => {
            this.errorMensaje.show(error, "obtener los docentes").then((result:any) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                this.isLoading = false;
              }
            });
          }
        });
  }


  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getDocentes();
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 10;
    this.getDocentes();
  }

  public limpiar(): void {
    this.filtros = new evaluacionHistoricaExtFilter();;
    this.filtrar();
  }

  public descargarExcel(): void {
    const url = `${Constants.URL_SERVICE.BASE_EVALUACIONES_HISTORICAS_EXTENDIDA + Constants.URL_SERVICE.EVALUACION_HISTORICA_EXTENDIDA.DESCARGAR}`;
    const filename = "lista.csv"
    this.estaDescargandoExcel = true;

    this.evaluacionHistoricaExtendidaService.getFile(url,
      this.filtros.rutDocente,
      this.filtros.rutSostenedor,
      this.filtros.sistemaEducativo,
      this.filtros.dependencia,
      this.filtros.rbd,
      this.filtros.comuna,
      this.filtros.anioPortafolio,
      this.filtros.tramo,
      this.filtros.beneficios,
      this.filtros.deprov
    ).subscribe(
      {
        next: (response: any) => {
          Toast.fire(
            "Descarga de docentes",
            "Descarga finalizada",
            "success"
          );
          this.evaluacionHistoricaExtendidaService.downloadBlob(filename, response);
          this.estaDescargandoExcel = false;
        }, error: (error: any) => {
          this.errorMensaje.show(error, "descargar los docentes");
          this.estaDescargandoExcel = false;
        }
      });
  }

}
