import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/base/services/auth.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import Swal from 'sweetalert2';
import {
  Ripple,
  initTE,
} from "tw-elements";
import { Portafolio2023 } from '../../models/Portafolio2023';
import { PaginationOptions } from '../../models/paginationOptions';
import { PortafolioHistoricoService } from '../../services/portafolio-historico.service';

export interface IPortafolio {
  [index: string]: any;
  idDocente: number;
  idAgrupacion: number;
  idPregunta: number;
  tipo: string;
}

@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.css']
})
export class PortafolioComponent {
  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ["Docente ID","Agrupación ID","Pregunta ID", "Tipo", ];


  filtros: Portafolio2023 = new Portafolio2023();
  inputs: { label: string, attribute: string, type: string }[] = [
    { label: "Docente Id", attribute: "idDocente", type: 'number' },
    { label: "Agrupación Id", attribute: "idAgrupacion", type: 'number' },
    { label: "Pregunta Id", attribute: "idPregunta", type: 'number' },
    { label: "Tipo", attribute: "tipo", type: 'string' },
  ];

  tableColumns:  { label: string, attribute: string,}[] = [
    { label: "Ver Contenido", attribute: "respuesta"},
    { label: "Docente Id", attribute: "idDocente"},
    { label: "Agrupación Id", attribute: "idAgrupacion" },
    { label: "Pregunta Id", attribute: "idPregunta"},
    { label: "Fecha de Creación", attribute: "fechaHora"},
  ];

  selected!: Portafolio2023;
  isLoading: boolean = false;
  options: PaginationOptions = new PaginationOptions();
  dataSource: MatTableDataSource<IPortafolio> = new MatTableDataSource();
  portafolios!: Portafolio2023[];
  pageIndex: number;
  length: number;
  tipos: string[];

  @ViewChild('iframe', {static:false}) iframe!: ElementRef;
  @ViewChild('abrirModalPDF') abrirModalPDF: any;

  mensajeError!: boolean;
  permisosCRUD: boolean = false;

  constructor(
    public portafolioService: PortafolioHistoricoService,
    private router: Router,
    private authService: AuthService,
    private errorMensaje: ErrorMensajeService) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    initTE({ Ripple });
    this.portafolioService.estaDescargandoExcelEmmit.subscribe((estado: boolean) => {
      this.estaDescargandoExcel = estado;
    });
    this.listarTipos();
    this.filtrar();
  }

  listarTipos() {
    this.portafolioService.getTipos().subscribe(
      {
        next: (response: any) => {
          this.tipos = response.tipos;
        },
        error: (error: any ) => this.errorMensaje.show(error, "obtener el listado de tipos").then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) this.isLoading = false;})
      });
  }

  filtrar() {
    this.isLoading = true;
    this.portafolioService.getPortafolios(this.options,this.filtros).subscribe(
      {
        next: (response: any) => {
          this.dataSource = new MatTableDataSource<IPortafolio>(response.list);
          this.portafolios = response.list
            this.pageIndex = (response.pageNum - 1);
            this.length = response.total;
            this.isLoading = false;
        },
        error: (error: any ) => this.errorMensaje.show(error, "obtener los portafolios").then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) this.isLoading = false;})
      });
  }

  onInputChange(value: any, type: string, attributeName: string) {
    if (type == 'number' && value && value instanceof String) {
      value = value.replace(/^\d*[0-9]\d*$/, "");
      value = Number(value)
    }
    this.filtros[attributeName] = value;
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.filtrar();
  }

  verArchivoRespuesta(portafolio: Portafolio2023) {
    this.setUrl(portafolio, portafolio.respuesta);
  }

  mostrarRespuestaEscrita(portafolio: Portafolio2023) {
    this.selected = portafolio;
  }

  setUrl(portafolio: Portafolio2023, archivo: string) {
    this.isLoading = true;
    this.iframe.nativeElement.contentWindow.location.replace("about:blank");
    this.portafolioService.obtenerArchivo(archivo).subscribe({
      next: (response: any) => {
        this.abrirModalPDF.nativeElement.click();
        this.isLoading = false;
        const url: string = response.archivo+"#toolbar=0";
        this.iframe.nativeElement.contentWindow.location.replace(url);
        this.selected = portafolio;
        this.hideModal();
      },
      error: (error: any) => {
        this.isLoading = false;
        this.selected = null;
        this.mensajeError = true;
        this.hideModal();
        this.errorMensaje.show(error, "obtener el documento para el usuario " + portafolio.idDocente);

      }
    });
  }

  hideModal(){
    document.createElement('cerrarModalPDF').click();
  }

  descargar(){}

  limpiar() {
    this.filtros = new Portafolio2023();
    this.filtrar();
  }

}
