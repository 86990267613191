import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Toast } from 'src/app/base/services/toast';
import Swal from 'sweetalert2';
import {
  Chip,
  Ripple,
  initTE,
} from "tw-elements";
import { PaginationOptions } from '../../../base/models/paginationOptions';
import { ErrorMensajeService } from '../../../base/services/errorMensaje.service';
import { InfoPagUsuariosOps } from '../../models/InfoPageUsuariosOps';
import { UsuarioOperaciones } from '../../models/usuarioOperaciones';
import { UsuariosCognitoMdaService } from '../../services/usuariosCognitoMda.service';

@Component({
  selector: 'app-mantenedor-usuarios-mda',
  templateUrl: './usuarios-operaciones-mda.component.html',
  styleUrls: ['./usuarios-operaciones-mda.component.css']
})
export class UsuariosOperacionesMdaComponent {
  isLoading: boolean = true;
  listaUsuariosCognito: InfoPagUsuariosOps = new InfoPagUsuariosOps();
  filtro: UsuarioOperaciones = new UsuarioOperaciones();

  abrirModal: boolean = false;
  modoCrear: boolean = false;

  pageIndex: number = 0;
  length: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: PaginationOptions = new PaginationOptions();

  usuarioEditar: UsuarioOperaciones;
  formularioUsuario: FormGroup;
  tipoUsuario: { name: string, selected: boolean }[] = [];
  roles: { name: string, selected: boolean }[] = [];
  token: string;



  constructor(
    private formBuilder: FormBuilder,
    private cognitoService: UsuariosCognitoMdaService,
    private errorMensaje: ErrorMensajeService) { }


  ngOnInit(): void {
    initTE({ Chip, Ripple });
    this.formularioUsuario = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]]
    });
    this.getListaUsuarios();
  }

  getListaUsuarios() {
    this.isLoading = true;
    this.listaUsuariosCognito = new InfoPagUsuariosOps();
    this.cognitoService.getListaUsuariosCognito(this.options, this.filtro.username)
      .subscribe(
        {
          next: (response: any) => {
            if (response && response.list.length > 0) {
              this.listaUsuariosCognito = response;
              this.length = response.total;
            }
            this.isLoading = false;
          },
          error: (error: any) => {
            this.errorMensaje.show(error, "obtener los usuarios de Cognito");
            this.isLoading = false;
          }
        }
      )
  }

  buscarUsuario() {
    this.isLoading = true;
    this.options.page = 1;
    this.options.size = 10;
    this.getListaUsuarios();

  }

  limpiarFiltro() {
    this.filtro = new UsuarioOperaciones();
    this.buscarUsuario();
  }

  eliminar(usuario: string) {
    Swal.fire({
      title: '¿Desea Eliminar este usuario?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',

    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarUsuario(usuario);
      }
    })
  }

  eliminarUsuario(username: string) {
    this.cognitoService.eliminarUsuario(username)
      .subscribe({
        next: (response: any) => {
          Toast.fire("Usuario eliminado", "El usuario ha sido eliminado", "success");
          this.getListaUsuarios();
        },
        error: (error: any) => this.errorMensaje.show(error, "eliminar el usuario de cognito")
      });
  }

  limpiarformularioUsuario() {
    this.usuarioEditar = null;
    this.tipoUsuario = [];
    this.roles.map((item: any) => this.tipoUsuario.push({ name: item.name, selected: false }));
  }

  abrirFormCreacion() {
    this.limpiarformularioUsuario();
    this.formularioUsuario.controls['username'].setValue(null);
    this.abrirModal = true;
    this.usuarioEditar = null;
    this.modoCrear = true;
    this.tipoUsuario = [];
    this.roles = [{ name: 'OP_MDA', selected: true }];
  }

  agregarForm() {
    this.cognitoService.agregarUsuario(this.formularioUsuario.getRawValue())
      .subscribe(
        {
          next: (response: any) => {
            if (response) {
              Toast.fire("Creación de usuario finalizada", "Se ha creado exitosamente al usuario.", "success");
            }
            this.getListaUsuarios();
          },
          error: (error: any) => this.errorMensaje.show(error, "crear el usuario en Cognito")
        }
      )
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getListaUsuarios();
  }
}
