<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

        <form [formGroup]="form">
          <div class="bg-white p-6 sm:p-6 sm:pb-4">

            <div class="flex justify-end">
              <button (click)="closeModal()"><mat-icon fontIcon="close"></mat-icon></button>
            </div>
            <h3 *ngIf="noticiaSelected == null" class="text-3xl text-center font-semibold leading-6 text-gray-900 mb-8">Agregar noticia</h3>
            <h3 *ngIf="noticiaSelected != null" class="text-3xl text-center font-semibold leading-6 text-gray-900 mb-8">Editar noticia</h3>

            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="orden">Orden</label>
              <input
                class="p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                type="number"
                placeholder="Prioridad"
                formControlName="orden"
                name="orden" />
              <div *ngIf="orden.touched && orden.invalid">
                <p *ngIf="orden.errors?.required" class="text-red-500">Orden es requerido...</p>
                <p *ngIf="orden.errors?.min" class="text-red-500">Solo se aceptan números positivos no decimales...</p>
              </div>
            </div>

            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="mensaje">Mensaje</label>
              <textarea
                rows="4"
                class="p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Contenido de la noticia..."
                formControlName="mensaje"
                name="mensaje">
              </textarea>
              <div *ngIf="mensaje.touched && mensaje.invalid">
                <p *ngIf="mensaje.errors?.required" class="text-red-500">Mensaje es requerido...</p>
              </div>
            </div>

            <mat-form-field class="w-full">
              <mat-label class="text-sm text-gray-700 font-bold">Perfiles</mat-label>
              <mat-select [formControl]="perfiles"  multiple>
                <mat-option *ngFor="let perfil of perfilesStatic" [value]="perfil.id">{{perfil.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="mb-4">
              <input
                type="checkbox"
                class="cursor-pointer w-4 h-4 accent-green-600 bg-gray-100 border-gray-300 rounded dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 focus:ring-green-500 dark:focus:ring-green-600"
                formControlName="mostrar"
                name="mostrar" />
              <label class="text-gray-700 text-sm font-bold mb-2 ml-2" for="mostrar">Deseo mostrar esta noticia en el portal DocenteMás</label>
            </div>

          </div>
          <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button *ngIf="noticiaSelected == null" class="bg-blue-700  text-white font-bold py-2 px-4 rounded disabled:opacity-50 cursor-not-allowed" type="submit" (click)="agregarNoticia()" [disabled] = "form.invalid">Guardar</button>
            <button *ngIf="noticiaSelected != null" class="bg-blue-700  text-white font-bold py-2 px-4 rounded disabled:opacity-50 cursor-not-allowed" type="submit" (click)="editarNoticia()" [disabled] = "form.invalid">Guardar</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
