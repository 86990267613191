export class RevisionFilter {
  rutDocente?: number;
  estadoRevision?: number;
  mostrarArchivados?: boolean
  mostrarAsignadas?: boolean;

  constructor(rutDocente?: number, estadoRevision?: number) {
    this.rutDocente = rutDocente;
    this.estadoRevision = estadoRevision;
    this.mostrarArchivados = false;
    this.mostrarAsignadas = false;
  }
}
