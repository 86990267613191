import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, map } from "rxjs";
import { Noticia } from "../models/noticia";
import { Constants } from "src/app/base/constants/constants";

@Injectable({
  providedIn: 'root'
})
export class MantenedorFeedService {

  constructor(
    private http: HttpClient
  ) { }

  $modal_feed = new EventEmitter<boolean>();

  noticiasList(): Observable<Noticia[]> {
    const url = `${Constants.BASE_MANTENEDOR_FEED}`;
    return this.http.get<Noticia[]>(url).pipe(map(response => response));
  }

  agregarNoticia(noticia: Noticia): Observable<Noticia> {
    const url = `${Constants.BASE_MANTENEDOR_FEED}`;
    return this.http.post<Noticia>(url, noticia).pipe(map(response => response));
  }

  editarNoticia(noticia: Noticia): Observable<Noticia> {
    const url = `${Constants.BASE_MANTENEDOR_FEED}`;
    return this.http.put<Noticia>(url, noticia).pipe(map(response => response));
  }

  eliminarNoticia(id: number){
    const url = `${Constants.BASE_MANTENEDOR_FEED}${id}`;
    return this.http.delete(url);
  }
}
