import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { PaginationOptions } from '../models/paginationOptions';
import { EventoFilter } from '../models/eventoFilter';

@Injectable({
  providedIn: 'root'
})
export class EventosService {
  constructor(
    private http: HttpClient
  ) { }

  consultarEventos(options: PaginationOptions, filtro: EventoFilter): Observable<any> {
    const params = this.parametros(filtro)
    const url = `${Constants.BASE_EVENTOS}/?page=${options.page}&size=${options.size}`;
    return this.http.get(url, {params: params}).pipe(map(response => response));
  }

  consultarFiltros() {
    const url = `${Constants.BASE_EVENTOS}/filtros`;
    return this.http.get(url).pipe(map(response => response));
  }

  getFile( filtro: EventoFilter ): Observable<any> {
    const url = `${Constants.BASE_EVENTOS}/descarga`
    const params = this.parametros(filtro);
    return this.http.get(url, { responseType: 'blob', params: params }).pipe(
      map(response => response)
    );
  }

  downloadBlob(filename: string, blob: any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  parametros(filtro: EventoFilter): HttpParams {
    let params = new HttpParams();
    params = params.set("rut", filtro.rut && filtro.rut > -1 ? filtro.rut : -1);
    params = params.set("idTipoEvento", filtro.idTipoEvento && filtro.idTipoEvento > -1 ? filtro.idTipoEvento : -1);
    params = params.set("idTipoObjetoAfectado", filtro.idTipoObjetoAfectado && filtro.idTipoObjetoAfectado > -1 ? filtro.idTipoObjetoAfectado : -1);
    params = params.set("fechaInicio", filtro.fechaInicio && filtro.fechaInicio!="" ? new Date(filtro.fechaInicio).toISOString().split('T')[0] + " 00:00:00": "")
    params = params.set("fechaFin", filtro.fechaFin && filtro.fechaFin!="" ? new Date(filtro.fechaFin).toISOString().split('T')[0] + " 23:59:59.999" : "")
    
    return params;
  }
}