export const estadosRevisionCorreccion = {
  PROCESANDO_EN_LA_NUBE: {
    id: 0,
    display_text: 'Procesando en la nube',
    display_description: 'El video está siendo procesado en la nube, aún no está disponible para su visualización.',
    display_color: '#A9A9A9',
    icon: 'cloud_sync' // Icono de proceso en nube
  },
  EN_CORRECCION: {
    id: 1,
    display_text: 'En proceso',
    display_description: 'El video está actualmente en proceso de corrección',
    display_color: '#3B82F6', // Azul (Tailwind blue-500)
    icon: 'autorenew' // Icono de proceso
  },
  CORRECTO: {
    id: 2,
    display_text: 'Correcto',
    display_description: 'El video cumple con los estándares sin necesidad de correcciones.',
    display_color: '#10B981', // Verde (Tailwind emerald-500)
    icon: 'check_circle' // Icono de correcto
  },
  CORRECTO_CON_CAMBIOS: {
    id: 3,
    display_text: 'Correcto con cambios',
    display_description: 'El video cumple con los estándares después de realizar algunas correcciones.',
    display_color: '#10B981', // Verde (Tailwind emerald-500)
    icon: 'check_circle_outline' // Icono de correcciones aplicadas
  },
  REVISION_FALLIDA: {
    id: 4,
    display_text: 'Revisión fallida',
    display_description: 'El video no cumple con los estándares y no se puede corregir.',
    display_color: '#EF4444', // Rojo (Tailwind red-500)
    icon: 'cancel' // Icono de fallo
  },
  PENDIENTE: {
    id: 5,
    display_text: 'Pendiente',
    display_description: 'Revisión creada pero sin correcciones.',
    display_color: '#F97316', // Naranja (Tailwind orange-500)
    icon: 'hourglass_empty' // Icono de pendiente
  },
  DESCONOCIDO: {
    id: 6,
    display_text: 'Desconocido',
    display_description: 'La revisión se encuentra en un estado desconocido',
    display_color: '#9CA3AF', // Gris (Tailwind gray-400)
    icon: 'help_outline' // Icono de estado desconocido
  }
};
