import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { Constants } from "src/app/base/constants/constants";
import { PaginationOptions } from "../models/paginationOptions";

@Injectable({
  providedIn: 'root'
})
export class CargasMasivasService {

  constructor(
    private http: HttpClient
  ) { }

  verificarHeaders(archivo: File): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_CARGAS_MASIVAS + Constants.URL_SERVICE.CARGAS_MASIVAS.VERIFICAR_HEADERS}`;
    const formData: FormData = new FormData();
    formData.append('archivoCsv', archivo);
    return this.http.post(url, formData);
  }

  cargarDatos(archivo: File): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_CARGAS_MASIVAS + Constants.URL_SERVICE.CARGAS_MASIVAS.CARGA_DATOS}`;
    const formData: FormData = new FormData();
    formData.append('archivoCsv', archivo);
    return this.http.post(url, formData);
  }

  cargasList(options: PaginationOptions): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_CARGAS_MASIVAS + Constants.URL_SERVICE.CARGAS_MASIVAS.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url);
  }

  cargarDatosTemp(columnasSeleccionadas: Map<string,boolean>): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_CARGAS_MASIVAS + Constants.URL_SERVICE.CARGAS_MASIVAS.CARGA_DATOS_TEMP}`;
    return this.http.post(url,  Object.fromEntries(columnasSeleccionadas));
  }

}
