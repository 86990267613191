import { HttpEventType } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PaginationOptions } from 'src/app/base/models/paginationOptions';
import { AuthService } from 'src/app/base/services/auth.service';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { MantenedorUsuarios } from 'src/app/registros/models/mantenedorUsuarios';
import { MantenedorService } from 'src/app/registros/services/mantenedor.service';
import Swal from 'sweetalert2';
import {
  Ripple,
  initTE,
} from "tw-elements";
import { Toast } from '../../../../base/services/toast';

export interface IMantenedorUsuarios {
  rut: number;
  username: number;
  roles: string[];
  perfiles: string[];
  tipoUsuario: string[];
}

@Component({
  selector: 'app-mantenedor-usuarios-list',
  templateUrl: './mantenedor-usuarios-list.component.html',
  styleUrls: ['./mantenedor-usuarios-list.component.css'],
})
export class MantenedorUsuariosListComponent {
  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["rut", "tipoUsuario", "acciones"];
  widthColumns: string[] = ["width: 10vw;", "width: 75vw;", "width: 15vw"];

  formulario: FormGroup;
  rut: number;
  rol: string[];
  filtros: MantenedorUsuarios = new MantenedorUsuarios();
  inputs: { label: string, attribute: string, type: string }[] = [
    { label: "Rut", attribute: "rut", type: 'number' },
    { label: "Roles", attribute: "roles", type: 'string' },
    { label: "Perfiles", attribute: "perfiles", type: 'string' },
  ];
  isLoading: boolean = false;
  options: PaginationOptions = new PaginationOptions();
  dataSource: MatTableDataSource<IMantenedorUsuarios> = new MatTableDataSource();
  response: any;
  pageIndex: number;
  length: number;

  middleIndex: number;

  agregarUsuarioMantenedor: boolean = false;
  editarUsuarioMantenedor: boolean = false;
  borrarUsuarioMantenedor: boolean = false;
  descagaUsuarioMantenedor: boolean = false;


  usuario: MantenedorUsuarios = new MantenedorUsuarios();
  formularioUsuario: FormGroup;
  @ViewChild("formUsuarios")
  formUsuarios: NgForm;
  usuarioEditar: MantenedorUsuarios;

  roles: string[] = [];

  tipoUsuario: { name: string, selected: boolean }[] = [];

  modoCrear: boolean;
  formularioValido: boolean;
  abrirModal: boolean;

  constructor(
    public mantenedorService: MantenedorService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private permisoPara: AutorizacionesService,
    private errorMensaje: ErrorMensajeService) { }

  ngOnInit(): void {
    initTE({ Ripple });
    this.listarRoles();
    this.formularioUsuario = this.formBuilder.group({
      rut: ['', [Validators.required, Validators.maxLength(8)]],
      rolesSeleccionados: ['', Validators.required]
    });
    this.formulario = this.formBuilder.group({
      rut: ['', [Validators.required, Validators.maxLength(8)]],
      rol: ['', Validators.required]
    });

    this.agregarUsuarioMantenedor = this.permisoPara.agregarUsuarioMantenedor();
    this.editarUsuarioMantenedor = this.permisoPara.editarUsuarioMantenedor();
    this.borrarUsuarioMantenedor = this.permisoPara.borrarUsuarioMantenedor();
    this.descagaUsuarioMantenedor = this.permisoPara.descargaUsuarioMantenedor();

    const acciones = !this.editarUsuarioMantenedor && !this.borrarUsuarioMantenedor;
    if (acciones) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('acciones'));
    }

    this.filtrar();

    this.roles.map((item, index) => this.tipoUsuario.push({ name: item, selected: false }))
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }

  listarRoles() {
    this.roles = [];
    this.mantenedorService.listarRoles()
    .subscribe({
      next: (response:any[]) => {
        response.map(item => this.roles.push(item.nombre));
      },
      error: (error:any) => this.errorMensaje.show(error,"obtener los roles")
    });
    this.middleIndex = this.roles.length / 2;
  }

  checkFormRut(): boolean {
    const rutForm = this.formularioUsuario.controls['rut'].value;
    return (rutForm > 0 && String(rutForm).length > 0 && String(rutForm).length <= 8);
  }

  ngAfterContentChecked(): void {
    const rolesSeleccionados = this.formularioUsuario.controls['rolesSeleccionados'].value;
    if (this.checkFormRut() &&
      rolesSeleccionados && rolesSeleccionados.length > 0) {
      this.formularioValido = true;
    } else {
      this.formularioValido = false;
    }
  }

  containRol(roles: any) {
    return this.authService.containRol(roles);
  }

  onInputChange(value: any, type: string, attributeName: string) {
    if (type == 'number' && value && value instanceof String) {
      value = value.replace(/^\d*[0-9]\d*$/, "");
      value = Number(value)
    }
    this.filtros[attributeName] = value;
  }

  checkRutFilter(): boolean {
    const rutForm = this.formulario.controls['rut'].value;
    return (rutForm > 0 && String(rutForm).length > 0 && String(rutForm).length <= 8);
  }

  onChangeChip(chip: { name: string, selected: boolean }) {
    chip.selected = !chip.selected;
    this.formularioUsuario.controls["rolesSeleccionados"].setValue(this.getSelectedRoles())
  }

  botonFiltrar(){
    this.options.page = 1;
    this.options.size = 10;
    this.filtrar()

  }

  filtrar() {
    this.isLoading = true;
    this.mantenedorService.getUsuariosFiltro(this.options, this.rut, this.rol)
      .subscribe(
        {
          next: response => {
            this.dataSource = new MatTableDataSource<IMantenedorUsuarios>(response.list);
            const rolesUsuario = response.tipoUsuario;
            this.response = response;
            this.pageIndex = (response.pageNum - 1);
            this.length = response.total;
            this.isLoading = false;
          }, error: (error: any) => this.errorMensaje.show(error, "obtener los usuarios").then((result:any) => {
            if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
              this.isLoading = false;
            }
          })

        });
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.filtrar();
  }

  limpiar() {
    this.rut = null;
    this.rol = null;
    this.options.page = 1;
    this.options.size = 10;
    this.filtrar();
  }

  editar(element: MantenedorUsuarios) {
    this.limpiarformularioUsuario();
    this.formularioUsuario.controls['rut'].setValue(null);
    this.formularioUsuario.controls['rolesSeleccionados'].setValue(null);
    this.abrirModal = true;
    this.usuarioEditar = element;
    this.modoCrear = false;
    const rolesUsuario: string[] = element.tipoUsuario;

    this.tipoUsuario.map((rol, index: number) => {
      this.tipoUsuario[index].selected = rolesUsuario.indexOf(rol.name) > -1 ? true : false;
    });
    this.formularioUsuario.controls["rut"].setValue(element.rut);
    this.formularioUsuario.controls["rolesSeleccionados"].setValue(this.getSelectedRoles())
  }

  lanzarModalAgregar() {
    this.usuarioEditar = null;
    this.abrirModal = true;
    this.limpiarformularioUsuario();
    this.formularioUsuario.controls['rut'].setValue(null);
    this.formularioUsuario.controls['rolesSeleccionados'].setValue(null);
  }

  eliminar(rut: number ){
    Swal.fire({
      title: '¿Desea Eliminar este usuario?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result:any) => {
      if (result.isConfirmed) {
        this.eliminarUsuario(rut);
      }
    })
  }

  eliminarUsuario(rut: number) {
    this.mantenedorService.borrarUsuario(rut)
    .subscribe({
      next:(response:any) =>{
        Toast.fire("Usuario eliminado","El usuario ha sido eliminado","success");
        this.limpiar();
      },
      error:(error:any) => this.errorMensaje.show(error,"eliminar el usuario")
    });
  }

  descargarCSVUsuarios() {
    const filename = 'lista-usuarios.csv';
    this.mantenedorService.descargaUsuarios(this.rut, this.rol).subscribe(
      {
        next: (event:any) => {
          if (event.type === HttpEventType.Response) {
            const blob = new Blob([event.body], { type: 'text/csv' });
            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = downloadUrl;
            a.download = 'lista-usuarios.csv';
            a.click();
            URL.revokeObjectURL(downloadUrl);
            a.remove();
            this.estaDescargandoExcel = false;
          }
        },
        error: (error:any) => {
          console.error(error);
          this.estaDescargandoExcel = false;
        },
      }
    );
  }

  descargar() {
    this.estaDescargandoExcel = true;
    this.filtrar();
    this.descargarCSVUsuarios();
  }

  /* Seccion del formulario de agregar/editar */

  limpiarformularioUsuario() {
    this.usuario.rut = null;
    this.tipoUsuario = [];
    this.roles.map((item, index) => this.tipoUsuario.push({ name: item, selected: false }))
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Se requiere tener este dato';
    }

    if (control.hasError('maxlength')) {
      return 'El número ingresado no es permitido';
    }

    return control.hasError('email') ? 'El email no es válido' : '';
  }

  getSelectedRoles(): string[] {
    const selectedRole: string[] = [];
    this.tipoUsuario.map((item) => {
      if (item.selected) selectedRole.push(item.name);
    });

    return selectedRole;
  }

  agregarForm() {
    const rutSelected = this.formularioUsuario.controls['rut'].value;
    const selectedRole = this.formularioUsuario.controls['rolesSeleccionados'].value;
    if (rutSelected == null || rutSelected == 0 || selectedRole && selectedRole.length == 0) {
      Toast.fire(
        "Error al agregar el usuario",
        "Faltan campos por completar en el formularioUsuario: ",
        "error"
      );
      return;
    }

    this.agregarUsuario(rutSelected, selectedRole);
  }

  agregarUsuario(rutSelected:any, roles: String[]) {
    this.mantenedorService.agregarUsuario(Number(rutSelected), roles)
      .subscribe({
        next: (response: any) => {
          Toast.fire(
            "Usuario agregado",
            response.mensaje,
            "success"
          );
          this.filtrar();
        },
        error: (error: any) => {
          this.errorMensaje.show(error, "agregar el usuario.");
        }
      });
  }

  editarForm() {
    const rutEditar = this.formularioUsuario.controls['rut'].value;
    const selectedRole = this.formularioUsuario.controls['rolesSeleccionados'].value;
    if (!this.formularioUsuario.controls['rut'].value ||
      this.formularioUsuario.controls['rolesSeleccionados'].value.length == 0) {
      this.formularioValido = false;
      return;
    }

    this.editarUsuario(rutEditar, selectedRole);
  }

  editarUsuario(rut: any, roles: string[]) {
    this.mantenedorService.editarUsuario(Number(rut), roles)
      .subscribe({
        next: (response: any) => {
          Toast.fire(
            "Usuario ha sido editado",
            response.mensaje,
            "success"
          );
          this.filtrar();
        },
        error: (error: any) => {
          this.errorMensaje.show(error, "editar el usuario.");
        }
      });
  }

  cancelarForm() {
    this.limpiarformularioUsuario();
  }
}
