import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Constants } from 'src/app/base/constants/constants';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Toast } from 'src/app/base/services/toast';
import Swal from 'sweetalert2';
import {
  Ripple,
  Tab,
  initTE,
} from "tw-elements";
import { Portafolio2023 } from '../../models/Portafolio2023';
import { Docente } from '../../models/docente';
import { FichaDocenteFilter } from '../../models/fichaDocenteFilter';
import { PaginationOptions } from '../../models/paginationOptions';
import { PortafolioAvanceService } from '../../services/portafolio-avance.service';
import { PortafolioDocentemasService } from '../../services/portafolio-docentemas.service';
import { ModulosPortafolio } from './models/modulos.model';
import { PreguntasPortafolio } from './models/preguntas.model';
import { Respuesta } from './models/respuesta.model';
import { Sospechosos } from './models/sospechosos.model';


export interface IPortafolio {
  [index: string]: any;
  idDocente: number;
  idAgrupacion: number;
  idPregunta: number;
  tipo: string;
}
@Component({
  selector: 'app-portafolio-docentemas',
  templateUrl: './portafolio-docentemas.component.html',
  styleUrls: ['./portafolio-docentemas.component.css']
})
export class PortafolioDocentemasComponent {
  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ["Docente ID","Agrupación ID","Pregunta ID", "Tipo", ];
  dataSource: MatTableDataSource<Docente> = new MatTableDataSource();
  datos!: any;
  isLoadingFile!: boolean;

  filtros: Portafolio2023 = new Portafolio2023();
  filtrosPFActual: FichaDocenteFilter = new FichaDocenteFilter();
  portafolios!:any;
  mensajeBusquedaSimple!: string;
  inputs: { label: string, attribute: string, type: string }[] = [
    { label: "Rut del Docente", attribute: "idDocente", type: 'number' }
  ];
  tutorial:boolean = true;
  mostrarRespuestaComoHTML: boolean = true;

  optionSelect = [
    { value: 0, label: 'Obtener la lista de Docentes Sospechosos' },
  ];
  selectedOption: number | null = null;
  docenteSospechosoSeleccionado!: number;
  preguntasNulasDocenteSospechoso!: Sospechosos[];
  sinRespuesta: boolean;
  ampliarDescripcionPregunta!: boolean;
  confirmacionPortafolio: boolean;

  onSospechosoChange() {
    if (this.docenteSospechosoSeleccionado) {
      this.tutorial = false;
      this.limpiarFormulariosExceptoRutDocente();
      let preguntasNulas: Sospechosos[] = [];
      this.listaSospechosos.map((item: Sospechosos) => {
        if (item.rutDocente == this.docenteSospechosoSeleccionado) preguntasNulas.push(item);
      });
      this.preguntasNulasDocenteSospechoso = preguntasNulas;
      this.filtrosPFActual.rutDocente = this.docenteSospechosoSeleccionado;
      this.obtenerPortafolioDocente();
    }
  }

  limpiarFormulariosExceptoRutDocente(){
    this.respuestaSeleccionada = null;
    this.preguntaSeleccionada = null;

    this.formulario.setValue({
      idform:'',
      idpreg:'',
      enunciado: '',
      respondidas: false
    });
  }

  verHistorialSospechoso(sosp: Sospechosos) {
    this.limpiarFormulariosExceptoRutDocente();
    this.formulario.setValue({
      idform:sosp.formularioID,
      idpreg:sosp.preguntaID,
      enunciado: '',
      respondidas: true
    });
      this.buscar();
    window.scrollBy({
      top: 300,
      behavior: 'smooth'
    });
  }

  modulo11: ModulosPortafolio;
  modulo12: ModulosPortafolio;
  modulo13: ModulosPortafolio;
  modulo2: ModulosPortafolio;
  modulo3: ModulosPortafolio;
  modulos: ModulosPortafolio[];
  moduloSeleccionado: ModulosPortafolio;
  labelModulos = [
    "Módulo 1 - Planificación",
    "Módulo 1 - Evaluación",
    "Módulo 1 - Reflexión",
    "Módulo 2 ",
    "Módulo 3 ",
  ];
  modulosHTML: {modulo: ModulosPortafolio, colapsado: boolean}[];

  listaSospechosos!: Sospechosos[];
  rutDocenteSospechosos!: number[];

  tableColumns:  { label: string, attribute: string,}[] = [
    { label: "Ver Contenido", attribute: "respuesta"},
    { label: "Docente Id", attribute: "idDocente"},
    { label: "Agrupación Id", attribute: "idAgrupacion" },
    { label: "Pregunta Id", attribute: "idPregunta"},
    { label: "Enunciado Pregunta", attribute: "pregunta"},
    { label: "Fecha de Creación", attribute: "fechaHora"},
  ];

  selected!: Portafolio2023;
  isLoading: boolean = false;
  options: PaginationOptions = new PaginationOptions();
  pageIndex: number;
  length: number;
  tipos: string[];
  tabFiltro: string = 'simple';
  tabRespuesta: string = 'respuestas';
  respuestaSeleccionada!: Respuesta;
  respuestasActual!: Respuesta[];

  @ViewChild('iframe', {static:false}) iframe!: ElementRef;
  @ViewChild('abrirModalPDF') abrirModalPDF: any;

  mensajeError!: boolean;
  permisosCRUD: boolean = false;

  formulario: FormGroup;
  @ViewChild("formPregunta") formRepresentante: NgForm;

  preguntaSeleccionada!: PreguntasPortafolio;
  versionesRespuestas!: Respuesta[];
  cargandoPregunta: boolean;
  errorArchivoRespuesta: string;

  constructor(
    public portafolioService: PortafolioDocentemasService,
    private http: HttpClient,
    private servicioPFActual: PortafolioAvanceService,
    private _formBuilder: FormBuilder,
    private errorMensaje: ErrorMensajeService) { }

  ngAfterViewInit() {
    if ( !this.paginator ) return;
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    initTE({  Tab, Ripple });
    this.portafolioService.estaDescargandoExcelEmmit.subscribe((estado: boolean) => {
      this.estaDescargandoExcel = estado;
    });
    this.formulario = this._formBuilder.group({
      enunciado: ['',],
      idform: ['',],
      idpreg: ['',],
      respondidas: [false,],
    });

    //this.listarTipos();
    //this.filtrar();
  }


  ampliarPregunta(state: boolean) {
    this.ampliarDescripcionPregunta = state;
  }

  buscar() {
    const filtrosPreguntas = this.formulario.getRawValue();
    let modulosFiltrados: ModulosPortafolio[] = [];
    const enunciado = filtrosPreguntas.enunciado.trim() ? filtrosPreguntas.enunciado.trim().toLowerCase() : null;
    const idform = filtrosPreguntas.idform ? filtrosPreguntas.idform : null;
    const idpreg = filtrosPreguntas.idpreg? filtrosPreguntas.idpreg : null;

    if (!enunciado && !idform && !idpreg)
    {
      this.modulos = this.obtenerPreguntasPortafolio();
      modulosFiltrados = this.modulos;
    } else
    {
      this.obtenerPreguntasPortafolio(true).map((modulo: ModulosPortafolio, index: number) => {
        if ( !enunciado && idform && modulo.formularioID === idform ) {
          if ( !idpreg ) modulosFiltrados.push(modulo);
          else {
            let preguntas: PreguntasPortafolio[] = modulo.preguntas;
            if ( idpreg > 0 ){
              preguntas = [];
              modulo.preguntas.map((pregunta: PreguntasPortafolio, i: number) => {
                if (pregunta.preguntaID === idpreg) preguntas.push(pregunta);
              })
            }
            const moduloTemp = modulo;
            moduloTemp.preguntas = preguntas;
            modulosFiltrados.push(moduloTemp);
          }
        }
        if ( enunciado ) {
          let preguntas: PreguntasPortafolio[] = [];
          modulo.preguntas.map((pregunta: PreguntasPortafolio, i: number) => {
            if (pregunta.preguntaTitulo.toLowerCase().includes(enunciado)) preguntas.push(pregunta);
          })
          modulo.preguntas.map((pregunta: PreguntasPortafolio, i: number) => {
            if (pregunta.agrupacionTitulo.toLowerCase().includes(enunciado)) preguntas.push(pregunta);
          })
          modulo.preguntas.map((pregunta: PreguntasPortafolio, i: number) => {
            if (pregunta.preguntaTipo.toLowerCase() == enunciado) preguntas.push(pregunta);
          })
          let moduloTemp = modulo;
            moduloTemp.preguntas = preguntas;
            modulosFiltrados.push(moduloTemp);
        }
      });
    }
    this.modulo11 = modulosFiltrados.find( (item: ModulosPortafolio) => item.modulo == 11  );
    this.modulo12 = modulosFiltrados.find( (item: ModulosPortafolio) => item.modulo == 12  );
    this.modulo13 = modulosFiltrados.find( (item: ModulosPortafolio) => item.modulo == 13  );
    this.modulo2  = modulosFiltrados.find( (item: ModulosPortafolio) => item.modulo == 2  );
    this.modulo3  = modulosFiltrados.find( (item: ModulosPortafolio) => item.modulo == 3  );
    this.modulosHTML = [];
    this.modulosHTML.push({ modulo:this.modulo11, colapsado:false},
      { modulo:this.modulo12, colapsado:false},
      { modulo:this.modulo13, colapsado:false},
      { modulo:this.modulo2, colapsado:false},
      { modulo:this.modulo3, colapsado:false},);
  }

  limitarPreguntaTitulo(preguntaTitulo: string) {
    return preguntaTitulo ? preguntaTitulo.slice(0,100) + "[...]": ''
  }

  asignarTabRespuesta(tab: string) {
    this.tabRespuesta = tab;
  }

  filtro(tab: string) {
    this.tabFiltro = tab;
  }

  seleccionarPregunta( pregunta: PreguntasPortafolio, modulo: ModulosPortafolio ){
    this.preguntaSeleccionada = null;
    this.cargandoPregunta = true;
    this.portafolioService.respuestas(
      this.filtrosPFActual.rutDocente,
      modulo.formularioID,
      pregunta.preguntaID
    )
    .subscribe(
      {
        next: (response: any) => {
          let respuestasActual: Respuesta[] = [];
          let respuestaActualPadre: Respuesta;
          let respuestasVersionadas: Respuesta[] = [];
          if (response && response.length == 0) {
            this.sinRespuesta = true;
            this.respuestaSeleccionada = null;
            this.respuestasActual = null;
            this.versionesRespuestas = null;
            this.cargandoPregunta = false;
            return;
          }
          this.sinRespuesta = false;
          response.map((item:Respuesta, index:number) => {
            if(item.actual) {
              if (!respuestaActualPadre) respuestaActualPadre = item;
              respuestaActualPadre.actual = true;
              respuestasActual.push(respuestaActualPadre);
              respuestaActualPadre = null
            }
            else {
              respuestasVersionadas.push(item);
            }
          })
          this.cargandoPregunta = false;
          const agrupacionTitulo: string = pregunta.agrupacionTitulo.split( "****").join("<br>")
          this.preguntaSeleccionada = pregunta;
          this.preguntaSeleccionada.agrupacionTitulo = agrupacionTitulo;
          this.respuestaSeleccionada = respuestasActual[0];
          this.respuestasActual = respuestasActual;
          this.versionesRespuestas = respuestasVersionadas;
          this.moduloSeleccionado = modulo;
          this.tabRespuesta = 'versiones';

        },
        error: (error:any) => {
          this.cargandoPregunta = false;
        }
      });
  }

  seleccionarVersionRespuesta (item: Respuesta) {
    this.respuestaSeleccionada = item;
    this.tabRespuesta = 'respuestas';
  }

  listarTipos() {
    this.portafolioService.getTipos().subscribe(
      {
        next: (response: any) => {
          this.tipos = response.tipos;
        },
        error: (error: any ) => this.errorMensaje.show(error, "obtener el listado de tipos").then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) this.isLoading = false;})
      });
  }

  postProcesamientoPortafolios(response:any):any {
    const uniqueRutDocentes = [...new Set(response.map((item:any) => item.rutDocente))];
    const result = uniqueRutDocentes.map(rutDocente => {
      const docentesData = response.filter((item:any) => item.rutDocente === rutDocente);
      const uniqueSostenedor = [...new Set(docentesData.map(
        (item:any) => `${item.rutSostenedor}-${item.emailSostenedor}`))]
        .map((item:any) => {
          const [rutSostenedor, emailSostenedor] = item.split('-');
          return { rutSostenedor: Number(rutSostenedor), emailSostenedor: emailSostenedor };
        });

      return {
        rutDocente: rutDocente,
        docente: docentesData[0],
        sostenedores: uniqueSostenedor
      };
    });
    return result;
  }

  obtenerPortafolioDocente(busquedaSimple: boolean = false) {
    this.isLoading = true;
    this.mensajeBusquedaSimple = "";
    if ( busquedaSimple ) this.limpiarFormulariosExceptoRutDocente();
    this.respuestaSeleccionada = null;
    this.preguntaSeleccionada = null;
    this.datos = null;

    const rutDocente:string = this.filtrosPFActual.rutDocente?.toString().trim();
    if (!rutDocente || rutDocente.includes("-") || rutDocente.includes(".")) {
      this.mensajeBusquedaSimple = "Ingrese un rut válido sin puntos ni guión.";
      this.isLoading = false;
      return;
    }

    // Obtención de datos base de docente

    this.servicioPFActual.portafolioList(
      this.options,
      this.filtrosPFActual.rutDocente,
      this.filtrosPFActual.rutSostenedor,
      this.filtrosPFActual.rbd
    ).subscribe(
      { next : (response:any) => {
      this.datos = this.postProcesamientoPortafolios(response.portafolios?.list);
      if (this.datos.length == 1) {
        this.datos = this.datos[0];
        this.obtenerConfirmacion();
      }
      else{
        if (this.datos.length > 1) this.dataSource = new MatTableDataSource<Docente>(this.datos);
        else {
          this.isLoading = false;
          this.datos = null;
          this.mensajeBusquedaSimple = "Sin portafolios para este Rut";
          return;
        }
      }
      this.pageIndex = (response.pageNum - 1);
      this.length = response.total;
      this.isLoading = false;
      }, error: (error: any) => {
        this.isLoading = false;
        this.datos = null;
        this.filtrosPFActual = new FichaDocenteFilter();
        this.errorMensaje.show(error, "obtener los docentes").then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            this.isLoading = false;
          }
        });
      }});

    // Obtención de preguntas de portafolio
    this.obtenerPreguntasPortafolio();
  }

  obtenerPreguntasPortafolio(onlyModules: boolean = false): ModulosPortafolio[] {
    this.cargandoPregunta = true;
    const respondidas: boolean = this.formulario.getRawValue()['respondidas'];
    this.portafolioService.preguntasPortafolio(this.filtrosPFActual.rutDocente, respondidas)
    .subscribe(
      {
        next: (data:  { modulos: ModulosPortafolio[]} ) => {
          this.cargandoPregunta = false;
          if (!data) return;
          const arrayModulos = data.modulos;
          this.modulos = arrayModulos;
          if (onlyModules) return;
          this.modulo11 = arrayModulos.find( (item: ModulosPortafolio) => item.modulo == 11  );
          this.modulo12 = arrayModulos.find( (item: ModulosPortafolio) => item.modulo == 12  );
          this.modulo13 = arrayModulos.find( (item: ModulosPortafolio) => item.modulo == 13  );
          this.modulo2 = arrayModulos.find( (item: ModulosPortafolio) => item.modulo == 2  );
          this.modulo3 = arrayModulos.find( (item: ModulosPortafolio) => item.modulo == 3  );
          this.modulosHTML = [];
          this.modulosHTML.push({ modulo:this.modulo11, colapsado:false},
            { modulo:this.modulo12, colapsado:true},
            { modulo:this.modulo13, colapsado:true},
            { modulo:this.modulo2, colapsado:true},
            { modulo:this.modulo3, colapsado:true},);
        },
        error: (err: any) => {
          this.cargandoPregunta = false;
        },
      }
    );
    return this.modulos;
  }

  toggleCollapse(index: number): void {
    this.modulosHTML.forEach((modulo, i) => {
      if (i === index) {
        modulo.colapsado = !modulo.colapsado;
      } else {
        modulo.colapsado = true; // Colapsar todos los demás módulos
      }
    });
  }

  onInputChange(value: any, type: string, attributeName: string) {
    if (type == 'number' && value && value instanceof String) {
      value = value.replace(/^\d*[0-9]\d*$/, "");
      value = Number(value)
    }
    this.filtros[attributeName] = value;
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.mensajeBusquedaSimple = null;
    this.obtenerPortafolioDocente();
  }

  verArchivoRespuesta(archivo: string) {
    this.setUrl( archivo );
  }

  mostrarRespuestaEscrita(portafolio: Portafolio2023) {
    this.selected = portafolio;
  }

  setUrl( archivo: string ) {
    this.isLoadingFile = true;
    this.iframe.nativeElement.contentWindow.location.replace("about:blank");
    this.portafolioService.obtenerArchivo(archivo).subscribe({
      next: (response: any) => {
        this.abrirModalPDF.nativeElement.click();
        this.isLoadingFile = false;
        const url: string = response.archivo+"#toolbar=0";
        this.abrirModalPDF.nativeElement.click();
        this.isLoading = false;
        this.iframe.nativeElement.contentWindow.location.replace(url);

        this.hideModal();
      },
      error: (error: any) => {
        this.isLoadingFile = false;
        this.selected = null;
        this.mensajeError = true;
        this.hideModal();
        this.errorMensaje.show(error, "obtener el documento para este docente. ");

      }
    });
  }
  onError() {
    this.errorArchivoRespuesta = 'No se encontró el contenido.';
  }

  hideModal(){
    document.createElement('cerrarModalPDF').click();
  }

  descargar(){}

  limpiar() {
    this.filtrosPFActual = new FichaDocenteFilter();
    this.formulario.setValue({idform:'', idpreg:'', enunciado:'', respondidas: false});
    this.mensajeBusquedaSimple = null;
    this.respuestaSeleccionada = null;
    this.preguntaSeleccionada = null;
    this.datos = null;
    this.preguntasNulasDocenteSospechoso = null;
  }

  filtroAvanzado() {
    if (this.selectedOption == 0) this.obtenerDocentesSospechosos();
  }

  obtenerDocentesSospechosos() {
    this.isLoading = true;
    this.limpiar();
    this.docenteSospechosoSeleccionado = null;

    this.portafolioService.getDocentesSospechosos()
    .subscribe({
      next: (response: Sospechosos[]) => {
        this.isLoading = false;
        let ruts: number[] = [];
        response.map((item: Sospechosos, index: number) => {
          if (!ruts.includes(item.rutDocente)) {
            ruts.push(item.rutDocente);
          }
        });
        this.rutDocenteSospechosos = ruts;
        this.listaSospechosos = response;
      },
      error: (err: any) => {
        this.isLoading = false;
      }
    });
  }

  descargarCSV(url:string, filename: string) {
    this.estaDescargandoExcel = true;

    this.portafolioService.descargarSospechosos(
      url
    ).subscribe(
      {
        next: (response: any) => {
          Toast.fire(
            "Descarga de Portafolios Sospechosos",
            "Descarga finalizada",
            "success"
          );
          this.portafolioService.downloadBlob(filename, response);
          this.estaDescargandoExcel = false;
        }, error: (error: any) => {
          this.errorMensaje.show(error, "descargar el avance de portafolio docente");
          this.estaDescargandoExcel = false;
        }
      });
  }

  descargarExcelSospechosos(){
    const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/csv-sospechosos`;
    const filename = "listaSospechosos.csv"
    this.descargarCSV(url, filename);
  }
  descargarExcelRespuestasPortafolioDocente(){
    const rutDocente = this.filtrosPFActual.rutDocente;
    const url = `${Constants.URL_SERVICE.BASE_PORTAFOLIO_ACTUAL}/csv-portafolio-respuestas/rutDocente/${rutDocente}`;
    const filename = `portafolioDocente_${rutDocente}.csv`
    this.descargarCSV(url, filename);
  }

  syncScroll(event: Event): void {
    const preguntasDiv = event.target as HTMLElement;
    const respuestasDiv = document.getElementById('respuestas');

    if (respuestasDiv) {
      respuestasDiv.scrollTop = preguntasDiv.scrollTop;
    }
  }

  respuestaHTML(state: boolean) {
    this.mostrarRespuestaComoHTML = state;
  }

  obtenerConfirmacion() {
    this.confirmacionPortafolio = false;
    this.portafolioService.obtenerConfirmacion(this.filtrosPFActual.rutDocente)
    .subscribe({
      next: (response: boolean | null) => {
        if (response == null) return;
        this.confirmacionPortafolio = response;
      },
      error: (err: any) => {

      }
    });
  }

}
