import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { Usuario } from '../../models/usuario';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  authFail!: boolean;
  msjeError!: string;
  showRecMsje!: boolean;
  usuario: Usuario;
  loading!: boolean;
  cognitoUser: CognitoUser;
  showInputForm: boolean;

  constructor(
    private router: Router,
  ) {
    this.usuario = new Usuario();
  }

  ngOnInit(): void {
    this.showInputForm = false;
  }


  sendMail() {
    this.loading = true;
    this.msjeError = "";
    if (!this.usuario.email) {
      this.loading = false;
      this.authFail = true;
      this.msjeError = "Falta su correo.";
      return;
    }

    var poolData = {
      UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
      ClientId: environment.cognitoClietId, // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    // datos del user
    var userData = {
      Username: this.usuario.email,
      Pool: userPool
    }
    this.cognitoUser = new CognitoUser(userData);
    const router: Router = this.router;
    this.cognitoUser.forgotPassword({
      onSuccess: (result) => {
        this.showRecMsje = true;
        this.loading = false;
        this.authFail = false;
        this.msjeError = "";
        router.navigate(['login']);
      },
      onFailure: (err: any) => {
        this.showRecMsje = false;
        this.loading = false;
        this.authFail = true;
        this.msjeError = "Se produjo un error al recuperar la cuenta.";
      },
      inputVerificationCode: () => {
        this.loading = false;
        this.showInputVerification();
      }
    });

  }

  showInputVerification() {
    this.loading = false;
    this.authFail = false;
    this.showRecMsje = true;
    this.showInputForm = true;
  }

  cambiar() {
    this.msjeError = "";
    this.showRecMsje = false;
    if (!this.usuario.codigo) this.msjeError = "Falta su código de acceso";
    if (!this.usuario.password) {
      if (this.msjeError) this.msjeError += " y su nueva contraseña."
      else this.msjeError = "Falta su nueva contraseña."
    }
    if (!this.usuario.codigo || !this.usuario.password) {
      this.authFail = true;
      return;
    }
    const router: Router = this.router;
    this.cognitoUser.confirmPassword(this.usuario.codigo, this.usuario.password, {
      onSuccess: (result) => {
        router.navigate(['login']);
        this.authFail = false;
      },
      onFailure:  (err: any) => {
        this.authFail = true;
        this.msjeError = "Estamos presentando errores en la plataforma";
      },
    });
  }
}
