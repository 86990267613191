import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../../../base/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class RubricaRevisionService {
  private baseUrl = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/rubrica`;

  constructor(private http: HttpClient) {}

  obtenerRubricaPorRutDocente(rutDocente: number): Observable<any> {
    const url = `${this.baseUrl}/docente/${rutDocente}`;
    return this.http.get(url);
  }

  guardarRespuestas(rutDocente: number, respuestas: { idRubrica: number; idRespuesta: number }[]): Observable<any> {
    const requestBody = {
      respuestas: respuestas
    };

    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/rubrica/docente/${rutDocente}`;
    return this.http.put(url, requestBody);
  }
}
