<mat-card  class="p-4 lg:h-screen xl:h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
    <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO style="flex-direction: row">
        <div class="flex flex-col gap-4 px-2">
          <p class="text-blue-950 text-2xl font-bold">Estado Docente Histórico</p>
          <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
            <div class="flex flex-row gap-1 py-2">
              <mat-icon>filter_list</mat-icon>
              <p class="text-lg font-bold">Filtros</p>
            </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label>RUT Docente</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutDocente" name="rutDocente" />
            </mat-form-field>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
            <mat-form-field class="fullWidth">
              <mat-label>RUT Sostenedor</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutSostenedor" name="rutSostenedor" />
            </mat-form-field>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12 col-xl-2">
            <mat-form-field class="fullWidth">
              <mat-label>RBD</mat-label>
              <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345" class="fullWidth" [(ngModel)]="filtros.rbd" />

            </mat-form-field>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12 col-xl-2">
            <mat-form-field class="fullWidth">
              <mat-label>Año Convocatoria</mat-label>
              <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="1234" class="fullWidth" [(ngModel)]="filtros.anioConvocatoria" />

            </mat-form-field>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12 col-xl-2">
            <mat-form-field class="fullWidth">
              <mat-label class="fullWidth">Estado de Inscripción</mat-label>

              <mat-select [(ngModel)]="filtros.estadoRegistro" placeholder="Estado de Inscripción">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let item of estadosInscripcion" [value]="item">{{item}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>

        </div>

        <div class="d-flex">
          <div class="float-left d-flex  align-self-start ">
            <mat-card-actions class="d-flex row">

                <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>

              <button
                  matTooltip="Descarga esta tabla completa"
                  type="button"
                  data-te-ripple-init
                  (click)="descargarExcel()"
                  data-te-ripple-color="light"
                  class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                  <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                  <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                  <div *ngIf="!estaDescargandoExcel" class="text-[14px]">Descargar</div>
                  <div *ngIf="estaDescargandoExcel" class="text-[14px]">Descargando</div>
              </button>
              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" class="pr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                Limpiar
              </button>
            </mat-card-actions>
          </div>
          <div class="float-right ms-auto my-auto d-flex justify-content-end">
            <mat-card-actions>
              <div class="dropdown">
                <button color="primary"
                data-te-ripple-color="light"
                type="button"
                data-te-ripple-init
                class="flex w-60 justify-items-center justify-center text-center items-center rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                  id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style="float: right;">
                  <svg xmlns="http://www.w3.org/2000/svg" class="px-2" width="16" height="16" fill="currentColor" class="bi bi-layout-three-columns" viewBox="0 0 16 16"> <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5H5V1H1.5zM10 15V1H6v14h4zm1 0h3.5a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H11v14z"/> </svg>
                  <p class="px-1"></p> Editar columnas <p class="px-1"></p>
                  <svg xmlns="http://www.w3.org/2000/svg" class="px-2"width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <div class="container" style="width: 400px;">
                    <div class="row">
                      <div class="col" style="flex-direction: row;">
                        <div *ngFor="let item of toggleColumns" class="form-check">
                          <input [(ngModel)]="item.value" checked class="form-check-input" type="checkbox"
                            [id]="item.key">
                          <label class="form-check-label" [for]="item.key">{{item.name}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-actions>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
  </mat-paginator>
  <div class="h-auto overflow-x-scroll rounded-2xl border border-gray-500 mx-2 px-2 "
  matTooltip="Doble click izq.: Inicio Tabla | Click derecho: Fin de Tabla | Arrastrar mouse mueve la tabla">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50">
      <thead class="bg-blue-700 cursor-grab  smooth-scroll sticky top-0" (mousedown)="startDrag($event)"
      (mouseup)="stopDrag($event)" (mousemove)="onDrag($event)" (dblclick)="onLeftDblClick($event)"
      (contextmenu)="onRightDblClick($event)" >
        <tr>
          <th scope="col" class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{nameDisplayedColumns[0]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{nameDisplayedColumns[1]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[0].value">{{nameDisplayedColumns[2]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[1].value">{{nameDisplayedColumns[3]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[2].value">{{nameDisplayedColumns[4]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[3].value">{{nameDisplayedColumns[5]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[4].value">{{nameDisplayedColumns[6]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[5].value">{{nameDisplayedColumns[7]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{nameDisplayedColumns[8]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{nameDisplayedColumns[9]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[6].value">{{nameDisplayedColumns[10]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[7].value">{{nameDisplayedColumns[11]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[8].value">{{nameDisplayedColumns[12]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " >{{nameDisplayedColumns[13]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">{{nameDisplayedColumns[14]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[9].value">{{nameDisplayedColumns[15]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[10].value">{{nameDisplayedColumns[16]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[11].value">{{nameDisplayedColumns[17]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[12].value">{{nameDisplayedColumns[18]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[13].value">{{nameDisplayedColumns[19]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[14].value">{{nameDisplayedColumns[20]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[15].value">{{nameDisplayedColumns[21]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[16].value">{{nameDisplayedColumns[22]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[17].value">{{nameDisplayedColumns[23]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[18].value">{{nameDisplayedColumns[24]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[19].value">{{nameDisplayedColumns[25]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[20].value">{{nameDisplayedColumns[26]}}</th>
          <th scope="col" class="rounded-tr-2xl pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="this.toggleColumns[21].value">{{nameDisplayedColumns[27]}}</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr class="min-w-full" *ngFor="let element of response">
          <th scope="row" class="negrita-tabla">{{element.agno}}</th>
          <th scope="row" class="negrita-tabla">
            <div style="min-width: 130px;">
              <button data-bs-toggle="modal" data-bs-target="#modalInformacionDelDocente"
                class="inline-block rounded-full border-2 border-primary px-6 pb-[6px] pt-2 text-[14px] font-bold uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                (click)="obtenerDocente(this.element)">
                {{ element.rutDocente +'-'+ element.dvDocente}}</button></div>
          </th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[0].value">{{element.nombres}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[1].value">{{ element.primerApellido }} {{
            element.segundoApellido }}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[2].value">{{element.email}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[3].value">{{element.numeroContacto1}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[4].value">{{element.numeroContacto2}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[5].value">{{element.nombreEstablecimiento}}
          </th>
          <th scope="row" class="negrita-tabla">{{element.rbd}}</th>
          <th scope="row" class="negrita-tabla">{{element.rutSostenedor}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[6].value">{{element.nombreSostenedor}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[7].value">{{element.estadoRegistro}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[8].value">{{element.debeRendir}}</th>
          <th scope="row" class="negrita-tabla">{{element.codNivel}}</th>
          <th scope="row" class="negrita-tabla">{{element.codSubsector}}</th>
          <th scope="row" class="negrita-tabla truncate-cell" *ngIf="this.toggleColumns[9].value">{{element.motivoNoInscripcion}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[10].value">{{element.fechaInscripcion | date:'d/M/yy h:mm'}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[11].value">{{element.fechaUltMod | date:'d/M/yy h:mm'}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[12].value">{{element.estadoValidacion}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[13].value">{{element.fechaValidacion | date:'d/M/yy h:mm'}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[14].value">{{element.solicitaCambioSubsector == true ? 'Si': element.solicitaCambioSubsector == false ? 'No': element.solicitaCambioSubsector}}
          </th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[15].value">
            {{element.estadoConfirmacionSubsector}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[16].value">{{element.nivelSolicitado}}</th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[17].value">{{element.subsectorSolicitado}}
          </th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[18].value">
            <div>
              <span *ngIf="element.beneficio70ter != null" class="badge rounded-pill me-2" [ngClass]="{'text-bg-success' : element.beneficio70ter == true,
            'text-bg-secondary' : element.beneficio70ter == false}">70Ter
              </span>
              <span *ngIf=" element.beneficio19n != null" class="badge rounded-pill me-2" [ngClass]="{'text-bg-success' : element.beneficio19n == true,
                          'text-bg-secondary' : element.beneficio19n == false}">19Ñ
              </span>
              <span *ngIf="element.portafolio2022 != null" class="badge rounded-pill me-2" [ngClass]="{'text-bg-success' : element.portafolio2022 == true,
                          'text-bg-secondary' : element.portafolio2022 == false}">Clase Grabada 2022
              </span>
              <span *ngIf="element.convalidacionPortafolio != null" class="badge rounded-pill me-2" [ngClass]="{'text-bg-success' : element.convalidacionPortafolio == true,
                          'text-bg-secondary' : element.convalidacionPortafolio == false}">Convalidación Portafolio
              </span>
              <span *ngIf="element.tramosVoluntarios != null" class="badge rounded-pill me-2" [ngClass]="{'text-bg-success' : element.tramosVoluntarios == true,
                          'text-bg-secondary' : element.tramosVoluntarios == false}">Tramos Voluntarios
              </span>
            </div>
          </th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[19].value">{{element.estadoEximirSuspender}}
          </th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[20].value">{{element.motivoSuspenderEximir}}
          </th>
          <th scope="row" class="negrita-tabla" *ngIf="this.toggleColumns[21].value">{{element.informeDocente == true ? 'Si': element.informeDocente == false ? 'No': element.informeDocente}}</th>


        </tr>
      </tbody>
    </table>
  </div>

</mat-card>

<!-- Modal -->

<div class="modal fade" id="modalInformacionDelDocente" tabindex="-1" aria-labelledby="modalInformacionDelDocenteLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="modalInformacionDelDocenteLabel">Información del docente</h1>
        <button type="button" data-bs-dismiss="modal" aria-label="Close"
          class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body" *ngIf="mostrarInfoDocente && acordeonesInfoDocente.length>0">

          <acordeon-tw
            [accordeonParent]="acordeonesInfoDocenteName"
            [acordeones]="acordeonesInfoDocente"></acordeon-tw>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mat-raised-button class="col-1 mx-2 ajustar" color="primary" *ngIf="permisoDescargarInforme"
          (click)="descargarInformeDocente()">
          <fa-icon [icon]="faExcel"></fa-icon>
          Descargar Informe</button>
        <button type="button"mat-raised-button class="col-1 mx-2 ajustar" color="secondary" data-bs-dismiss="modal">Cerrar</button>

      </div>
    </div>
  </div>
</div>

<ng-template #infoDatosPersonales
  let-personalDataSection="personalDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <div class="" *ngIf="personalDataSection">
    <table style="width:100%;" role="table" aria-label="tabla info datos personales">
      <tr *ngFor="let item of personalDataSection">
        <th>
          {{item.value}}
        </th>
        <td *ngIf="item.key != 'apellidos'" style="width:fit-content">{{docenteSeleccionadoModal[item.key]}}
        </td>
        <td *ngIf="item.key == 'apellidos'" style="width:fit-content">
          {{docenteSeleccionadoModal['primerApellido']}} {{docenteSeleccionadoModal['segundoApellido']}}
        </td>
      </tr>
    </table>
  </div>
</ng-template>

<ng-template #infoDatosEstablecimiento
  let-establishmentDataSection="establishmentDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla establecimiento">
    <tr *ngFor="let item of establishmentDataSection">
      <th>
        {{item.value}}
      </th>
      <td style="width:fit-content">{{docenteSeleccionadoModal[item.key]}}</td>
    </tr>
  </table>
</ng-template>

<ng-template #infoDatosInscripcion
  let-inscriptionDataSection="inscriptionDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla inscripcion">
    <tr *ngFor="let item of inscriptionDataSection">
      <th>
        {{item.value}}
      </th>
      <td *ngIf="esFecha(item.key)" style="width:fit-content">{{docenteSeleccionadoModal[item.key] |
        date:'dd-MM-y'}}</td>
      <td *ngIf="!esFecha(item.key)" style="width:fit-content">{{docenteSeleccionadoModal[item.key]}}</td>
    </tr>
  </table>
</ng-template>

<ng-template #infoValidacion
  let-validationDataSection = "validationDataSection"
  let-esFecha = "esFecha"
  let-docenteSeleccionadoModal = "docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla validacion">
    <tr *ngFor="let item of validationDataSection">
      <th>
        {{item.value}}
      </th>

      <td
        *ngIf="item.key != 'beneficios' && item.key != 'rindePortafolio' && item.key != 'solicitaCambioSubsector' && !esFecha(item.key)"
        style="width:fit-content">{{docenteSeleccionadoModal[item.key]}}</td>
      <td *ngIf="item.key == 'solicitaCambioSubsector'" style="width:fit-content">{{
        (docenteSeleccionadoModal['estadoValidacion'] | lowercase) == 'no validado' ? '' :
        docenteSeleccionadoModal[item.key] ? 'Sí' : 'No'}}</td>
      <td *ngIf="esFecha(item.key)" style="width:fit-content">{{docenteSeleccionadoModal[item.key] |
        date:'dd-MM-y'}}</td>
      <td *ngIf="item.key == 'beneficios'" style="width:fit-content">
        <span *ngIf="docenteSeleccionadoModal['beneficio70ter'] != null"
          class="badge rounded-pill me-2 mb-1" [ngClass]="{'text-bg-success' : docenteSeleccionadoModal['beneficio70ter'] == true,
                      'text-bg-secondary' : docenteSeleccionadoModal['beneficio70ter'] == false}">70Ter
        </span>
        <span *ngIf=" docenteSeleccionadoModal['beneficio19n'] != null"
          class="badge rounded-pill me-2 mb-1" [ngClass]="{'text-bg-success' : docenteSeleccionadoModal['beneficio19n'] == true,
                      'text-bg-secondary' : docenteSeleccionadoModal['beneficio19n'] == false}">19Ñ
        </span>
        <span *ngIf="docenteSeleccionadoModal['portafolio2022'] != null"
          class="badge rounded-pill me-2 mb-1" [ngClass]="{'text-bg-success' : docenteSeleccionadoModal['portafolio2022'] == true,
                      'text-bg-secondary' : docenteSeleccionadoModal['portafolio2022'] == false}">Clase
          Grabada 2022
        </span>
        <span *ngIf="docenteSeleccionadoModal['convalidacionPortafolio'] != null"
          class="badge rounded-pill me-2 mb-1"
          [ngClass]="{'text-bg-success' : docenteSeleccionadoModal['convalidacionPortafolio'] == true,
                      'text-bg-secondary' : docenteSeleccionadoModal['convalidacionPortafolio'] == false}">Convalidación Portafolio
        </span>
        <span *ngIf="docenteSeleccionadoModal['tramosVoluntarios'] != null"
          class="badge rounded-pill me-2 mb-1"
          [ngClass]="{'text-bg-success' : docenteSeleccionadoModal['tramosVoluntarios'] == true,
                      'text-bg-secondary' : docenteSeleccionadoModal['tramosVoluntarios'] == false}">Tramos Voluntarios
        </span>
      </td>
    </tr>
  </table>
</ng-template>

<ng-template #infoEximirSuspender
  let-exemptSuspendDataSection="exemptSuspendDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal"
  >
  <table style="width:100%;" role="table" aria-label="tabla exiir suspender">
    <tr *ngFor="let item of exemptSuspendDataSection">
      <th>
        {{item.value}}
      </th>
      <td style="width:fit-content">{{docenteSeleccionadoModal[item.key]}}</td>
    </tr>
  </table>
</ng-template>


<ng-template #infoInformes
  let-informeDataSection="informeDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla informes">
    <tr *ngFor="let item of informeDataSection">
      <th>
        {{item.value}}
      </th>
      <td style="width:fit-content">{{docenteSeleccionadoModal[item.key]? "Si": "No"}}</td>
    </tr>
  </table>
</ng-template>
