import { Component, ElementRef, Input, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import {
  Collapse,
  initTE,
} from "tw-elements";

@Component({
  selector: 'acordeon-tw',
  templateUrl: './acordeon-tw.component.html'
})
export class AcordeonTWComponent {
  accordeonParentHash!: string;
  estaMenuAbierto!: boolean;
  activeMenu!: string;
  @ViewChild('scroll') scroll: ElementRef;
  @Input() accordeonParent!: string;
  @Input() acordeones!: {
    content: TemplateRef<any>,
    id: string,
    title: string,
    target: string,
    showFirst: boolean,
    keepOpen: boolean,
    context: any,
    injector: any
  }[];

  ngOnInit(): void {
    initTE({ Collapse });
    this.accordeonParentHash = "#" + this.accordeonParent;
  }

  scrollToBottom() {
    try {
      const el = this.scroll.nativeElement;
      const max = Math.max(0, el.scrollHeight - el.offsetHeight);
      this.scroll.nativeElement.scrollTop = max;
    } catch (err) { }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('acordeones')) {
      this.activeMenu = "";
      if (this.acordeones[0]?.showFirst) this.activeMenu = this.acordeones[0].id;
    }
  }

  clickAcordeon(index: number, idActive: string) {
    this.acordeones[index].showFirst = !this.acordeones[index].showFirst;
    this.activeMenu = this.acordeones[index].showFirst ? idActive : "";
    if (this.activeMenu) this.scrollToBottom();
  }

}
