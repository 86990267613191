import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { PaginationOptions } from "../../models/paginationOptions";
import { HistorialLlamadasFilter } from "../../models/historialLlamadasFilter";
import { HistorialLlamadasService } from "../../services/historial-llamadas.service";
import { ErrorMensajeService } from "src/app/base/services/errorMensaje.service";
import Swal from "sweetalert2";
import {
  Input,
  initTE,
  Select,
  Datetimepicker
} from "tw-elements";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MatTableDataSource } from "@angular/material/table";
import { Historial } from "../../models/historialLlamadas";

@Component({
    selector: 'app-historial-llamadas',
    templateUrl: './historial-llamadas.component.html',
    styleUrls: ['./historial-llamadas.component.css']
  })

  export class HistorialLlamadasComponent implements OnInit{
    faSpinner = faSpinner;
    pageIndex: number;
    length: number;
    options: PaginationOptions = new PaginationOptions();
    isLoading = false;
    response: any;
    descargandoCsv!: boolean;
    filtros: HistorialLlamadasFilter = new HistorialLlamadasFilter();
    dataSource: MatTableDataSource<Historial> = new MatTableDataSource();
    today = new Date(); 
    minDate = new Date("Saturday, 1 January 2000 0:00:00");

    constructor(
      private HistorialLlamadasService: HistorialLlamadasService,
      private errorMensaje: ErrorMensajeService,
    ) { }

    pageChanged(event: PageEvent) {
      this.options.page = event != null ? (event.pageIndex + 1) : 1;
      this.options.size = event != null ? event.pageSize : 10;
      this.getHistorial();
    }

    ngOnInit(): void {
      this.filtros = new HistorialLlamadasFilter();
      this.getHistorial();
    }

    getHistorial(): void {
      this.isLoading = true;
      this.HistorialLlamadasService.historialList(this.options, this.filtros).subscribe(
        { next : (response:any) => {
        this.dataSource = new MatTableDataSource<Historial>(response.list);
        this.response = response.list;
        this.pageIndex = (response.pageNum - 1);
        this.length = response.total;
        this.isLoading = false;
        }, error: (error: any) => {
          this.isLoading = false;
          this.errorMensaje.show(error, "obtener los docentes").then((result:any) => {
            if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
              this.isLoading = false;
            }
          });
        }});

    }

    public filtrar(): void {
      this.options.page = 1;
      this.options.size = 10;
      this.getHistorial();
    }

    public limpiar(): void {
      this.filtros = new HistorialLlamadasFilter();
      this.filtrar();
    }

    public descargarCsv(rut: string): void {
      this.descargandoCsv = true;
      const filename = "listaHistorialLlamadas.csv";
      this.HistorialLlamadasService.getFile(this.filtros).subscribe((response: any) => {
        this.HistorialLlamadasService.downloadBlob(filename, response);
        this.descargandoCsv = false;
      })
    }
  }
