import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';

import { PaginationOptions } from '../models/paginationOptions';
import { SostenedorFilter } from '../models/sostenedorFilter';

@Injectable({
  providedIn: 'root'
})
export class SostenedorService {

  constructor(
    private http: HttpClient
  ) { }

  sostenedoresList(options: PaginationOptions, filter: SostenedorFilter): Observable<any> {
    let params = this.obtenerParamsSostenedor(filter.rutSostenedor, filter.nombreSostenedor, filter.idSector);
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.SOSTENEDOR.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url,{params}).pipe(map(response => response));
  }

  obtenerParamsSostenedor(rutSostenedor: any, nombreSostenedor: any, idSector: any): HttpParams {
    let params = new HttpParams();

    if (rutSostenedor && rutSostenedor > 0) {
      params = params.set('rutSostenedor', rutSostenedor);
    }
    if (idSector && idSector > 0) {
      params = params.set('idSector', idSector);
    }
    if (nombreSostenedor && nombreSostenedor.trim() != "") {
      params = params.set('nombreSostenedor', nombreSostenedor.trim());
    }
    return params;
  }

}
