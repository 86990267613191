export class SD {
  codigoQR!: string;
  rutDocente!: number;
  comentario!: string;
  idBulto!: number;
  idCaja!: number;
  folioCaja!: string;
  folioBulto!: string; //nuevo, sin alterar a la bd
  rbd!: number;
  nombreDocente!: string;
  bultos!: string;
}
