import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { Encargado } from '../models/encargado';
import { EncargadoEstablecimiento } from '../models/encargadoEstablecimiento';
import { EncargadoEvaluacionFilter } from '../models/encargadoEvaluacionFilter';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class EncargadoEvaluacionService {
  estaDescargandoExcel = false;
  constructor(
    private http: HttpClient
  ) { }

  encargadosEvaluacionList(options: PaginationOptions, filter: EncargadoEvaluacionFilter): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.ENCARGADOS_EVALUACION.LISTAR}?page=${options.page}&size=${options.size}`;
    let params = this.obtenerParams(filter.rutSostenedor, filter.rutEncargadoEvaluacion,filter.rbd,
      filter.dependencia,filter.region, filter.estadoAcceso);
    return this.http.get(url, {params: params}).pipe(map(response => response));
  }

  getEncargado(rutEncargado: number, rutSostenedor: number) {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.ENCARGADOS_EVALUACION.FIND}${rutEncargado}/sostenedor/${rutSostenedor}`;
    return this.http.get(url);
  }

  update(encargado: Encargado, rutSostenedorOriginal: number) {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION}${Constants.URL_SERVICE.ENCARGADOS_EVALUACION.UPDATE}/${encargado.rut}/sostenedor/${rutSostenedorOriginal}`;
    return this.http.put<Encargado>(url,encargado);
  }

  save(encargado: Encargado) {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.ENCARGADOS_EVALUACION.UPDATE}`;
    return this.http.post(url, encargado);
  }

  delete(encargado: EncargadoEstablecimiento) {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.ENCARGADOS_EVALUACION.DELETE}${encargado.rut}/sostenedor/${encargado.rutSostenedor}`;
    return this.http.delete(url);
  }

  getFile(url:string, filter: EncargadoEvaluacionFilter): Observable<any>{
    let params = this.obtenerParams(filter.rutSostenedor, filter.rutEncargadoEvaluacion,filter.rbd,
      filter.dependencia,filter.region, filter.estadoAcceso);

    return this.http.get(url, { responseType: 'blob', params:params }).pipe(
      catchError(this.handleError)
    );
  }

  downloadBlob(filename:string, blob:any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
        dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }

  handleError(error:any) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    return throwError(errorMessage);
  }

  obtenerParams(rutSostenedor:any,rutEncargadoEvaluacion:any,rbd:any, dependencia:any, region:any,
    estadoAcceso:any): HttpParams {
    let params = new HttpParams();
      if (rutSostenedor && rutSostenedor > 0) {
        params = params.set('rutSostenedor',rutSostenedor);
      }
      if (rutEncargadoEvaluacion && rutEncargadoEvaluacion > 0) {
        params = params.set('rutEncargadoEvaluacion',rutEncargadoEvaluacion);
      }
      if (rbd && rbd > 0) {
        params = params.set('rbd',rbd);
      }
      if (dependencia && dependencia != null) {
        params = params.set('dependencia',dependencia);
      }
      if (region && region != null) {
        params = params.set('region',region);
      }
      if (estadoAcceso && estadoAcceso != null) {
        params = params.set('estadoAcceso',estadoAcceso);
      }
      return params;
  }
}
