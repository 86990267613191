import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { EstablecimientoFilter } from '../models/establecimientoFilter';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class DependenciaService {

  constructor(
    private http: HttpClient
  ) { }

  dependenciaList(): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.DEPENDENCIA.LISTAR}`;
    return this.http.get(url).pipe(map(response => response));
  }

}
