import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Constants } from '../../base/constants/constants';
import { Portafolio2023 } from '../models/Portafolio2023';
import { PaginationOptions } from '../models/paginationOptions';

@Injectable({
  providedIn: 'root'
})
export class PortafolioHistoricoService {
  estaDescargandoExcelEmmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private http: HttpClient
  ) { }

  emitirEstadoDescargaPortafolio(estado: boolean) {
    this.estaDescargandoExcelEmmit.emit(estado);
  }

  getTipos():  Observable<any> {
    const url = `${Constants.BASE_PORTAFOLIO}/tipos`;
    return this.http.get(url).pipe(map((response: any) => response));
  }

  getPortafolios(options: PaginationOptions, filter: Portafolio2023):
    Observable<any> {
    const url = `${Constants.BASE_PORTAFOLIO}/`;
    let params =  this.obtenerParams(filter.idAgrupacion, filter.idDocente, filter.idPregunta, filter.tipo);
    params = params
      .set('page', options.page)
      .set('size', options.size)
    return this.http.get(url, { params: params }).pipe(
      map((response: any) => response));
  }

  agregarPortafolio(representante: any, tipo: number): Observable<any> {
    representante.idTipo = tipo;
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.AGREGAR}`;
    return this.http.post(url, representante).pipe(map(response => response));
  }

  editarPortafolio(representante: any, tipo: number): Observable<any> {
    representante.idTipo = tipo;
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.EDITAR}`;
    return this.http.put(url, representante).pipe(map(response => response));
  }

  deletePortafolio(rutRepresentante: number, rutEmpresa: number) {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.ELIMINAR}/${rutRepresentante}/${rutEmpresa}`;
    return this.http.delete(url);
  }

  handleError(error: any) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    return throwError(errorMessage);
  }

  descargarPortafolio(filtros: Portafolio2023): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.DESCARGAR}}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      catchError(this.handleError),
    );

  }

  obtenerParams(idAgrupacion:number, idDocente:number, idPregunta:number, tipo: string): HttpParams {
    let params = new HttpParams();

    if (idAgrupacion && idAgrupacion > -1) {
      params = params.set('idAgrupacion', idAgrupacion);
    }
    if (idDocente && idDocente > -1) {
      params = params.set('idDocente', idDocente);
    }
    if (idPregunta && idPregunta> -1) {
      params = params.set('idPregunta', idPregunta);
    }
    if (tipo && tipo.trim() != "") {
      params = params.set('tipo', tipo.trim());
    }
    return params;
  }

  descargaPortafolio( filter: Portafolio2023 ): Observable<any> {
    this.emitirEstadoDescargaPortafolio(true);
    const params = this.obtenerParams(filter.idAgrupacion, filter.idDocente, filter.idPregunta, filter.tipo);
    const url = `${Constants.URL_SERVICE.BASE_ENCARGADO_EVALUACION + Constants.URL_SERVICE.REPRESENTANTES.DESCARGAR}`;
    return this.http.get(url,
      {
        responseType: 'blob',
        observe: 'events',
        reportProgress: true,
        params: params
      })
  }

  obtenerArchivo(archivo: string){
    let header = new HttpHeaders();
    const url = `${Constants.BASE_PORTAFOLIO}/respuesta`;
    header = header.set('Authorization','Bearer ');
    return this.http.get(url, {headers: header, params: {archivo: archivo}});
  }

}
