import { Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/base/services/auth.service';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { PaginationOptions } from 'src/app/registros/models/paginationOptions';
import { RepresentanteLegal } from 'src/app/registros/models/representanteLegal';
import { RepresentanteService } from 'src/app/registros/services/representante.service';
import Swal from 'sweetalert2';
import {
  Ripple,
  initTE,
} from "tw-elements";
import { Toast } from '../../../../base/services/toast';

export interface IRepresentanteLegal {
  rut: number;
  rbd: number;
  nombreEstablecimiento: string;
  rutEmpresa: number;
  nombreEmpresa: string;
  tipo: string;
  nombres: string;
  primerApellido: string;
  segundoApellido: string;
  correo: string;
  telefono: number;
}

@Component({
  selector: 'app-representantes-legales',
  templateUrl: './representantes-legales.component.html',
  styleUrls: ['./representantes-legales.component.css']
})
export class RepresentantesLegalesComponent {
  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ["rut", "rutEmpresa", "nombreEstablecimiento","tipo", "nombres", "primerApellido",
    "segundoApellido", "correo", "telefono", "acciones"];

  filtros: RepresentanteLegal = new RepresentanteLegal();
  inputs: { label: string, attribute: string, type: string }[] = [
    { label: "Rut", attribute: "rut", type: 'number' },
    { label: "Rut Empresa", attribute: "rutEmpresa", type: 'number' },
    { label: "RBD", attribute: "rbd", type: 'number' },
  ];
  inputsRepresentante: { label: string, attribute: string, type: string }[] = [
    { label: "Nombres", attribute: "nombres", type: 'string' },
    { label: "Primer Apellido", attribute: "primerApellido", type: 'string' },
    { label: "Segundo Apellido", attribute: "segundoApellido", type: 'string' },
  ];
  selected!: RepresentanteLegal;
  isLoading: boolean = false;
  options: PaginationOptions = new PaginationOptions();
  dataSource: MatTableDataSource<IRepresentanteLegal> = new MatTableDataSource();
  response: any;
  pageIndex: number;
  length: number;

  acciones!: boolean;
  agregarRepresentante: boolean = false;
  editarRepresentante: boolean = false;
  borrarRepresentante: boolean = false;

  constructor(
    public representanteService: RepresentanteService,
    private router: Router,
    private authService: AuthService,
    private permisoPara: AutorizacionesService,
    private errorMensaje: ErrorMensajeService) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    initTE({ Ripple });
    this.agregarRepresentante = this.permisoPara.agregarRepresentanteLegal();
    this.editarRepresentante = this.permisoPara.editarRepresentanteLegal();
    this.borrarRepresentante = this.permisoPara.eliminarRepresentanteLegal();
    this.acciones = this.agregarRepresentante && this.editarRepresentante && this.borrarRepresentante;
    this.representanteService.estaDescargandoExcelEmmit.subscribe((estado: boolean) => {
      this.estaDescargandoExcel = estado;
    });
    this.filtrar();
  }

  containRol(roles: any) {
    return this.authService.containRol(roles);
  }

  onInputChange(value: any, type: string, attributeName: string) {
    if (type == 'number' && value && value instanceof String) {
      value = value.replace(/^\d*[0-9]\d*$/, "");
      value = Number(value)
    }
    this.filtros[attributeName] = value;
  }

  lanzarAgregarNuevoRepresentanteLegal(): void {
    this.selected = new RepresentanteLegal();
  }

  filtrar() {
    this.isLoading = true;
    this.representanteService.getRepresentantesLegalesFiltro(this.options,
      this.filtros.rut,
      this.filtros.rbd,
      this.filtros.rutEmpresa,
      this.filtros.nombres,
      this.filtros.primerApellido,
      this.filtros.segundoApellido).subscribe(
        {
          next: response => {
            this.dataSource = new MatTableDataSource<IRepresentanteLegal>(response.list);
            this.response = response.list;
            this.pageIndex = (response.pageNum - 1);
            this.length = response.total;
            this.isLoading = false;
          }, error: (error: any) => this.errorMensaje.show(error, "obtener los Representantes Legales").then((result:any) => {
            if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
              this.isLoading = false;
            }
          })
        });
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.filtrar();
  }

  limpiar() {
    this.filtros = new RepresentanteLegal();
    this.filtrar();
  }

  cambiarVista(valor: boolean) {
    if (!valor) {
      this.selected = null;
      this.filtrar();
    }
  }

  editar(element: RepresentanteLegal) {
    this.selected = element;
  }

  eliminar(rutRepresentante: number, rutEmpresa: number) {
    Swal.fire({
      title: '¿Desea Eliminar este Representante Legal?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarRepresentante(rutRepresentante, rutEmpresa)
      }
    })
  }

  eliminarRepresentante(rut: number, rutEmpresa: number) {
    this.representanteService.deleteRepresentanteLegal(rut, rutEmpresa)
      .subscribe(
        {
          next: (response: any) => {
            Toast.fire(
              "Representante legal eliminado",
              "",
              "success"
            );
            this.filtrar();
          },
          error: (error: any) => {
            this.errorMensaje.show(error, "eliminando el representante legal");
          }
        }
      );
  }

  descargarCSVRepresentantes() {
    const filename = 'representantesLegales.csv';
    this.estaDescargandoExcel = true;
    this.representanteService.descargaRepresentantesLegales(this.filtros.rut,
      this.filtros.rbd,
      this.filtros.rutEmpresa,
      this.filtros.nombres,
      this.filtros.primerApellido,
      this.filtros.segundoApellido)
      .subscribe(

          value => {
            this.estaDescargandoExcel = true;
            if (value.type === HttpEventType.Response) {
              const blob = new Blob([value.body], { type: 'text/csv' });
              const downloadUrl = URL.createObjectURL(blob);
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.setAttribute('style', 'display: none');
              a.href = downloadUrl;
              a.download = filename;
              a.click();
              URL.revokeObjectURL(downloadUrl);
              a.remove();
              Toast.fire("Descarga Finalizada", "La descarga de representantes ha finalizado.", "success");
            }
            this.estaDescargandoExcel = false;
          },
          error => {}

      );

  }

  descargar() {
    this.filtrar();
    this.descargarCSVRepresentantes();
  }
}
