import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, IAuthenticationCallback } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { Usuario } from '../../models/usuario';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  typePassword: string = "password";
  isPasswordVisible!: boolean;
  authFail: boolean = false;
  usuario: Usuario;
  msjeError!: string;
  loading!: boolean;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.usuario = new Usuario();
  }

  visualizacionPassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.typePassword = this.isPasswordVisible ? "text" : "password";
  }

  iniciaSesion():void{
    this.loading = true;
    this.msjeError = "";
    if(!this.usuario.username){
      this.msjeError = "Falta su correo"
    }
    if(!this.usuario.password ){
      if (this.msjeError) this.msjeError = this.msjeError + " y contraseña."
      else this.msjeError = "Falta su contraseña"
    }
    if(!this.usuario.username || !this.usuario.password ){
      this.authFail = true;
      this.loading = false;
      return;
    }

    var poolData = {
      UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
      ClientId: environment.cognitoClietId, // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    // datos del user
    var userData = {
      Username: this.usuario.username,
      Pool: userPool,
      Storage: sessionStorage
    }
    var cognitoUser = new CognitoUser(userData);
    // credenciales
    var authData = {
      Username: this.usuario.username,
      Password: this.usuario.password
    }
    var authDetails = new AuthenticationDetails(authData);
    // login
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        this.authFail = false;
        this.router.navigate(['private']);
        this.loading = false;
      },
      onFailure: (err) => {
        this.authFail = true;
        this.loading = false;
        this.msjeError = "Sus credenciales son incorrectas, reintente."
      },
    });
    //var

  }

}
