import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../base/constants/constants';
import { Portafolio } from '../models/portafolio';
@Injectable({
    providedIn: 'root'
  })
export class CierreProcesoService {
    constructor(
        private http: HttpClient
    ) { }

    getModulos(): Observable<any> {
      const url = Constants.BASE_CIERRE_PROCESOS + "/";
      return this.http.get(url).pipe(map(response => response));
    }

    getPortafolio(modulo: string): Observable<any> {
        const url = Constants.BASE_CIERRE_PROCESOS+"/" + modulo;
        return this.http.get(url).pipe(map(response => response));
    }

    editarPortafolio(data: Portafolio): Observable<any> {
        const url = Constants.BASE_CIERRE_PROCESOS + "/";
        return this.http.put(url, data)
            .pipe(map(response => response));
    }
}
