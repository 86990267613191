<mat-card class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2"  #FILTRO style="flex-direction: row">

    <div class="flex flex-col gap-4 px-2">
      <p class="text-blue-950 text-2xl font-bold">Avance Portafolio</p>
      <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
        <div class="flex flex-row gap-1 py-2">
          <mat-icon>filter_list</mat-icon>
          <p class="text-lg font-bold">Filtros</p>
        </div>

        <div class="row" aria-label="sección de inputs">
          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label>RUT Docente</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutDocente" name="rutDocente" />
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label>RUT Sostenedor</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutSostenedor" name="rutSostenedor" />
            </mat-form-field>
          </div>
          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label>RBD</mat-label>
              <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345" class="fullWidth" [(ngModel)]="filtros.rbd" />

            </mat-form-field>
          </div>


        </div>

        <div class="d-flex" aria-describedby="footer filtro">
          <div aria-labelledby="botones inf. izq" class="float-left d-flex  align-self-start ">
            <mat-card-actions class="d-flex row">
              <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center uppercase items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-sm font-medium  text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>
              <button
                  matTooltip="Descarga esta tabla completa"
                  type="button"
                  data-te-ripple-init
                  (click)="descargarExcel()"
                  data-te-ripple-color="light"
                  class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                  <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                  <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                  <div *ngIf="!estaDescargandoExcel" class="text-[14px]">Descargar</div>
                  <div *ngIf="estaDescargandoExcel" class="text-[14px]">Descargando</div>
              </button>
              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center uppercase ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-sm font-medium  text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                <p class="pr-1"></p> Limpiar
              </button>
            </mat-card-actions>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div #TABLA class="overflow-x-scroll rounded-2xl shadow-2 border border-gray-200 m-2 p-2" aria-label="sección tabla" >
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
    <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50" role="table">
      <thead class="bg-blue-700 ">
        <tr>
          <th scope="col" class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black " > Rut del Docente</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">Nombre Completo</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black max-w-[210px]">Establecimiento, Sostenedor y Director</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black max-w-[160px]">Fechas de Respuestas</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">Módulo 1 <br> Planificación</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">Módulo 1 <br> Evaluación</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">Módulo 1 <br> Reflexión</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">Módulo 2 <br> Clase grabada</th>
          <th scope="col" class="rounded-tr-2xl pr-4 py-3 text-left text-blue-100 text-sm font-black " >Módulo 3 <br> Trabajo Colaborativo</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr class="min-w-full" *ngFor="let element of portafolios">
          <td class="negrita-tabla" #rut>
            <div class="min-w-[100px] pl-1">
              <!-- (click)="obtenerDocente(element)" data-bs-toggle="modal" data-bs-target="#modalInformacionDelDocente"  -->
              <button type="button"
                class="inline-block rounded-xl border-2 border-primary items-center w-full pb-[6px] pt-2 text-sm font-bold  text-primary transition duration-150
                ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600
                focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                matTooltip="Copiar {{element.rutDocente}}"
                (click)="copyText(element.rutDocente)"
                >
                {{ element.rutDocente +'-'+ element.docente.dvDocente}}</button>
              </div>
          </td>
          <td class="negrita-tabla" #rut>
            <div class="flex flex-col">
              <p class="text-xs uppercase">{{element.docente.nombres ? element.docente.nombres :'-'}}</p>
              <p class="text-xs uppercase">{{ element.docente.primerApellido? element.docente.primerApellido : '-' }}</p>
              <p class="text-xs uppercase">{{element.docente.segundoApellido ? element.docente.segundoApellido : '-' }}</p>
            </div>
          </td>
          <td class="negrita-tabla" #ESTABLECIMIENTO class=" max-w-[210px]">
            <div class="flex flex-col ">
              <p class="break-words capitalize text-xs">{{element.docente.nombreEstablecimiento}}</p>
              <div class="flex flex-row gap-2 py-1 items-center">
                <span class="rounded-full border-2 border-blue-800 text-blue-800 text-xs font-bold px-2 flex items-center">RBD</span>
                <p class="truncate-cell capitalize text-md font-medium text-gray-600 flex items-center justify-center">
                  {{element.docente.rbd}}
                </p>
              </div>
              <div #sostenedor class="items-start text-xs">
                <span class="rounded-2xl py-2 mr-4 break-words border-2 border-blue-700 text-black px-2 flex flex-col gap-1">
                  <p class="uppercase text-xs font-bold text-blue-800">Sostenedor/a</p>
                  <p class="truncate capitalize text-md font-medium text-gray-600">
                    {{element.sostenedores[0].rutSostenedor}}
                  </p>
                  <select name="sostenedores" class="border-2 border-slate-700 py-1 rounded-xl pl-2 text-xs" *ngIf="element.sostenedores.length > 1">
                    <option *ngFor="let item of element.sostenedores; let i=index" value={{item.emailSostenedor}} class="text-xs">
                      {{item.emailSostenedor}}
                    </option>
                  </select>
                  <p *ngIf="element.sostenedores.length == 1" class="uppercase text-[10px] max-w-fit overflow-hidden text-ellipsis whitespace-nowrap" matTooltip={{element.sostenedores[0].emailSostenedor}}>
                    {{element.sostenedores[0].emailSostenedor}}
                  </p>
                </span>
              </div>

              <div #director class="items-start text-xs py-1">
                <span class="rounded-2xl mr-4 py-2 break-words border-2 border-blue-700 text-black px-2 flex flex-col gap-1">
                  <p class="uppercase text-xs font-bold text-blue-800">Director/a</p>
                  <p class="truncate capitalize text-md font-medium text-gray-600">
                    {{element.docente.rutDirector}}
                  </p>
                  <p class="uppercase text-[10px] max-w-fit overflow-hidden text-ellipsis whitespace-nowrap" matTooltip={{element.docente.emailDirector}}>
                    {{element.docente.emailDirector}}
                  </p>
                </span>
              </div>

            </div>
          </td>
          <td class="negrita-tabla" #fechas>
            <div class="flex flex-col max-w-[130px]" class="text-sm ">
              <div class="text-xs font-bold">Fecha de Creación PF</div>
              {{element.docente.creacionPortafolio | date:' dd-MM-yyyy HH:mm:ss'}}

              <div class="text-xs font-bold text-slate-800 py-1">Fecha de últ. guardado</div>
              {{element.docente.actualizacionPortafolio | date:' dd-MM-yyyy HH:mm:ss'}}
            </div>
          </td>
          <td #m11 class="text-sm">
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m11_total, text: 'en Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m11_respondidas, text: 'Respondidas'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m11_opcional_total, text: 'Opcional Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m11_opcional_respondidas, text: 'Opc. Respondidas'}" ></ng-container>
          </td>

          <td #m12 class="text-sm">
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m12_total, text: 'en Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m12_respondidas, text: 'Respondidas'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m12_opcional_total, text: 'Opcional Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m12_opcional_respondidas, text: 'Opc. Respondidas'}" ></ng-container>
          </td>

          <td #m13 class="text-sm">
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m13_total, text: 'en Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m13_respondidas, text: 'Respondidas'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m13_opcional_total, text: 'Opcional Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m13_opcional_respondidas, text: 'Opc. Respondidas'}" ></ng-container>
          </td>

          <td #m2 class="text-sm">
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m2_total, text: 'en Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m2_respondidas, text: 'Respondidas'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m2_opcional_total, text: 'Opcional Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m2_opcional_respondidas, text: 'Opc. Respondidas'}" ></ng-container>
          </td>

          <td #m3 class="text-sm">
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m3_total, text: 'en Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m3_respondidas, text: 'Respondidas'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m3_opcional_total, text: 'Opcional Total'}" ></ng-container>
            <ng-container *ngTemplateOutlet="respuestasModulo; context:{dato:element.docente.m3_opcional_respondidas, text: 'Opc. Respondidas'}" ></ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 30, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
  </mat-paginator>
</mat-card>

<ng-template #respuestasModulo
  let-dato="dato" let-texto="text">
  <p class='text-sm font-bold text-blue-800' *ngIf="dato > 0">{{dato}} {{texto}}</p>
  <p class="text-sm text-slate-500" *ngIf="!(dato > 0)">0 {{texto}}</p>
</ng-template>


<!-- Modal Docente -->

<div class="modal fade" id="modalInformacionDelDocente" tabindex="-1"
  aria-labelledby="modalInformacionDelDocenteLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="flex flex-col" *ngIf="docenteSeleccionadoModal">
          <h1 class="modal-title fs-5" id="modalInformacionDelDocenteLabel">Detalles extendidos del docente <b>{{docenteSeleccionadoModal.rutDocente}}</b></h1>
          <!-- <div class="text-left text-md flex flex-row gap-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M10 11V8l5 4-5 4v-3H1v-2h9zm-7.542 4h2.124A8.003 8.003 0 0 0 20 12 8 8 0 0 0 4.582 9H2.458C3.732 4.943 7.522 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-4.478 0-8.268-2.943-9.542-7z"/> </g> </svg>
            <p *ngIf="docenteSeleccionadoModal.ultimoLogin && docenteSeleccionadoModal.ultimoLogin.includes('-')">Últ. Ingreso: {{docenteSeleccionadoModal.ultimoLogin | date:'dd-MM-yyyy HH:mm:ss'}}</p>
            <p *ngIf="!docenteSeleccionadoModal.ultimoLogin">Últ Ingreso: No ha ingresado a la plataforma</p>
            <p *ngIf="docenteSeleccionadoModal.ultimoLogin && !docenteSeleccionadoModal.ultimoLogin.includes('-')">Últ. Ingreso: {{docenteSeleccionadoModal.ultimoLogin}} </p>
          </div> -->
        </div>
        <button type="button"  data-bs-dismiss="modal" aria-label="Close">✕</button>
      </div>
      <div class="modal-body" *ngIf="mostrarInfoDocente && acordeonesInfoDocente.length>0">

        <acordeon-tw
          [accordeonParent]="acordeonesInfoDocenteName"
          [acordeones]="acordeonesInfoDocente"></acordeon-tw>
      </div>
    </div>
  </div>
</div>

<ng-template #infoDatosPersonales
  let-personalDataSection="personalDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <div class="" *ngIf="personalDataSection">
    <table style="width:100%;" role="table" aria-label="info datos personales">
      <tr *ngFor="let item of personalDataSection">
        <th>
          {{item.value}}
        </th>
        <td *ngIf="item.key != 'apellidos'" style="width:fit-content">{{docenteSeleccionadoModal[item.key]}}
        </td>
        <td *ngIf="item.key == 'apellidos'" style="width:fit-content">
          {{docenteSeleccionadoModal['primerApellido']}} {{docenteSeleccionadoModal['segundoApellido']}}
        </td>
      </tr>
    </table>
  </div>
</ng-template>


<ng-template #infoDatosEstablecimiento
  let-establishmentDataSection="establishmentDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla info establecimiento">
    <tr *ngFor="let item of establishmentDataSection">
      <th>
        {{item.value}}
      </th>
      <td class="break-words text-wrap">{{docenteSeleccionadoModal[item.key]}}</td>
    </tr>
  </table>
</ng-template>

<ng-template #infoDatosInscripcion
  let-inscriptionDataSection="inscriptionDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla info inscripción">
    <tr *ngFor="let item of inscriptionDataSection">
      <th>
        {{item.value}}
      </th>
      <td *ngIf="['codNivel','codSubsector'].includes(item.key)" class="flex flex-col gap-1 pb-2">
        <p *ngIf="item.key=='codNivel'" class="text-xs text-slate-700 cursor-zoom-in text-ellipsis" title="{{docenteSeleccionadoModal['nombreNivelPrevio']}}"> {{docenteSeleccionadoModal['nombreNivelPrevio']}} </p>
        <p *ngIf="item.key=='codSubsector'" class="text-xs text-slate-700 cursor-zoom-in text-ellipsis" title="{{docenteSeleccionadoModal['nombreSubsectorPrevio']}}"> {{docenteSeleccionadoModal['nombreSubsectorPrevio']}} </p>
        <p class="text-sm"> {{docenteSeleccionadoModal[item.key]}} </p>
      </td>
      <td *ngIf="!['codNivel','codSubsector'].includes(item.key)" class="pb-2">
        <p *ngIf="item.value.includes('Fecha')">{{docenteSeleccionadoModal[item.key] | date:'dd-MM-yyyy HH:mm:ss' }}</p>
        <p *ngIf="!item.value.includes('Fecha')" class="text-wrap break-words">{{docenteSeleccionadoModal[item.key]}}</p>
      </td>

    </tr>
  </table>
</ng-template>


<ng-template #infoValidacion
  let-validationDataSection = "validationDataSection"
  let-esFecha = "esFecha"
  let-docenteSeleccionadoModal = "docenteSeleccionadoModal"
  >
  <div class="border-2 border-slate-600 rounded-xl p-4 ">
    <table style="width:100%;" role="table" aria-label="info tabla validacion" >
      <tr *ngFor="let item of validationDataSection" >
        <th #header class="w-4/6">
          <p  class="font-bold">{{item.value}}</p>
        </th>

        <!-- <div *ngIf="docenteSeleccionadoModal[item.key] && ['Pendiente'].includes(docenteSeleccionadoModal[item.key] ) " class="text-gray-400"><img width="24" height="24" src="../../../../assets/images/content/pending.svg" alt="pending"></div>
 -->

        <td #iconos class="px-4">
          <div  *ngIf="item.key == 'estadoValidacion'" >
              <svg *ngIf="datosDocenteValidados() && docenteSeleccionadoModal[item.key] && ['Aceptado', 'Validado'].includes(docenteSeleccionadoModal[item.key])" width="16" height="16" style="color: rgb(51, 215, 138);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" fill="#33d78a"></path></svg>
              <svg *ngIf="!datosDocenteValidados() || docenteSeleccionadoModal[item.key] && ['No Validado', 'Rechazado'].includes(docenteSeleccionadoModal[item.key] ) " width="16" height="16" style="color: red;" width="20" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="#c43b0e"></path> </svg>
          </div>
        </td>


        <div #celdaDatosValidacion class="pb-1">
          <div  *ngIf="item.key == 'estadoValidacion'">
            <td>
              <p class="text-md font-bold">{{ datosDocenteValidados() ? docenteSeleccionadoModal[item.key] : "No Validado"}}</p>
              <p *ngIf="historialValidacion" class="text-sm">
                {{!datosDocenteValidados() ? "Actualizado fuera de plazo" : "Datos Actualizados"}}
              </p>
              <p *ngIf="!historialValidacion" class="text-sm">
                Docente no actualiza sus datos
              </p>
            </td>
          </div>

          <div  *ngIf="item.key == 'fechaValidacion'" class="pb-2" >
            <td>
              {{docenteSeleccionadoModal[item.key] | date:'dd-MM-yyyy HH:mm:ss' }} {{!docenteSeleccionadoModal[item.key] ? "Docente no ha validado": ""}}
            </td>
          </div>

          <div  *ngIf="item.key == 'agnoPortafolioCorregido'" class="flex flex-row gap-2"  >
            <td class="text-sm">{{docenteSeleccionadoModal[item.key] ? docenteSeleccionadoModal[item.key] : 'Sin portafolios corregidos'}}</td>
          </div>

        </div>
      </tr>
    </table>
  </div>
</ng-template>

<ng-template #infoEximirSuspender
  let-exemptSuspendDataSection="exemptSuspendDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla info eximir suspender">
    <tr *ngFor="let item of exemptSuspendDataSection; let i=index" class="pb-4 border-0">
      <th>
        {{item.value}}
      </th>
      <td *ngIf="i<=1" class="text-md">{{docenteSeleccionadoModal[item.key] | date:'dd-MM-yyyy HH:mm:ss'}}</td>
      <td *ngIf="i>1" class="break-words text-wrap text-md">{{docenteSeleccionadoModal[item.key]}}</td>
    </tr>
  </table>
</ng-template>


<!-- Modal Tareas-->

<div class="modal fade" id="modalTareas" tabindex="-1"
  aria-labelledby="modalInformacionDelDocenteLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="flex flex-col">
          <h1 class="modal-title fs-5" id="modalInformacionDelDocenteLabel">Búsqueda de Preguntas de Tareas</h1>
          <button type="button"  data-bs-dismiss="modal" aria-label="Close">✕</button>
        </div>
      </div>
      <div class="modal-body">

        <div class="row" aria-label="sección de inputs">
          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label>Búsqueda de Pregunta</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutDocente" name="pregunta" />
            </mat-form-field>
          </div>
      </div>

        <div class="d-flex" aria-describedby="footer filtro">
          <div aria-labelledby="botones inf. izq" class="float-left d-flex  align-self-start ">
            <mat-card-actions class="d-flex row">
              <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-sm font-medium  text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>
              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-sm font-medium  text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                <p class="pr-1"></p> Limpiar
              </button>
            </mat-card-actions>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
