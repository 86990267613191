import { ChangeDetectorRef, Component, Input, NgZone, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'lootie-animation',
  templateUrl: './lootie-animations.component.html',
  standalone: true,
  imports: [LottieComponent],
})
export class LootieAnimationsComponent implements AfterViewInit{
  @Input("file") file!: string;
  @Input("height") height: string = "50vh";
  optionsLootie: AnimationOptions = {
    path: '../../../../../../assets/lotties/lost.json',
  };

  onLoopCompleteCalledTimes = 0;

  constructor(
    private ngZone: NgZone,
    private ref: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngAfterViewInit(){
    if(!this.file) return;
    this.optionsLootie = {
      path: '../../../../../../assets/lotties/' + this.file,
    };
  }

  onLoopComplete(): void {
    this.ngZone.run(() => {
      this.onLoopCompleteCalledTimes++;
      this.ref.detectChanges();
    });
  }
}
