<mat-card class="px-8 pt-4 flex flex-col" style="box-shadow: none !important; overflow-x: hidden;">

    <div class="h-auto">
        <div class="flex flex-row gap-2">
            <h3 class="py-4 text-black"> Cierre de Procesos Docentemás </h3>
            <button matTooltip="Ayuda sobre este módulo" (click)="openHelper()">
                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
                        fill="#0D0D0D" />
                    <path
                        d="M12 14a1 1 0 0 1-1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 1-1 1zm-1.5 2.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
                        fill="#0D0D0D" />
                    <path
                        d="M12.39 7.811c-.957-.045-1.76.49-1.904 1.353a1 1 0 0 1-1.972-.328c.356-2.136 2.303-3.102 3.971-3.022.854.04 1.733.347 2.409.979C15.587 7.44 16 8.368 16 9.5c0 1.291-.508 2.249-1.383 2.832-.803.535-1.788.668-2.617.668a1 1 0 1 1 0-2c.67 0 1.186-.117 1.508-.332.25-.167.492-.46.492-1.168 0-.618-.212-1.003-.472-1.246-.277-.259-.68-.42-1.138-.443z"
                        fill="#0D0D0D" />
                </svg>
            </button>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
        <div *ngIf="help" class="rounded-[8px] bg-[#E3EBFF] px-4 py-6 h-20 ">
            <div class="flex flex-row gap-2 items-center float-start items-center">
                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
                        fill="#0D0D0D" />
                    <path
                        d="M12 14a1 1 0 0 1-1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 1-1 1zm-1.5 2.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
                        fill="#0D0D0D" />
                    <path
                        d="M12.39 7.811c-.957-.045-1.76.49-1.904 1.353a1 1 0 0 1-1.972-.328c.356-2.136 2.303-3.102 3.971-3.022.854.04 1.733.347 2.409.979C15.587 7.44 16 8.368 16 9.5c0 1.291-.508 2.249-1.383 2.832-.803.535-1.788.668-2.617.668a1 1 0 1 1 0-2c.67 0 1.186-.117 1.508-.332.25-.167.492-.46.492-1.168 0-.618-.212-1.003-.472-1.246-.277-.259-.68-.42-1.138-.443z"
                        fill="#0D0D0D" />
                </svg>
                Este módulo es un gestor de procesos de Docentemás que modifica alertas de los componentes internos de
                la plataforma pública.
            </div>

            <button class="flex float-right items-center" (click)="closeHelper()">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
            </button>
        </div>
    </div>

    <div class="flex lg:flex-row xs:flex-col xl:gap-8 lg:gap-8 h-auto my-2 min-h-max">
        <div aria-label="Lista de procesos" class="lg:w-[20%] xs:w-[80%] py-2 px-4 rounded-[8px] border-gray-200 border-2">
            <h3 class="lg:pt-8 lg:pb-10" >Lista de Procesos</h3>

            <div class="flex flex-col gap-1">
              <div *ngFor="let modulo of procesos">
                <button  (click)="getModulo(modulo)"
                  [disabled]="estaEditando"
                  [ngClass]="{'border-l-blue-300 border-l-[12px] text-md': menuActivo == '{{modulo.split(` `).join(`_`)}}'}"
                  class="d-flex w-full h-14 hover:border-b-2 hover:border-b-warning-100  lg:mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 px-4 text-xs uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                  {{modulo.split('_').join(' ')}}
                </button>
              </div>

            </div>
        </div>

        <div aria-label="Detalles de Proceso" class="w-[80%] py-2 px-8 rounded-[8px] border-gray-200 border-2">
            <mat-progress-bar class="rounded-[8px]" mode="indeterminate" *ngIf="isLoadingPortafolio" />
            <div *ngIf="portafolio">
                <div #estado>
                    <h3 class="pt-4 uppercase"> {{menuActivo.split('_').join(' ')}}</h3>
                    <h3 class="py-4 text-slate-600">Estado</h3>
                    <select
                        class="w-full px-8 bg-slate-100 rounded-[8px] py-2 border-2 border-slate-300 active:border-blue-600"
                        name="" id="" placeholder="Selecciona o crea un estado" [(ngModel)]="portafolio.idStatus">
                        <option value="0">READ_ONLY</option>
                        <option value="1">EDIT</option>
                    </select>
                    <p class="flex flex-row gap-1 justify-start items-center text-slate-600">Se guardarán sus cambios al
                        accionar <svg class="px-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" class="bi bi-cloud-check-fill" viewBox="0 0 16 16">
                            <path
                                d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 4.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                        </svg></p>
                </div>

                <div #mensaje>
                    <h3 class="py-4 text-slate-600" >Mensaje</h3>
                    <!-- <textarea class="border-2 w-full" name="" id="" [(ngModel)]="portafolio.message" rows="6"> </textarea> -->
                    <div class="NgxEditor__Wrapper rounded-[8px]">
                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                        </ngx-editor-menu>
                        <ngx-editor [editor]="editor" [(ngModel)]="portafolio.message" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
                      </div>
                </div>

                <div #previsualizacion>
                    <h3 class="pt-4 text-slate-600" >Previsualización</h3>
                    <div class="min-h-20 w-full p-4 my-4 bg-[#E3EBFF] rounded-xl">
                        <div class="text-center" #preview></div>
                    </div>
                </div>

                <button (click)="portafolio && guardar()"
                    class="d-flex gap-2 w-40 float-right h-12  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[16px]   uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                    <svg *ngIf="!estaEditando" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                        class="bi bi-cloud-check-fill" viewBox="0 0 16 16">
                        <path
                            d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 4.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                    </svg>
                    <fa-icon *ngIf="estaEditando" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                    GUARDAR
                </button>
            </div>

            <div *ngIf="!portafolio">
                <h3 class="pt-8 pb-10">Detalles de Proceso</h3>
                <p class="">Seleccione un proceso a visualizar.</p>
            </div>
        </div>
    </div>
</mat-card>
